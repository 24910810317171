import * as React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'

// import styles
import './styles.css'

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const introHelperStyles = {
  width: 320,
  padding: 16,
  borderRadius: 8
}

export interface IntroCreatorSteps {
  id: string;
  title: string;
  description: string;
  action?: {
    title: string;
    onPress: () => void;
  };
  onSkip?: () => void;
}

export interface IntroCreatorProps {
  steps: IntroCreatorSteps[],
}

const IntroCreator: React.FC<IntroCreatorProps> = ({
  steps
}) => {
  const [curStep, setCurStep] = React.useState(0)
  const [curTarget, setCurTarget] = React.useState<any>(null)
  const boundingBoxRef = React.useRef(null)
  const [timeStamp, setTimeStamp] = React.useState(Date.now())

  const getElementById = async (id: string): Promise<any> => {
    let count = 0
    while (true) {
      count = count + 1
      const element = document.getElementById(id)
      if (element || count > 4) {
        return element
      } else {
        await sleep(1000)
      }
    }
  }

  const getBoxPositionStyles = (element) => {
    const boundingElement = element.getBoundingClientRect()
    // window.scrollTo(0, boundingElement.bottom + 20)
    return {
      width: `${boundingElement.width + 8}px`,
      height: `${boundingElement.height + 8}px`,
      top: `${boundingElement.top - 44 / 2}px`,
      left: `${boundingElement.left - 44 / 2}px`
    }
  }

  const getIntroHelperPositionStyles = (element) => {
    const offset = 20
    const boundingElement = element.getBoundingClientRect()
    const elementOffsetWidth = introHelperStyles.width + (introHelperStyles.padding * 2) + offset

    if (boundingElement.left - elementOffsetWidth > 0) {
      return {
        top: `${boundingElement.top}px`,
        left: `${boundingElement.left - elementOffsetWidth}px`
      }
    } else if (boundingElement.left + elementOffsetWidth < window.innerWidth) {
      return {
        top: `${boundingElement.top}px`,
        left: `${boundingElement.left + elementOffsetWidth - offset}px`
      }
    }
  }

  React.useEffect(() => {
    (async () => {
      if (curTarget) {
        curTarget.style.zIndex = '9'
      }
      const curElement = await getElementById(steps[curStep].id)
      setCurTarget(curElement)

      // initial render gives wrong position for anchor
      setTimeout(() => {
        setTimeStamp(Date.now())
      }, 500)
    })()
  }, [curStep])

  if (!curTarget || steps.length < curStep + 1) {
    return null
  } else {
    curTarget.style.position = 'relative'
    curTarget.style.borderRadius = '8px'
    curTarget.style.zIndex = '999999'
  }

  return (
    <div key={timeStamp}>
      <div className="intro-overlay"></div>
      <Box
        ref={boundingBoxRef}
        aria-controls="user-controls"
        style={{
          backgroundColor: 'transparent',
          border: '2px solid #00a7ff',
          borderRadius: 16,
          position: 'absolute',
          margin: 16,
          zIndex: '99999999 !important',
          ...getBoxPositionStyles(curTarget)
        }}
      />
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{
          position: 'absolute',
          zIndex: '999999',
          ...getIntroHelperPositionStyles(curTarget),
          background: 'white',
          overflowY: 'auto',
          ...introHelperStyles
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            marginBottom: 2
          }}
        >
          <Typography variant="h4" color="secondary">
            {steps[curStep].title}
          </Typography>
          <Typography variant="caption" style={{ color: 'black' }}>
            {steps[curStep].description}
          </Typography>
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{
            width: '100%'
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              setCurStep(steps.length)
            }}
          >
            Skip
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              if (steps[curStep].action?.onPress) {
                steps[curStep].action.onPress()
              }
              setCurStep(curStep + 1)
            }}
          >
            {steps[curStep].action ? steps[curStep].action.title : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </div>
  )
}

export default IntroCreator
