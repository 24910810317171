import axios from 'axios'

/**
 * @function businessSubscriptions Used to fetch all subscription details
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessSubscriptions = (nrctUID, jwtToken) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/subscription`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwtToken}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
