import {
  Stack,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React from 'react'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import { formatDistance } from 'date-fns'

// importing ui
import WideModal from 'ui/Modals/WideModal'

// importing api
import { businessPaymentSubscriptionCancel } from 'api/business/payment/subscription'
import { businessPaymentTransactionHistory } from 'api/business/payment/transaction'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

// importing redux store
import { useAppDispatch, useAppSelector } from '../../../Utils/redux/store'
import { updateUserSubscriptionDetailsInRedux } from '../../../Utils/redux/actions/subscriptionAction'

// importing components
import './index.css'
import { UserContext } from '../../../Context/UserContext'
import { GlobalFuncContext } from '../../../Context/GlobalFuncHOC'

export interface UpgradePlanPaymentsProps {
  open: boolean;
  onClose: () => void;
}

const SettingsModal: React.FC<UpgradePlanPaymentsProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const reduxDispatch = useAppDispatch()

  // context
  const { business, jwtToken, activeBusiness } = React.useContext(UserContext)
  const { openDialog, openUpgradePayment } = React.useContext(GlobalFuncContext)

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const endDate = subscriptionState.subscription.subscriptionEndDate
  const planName = subscriptionState.subscription.planName
  const isPlanCancelled = subscriptionState.subscription.isPlanCancelled

  // component states
  const [section, setSection] = React.useState<'payments'>('payments')
  const [invoices, setInvoices] = React.useState<any>([])

  const _onClose = () => {
    setSection('payments')
    setInvoices([])
    props.onClose()
  }

  const onCancelSubscription = () => {
    businessPaymentSubscriptionCancel(
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resBody) => {
        console.log(resBody)
        enqueueSnackbar('User subscription plan cancelled!', { variant: 'success' })
        reduxDispatch(updateUserSubscriptionDetailsInRedux())
        recordGaEvent('settingsModalCancelledSubscription', {
          plan: planName,
          businessId: business[activeBusiness].business_id
        })
      })
      .catch((err) => console.log(err.message))
  }

  React.useEffect(() => {
    if (props.open && business.length > 0 && jwtToken) {
      businessPaymentTransactionHistory(
        1,
        business[activeBusiness].ID,
        jwtToken
      )
        .then((resBody: any) => {
          setInvoices(resBody.invoices || [])
        })
        .catch((err) => console.log(err.message))
    }
  }, [props.open])

  return (
    <WideModal
      title="Settings"
      isOpen={props.open}
      onClose={_onClose}
    >
      <Stack
        direction="column"
        sx={{
          width: '100%'
        }}
      >
        <ListItemButton sx={{ backgroundColor: '#F7F7FF' }} onClick={() => {}}>
          <ListItemText
            primaryTypographyProps={{
              color: 'primary'
            }}
            primary="Payments & Subscription"
          />
          {section === 'payments' ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={section === 'payments'} unmountOnExit>
          <List component="div" disablePadding>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                marginTop: 1.5,
                marginBottom: 1.5,
                marginLeft: 4
              }}
            >
              <Typography variant="body1" component="span">
                Current Plan: {planName} {isPlanCancelled && `(ends ${formatDistance(new Date(endDate), new Date(), { addSuffix: true })})`}
                <Typography
                  variant="body1"
                  component="span"
                  onClick={() => {
                    openUpgradePayment('Upgrade your current plan!')
                    recordGaEvent('settingsModalChangeCurrentPlanClicked', {
                      currentPlan: planName,
                      businessId: business[activeBusiness].business_id
                    })
                  }}
                  sx={{
                    color: '#0080DC',
                    marginLeft: 2,
                    cursor: 'pointer'
                  }}
                >
                  change
                </Typography>
              </Typography>
            </Stack>
            <Divider />
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                openDialog({
                  title: 'Cancel Subscription',
                  description: "Are you sure that you want to cancel your subscription? You'll lose your reviews, offers, analytics when this billing period ends.",
                  buttonList: [
                    {
                      text: 'Nevermind',
                      color: 'primary',
                      variant: 'contained',
                      onClick: () => {}
                    },
                    {
                      text: 'Cancel Plan',
                      color: 'secondary',
                      variant: 'contained',
                      onClick: onCancelSubscription
                    }
                  ]
                })
                recordGaEvent('settingsModalCancelSubscriptionClicked', {
                  currentPlan: planName,
                  businessId: business[activeBusiness].business_id
                })
              }}
              disabled={isPlanCancelled || planName === 'Free'}
            >
              <ListItemText
                primaryTypographyProps={{
                  color: 'error'
                }}
                primary="Cancel Subscription"
              />
            </ListItemButton>
            <Divider />
            <Stack direction="column" sx={{ marginTop: 1.5, marginBottom: 1.5, marginLeft: 4 }}>
              <Typography variant="body1">
                Invoice
              </Typography>
              {invoices.length > 0
                ? (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Invoice Total</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoices.map((invoice) => {
                            return (
                              <TableRow key={invoice.id}>
                                {/* Why multiple of 1000? Stripe timestamp is from seconds while js uses milliseconds */}
                                <TableCell>{dayjs(invoice.created * 1000).format('MM/DD/YYYY')}</TableCell>
                                <TableCell>{invoice.lines.data[0].metadata.plan_name} Plan</TableCell>
                                <TableCell>${Math.floor(invoice.total / 100)}</TableCell>
                                <TableCell>
                                  <Typography variant="body2" component="span">
                                    {invoice.status.toUpperCase()}
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      sx={{
                                        marginLeft: 1,
                                        color: '#0080DC',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => {
                                        window.open(invoice.invoice_pdf)
                                        recordGaEvent('settingsModalDownloadInvoiceClicked', {
                                          plane: planName,
                                          businessId: business[activeBusiness].business_id,
                                          invoicePdf: invoice.invoice_pdf
                                        })
                                      }}
                                    >
                                      (Download)
                                    </Typography>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                : (
                    <Typography variant="caption">
                      No Invoices Created Yet
                    </Typography>
                  )}
            </Stack>
          </List>
        </Collapse>
      </Stack>
    </WideModal>
  )
}

export default SettingsModal
