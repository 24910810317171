import axios from 'axios'

/**
 * @function businessReviews Get a business's reviews
 * @param {string} businessId - business's ID
 * @param {"latest" || "oldest" || "low-rating" || "high-rating" || "popularity"} sort - type of sort on the list
 * @param {number} page - number of the page
 * @returns {Promise} Promise resolution represents list of posts on success
 */
export const businessReviews = (businessId, sort, page, offerId = null, nrctUID, jwtToken) => {
  return new Promise((resolve, reject) => {
    const params = {
      business_id: businessId,
      sort: sort,
      page: page
    }
    if (offerId !== null) {
      params.offer_id = offerId
    }
    axios
      .get(
        `${process.env.REACT_APP_BUSINESS_URL}/business/reviews`, {
          params: params,
          headers: {
            'NRCT-UID': nrctUID,
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then((res) => {
        resolve({
          ...res.data,
          body: res.data.body || []
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessReviewsHidden Get a business's reviews hidden
 * @param {string} businessId - business's ID
 * @param {"latest" || "oldest" || "low-rating" || "high-rating" || "popularity"} sort - type of sort on the list
 * @param {number} page - number of the page
 * @returns {Promise} Promise resolution represents list of posts on success
 */
export const businessReviewsHidden = (businessId, sort, page, offerId = null, nrctUID, jwtToken) => {
  return new Promise((resolve, reject) => {
    const params = {
      business_id: businessId,
      sort: sort,
      page: page
    }
    if (offerId !== null) {
      params.offer_id = offerId
    }
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/reviews/hidden`, {
      params: params,
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwtToken}`
      }
    }
    )
      .then((res) => {
        resolve({
          ...res.data,
          body: res.data.body || []
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessReviewsForUserPortal Get a business's reviews on user portal
 * @param {string} businessId - business's ID
 * @param {"latest" || "oldest" || "low-rating" || "high-rating" || "popularity"} sort - type of sort on the list
 * @param {number} page - number of the page
 * @returns {Promise} Promise resolution represents list of posts on success
 */
export const businessReviewsForUserPortal = (businessId, sort, page, offerId = null) => {
  return new Promise((resolve, reject) => {
    const params = {
      business_id: businessId,
      sort: sort,
      page: page
    }
    if (offerId !== null) {
      params.offer_id = offerId
    }
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/reviews/userfacing`, {
      params: params
    })
      .then((res) => {
        resolve({
          ...res.data,
          body: res.data.body || []
        })
      })
      .catch((err) => {
        reject(err)
      })
  })
}
