import { Mail, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputBase
} from '@mui/material'
import axios from 'axios'
import firebase from 'firebase'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import TextField from 'ui/TextInputs/PriceFieldsInput'
import { TextLabel } from 'ui/TextInputs/TextInput'
import '../../../firebase'

function EmailSignup ({ onSuccess, onError, chosen, setChosen, isLogin }) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPass, setShowPass] = useState(false)

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passError, setPassError] = useState('')
  const [userNotFoundOnSignUp, setUserNotFoundOnSignUp] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const validate = (next) => {
    // check name only on signup flow when userNotFoundOnSignUp is true
    if (!isLogin && name.trim().length === 0 && userNotFoundOnSignUp) {
      return setNameError('Name is required')
    } else {
      setNameError('')
    }

    if (email.trim().length === 0) {
      return setEmailError('Email is required')
    } else {
      setEmailError('')
    }
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|]).{8,32}$/

    if (!passwordRegex.test(password)) {
      return setPassError(
        'Password must contain minimum 8 characters, at least 1 uppercase letter, one lowercase letter, one number and one special character'
      )
    } else {
      setPassError('')
    }

    return next()
  }

  // The code is not getting used as of now.. It was used in the old email signup flow
  // const emailSignupFirebase = () => {
  //   setLoading(true);
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(() => {
  //       firebase
  //         .auth()
  //         .currentUser.getIdToken(true)
  //         .then((token) => {
  //           document.cookie = `jwt_token=${token}`;
  //           onSuccess(token);
  //           setLoading(false);
  //         });
  //     })
  //     .catch((error) => {
  //       // console.log(error.code);
  //       if (error.code === "auth/email-already-in-use") {
  //         setEmailError("Email already exists");
  //       } else if (error.code === "auth/weak-password") {
  //         setPassError(
  //           "Password must contain minimum 8 characters, at least 1 uppercase letter, one lowercase letter, one number and one special character"
  //         );
  //       } else if (error.code === "auth/invalid-email") {
  //         setEmailError("Invalid Email");
  //       } else {
  //         onError();
  //         // setLoading(false);
  //       }
  //       setLoading(false);
  //     });
  // };

  const emailSignup = async () => {
    if (name.trim().length === 0) {
      enqueueSnackbar('Please enter your name.', {
        variant: 'error'
      })

      return
    }

    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/signup/email`, {
        email,
        name: name,
        password
      })
      .then((res) => {
        emailLogin()
      })
      .catch((error) => {
        onError(error)
        setLoading(false)
      })
  }

  const emailLogin = (e) => {
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          // if sign up flow
          if (isLogin === false) {
            setUserNotFoundOnSignUp(true)
            setNameError('Account with that email does not exist, provide a name to register a new account!')
          } else {
            enqueueSnackbar('User Not Found!', { variant: 'error' })
          }
          setLoading(false)
        } else if (error.code === 'auth/wrong-password') {
          setPassError('The password entered is either wrong or this account can only be logged into via OAuth!')
          enqueueSnackbar('Invalid Password', { variant: 'error' })
          setLoading(false)
        } else {
          console.log(error)
          onError(error)
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    setNameError('')
    setEmailError('')
    setPassError('')
    setLoading(false)
    setUserNotFoundOnSignUp(false)
  }, [chosen])

  return (
    <>
      {chosen && (
        <>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Email"
                id="email"
                value={email}
                error={emailError !== ''}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                variant="outlined"
              />
              {emailError !== '' && (
                <FormHelperText error>{emailError}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextLabel
                style={{
                  marginLeft: 6,
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                Password
              </TextLabel>
              <InputBase
                sx={[
                  {
                    marginTop: '8px',
                    borderRadius: '5px',
                    position: 'relative',
                    border: '1px solid transparent',
                    backgroundColor: '#8aa6de14',

                    fontSize: 16,
                    padding: '8px 16px',
                    fontFamily: 'Avenir Next'
                  },
                  {
                    '&:focus': {
                      border: '1px solid #8aa6de41'
                    }
                  },
                  passError !== '' && {
                    border: '1px solid #ff000041',
                    backgroundColor: '#ff000014'
                  }
                ]}
                type={showPass ? 'text' : 'password'}
                error={passError !== ''}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass((prev) => !prev)}>
                      {showPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passError !== '' && (
                <FormHelperText error>{passError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {!isLogin && userNotFoundOnSignUp && (
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  label="Name"
                  id="name"
                  value={name}
                  error={nameError !== ''}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  variant="outlined"
                />
                {nameError !== '' && (
                  <FormHelperText error>{nameError}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        </>
      )}
      <Grid item>
        <FormControl fullWidth>
          <button
            onClick={() => {
              if (chosen) {
                if (isLogin) {
                  return validate(emailLogin)
                }

                // if is for sign up flow
                if (userNotFoundOnSignUp) {
                  validate(emailSignup)
                } else {
                  validate(emailLogin)
                }
              } else {
                setChosen(true)
              }
            }}
            style={{
              background: '#828282',
              borderRadius: 10,
              color: '#FFFFFF',
              border: 'none',
              display: 'inline',
              padding: '14px',
              cursor: 'pointer',
              fontSize: 16,
              margin: 2
            }}
            disabled={loading}
          >
            {loading
              ? (
              <CircularProgress
                size={18}
                style={{
                  verticalAlign: 'middle',
                  marginRight: 10,
                  color: '#FFFFFF'
                }}
              />
                )
              : (
              <Mail
                style={{
                  verticalAlign: 'middle',
                  marginRight: 10,
                  height: 16,
                  position: 'absolute',
                  left: 24
                }}
              />
                )}
            Continue with Email
          </button>
        </FormControl>
      </Grid>
    </>
  )
}

export default EmailSignup
