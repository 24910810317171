import axios from 'axios'
import { alsoPublicRouteHeadersFormat } from '../helpers'

/**
 * @function postFetch Retrieve the post without auth
 * @param {string} postId - post's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents post object
 */
export const postFetch = (postId, nrctUID = null, jwt = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/post/fetch`, {
      params: {
        post: postId
      },
      headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
    })
      .then((res) => {
        if (res.data.msg === 'done') {
          resolve(res.data.body)
        } else {
          throw new Error('Could not get post!')
        }
      })
      .catch((err) => reject(err))
  })
}
