import { CircularProgress } from '@mui/material'
import firebase from 'firebase'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import '../../../firebase'
import facebookIcon from '../../assets/facebook.png'

function FacebookSignin ({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fbAuth = () => {
    setLoading(true)
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          enqueueSnackbar('Please try signing in with a different method.', {
            variant: 'error'
          })
        } else {
          onError()
        }

        setLoading(false)
      })
  }

  return (
    <button
      onClick={fbAuth}
      style={{
        background: '#1877F2',
        borderRadius: 10,
        color: '#FFFFFF',
        border: 'none',
        display: 'inline',
        padding: '14px',
        cursor: 'pointer',
        fontSize: 16,
        margin: 2
      }}
      disabled={loading}
    >
      {loading
        ? (
        <CircularProgress
          size={18}
          style={{ verticalAlign: 'middle', marginRight: 10, color: '#FFFFFF' }}
        />
          )
        : (
        <img
          src={facebookIcon}
          style={{
            verticalAlign: 'middle',
            marginRight: 10,
            height: 16,
            position: 'absolute',
            left: 24
          }}
          alt=""
        />
          )}
      Continue with Facebook
    </button>
  )
}

export default FacebookSignin
