import * as React from 'react'
import { MenuRounded } from '@mui/icons-material'
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
  Box,
  Stack
} from '@mui/material'
import { Link } from 'react-router-dom'

// importing ui
import Nearcast from 'ui/Icons/Nearcast'

// import components
import { SidebarElement } from '../../General/Sidebar'

export interface LandingHeaderProps {
  leftAction: JSX.Element[];
  rightActions: JSX.Element[];
}

const LandingHeader: React.FC<LandingHeaderProps> = (props) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('md'))
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <AppBar
      elevation={0}
      sx={(theme) => ({
        backgroundColor: {
          xs: 'rgba(41, 41, 41, 0.38)',
          md: 'rgba(255, 255, 255, 0.38)'
        },
        backdropFilter: 'blur(20px)',
        webkitBackdropFilter: 'blur(20px)',
        paddingLeft: {
          xs: 1,
          md: 10
        },
        paddingRight: {
          xs: 1,
          md: 10
        },
        paddingTop: 1,
        paddingBottom: 1
      })}
    >
      <Toolbar
        sx={{
          minHeight: 54,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        disableGutters
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginLeft: {
              xs: 0,
              md: '20px'
            }
          }}
        >
          {/* Hamburger Icon */}
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'flex',
                md: 'none'
              },
              marginRight: 1
            }}
          >
            <IconButton
              onClick={() => {
                setDrawerOpen(true)
              }}
            >
              <MenuRounded
                sx={{
                  color: {
                    xs: 'white',
                    md: 'black'
                  }
                }}
              />
            </IconButton>
          </Box>

          <Link
            to="/"
          >
            <Nearcast
              height={small ? 24 : 34}
              style={{
                display: 'block',
                alignSelf: 'center',
                [theme.breakpoints.down('md')]: {
                  marginInline: 'auto'
                }
              }}
              htmlColor={theme.breakpoints.values.md > window.innerWidth ? 'white' : theme.palette.primary.main}
            />
          </Link>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{
              marginLeft: 4,
              display: {
                xs: 'none',
                md: 'flex'
              }
            }}
          >
            {props.leftAction}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifySelf="flex-end"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{
            marginRight: {
              xs: 0,
              md: '20px'
            }
          }}
        >
          {props.rightActions}
        </Stack>
      </Toolbar>
      <SwipeableDrawer
        anchor="left"
        variant="temporary"
        open={drawerOpen}
        onOpen={() => {
          setDrawerOpen(true)
        }}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        <Box
          sx={{
            width: '80vw',
            padding: 2
          }}
          role="presentation"
        >
          <Nearcast
            height={27}
            style={{
              display: 'block',
              alignSelf: 'center',
              marginInline: 'auto',
              marginTop: '30px',
              marginBottom: '30px'
            }}
            htmlColor={theme.palette.primary.main}
          />
          <Stack
            direction="column"
            spacing={1}
          >
            {props.leftAction}
            {props.rightActions}
          </Stack>
        </Box>
      </SwipeableDrawer>
    </AppBar>
  )
}

export default LandingHeader
