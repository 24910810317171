import React, { useEffect, useState } from 'react'
import { FormControl, Button, Stack, Typography, FormHelperText } from '@mui/material'
import validator from 'validator'
import { useSnackbar } from 'notistack'

// import ui components
import StandardModal from '../../../Modals/StandardModal'
import PriceFieldsInput from '../../../TextInputs/PriceFieldsInput'

// import apis
import { forgotPassword } from 'api/forgot/password'
import { forgotVerify } from 'api/forgot/verify'

export interface ForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
  hasResetToken?: string | false;
  navigate?: (r: any) => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  open,
  onClose,
  hasResetToken = false,
  navigate = (r) => {}
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [email, setEmail] = useState('')
  const [inputErrors, setInputErrors] = useState({
    email: null
  })
  const [isSendingLink, setIsSendingLink] = useState(false)
  const [wasResetEmailSent, setWasResetEmailSent] = useState(false)
  const [isResendingLink, setIsResendingLink] = useState(false)
  // states for reset password
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [resetInputErrors, setResetInputError] = useState({
    password: null
  })
  // const [showPassword, setShowPassword] = useState(false)
  // const [showRePassword, setShowRePassword] = useState(false)
  const [isSettingPassword, setIsSettingPassword] = useState(false)
  const [wasPasswordUpdated, setWasPasswordUpdated] = useState(false)

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|]).{8,32}$/
    if (password.length === 0) {
      return setResetInputError({
        password: null
      })
    }
    if (!passwordRegex.test(password)) {
      setResetInputError({
        password: 'Requires minimum 8 characters and at least one uppercase letter, one lowercase letter, one number and one special character'
      })
    } else {
      setResetInputError({
        password: null
      })
    }
  }

  const onPressCreatePassword = () => {
    if (password.length === 0 || password !== rePassword) {
      return
    }

    setIsSettingPassword(true)
    forgotVerify(password, hasResetToken)
      .then(() => setWasPasswordUpdated(true))
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar('We ran into a problem, please try again later!', { variant: 'error' })
      })
      .finally(() => setIsSettingPassword(false))
  }

  const onPressSendLink = () => {
    if (!validator.isEmail(email)) {
      return setInputErrors({
        email: 'Please enter a valid email'
      })
    } else {
      setInputErrors({
        email: null
      })
    }

    setIsSendingLink(true)
    forgotPassword(email)
      .then(() => {
        setWasResetEmailSent(true)
        enqueueSnackbar('Reset password email has been sent!', { variant: 'success' })
      })
      .catch((err) => {
        // if the user does not exist, message will be "invalid user"
        if (err.response.data.msg === 'invalid user') {
          enqueueSnackbar('Email entered is not registered with us!', { variant: 'error' })
          return setInputErrors({
            email: 'This email is not registered with us!'
          })
        }

        enqueueSnackbar('We ran into a problem, please try again later!', { variant: 'error' })
      })
      .finally(() => {
        setIsSendingLink(false)
      })
  }

  useEffect(() => {
    validatePassword()
  }, [password])

  const renderModalContent = () => {
    if (hasResetToken) {
      if (wasPasswordUpdated) {
        return (
          <Stack direction="column" alignItems="center">
            <Stack direction="column" alignItems="center" style={{ width: '80%' }}>
              <img
                src={require('./password.svg').default}
                style={{
                  width: 200
                }}
              />
              <Typography variant='h5' textAlign="center" style={{ marginTop: 8 }}>
                Password has been changed successfully
              </Typography>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginTop: 16
                }}
                disableElevation
                onClick={() => {
                  onClose()
                  navigate('/login')
                }}
              >
                Login
              </Button>
            </Stack>
          </Stack>
        )
      }

      return (
        <Stack direction="column" alignItems="center">
          <Stack direction="column" style={{ width: '80%' }}>
            <FormControl margin='normal'>
              <PriceFieldsInput
                label="Password"
                placeholder="Password"
                type={'password'}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                //       {showPassword ? <VisibilityOff /> : <Visibility />}
                //     </IconButton>
                //   </InputAdornment>
                // }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={resetInputErrors.password}
              />
              {resetInputErrors.password && (
                <FormHelperText error={true}>
                  {resetInputErrors.password}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl margin='normal'>
              <PriceFieldsInput
                label="Re-enter Password"
                placeholder="Re-enter Password"
                type={'password'}
                onChange={(e) => setRePassword(e.target.value)}
                error={(password !== rePassword) && rePassword.length > 0}
                disabled={resetInputErrors.password}
              />
              {(password !== rePassword) && rePassword.length > 0 && (
                <FormHelperText error={true}>
                  Passwords don't match
                </FormHelperText>
              )}
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: 16,
                marginBottom: 8
              }}
              onClick={onPressCreatePassword}
              disableElevation
              disabled={
                password.length === 0 ||
                rePassword.length === 0 ||
                password !== rePassword ||
                isSettingPassword
              }
            >
              {isSettingPassword ? 'Creating Password' : 'Create Password'}
            </Button>
          </Stack>
        </Stack>
      )
    }

    if (wasResetEmailSent) {
      return (
        <Stack direction="column" alignItems="center">
          <Stack direction="column" style={{ width: '90%' }}>
            <Stack
              direction="row"
              alignItems="flex-start"
              style={{ marginTop: 16, marginBottom: 16 }}
              flexWrap="wrap"
            >
              <Typography textAlign="center" variant="h4" component="span">
                Password reset link has been sent to {email} successfully
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginBottom: 16
              }}
              onClick={() => {
                setIsResendingLink(true)
                setTimeout(() => {
                  setIsResendingLink(false)
                }, 1000 * 60)
                onPressSendLink()
              }}
              disableElevation
              disabled={isResendingLink}
            >
              Re-send Link
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginBottom: 8
              }}
              onClick={() => {
                setEmail('')
                setWasResetEmailSent(false)
                setIsResendingLink(false)
              }}
              disableElevation
            >
              Change Email Id
            </Button>
          </Stack>
        </Stack>
      )
    }

    return (
      <Stack direction="column" alignItems="center">
        <Stack direction="column" style={{ width: '80%' }}>
          <FormControl margin='normal'>
            <PriceFieldsInput
              label="Enter you Email ID"
              placeholder="Enter your email id"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              error={inputErrors.email}
            />
            {inputErrors.email && (
              <FormHelperText error={true}>
                {inputErrors.email}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            style={{
              marginTop: 16,
              width: 'auto'
            }}
            variant="contained"
            color="primary"
            onClick={onPressSendLink}
            disableElevation
            disabled={isSendingLink}
          >
            {isSendingLink ? 'Sending' : 'Send'}
          </Button>
          <Typography
            variant='caption'
            textAlign='center'
            style={{
              marginTop: 16
            }}
          >
            Password reset link will be sent to the email id entered above
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <StandardModal
      isOpen={open}
      onClose={onClose}
      title={hasResetToken ? 'Set new password' : 'Forgot Password'}
    >
      {renderModalContent()}
    </StandardModal>
  )
}

export default ForgotPasswordModal
