import {
  Button,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'

// importing ui
import ImageWideModal from 'ui/Modals/ImageWideModal'

// importing components
import { GlobalFuncContext } from '../../../Context/GlobalFuncHOC'

const arrayOfUpgrades = [
  {
    image: require('./1.png'),
    header: 'Hide Nearcast',
    description: 'With a higher plan, you will be able to hide more negative reviews on your public business page.'
  },
  {
    image: require('./2.png'),
    header: 'Download reviews without our Watermark',
    description: 'Download authentic customer feedback without our branding to post online.'
  },
  {
    image: require('./3.png'),
    header: 'More Assured Reviews',
    description: 'We will boost your business in our internal neighborhood influencer network to make videos to promote your business.'
  },
  {
    image: require('./4.png'),
    header: 'Advance Analytics',
    description: 'Learn more about which offers, content, and services helped your business grow.'
  }
]

export interface UpgradePlanToAccessProps {
  open: boolean;
  onClose: () => void;
}

const UpgradePlanToAccess: React.FC<UpgradePlanToAccessProps> = (props) => {
  const { openUpgradePayment } = React.useContext(GlobalFuncContext)
  return (
    <ImageWideModal
      image={require('./upgrade.png')}
      isOpen={props.open}
      onClose={props.onClose}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography variant="h4">
          Upgrade your Plan
        </Typography>
        <Typography variant="body2">
          With this upgrade, you get extended limits to our features and a lot more.
        </Typography>
        <Stack spacing={2} sx={{ marginTop: 4, width: '100%' }}>
          {arrayOfUpgrades.map((upgrades) => {
            return (
              <Stack
                key={upgrades.header}
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{
                  width: '100%'
                }}
                spacing={2}
              >
                <img
                  src={upgrades.image}
                  style={{
                    width: 40,
                    height: 40
                  }}
                />
                <Stack>
                  <Typography variant="h5">
                    {upgrades.header}
                  </Typography>
                  <Typography variant="caption">
                    {upgrades.description}
                  </Typography>
                </Stack>
              </Stack>
            )
          })}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.onClose()
              openUpgradePayment('Upgrade my Plan')
            }}
            sx={{
              marginTop: '32px !important'
            }}
            disableElevation
          >
            Upgrade
          </Button>
        </Stack>
      </Stack>
    </ImageWideModal>
  )
}

export default UpgradePlanToAccess
