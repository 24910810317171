import axios from 'axios'

/**
 * @function signUp signUp a new user
 * @param {string} token - token of the user profile
 * @param {string} address - object of the place
 * @param {string} latitude - of the place
 * @param {string} longitude - of the place
 * @param {string} mobile - user's mobile number
 * @param {string} otp - otp that user received
 * @param {boolean} isBusiness - (optional) if sent would register a business account
 * @param {Object} business - (optional) is required if isBusiness is true
 * @returns {Promise} Promise object represents
 */
export const signUp = (
  token,
  address,
  latitude,
  longitude,
  mobile,
  otp,
  isBusiness = false,
  business = null
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
      id_token: token,
      address: address,
      lat: latitude,
      lon: longitude,
      phone: mobile,
      otp: otp,
      is_business: isBusiness,
      business: business
    })
      .then((res) => {
        if (res.data) {
          resolve(res.data.body)
        } else {
          resolve(true)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
