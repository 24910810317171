import React, { useState, useEffect, useRef, useCallback } from 'react'
import { formatDistance } from 'date-fns'
import {
  Grid,
  Stack,
  ListItem,
  IconButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Card,
  FormControl,
  List,
  Button
} from '@mui/material'
// import PostDetails from './PostDetails'
import {
  MoreVert,
  LinkRounded,
  EditRounded,
  DeleteRounded,
  ErrorRounded,
  Send,
  Favorite,
  Share
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import CommentElement from './CommentElement'
import EditPostCaption from './EditPostCaption'
// import { UserContext } from '../../context/UserContext'
// import { onKeypressAction } from '../../utils/keypress'

// importing ui
import WideModal from '../../../Modals/WideModal'
import PriceFieldsInput from '../../../TextInputs/PriceFieldsInput'
import StandardModal from '../../../Modals/StandardModal'
import ReportModal from '../ReportModal'
import NearcastVideoPlayer from '../../NearcastVideoPlayer/NearcastVideoPlayer'

// importing apis
import { feedData } from 'api/analytics/feed'
import { reportPost } from 'api/report/post'
import { postFetch } from 'api/post/fetch'
import { postComments as postCommentsAPI } from 'api/post/comments'
import { reportComment } from 'api/report/comment'
import { commentDelete } from 'api/comment/delete'
import { commentCreate } from 'api/comment/create'
import { postDelete } from 'api/post/delete'
import { postLike } from 'api/post/like'
import { postUnlike } from 'api/post/unlike'
import { postView } from 'api/post/view'

// importing helpers
import { getUserDomainURL } from 'helpers/url'

// the different screen dimensions to record in
const screenDimensionsPortrait = {
  width: 300,
  height: 600
}

const EmojiButton = ({ emoji, setCommentText }) => {
  return (
    <div
      style={{
        height: '35px',
        width: '40px',
        textAlign: 'center',
        paddingTop: '5px',
        fontSize: '22px',
        cursor: 'pointer'
      }}
      onClick={() => {
        setCommentText((prevCommentText) => prevCommentText + emoji)
      }}
    >
      {emoji}
    </div>
  )
}

export interface PostViewModalNearcastType {
  postId: string | null;
  postCaption: string;
  postPlaybackToken: string;
  postSticker: any[];
  postMusic: any[];
  creator: {
    userId: string;
    username: string;
    userCover: string;
  }
}

export interface PostViewModalProps {
  nearcast: PostViewModalNearcastType;
  forWhichPortal: 'business' | 'user' | 'admin';
  open: Boolean;
  onClose: () => void;
  hasUserDetails: {
    isUserLoggedIn: Boolean;
    jwtToken: string;
    nrctUID: string;
    preferences: {
      videoMuted: boolean,
      toggleVideoMuted: () => {}
    }
  },
  openShareModal: any
}

// localRefresher is used to update posts in profile screen, where the users posts aren't coming from redux
// but are loaded in component state
const PostViewModal = ({
  nearcast,
  forWhichPortal,
  open,
  onClose,
  hasUserDetails,
  openShareModal
}) => {
  const observer = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  // component states
  const [allPostDetails, setAllPostDetails] = useState(null)
  const [mainPostMenu, setMainPostMenu] = useState(null)
  // states for post comment
  const [loadComments, setLoadComments] = useState(true)
  const [commentPage, setCommentPage] = useState(1)
  const [commentHasMore, setCommentHasMore] = useState(true)
  const [postComments, setPostComments] = useState([])
  // state for input
  const [commentText, setCommentText] = useState('')
  // state for music modal
  const [isMusicModalOpen, setIsMusicModalOpen] = useState(false)
  // state for report modal
  const reportModalStateInitial = {
    isOpen: false,
    for: 'post',
    id: ''
  }
  const [reportModalState, setReportModalState] = useState(reportModalStateInitial)
  // state for edit modal
  const [isEditPostOpen, setIsEditPostOpen] = useState(false)
  // state for payment modal
  const [paymentData, setPaymentData] = useState<any>({})
  // state for post liked
  const [postLiked, setPostLiked] = useState(false)

  const openUserProfile = () => {
    window.open(getUserDomainURL(`/u/${nearcast.creator.userId}`))
  }

  const getPostDetails = () => {
    postFetch(
      nearcast.postId,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then((resBody) => {
        setAllPostDetails(resBody)
      })
      .catch((err) => console.log(err))
  }

  const fetchComments = (pageNumber = commentPage) => {
    if (!commentHasMore && pageNumber !== 1) {
      return
    }
    if (pageNumber === 1) {
      setLoadComments(true)
    }
    postCommentsAPI(
      nearcast.postId,
      pageNumber,
      10,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then((resBody) => {
        if (resBody.length === 0) {
          setCommentHasMore(false)
          return
        }
        if (pageNumber === 1) {
          setPostComments(resBody)
        } else {
          setPostComments([...postComments, ...resBody])
        }
        setCommentPage((c) => c + 1)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
      .finally(() => {
        setLoadComments(false)
      })
  }

  // for reporting: post or comments
  const reportPostApi = (reportType) => {
    reportPost(
      nearcast.postId,
      reportType,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then(() => {
        enqueueSnackbar('You reported this post.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(
          "We weren't able to report this post, please try again.", {
            variant: 'error'
          })
      })
  }
  const reportCommentApi = (id, reportType = 'User reported comment from the web') => {
    reportComment(
      id,
      reportType,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then((res) => {
        enqueueSnackbar('You reported this comment.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar("We weren't able to report this comment, please try again.", {
          variant: 'error'
        })
      })
  }

  const deleteCommentApi = (id) => {
    // remove the comment locally
    const filteredComments = postComments.filter((comment) => comment.id !== id)
    setPostComments(filteredComments)
    commentDelete(
      id,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then(() => {
        enqueueSnackbar('You deleted this comment.', {
          variant: 'success'
        })
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: 'error'
        })
      })
      .finally(() => {
        fetchComments()
        setLoadComments(false)
      })
  }

  const postCommentsApi = () => {
    // if user not logged in, hence personal is null don't let user comment
    if (hasUserDetails.isUserLoggedIn === false) {
      return enqueueSnackbar('You are not logged in, Please login to like or comment!', {
        variant: 'info'
      })
    }
    if (commentText.length === 0 || !hasUserDetails.jwtToken) {
      return
    }
    const commentedText = commentText
    setCommentText('')
    commentCreate(
      nearcast.postId,
      commentedText,
      hasUserDetails.nrctUID,
      hasUserDetails.jwtToken
    )
      .then((resBody) => {
        fetchComments(1)
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  const likePostApi = async () => {
    // if the user is not logged in, hence the personal array is null don't let user like
    if (hasUserDetails.isUserLoggedIn === false) {
      return enqueueSnackbar('You are not logged in, Please login to like or comment!', {
        variant: 'info'
      })
    }

    // Bad Code: likePostApi should do what it's name suggests and not unlike a post as well
    try {
      if (!postLiked) {
        setPostLiked(true)
        await postLike(
          nearcast.postId,
          hasUserDetails.nrctUID,
          hasUserDetails.jwtToken
        )
        enqueueSnackbar('You liked this post.', {
          variant: 'success',
          autoHideDuration: '200'
        })
      } else {
        setPostLiked(false)
        await postUnlike(
          nearcast.postId,
          hasUserDetails.nrctUID,
          hasUserDetails.jwtToken
        )
        enqueueSnackbar('You disliked this post.', {
          variant: 'success'
        })
      }
    } catch (err) {
      setPostLiked((prev) => !prev)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const lastCommentElementRef = useCallback(
    (node) => {
      if (loadComments) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && commentHasMore) {
          fetchComments()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [commentHasMore, fetchComments, loadComments]
  )

  // for analytics only
  useEffect(() => {
    if (hasUserDetails.isUserLoggedIn && forWhichPortal === 'user') {
      const data = {
        post_id: nearcast.postId,
        user_id: hasUserDetails.nrctUID
      }
      // for business analytics
      feedData('post_views', data, hasUserDetails.jwtToken, hasUserDetails.nrctUID)
      // for post count
      postView(post.post_id, hasUserDetails.nrctUID, hasUserDetails.jwtToken)
    }
  }, [])

  useEffect(() => {
    if (hasUserDetails.isUserLoggedIn && nearcast.postId) {
      getPostDetails()
      fetchComments()
    }
  }, [hasUserDetails])

  useEffect(() => {
    if (allPostDetails) {
      setPostLiked(allPostDetails.is_liked)
    }
  }, [allPostDetails])

  const renderComments = () => {
    if (loadComments) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: 1,
            height: '300px',
            overflow: 'auto'
          }}
        >
          <CircularProgress />
        </Stack>
      )
    }

    if (postComments.length === 0) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: 1,
            height: '300px',
            overflow: 'auto'
          }}
        >
          <img
            src={require('./noComments.svg').default}
            style={{
              width: 200
            }}
          />
          <Typography variant="body2" style={{ textAlign: 'center' }}>
            {hasUserDetails.isUserLoggedIn
              ? 'Be the first one to post a comment'
              : 'Login to view comments'}
          </Typography>
        </Stack>
      )
    }

    return (
      <Stack
        sx={{
          marginTop: 1,
          height: '280px',
          overflow: 'auto'
        }}
      >
        {postComments.map((comment, index) => {
          return (
            <CommentElement
              key={comment.id}
              forwardRef={postComments.length - 1 === index ? lastCommentElementRef : null}
              ownComment={
                hasUserDetails.nrctUID === comment.user_id ||
                hasUserDetails.nrctUID === nearcast.userId
              }
              comment={comment}
              deleteComment={deleteCommentApi}
              reportComment={(id) => setReportModalState({
                isOpen: true,
                for: 'comment',
                id: id
              })}
            />
          )
        })}
      </Stack>
    )
  }

  const renderPostDetails = () => {
    if (nearcast.postId === null) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'relative',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Typography variant="h4">
            Anonymous Post
          </Typography>
        </Stack>
      )
    }

    if (allPostDetails === null) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'relative',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <CircularProgress />
        </Stack>
      )
    }

    return (
      <Stack
        direction="column"
        style={{
          position: 'relative',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <ListItem
          sx={{
            marginTop: {
              xs: 1,
              md: 0
            }
          }}
          alignItems="flex-start"
          style={{ margin: 0, padding: 0 }}
          secondaryAction={
            <MoreVert
              style={{
                cursor: 'pointer'
              }}
              onClick={(e) => {
                setMainPostMenu(e.currentTarget)
              }}
              aria-controls="post-menu"
              size="large"
            />
          }
        >
          <ListItemAvatar
            onClick={openUserProfile}
            style={{
              cursor: 'pointer'
            }}
          >
            <Avatar
              key={nearcast.creator.userId}
              src={nearcast.creator.userCover}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant='h5' onClick={openUserProfile} style={{ cursor: 'pointer' }}>
                {allPostDetails.first_name}
              </Typography>
            }
            secondary={
              <Typography variant='caption'>
                {`Posted ${formatDistance(new Date(allPostDetails.posted_at), new Date(), { addSuffix: true })}`}
              </Typography>
            }
          />
          <Menu
            anchorEl={mainPostMenu}
            open={Boolean(mainPostMenu)}
            keepMounted
            onClose={() => {
              setMainPostMenu(null)
            }}
          >
            <MenuItem
              key="copyLink"
              onClick={() => {
                navigator.clipboard.writeText(getUserDomainURL(`/p/${nearcast.postId}`))
                setMainPostMenu(null)
                enqueueSnackbar(
                  "We copied this post's link to your clipboard.",
                  {
                    variant: 'success'
                  }
                )
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LinkRounded style={{ marginRight: '8px' }} />
                <Typography>Copy link</Typography>
              </div>
            </MenuItem>
            {hasUserDetails.isUserLoggedIn &&
              (nearcast.creator.userId === hasUserDetails.nrctUID
                ? (
                <MenuList>
                  <MenuItem
                    key="editPost"
                    onClick={() => {
                      setMainPostMenu(null)
                      setIsEditPostOpen(true)
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <EditRounded style={{ marginRight: '8px' }} />
                      <Typography>Edit post</Typography>
                    </div>
                  </MenuItem>
                  <MenuItem
                    key="deletePost"
                    onClick={async () => {
                      try {
                        await postDelete(
                          nearcast.postId,
                          hasUserDetails.nrctUID,
                          hasUserDetails.jwtToken
                        )
                        onClose()
                      } catch (err) {
                        enqueueSnackbar(err.message, {
                          variant: 'error'
                        })
                      }
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DeleteRounded
                        color="error"
                        style={{ marginRight: '8px' }}
                      />
                      <Typography color="error">Delete post</Typography>
                    </div>
                  </MenuItem>
                </MenuList>
                  )
                : (
                <MenuItem
                  key="reportPost"
                  onClick={() => {
                    setReportModalState({
                      isOpen: true,
                      for: 'post',
                      id: ''
                    })
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ErrorRounded
                      color="secondary"
                      style={{ marginRight: '8px' }}
                    />
                    <Typography color="secondary">Report post</Typography>
                  </div>
                </MenuItem>
                  ))}
        </Menu>
        </ListItem>
        <Typography variant="subtitle1">
          {allPostDetails.post_caption}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            cursor: nearcast.postMusic !== null ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (nearcast.postMusic !== null) {
              setIsMusicModalOpen(true)
            }
          }}
        >
          {nearcast.postMusic !== null
            ? nearcast.postMusic.length === 1
              ? `🎶 ${nearcast.postMusic[0].song_name} • ${nearcast.postMusic[0].author_name}`
              : '🎶 Multiple Music Tracks (click to know more)'
            : 'No Music Track'
          }
        </Typography>
        <Stack
          direction="column"
        >
          <Typography variant="h4">Comments</Typography>
          {renderComments()}
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: 'white',
            paddingTop: 8
          }}
        >
          <Card
            style={{
              display: 'flex',
              borderRadius: '10px',
              marginLeft: '0px',
              justifyContent: 'space-around',
              alignItems: 'center',
              userSelect: 'none'
            }}
          >
            <EmojiButton emoji="😂" setCommentText={setCommentText} />
            <EmojiButton emoji="😡" setCommentText={setCommentText} />
            <EmojiButton emoji="😭" setCommentText={setCommentText} />
            <EmojiButton emoji="🔥" setCommentText={setCommentText} />
            <EmojiButton emoji="💯" setCommentText={setCommentText} />
            <EmojiButton emoji="🙌" setCommentText={setCommentText} />
            <EmojiButton emoji="👏" setCommentText={setCommentText} />
          </Card>
          <Stack
            direction="row"
            style={{ width: '100%' }}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl fullWidth>
              <PriceFieldsInput
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                placeholder="Say something nice..."
                disabled={!hasUserDetails.jwtToken}
                inputProps={{ maxLength: '511' }}
              />
            </FormControl>
            <IconButton
              onClick={() => {
                postCommentsApi()
              }}
              color="primary"
              size="small"
              style={{
                width: 40,
                height: 40,
                marginLeft: 4
              }}
            >
              <Send htmlColor="white" />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" style={{ marginTop: 8 }}>
          <Button
            color="primary"
            variant={postLiked ? 'outlined' : 'contained'}
            disableElevation
            startIcon={<Favorite htmlColor={postLiked ? '#3A8F78' : 'white'} />}
            onClick={() => likePostApi()}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              padding: '5px 10px',
              borderRadius: 5,
              cursor: 'pointer',
              width: '90px'
            }}
          >
            {postLiked ? 'Liked' : 'Like'}
          </Button>
          <Button
            color="ghost"
            variant="contained"
            disableElevation
            startIcon={<Share />}
            onClick={() => openShareModal(
              'Post',
              `Checkout this post at Nearcast: ${nearcast.postCaption}`,
              `/p/${nearcast.postId}`
            )}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              padding: '5px 10px',
              borderRadius: 5,
              cursor: 'pointer',
              width: '90px',
              marginLeft: 10
            }}
          >
            Share
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <WideModal
      isOpen={open}
      onClose={onClose}
    >
      <Grid
        container
        sx={{
          height: screenDimensionsPortrait.height
        }}
      >
        <Grid
          sm={12}
          md={5}
          lg={5}
          item
          sx={{
            width: '100%'
          }}
        >
          <Stack
            direction="column"
            alignItems={{
              xs: 'center',
              md: 'flex-start'
            }}
            sx={{
              width: '100%'
            }}
          >
            <NearcastVideoPlayer
              type='post'
              video={{
                source: nearcast.postPlaybackToken,
                muted: hasUserDetails.isUserLoggedIn ? hasUserDetails.preferences.videoMuted : false,
                toggleMuted: hasUserDetails.isUserLoggedIn ? hasUserDetails.preferences.toggleVideoMuted : () => {}
              }}
              stickers={nearcast.postSticker}
              updateSticker={(s, m) => {}}
              screenDimensions={screenDimensionsPortrait}
            />
          </Stack>
        </Grid>
        <Grid
          sm={12}
          md={7}
          lg={7}
          item
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          {renderPostDetails()}
        </Grid>
      </Grid>
      {/* Modal for displaying music */}
      {nearcast.postMusic && (
        <StandardModal
          isOpen={isMusicModalOpen}
          onClose={() => setIsMusicModalOpen(false)}
          title="All Tracks on Post"
          buttons={[
            {
              title: 'Close',
              onPress: () => setIsMusicModalOpen(false),
              color: 'ghost'
            }
          ]}
        >
          <Typography variant="caption">
            These music tracks are used in this post
          </Typography>
          <List>
            {nearcast.postMusic.map((music, index) => {
              return (
                <ListItem key={`${index}`}>
                  <ListItemAvatar>
                    <Avatar
                      alt={music.song_name}
                      src={`${process.env.REACT_APP_BACKEND_URL}/${music.cover}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={music.song_name}
                    secondary={music.author_name}
                  />
                </ListItem>
              )
            })}
          </List>
        </StandardModal>
      )}
      {/* Modal for reporting */}
      <ReportModal
        isOpen={reportModalState.isOpen}
        onClose={() => setReportModalState(reportModalStateInitial)}
        onSuccess={(reportType) => {
          setReportModalState(reportModalStateInitial)
          switch (reportModalState.for) {
            case 'post':
              return reportPostApi(reportType)
            case 'comment':
              return reportCommentApi(reportModalState.id, reportType)
            default:
              console.log('No Match for report')
          }
        }}
      />
      {/* Modal for editing post caption: Should only be there if hasUserDetails is true and id of the post creator and current user match */}
      {allPostDetails && hasUserDetails.nrctUID === nearcast.creator.userId && (
        <EditPostCaption
          postDetails={{
            postId: nearcast.postId,
            postCaption: allPostDetails.post_caption
          }}
          userDetails={{
            nrctUID: hasUserDetails.nrctUID,
            jwtToken: hasUserDetails.jwtToken
          }}
          isOpen={isEditPostOpen}
          onClose={() => {
            setIsEditPostOpen(false)
            // refresh post details
            getPostDetails()
          }}
        />
      )}
    </WideModal>
  )
}

export default PostViewModal
