import * as React from 'react'
import {
  FormControl,
  Grid,
  Typography,
  Paper,
  Stack,
  Divider,
  CircularProgress,
  Checkbox,
  Slide
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { Add } from '@mui/icons-material'
import NearcastCard from '../NearcastCard/NearcastCard'
import axios from 'axios'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

// importing ui
import WideModal from 'ui/Modals/WideModal'
import StandardModal from 'ui/Modals/StandardModal'
import DatePicker from 'ui/Pickers/DatePicker/DatePicker'
import TimePicker from 'ui/Pickers/TimePicker/TimePicker'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'

// importing context
import { UserContext } from '../../Context/UserContext'

// importing components
import { TextLabel } from 'ui/TextInputs/TextInput'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import TextAreaInput from 'ui/TextInputs/TextAreaInput'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'

// importing apis
import { businessOfferDetails } from 'api/business/offer/details'
import { businessOfferEdit } from 'api/business/offer/edit'
import { feedData } from 'api/analytics/feed'

// importing hooks
import useDebounce from 'hooks/useDebounce'

export interface CreateEditOfferModalProps {
  type: 'create' | 'edit' | 'duplicate';
  offerId?: string; // required if type: duplicate
  open: boolean;
  onClose: () => {};
}

const CreateEditOfferModal: React.FC<CreateEditOfferModalProps> = (props) => {
  const { type, offerId, open, onClose } = props
  const { business, jwtToken, activeBusiness } = React.useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  // modal content wrapper ref
  const contentWrapperRef = React.useRef(null)

  // component states
  const [loading, setLoading] = React.useState<boolean>(true)
  // if the type is edit
  const [isEditOffer, setIsEditOffer] = React.useState<boolean>(false)
  const [editOfferCover, setEditOfferCover] = React.useState<string>('')
  const [title, setTitle] = React.useState<string>('')
  const [desc, setDesc] = React.useState<string>('')
  const [nearcoins, setNearcoins] = React.useState<string>('100')
  const [dateStart, setDateStart] = React.useState<string>(
    dayjs().format('YYYY-MM-DD[T]HH:mm')
  )
  const [dateEnd, setDateEnd] = React.useState<string>(
    dayjs().add(1, 'y').format('YYYY-MM-DD[T]HH:mm')
  )
  const [posts, setPosts] = React.useState<any[]>([])
  const [selectedPost, setSelectedPost] = React.useState<string | false>(false)
  // imageSelectedByUser for if the image got cropped then for edit image functionality
  // Temp one for if user closes the modal without cropping and clicking submit
  const [imageSelectedByUserTemp, setImageSelectedByUserTemp] = React.useState<File | false>(false)
  const [imageSelectedByUser, setImageSelectedByUser] = React.useState<File | false>(false)
  const [coverImage, setCoverImage] = React.useState<File | false>(false)
  // Instore Offer States
  const [redemption, setRedemption] = React.useState<string>('')
  const [avail, setAvail] = React.useState<boolean>(false) // is the redemption code available
  const debouncedRedemption = useDebounce(redemption, 500)
  // state for crop modal
  const [openCropImage, setOpenCropImage] = React.useState<boolean>(false)
  // state for post selection modal
  const [isPostSelectionOpen, setIsPostSelectionOpen] = React.useState<boolean>(false)
  const [selectedPostOnModal, setSelectedPostOnModal] = React.useState<string | false>(false)
  // error fields for input fields
  const inputErrorInitialState = {
    title: null,
    description: null,
    nearcoins: null,
    dateStart: null,
    dateEnd: null,
    posts: null,
    selectedPost: null,
    coverImage: null,
    redemption: null
  }
  const [inputErrors, setInputError] = React.useState<typeof inputErrorInitialState>(inputErrorInitialState)
  // modals primary action processing state
  const [processing, setProcessing] = React.useState<boolean>(false)
  // include time checkbox
  const [shouldIncludeTime, setShouldIncludeTime] = React.useState<boolean>(false)

  // should return true if a valid request can be made
  const validate = () => {
    const newInputError = inputErrorInitialState
    if (title.trim().length === 0) {
      newInputError.title = 'Please provide a offer title!'
    }
    if (desc.trim().length === 0) {
      newInputError.description = 'Please provide a offer description'
    }
    if (Number(nearcoins) < 0 || nearcoins.length === 0) {
      newInputError.nearcoins = 'Enter a positive amount (can be zero)'
    }
    if (!dateStart) {
      newInputError.dateStart = 'Please enter a start date for the offer'
    }
    if (!dateEnd) {
      newInputError.dateEnd = 'Please enter a end date for the offer'
    }
    // dont check for images on edit offer
    if (!isEditOffer) {
      if (!selectedPost && !coverImage) {
        newInputError.selectedPost = 'Please upload a cover image'
        newInputError.coverImage = 'Please upload a cover image'
      }
    }
    if (redemption.trim().length === 0) {
      newInputError.redemption = "You'll need to enter a redemption code, if you want it to be a in store offer"
    }
    if (redemption.includes(' ')) {
      newInputError.redemption = "Redemption code can't have spaces!"
    }
    // dont check if the redemption is available on edit offer
    if (!isEditOffer) {
      if (!avail) {
        newInputError.redemption = 'Redemption code already in use, try another!'
      };
    }
    setInputError(newInputError)
    if (Object.values(newInputError).find((v) => v !== null)) {
      return false
    } else {
      return true
    }
  }

  // should handle creating an offer
  const createAPI = async () => {
    if (!validate()) {
      return
    }

    if (dayjs(dateStart).isAfter(dayjs(dateEnd))) {
      enqueueSnackbar('Start date should be before the end date', {
        variant: 'error',
        autoHideDuration: 3000
      })
      return
    }

    let data: any = {
      title,
      description: desc,
      nearcoins: Number(nearcoins),
      start_date: new Date(dateStart).toISOString(),
      end_date: new Date(dateEnd).toISOString(),
      is_time_included: true,
      is_active: true,
      is_in_store_offer: true,
      in_store_offer: {
        redemption_code: redemption
      }
    }

    if (selectedPost) {
      data = { ...data, post_id: selectedPost }
    }

    setProcessing(true)
    axios
      .post(`${process.env.REACT_APP_BUSINESS_URL}/offer/create`, data, {
        headers: {
          'NRCT-UID': business[activeBusiness].ID,
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then((res) => {
        const offerId = res.data.body.ID
        // business-analytics
        feedData('business_offer_creation', {
          offer_id: offerId,
          business_id: business[activeBusiness].business_id,
          created_at: new Date().toISOString()
        })
        if (!selectedPost && coverImage) {
          axios
            .get(
              `${process.env.REACT_APP_BUSINESS_URL}/offer/pre_sign?offer_id=${
                res.data.body.ID
              }&extension=${coverImage.type.split('/')[1]}`,
              {
                headers: {
                  'NRCT-UID': business[activeBusiness].ID,
                  Authorization: `Bearer ${jwtToken}`
                }
              }
            )
            .then(async (response) => {
              await axios.put(response.data.url, coverImage, {
                headers: {
                  'Content-type': coverImage.type,
                  'Access-Control-Allow-Origin': '*'
                }
              })
              enqueueSnackbar('Offer Created', { variant: 'success' })
              history.push(`/offer/${offerId}`, {
                isNewOffer: true
              })
              onClose()
              clearAllStates()
            })
            .catch(() => {
              setProcessing(false)
              enqueueSnackbar('Some error occured! Retry', {
                variant: 'error'
              })
            })
        } else {
          enqueueSnackbar('Offer Created', { variant: 'success' })
          history.push(`/offer/${offerId}`, {
            isNewOffer: true
          })
          onClose()
          clearAllStates()
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
        setProcessing(false)
      })
  }

  // should handle editing of an offer
  const editAPI = async () => {
    if (!validate()) {
      return
    }

    if (dayjs(dateStart).isAfter(dayjs(dateEnd))) {
      enqueueSnackbar('Start date should be before the end date', {
        variant: 'error',
        autoHideDuration: 3000
      })
      return
    }

    const data: any = {
      end_date: new Date(dateEnd).toISOString()
    }

    setProcessing(true)
    businessOfferEdit(offerId, data, business[activeBusiness].ID, jwtToken)
      .then((res) => {
        enqueueSnackbar('Offer Edited Successfully', { variant: 'success' })
        onClose()
        clearAllStates()
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
        setProcessing(true)
      })
  }

  // should get business posts
  const getPosts = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/posts`, {
        params: {
          page: 1,
          pageSize: 9,
          ts: new Date().toISOString(),
          userId: business[activeBusiness].ID
        },
        headers: {
          'NRCT-UID': business[activeBusiness].ID,
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then((res) => {
        // console.log(res.data.body);
        setPosts(res.data.body)
      })
  }

  // clear all component states
  const clearAllStates = () => {
    setIsEditOffer(false)
    setSelectedPost(false)
    setSelectedPostOnModal(false)
    setTitle('')
    setDesc('')
    setNearcoins('0')
    setRedemption('')
    setInputError(inputErrorInitialState)
  }

  React.useEffect(() => {
    if (isEditOffer) {
      return
    }
    const checkURL = async () => {
      if (debouncedRedemption === '') {
        setAvail(false)
        return
      }
      axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business/redemption/present`, {
        code: debouncedRedemption
      },
      {
        headers: {
          'NRCT-UID': business[activeBusiness].ID,
          Authorization: `Bearer ${jwtToken}`
        }
      })
        .then((res) => {
          // console.log("Redemption Code: ", res);
          setAvail(!res.data.body.is_present)
          if (res.data.body.is_present) {
            setInputError({
              ...inputErrors,
              redemption: 'Redemption code already in use, try another!'
            })
          } else {
            setInputError({
              ...inputErrors,
              redemption: null
            })
          }
        })
        .catch((err) => {
          setAvail(false)
        })
    }
    checkURL()
  }, [debouncedRedemption])

  React.useEffect(() => {
    const getOfferDetails = async (offerId) => {
      businessOfferDetails(offerId)
        .then((resBody) => {
          setTitle(resBody.title)
          setDesc(resBody.description)
          setNearcoins(resBody.nearcoins)
          if (resBody.post_id) {
            setSelectedPost(resBody.post_id)
          }
          setDateStart(
            dayjs(resBody.start_date).format('YYYY-MM-DD[T]HH:mm')
          )
          setDateEnd(
            dayjs(resBody.end_date).format('YYYY-MM-DD[T]HH:mm')
          )
          setRedemption(resBody.in_store_redemption)
          setEditOfferCover(resBody.cover)
          setLoading(false)
        })
    }
    getPosts()
    switch (type) {
      case 'create':
        setIsEditOffer(false)
        setLoading(false)
        break
      case 'duplicate':
        setIsEditOffer(false)
        getOfferDetails(offerId)
        break
      case 'edit':
        getOfferDetails(offerId)
        setIsEditOffer(true)
        break
      default:
        break
    }
  }, [type, offerId])

  const modalPrimaryButtonAction = (): () => void => {
    if (isEditOffer) {
      return editAPI
    }

    return createAPI
  }

  const modalPrimaryButtonText = (): string => {
    if (isEditOffer) {
      return processing ? 'Updating' : 'Update'
    }

    return processing ? 'Creating' : 'Create'
  }

  const styles = {
    contentContainer: {
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 10
    },
    uploadedImage: {
      height: '100%',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
      textAlign: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: 10,
      '&:hover': {
        opacity: '0.2',
        cursor: 'pointer'
      },
      borderStyle: inputErrors.coverImage ? 'solid' : 'none',
      borderColor: inputErrors.coverImage ? 'red' : 'transparent',
      borderWidth: 2,
      cursor: isEditOffer ? 'default' : 'pointer'
    }
  } as const

  return (
    <WideModal
      isOpen={open}
      onClose={() => {
        onClose()
        clearAllStates()
      }}
      title={type !== 'edit' ? 'Create Offer' : 'Edit Offer'}
      buttons={[
        {
          title: 'Cancel',
          color: 'ghost',
          onPress: onClose,
          variant: 'outlined'
        },
        {
          title: modalPrimaryButtonText(),
          color: processing ? 'ghost' : 'primary',
          onPress: modalPrimaryButtonAction()
        }
      ]}
      style={{}}
    >
      <Divider />
        {loading
          ? <div
          style={{
            height: '200px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#FFF',
            zIndex: 1000
          }}
        >
          <CircularProgress />
        </div>
          : <Grid
            ref={contentWrapperRef}
            spacing={0}
            container
            style={styles.contentContainer}
        >
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={8}>
                <FormControl fullWidth margin="normal">
                  <CustomPriceFields
                    label="Title *"
                    placeholder="Enter your offer title"
                    value={title}
                    inputProps={{ maxLength: '63' }}
                    onChange={(e) => {
                      setTitle(e.target.value)
                      if (inputErrors.title) {
                        validate()
                      }
                    }}
                    style={{
                      borderRadius: 10
                    }}
                    error={inputErrors.title}
                    disabled={isEditOffer}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextAreaInput
                    multiline
                    rows={3}
                    label="What are you offering? *"
                    placeholder="Make this sound fun and clear so your customers are interested in your offer"
                    value={desc}
                    inputProps={{ maxLength: '255' }}
                    onChange={(e) => {
                      setDesc(e.target.value)
                      if (inputErrors.description) {
                        validate()
                      }
                    }}
                    style={{
                      borderRadius: 10
                    }}
                    error={inputErrors.description}
                    disabled={isEditOffer}
                  />
                  <span style={{ fontSize: '10px', textAlign: 'right' }}>
                  {255 - desc.length} characters left
                  </span>
                </FormControl>
                {/* For now have the nearcoins fields commented as it's anyways fixed value from our end  */}
                {/* <Stack
                  direction="column"
                  alignItems="flex-start"
                  style={{ marginTop: 0 }}
                >
                  <TextLabel style={{ marginLeft: 4 }}>
                    How many Nearcoins are you willing to offer per review?
                  </TextLabel>
                  <CustomPriceFields
                    type="number"
                    value={nearcoins}
                    inputProps={{ maxLength: '63' }}
                    onChange={(e) => {
                      setNearcoins(e.target.value)
                    }}
                    style={{
                      borderRadius: 10
                    }}
                    error={inputErrors.nearcoins}
                    disabled={isEditOffer || true} // should always be disabled for now
                  />
                </Stack> */}
                <Stack direction="row" spacing={1} style={{ marginTop: 8 }}>
                  <FormControl margin="none">
                    {/* <CustomPriceFields
                      type="datetime-local"
                      label="Start date"
                      value={dateStart}
                      inputProps={{ maxLength: '63' }}
                      onChange={(e) => {
                        setDateStart(e.target.value)
                      }}
                      style={{
                        borderRadius: 10
                      }}
                      error={inputErrors.dateStart}
                      disabled={isEditOffer}
                    /> */}
                    <TextLabel>
                      Start date
                    </TextLabel>
                    <DatePicker
                      value={dateStart}
                      onChange={(e) => setDateStart(dayjs(e).format())}
                      placeholder='mm/dd/yyyy'
                      error={inputErrors.dateStart}
                      disabled={isEditOffer}
                    />
                  </FormControl>
                  <FormControl margin="none">
                    {/* <CustomPriceFields
                      type="datetime-local"
                      label="End date"
                      value={dateEnd}
                      inputProps={{ maxLength: '63' }}
                      onChange={(e) => {
                        setDateEnd(e.target.value)
                      }}
                      style={{
                        borderRadius: 10
                      }}
                      error={inputErrors.dateEnd}
                    /> */}
                    <TextLabel>
                      End date
                    </TextLabel>
                    <DatePicker
                      value={dateEnd}
                      onChange={(e) => setDateEnd(dayjs(e).format())}
                      error={inputErrors.dateEnd}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="column">
                  <Stack style={{ marginTop: 8, marginBottom: 8 }} direction="row" alignItems="center">
                    <Checkbox
                      checked={shouldIncludeTime}
                      onChange={() => setShouldIncludeTime(!shouldIncludeTime)}
                    />
                    <Typography variant="body2">
                      Include start and end time
                    </Typography>
                  </Stack>
                  {shouldIncludeTime && (
                    <Slide direction="right" in={shouldIncludeTime}>
                      <Stack direction="row" spacing={1}>
                        <FormControl margin="none">
                          <TextLabel>
                            Start time
                          </TextLabel>
                          <TimePicker
                            value={dateStart}
                            onChange={(e) => setDateStart(dayjs(e).format())}
                          />
                        </FormControl>
                        <FormControl margin="none">
                          <TextLabel>
                            End time
                          </TextLabel>
                          <TimePicker
                            value={dateEnd}
                            onChange={(e) => setDateEnd(dayjs(e).format())}
                          />
                        </FormControl>
                      </Stack>
                    </Slide>
                  )}
                </Stack>
                <FormControl margin="normal">
                  <CustomPriceFields
                    label="Coupon Code *"
                    placeholder="Enter a Code"
                    value={redemption}
                    inputProps={{ maxLength: '12' }}
                    onChange={(e) => {
                      setRedemption(e.target.value.trim().toUpperCase())
                    }}
                    style={{
                      borderRadius: 10
                    }}
                    error={isEditOffer ? false : inputErrors.redemption}
                    disabled={isEditOffer}
                  />
                  {!isEditOffer && inputErrors.redemption && (
                    <Typography style={{ marginTop: 4 }} color="error" variant="caption">
                      {inputErrors.redemption}
                    </Typography>
                  )}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth margin="normal">
                <TextLabel style={{}}>
                  Add a cover photo
                </TextLabel>
                <div
                    style={{
                      marginTop: '8px',
                      marginBottom: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                >
                  <ImagePicker
                    disabled={isEditOffer}
                    id="contained-file"
                    onChange={(e) => {
                      setImageSelectedByUserTemp(e.target.files[0])
                      setOpenCropImage(true)
                      setSelectedPost(false)
                      setSelectedPostOnModal(false)
                    }}
                  />
                    <label style={{ alignSelf: 'stretch' }}>
                        {isEditOffer
                          ? <img
                            style={styles.uploadedImage}
                            src={editOfferCover}
                            alt="edit"
                          />
                          : coverImage
                            ? (
                                <Stack direction="column">
                                  <img
                                    style={styles.uploadedImage}
                                    src={URL.createObjectURL(coverImage)}
                                    alt="create | duplicate"
                                    onClick={() => {
                                      document.getElementById('contained-file').click()
                                    }}
                                  />
                                  <Stack direction="row" style={{ marginTop: 12 }}>
                                    <Typography
                                      style={{
                                        cursor: 'pointer',
                                        color: '#007aff'
                                      }}
                                      variant="caption"
                                      onClick={() => {
                                        if (imageSelectedByUser) {
                                          setImageSelectedByUserTemp(imageSelectedByUser)
                                          setOpenCropImage(true)
                                        }
                                      }}
                                    >
                                      Edit image
                                    </Typography>
                                    <Divider orientation='vertical' flexItem style ={{ marginLeft: 8, marginRight: 8 }} />
                                    <Typography
                                      style={{
                                        cursor: 'pointer',
                                        color: '#007aff'
                                      }}
                                      variant="caption"
                                      onClick={() => {
                                        document.getElementById('contained-file').click()
                                      }}
                                    >
                                      Choose another image
                                    </Typography>
                                  </Stack>
                                </Stack>
                              )
                            : (
                                <div
                                  style={styles.uploadedImage}
                                  onClick={() => {
                                    document.getElementById('contained-file').click()
                                  }}
                                >
                                    <Paper
                                        variant="outlined"
                                        style={{
                                          width: '100%',
                                          height: '150px',
                                          borderRadius: 10,
                                          backgroundColor: '#f2f2f2',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                        elevation={0}
                                    >
                                        <Add
                                            htmlColor="#FFFFFF"
                                            style={{
                                              fontSize: 28,
                                              alignSelf: 'center',
                                              backgroundColor: '#bdbdbd',
                                              borderRadius: '50%',
                                              height: 50,
                                              width: 50
                                            }}
                                        />
                                    </Paper>
                                </div>
                              )
                        }
                    </label>
                </div>
              </FormControl>
              {/* {(!isEditOffer && posts.length > 0) && (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      <Typography variant="h6">
                        OR
                      </Typography>
                    </Stack>
                    <FormControl fullWidth margin="normal">
                      <Button
                        variant="contained"
                        onClick={() => setIsPostSelectionOpen(true)}
                      >
                        {selectedPost ? 'Post Selected' : 'Select Post'}
                      </Button>
                    </FormControl>
                  </>
              )} */}
            </Grid>
          </Grid>
        </Grid>
        }
        {openCropImage && (
          <CropImageModal
            open={openCropImage}
            onClose={() => {
              setOpenCropImage(false)
              setImageSelectedByUserTemp(false)
            }}
            aspect={ 9 / 16 }
            onCropComplete={(blob) => {
              let fileType = ''
              if (imageSelectedByUserTemp) {
                fileType = imageSelectedByUserTemp.type
              }
              const file = new File([blob], `nearcast-group-${Date.now()}`, { type: fileType })
              setCoverImage(file)
              setImageSelectedByUser(imageSelectedByUserTemp)
            }}
            imageFile={imageSelectedByUserTemp}
          />
        )}
        <StandardModal
          title="Select a Nearcast"
          isOpen={isPostSelectionOpen}
          onClose={() => {
            setSelectedPostOnModal(selectedPost)
            setIsPostSelectionOpen(false)
          }}
          buttons={[
            {
              title: 'Select',
              color: 'primary',
              onPress: () => {
                setCoverImage(false)
                setSelectedPost(selectedPostOnModal)
                setIsPostSelectionOpen(false)
              }
            }
          ]}
          style={{}}
        >
          <Stack direction="row" flexWrap="wrap">
            {posts.map((post) => {
              return (
                <NearcastCard
                  key={post.post_id}
                  author={post.username}
                  authorCover=""
                  postCover={post.post_image_token}
                  postCaption={post.post_caption}
                  onPress={() => setSelectedPostOnModal(post.post_id)}
                  isSelected={selectedPostOnModal === post.post_id}
                />
              )
            })}
          </Stack>
        </StandardModal>
      <Divider />
    </WideModal>
  )
}

CreateEditOfferModal.defaultProps = {
  type: 'create',
  open: false,
  onClose: () => {
    return undefined
  }
}

export default CreateEditOfferModal
