import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  CardActionArea
} from '@mui/material'
import { ChevronLeft, Add, MoreHoriz } from '@mui/icons-material'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import CardMenu from 'ui/Menu/CardMenu'
import Dialog from 'ui/Dialog/Dialog'

// importing components
import Header from '../../Components/General/Header/Header'
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'
import { formatDate } from '../../Utils/format'
import CreateEditOfferModal from '../../Components/Modals/CreateEditOfferModal'
import SendOfferModal from '../../Components/Modals/SendOfferModal'
import ShareOfferModal from '../../Components/Modals/ShareOfferModal'

// importing apis
import { offers, offersOld } from 'api/offers'
import { businessOfferEnd } from 'api/business/offer/end'
import { businessOfferEdit } from 'api/business/offer/edit'

// importing redux store
import { useAppSelector } from '../../Utils/redux/store'

const OfferCard = ({
  id,
  title,
  cover,
  startedAt,
  redemptionCode,
  isActive,
  onShare,
  onEdit,
  onDuplicate,
  onDelete,
  onSend,
  onActivateToggle
}) => {
  const history = useHistory()
  const [isMenuOpen, setIsMenuOpen] = useState(null)

  return (
    <Card
      style={styles.allOffersOfferCard}
      sx={{
        marginRight: {
          xs: '0px',
          sm: '16px'
        }
      }}
    >
      <IconButton
        style={styles.allOffersOfferCardMenuButton}
        onClick={(e) => setIsMenuOpen(e.currentTarget)}
      >
        <MoreHoriz htmlColor='white' />
      </IconButton>
      <CardMenu
        id={id}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(null)}
        menuItems={[
          {
            text: 'Open',
            onPress: () => history.push(`/offer/${id}`)
          },
          {
            text: 'Send',
            onPress: () => {
              onSend()
              setIsMenuOpen(false)
            }
          },
          {
            text: 'Share',
            onPress: () => {
              onShare()
              setIsMenuOpen(false)
            }
          },
          {
            text: 'Edit',
            onPress: () => {
              onEdit()
              setIsMenuOpen(false)
            }
          },
          {
            text: isActive ? 'Deactivate' : 'Activate',
            onPress: () => {
              onActivateToggle()
              setIsMenuOpen(false)
            }
          },
          {
            text: 'Duplicate',
            onPress: () => {
              onDuplicate()
              setIsMenuOpen(false)
            },
            color: 'primary'
          },
          {
            text: 'Delete',
            onPress: () => {
              onDelete()
              setIsMenuOpen(false)
            },
            color: 'error'
          }
        ]}
      />
      <CardActionArea onClick={() => history.push(`/offer/${id}`)}>
        <CardMedia component='img' height='160' image={cover} alt={title} />
        <CardContent>
          <Stack direction='column'>
            <Typography variant='h6'>{title}</Typography>
            <Typography variant='caption'>Started on {formatDate(startedAt)}</Typography>
            <Typography variant='caption'>Coupon Code: {redemptionCode}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const AllOffers = (props) => {
  // user context
  const { business, jwtToken, activeBusiness } = useContext(UserContext)
  const { openUpgradePlanToAccess } = useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const subscriptionPlan = subscriptionState.subscription.planDetails
  const createOfferCount = subscriptionPlan.createOffer

  const [loading, setLoading] = useState(true)
  const [currentBusinessOffers, setCurrentBusinessOffers] = useState([])
  const [pastBusinessOffers, setPastBusinessOffers] = useState([])
  // common card delete dialog states
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [deleteOfferTitle, setDeleteOfferTitle] = useState(null)
  const [deleteOfferId, setDeleteOfferId] = useState(null)
  const [isDeletingOffer, setIsDeletingOffer] = useState(false)
  // create offer modal states
  const [createOfferType, setCreateOfferType] = useState('create') // 'create' | 'edit' | 'duplicate'
  const [offerId, setOfferId] = useState(false) // only when edit or duplicate: false | string
  const [isCreateOfferOpen, setIsCreateOfferOpen] = useState(false)
  const [isSendOfferOpen, setIsSendOfferOpen] = useState(false)
  // share offer modal states
  const [isShareOfferOpen, setIsShareOfferOpen] = useState(false)
  const [shareOfferDetails, setShareOfferDetails] = useState({
    id: '',
    redemption: '',
    description: ''
  }) // should have: id, redemption and description

  const getBusinessCurrentOffers = useCallback(() => {
    offers(business[activeBusiness].business_id, business[activeBusiness].ID, jwtToken)
      .then((resBody) => {
        if (resBody) {
          setCurrentBusinessOffers(resBody)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [activeBusiness, business, jwtToken])

  const getBusinessPastOffers = useCallback(() => {
    offersOld(business[activeBusiness].business_id, business[activeBusiness].ID, jwtToken)
      .then((resBody) => {
        if (resBody) {
          setPastBusinessOffers(resBody)
        } else {
          setPastBusinessOffers([])
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [activeBusiness, business, jwtToken])

  const onBusinessOfferDelete = () => {
    setIsDeletingOffer(true)
    businessOfferEnd(deleteOfferId, business[activeBusiness].ID, jwtToken)
      .then(() => {
        setDeleteOfferTitle(null)
        setDeleteOfferId(null)
        setIsDeleteOpen(false)
        enqueueSnackbar('Offer successfully delete!', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
      })
      .finally(() => {
        setIsDeletingOffer(false)
        getBusinessCurrentOffers()
        getBusinessPastOffers()
      })
  }

  const onBusinessOfferActiveToggle = (offer) => {
    businessOfferEdit(
      offer.id,
      {
        is_active: !offer.is_active
      },
      business[activeBusiness].ID,
      jwtToken
    )
      .then(() => {
        enqueueSnackbar(
          offer.is_active ? 'Offer successfully deactivated!' : 'Offer successfully activated!',
          { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
      })
      .finally(() => {
        getBusinessCurrentOffers()
        getBusinessPastOffers()
      })
  }

  useEffect(() => {
    if (jwtToken && business.length > 0 && activeBusiness >= 0) {
      getBusinessCurrentOffers()
      getBusinessPastOffers()
    }
  }, [jwtToken, business, activeBusiness, getBusinessCurrentOffers, getBusinessPastOffers])

  return (
    <>
      {!business || business.length <= 0 ? (
        <Redirect to='/' />
      ) : (
        <>
          <Header />
          <Container maxWidth='xl' sx={{ mt: '78px', marginBottom: '74px' }}>
            <Grid container spacing={1}>
              {/* My Offers Past Offers screen */}
              <Grid container item xs={12} lg={9} spacing={1}>
                <Grid item lg={1}>
                  <Stack direction='row' justifyContent='flex-end'>
                    <IconButton
                      style={{
                        alignSelf: 'flex-end',
                        backgroundColor: '#F2F2F2'
                      }}
                      onClick={() => history.push('/dashboard')}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item lg={11}>
                  {/* Current Offers header */}
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                  >
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Typography color='primary' variant='h3'>
                        Current Offers
                      </Typography>
                    </Stack>
                    <Button
                      variant='contained'
                      startIcon={<Add />}
                      onClick={() => {
                        if (createOfferCount === 0) {
                          return openUpgradePlanToAccess()
                        }
                        setCreateOfferType('create')
                        setOfferId(false)
                        setIsCreateOfferOpen(true)
                      }}
                    >
                      Create Offer
                    </Button>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent={{
                      xs: 'center',
                      sm: 'flex-start'
                    }}
                    flexWrap="wrap"
                    style={{
                      marginTop: 30
                    }}
                  >
                    {loading
                      ? (
                      <Stack
                        style={{ width: '100%', marginBottom: 10 }}
                        direction='row'
                        justifyContent='center'
                      >
                        <CircularProgress />
                      </Stack>
                        )
                      : (
                          currentBusinessOffers.map((offer) => {
                            return (
                          <OfferCard
                            key={offer.id}
                            id={offer.id}
                            cover={offer.cover}
                            title={offer.title}
                            startedAt={offer.start_date}
                            redemptionCode={offer.in_store_redemption}
                            isActive={offer.is_active}
                            onShare={() => {
                              setShareOfferDetails({
                                id: offer.id,
                                redemption: offer.in_store_redemption,
                                description: offer.description
                              })
                              setIsShareOfferOpen(true)
                            }}
                            onEdit={() => {
                              setCreateOfferType('edit')
                              setOfferId(offer.id)
                              setIsCreateOfferOpen(true)
                            }}
                            onDuplicate={() => {
                              if (createOfferCount === 0) {
                                return openUpgradePlanToAccess()
                              }
                              setCreateOfferType('duplicate')
                              setOfferId(offer.id)
                              setIsCreateOfferOpen(true)
                            }}
                            onDelete={() => {
                              setDeleteOfferTitle(offer.title)
                              setDeleteOfferId(offer.id)
                              setIsDeleteOpen(true)
                            }}
                            onSend={() => {
                              setOfferId(offer.id)
                              setIsSendOfferOpen(true)
                            }}
                            onActivateToggle={() => onBusinessOfferActiveToggle(offer)}
                          />
                            )
                          })
                        )}
                  </Stack>
                  <Divider style={{ marginTop: 30, marginBottom: 10 }} />
                  {/* Past Offers header */}
                  {pastBusinessOffers.length > 0 && (
                    <>
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        spacing={1}
                      >
                        <Stack direction='row' alignItems='center' spacing={1}>
                          <Typography color='primary' variant='h3'>
                            Past Offers
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction='row'
                        alignItems='flex-start'
                        justifyContent={{
                          xs: 'center',
                          sm: 'flex-start'
                        }}
                        flexWrap='wrap'
                        style={{
                          marginTop: 30
                        }}
                      >
                        {pastBusinessOffers.map((offer) => {
                          return (
                              <OfferCard
                                key={offer.id}
                                id={offer.id}
                                cover={offer.cover}
                                title={offer.title}
                                startedAt={offer.start_date}
                                redemptionCode={offer.in_store_redemption}
                                onShare={() => {
                                  setShareOfferDetails({
                                    id: offer.id,
                                    redemption: offer.in_store_redemption,
                                    description: offer.description
                                  })
                                  setIsShareOfferOpen(true)
                                }}
                                onEdit={() => {
                                  setCreateOfferType('edit')
                                  setOfferId(offer.id)
                                  setIsCreateOfferOpen(true)
                                }}
                                onDuplicate={() => {
                                  setCreateOfferType('duplicate')
                                  setOfferId(offer.id)
                                  setIsCreateOfferOpen(true)
                                }}
                                onDelete={() => {
                                  setDeleteOfferTitle(offer.title)
                                  setDeleteOfferId(offer.id)
                                  setIsDeleteOpen(true)
                                }}
                                onSend={() => {
                                  setOfferId(offer.id)
                                  setIsSendOfferOpen(true)
                                }}
                              />
                          )
                        })}
                      </Stack>
                    </>
                  )}
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} lg={3}>
                <Box style={styles.boxContainer}>
                  <Stack direction='column' justifyContent='center'>
                    <img
                      style={styles.allOffersRightColumnImage}
                      alt='Mobile Marketing Offer'
                      src={require('../../Components/assets/mobileMarketing.svg').default}
                    />
                    <Typography variant='h6'>What is an offer?</Typography>
                    <Typography variant='caption'>
                      <ul style={{ paddingLeft: 14 }}>
                        <li>
                          An offer allows you to share a 15 to 60 second Nearcast with your customes
                          by text or email on a promotion or something for sale
                        </li>
                        <li>
                          Your customers do not need to be Nearcast users for you to create and send
                          offer
                        </li>
                        <li>It's simple, start by clicking "Create offer"</li>
                      </ul>
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Dialog
            isOpen={isDeleteOpen}
            title={`Do you want to delete offer "${deleteOfferTitle}"?`}
            cancelText='No'
            onCancel={() => {
              setIsDeleteOpen(false)
              setDeleteOfferId(null)
              setDeleteOfferTitle(null)
            }}
            confirmText={isDeletingOffer ? 'Deleting' : 'Delete'}
            onConfirm={() => {
              if (isDeletingOffer) {
                return
              }
              onBusinessOfferDelete()
            }}
          />
          {isCreateOfferOpen && (
            <CreateEditOfferModal
              type={createOfferType}
              open={isCreateOfferOpen}
              onClose={() => {
                setIsCreateOfferOpen(false)
                getBusinessCurrentOffers()
                getBusinessPastOffers()
              }}
              offerId={offerId}
            />
          )}
          {isSendOfferOpen && (
            <SendOfferModal
              open={isSendOfferOpen}
              onClose={() => {
                setIsSendOfferOpen(false)
              }}
              offerId={offerId}
            />
          )}
          {isShareOfferOpen && (
            <ShareOfferModal
              offerId={shareOfferDetails.id}
              offerRedemption={shareOfferDetails.redemption}
              offerDescription={shareOfferDetails.description}
              open={isShareOfferOpen}
              onClose={() => setIsShareOfferOpen(false)}
            />
          )}
        </>
      )}
    </>
  )
}

const styles = {
  boxContainer: {
    backgroundColor: '#F4F7FD',
    borderRadius: 10,
    padding: 16
  },
  allOffersOfferCard: {
    position: 'relative',
    width: 300,
    marginBottom: 16,
    borderRadius: 10,
    zIndex: 8,
    cursor: 'pointer'
  },
  allOffersOfferCardMenuButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    backgroundColor: '#505050',
    cursor: 'pointer'
  },
  allOffersRightColumnImage: {
    width: 250,
    alignSelf: 'center'
  }
}

export default AllOffers
