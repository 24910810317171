import { AxiosRequestHeaders } from 'axios'

export const alsoPublicRouteHeadersFormat = (nrctUID, jwt): AxiosRequestHeaders => {
  let headers = {}
  if (nrctUID !== null && jwt !== null) {
    headers = {
      'NRCT-UID': nrctUID,
      Authorization: `Bearer ${jwt}`
    }
  }
  return headers
}
