import axios from 'axios'

/**
 * @function businessAnalyticsBusinessReviews Used to fetch business review scans time series
 * @param {string} businessId - offer id for which to fetch nearcasts for
 * @param {string} startTime - iso string of the start time from when needs to be fetched
 * @param {string} endTime - iso string of the end time till when data needs to be fetched
 * @param {string} nrctUID - user's id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} resolves to list of reviews time stream
 */
export const businessAnalyticsBusinessReviews = (businessId, startTime, endTime, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business-analytics/business/reviews`, {
      business_id: businessId,
      from: startTime,
      to: endTime
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessAnalyticsBusinessReviewsCreated Used to fetch business review created time series
 * @param {string} businessId - offer id for which to fetch nearcasts for
 * @param {string} startTime - iso string of the start time from when needs to be fetched
 * @param {string} endTime - iso string of the end time till when data needs to be fetched
 * @param {string} nrctUID - user's id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} resolves to list of reviews time stream
 */
export const businessAnalyticsBusinessReviewsCreated = (businessId, startTime, endTime, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business-analytics/business/reviews/created`, {
      business_id: businessId,
      from: startTime,
      to: endTime
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessAnalyticsBusinessReviewsRating Used to fetch business aggregate rating
 * @param {string} businessId - offer id for which to fetch nearcasts for
 * @param {string} startTime - iso string of the start time from when needs to be fetched
 * @param {string} endTime - iso string of the end time till when data needs to be fetched
 * @param {string} nrctUID - user's id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} resolves to list of reviews time stream
 */
export const businessAnalyticsBusinessReviewsRating = (businessId, startTime, endTime, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business-analytics/business/reviews/rating`, {
      business_id: businessId,
      from: startTime,
      to: endTime
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
