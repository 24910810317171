import { ContentCopy } from '@mui/icons-material'
import { Stack, Switch, Typography, IconButton, Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import { businessUpdateSchedulePatch } from 'api/business/update/schedule'
import * as dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import WideModal from 'ui/Modals/WideModal'
import TimePicker from 'ui/Pickers/TimePicker/TimePicker'
import { UserContext } from '../../Context/UserContext'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#10B981',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#10B981',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 23,
    height: 23
  },
  track: {
    borderRadius: 13,
    border: 'none',
    backgroundColor: '#E2E8F0',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

function ScheduleModal ({ open, setOpen, data, setData }) {
  const [tempData, setTempData] = useState([])
  const { business, jwtToken, activeBusiness } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const setData = () => {
      const testData = data.business_schedules
      const sorter = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
      }
      testData.sort(function sortByDay (a, b) {
        const day1 = a.day.toLowerCase()
        const day2 = b.day.toLowerCase()
        return sorter[day1] - sorter[day2]
      })
      setTempData(testData)
    }
    if (data.business_schedules) setData()
    // eslint-disable-next-line
  }, [open])

  const handleChange = (event, item) => {
    const otherData = tempData.map((element) => {
      if (element.id === item.id) {
        if (event.target.name === 'is_active') {
          console.log(element)
          if (element.is_active === false && !element.start_time) {
            return {
              ...element,
              [event.target.name]: event.target.checked,
              start_time: dayjs().hour(8).minute(0),
              end_time: dayjs().hour(18).minute(0)
            }
          }
          return {
            ...element,
            [event.target.name]: event.target.checked
          }
        } else if (event.target.name === 'start_time') {
          if (
            dayjs(element.end_time)
              .set('year', 2022)
              .set('month', 0)
              .set('date', 1)
              .isAfter(dayjs(event.target.value)) ||
            !element.end_time
          ) {
            return {
              ...element,
              [event.target.name]: event.target.value
            }
          } else {
            enqueueSnackbar('Start Time should be before End Time', {
              variant: 'warning'
            })
            return element
          }
        } else {
          if (
            dayjs(element.start_time)
              .set('year', 2022)
              .set('month', 0)
              .set('date', 1)
              .isBefore(dayjs(event.target.value)) ||
            !element.start_time
          ) {
            return {
              ...element,
              [event.target.name]: event.target.value
            }
          } else {
            enqueueSnackbar('End Time should be after Start Time', {
              variant: 'warning'
            })
            return element
          }
        }
      } else {
        return element
      }
    })
    setTempData([...otherData])
  }

  const update = async () => {
    let flag = false
    tempData.forEach((item) => {
      if (item.is_active && (!item.start_time || !item.end_time)) flag = true
    })
    if (flag) {
      enqueueSnackbar('Please choose time', { variant: 'error' })
      return
    }
    businessUpdateSchedulePatch(tempData, business[activeBusiness].ID, jwtToken)
      .then((res) => {
        enqueueSnackbar('Schedule Updated!', { variant: 'success' })
        setData((prev) => ({ ...prev, business_schedules: tempData }))
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar('There was some error!', { variant: 'error' })
      })
  }

  return (
    <WideModal
      isOpen={open}
      onClose={() => setOpen(false)}
      title='Add/Modify Business Hours'
      buttons={[
        { title: 'Cancel', color: 'ghost', onPress: () => setOpen(false) },
        { title: 'Save', color: 'primary', onPress: update }
      ]}
    >
      <Stack spacing={2}>
        {tempData.map((item, i) => (
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} key={i} alignItems='center'>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              style={{ width: '100%', maxWidth: 380 }}
              justifyContent={{ xs: 'space-between', md: 'initial' }}
            >
              <Typography sx={{ width: 150 }}>{item.day}</Typography>
              <span style={{ width: 120 }}>
                <IOSSwitch
                  checked={item.is_active}
                  onChange={(event) => {
                    handleChange(event, item)
                  }}
                  name='is_active'
                />
                {item.is_active ? 'Open' : 'Closed'}
              </span>
            </Stack>
            {item.is_active
              ? (
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ width: '100%', maxWidth: 380 }}
              >
                <TimePicker
                  value={dayjs(item.start_time)}
                  name='start_time'
                  label='Opening Time'
                  placeholder='Opening Time'
                  onChange={(val) => {
                    handleChange(
                      {
                        target: {
                          value: dayjs(val)
                            .set('year', 2022)
                            .set('month', 0)
                            .set('date', 1)
                            .format(),
                          name: 'start_time'
                        }
                      },
                      item
                    )
                  }}
                  dense
                />
                <span>-</span>
                <TimePicker
                  value={dayjs(item.end_time)}
                  name='end_time'
                  label='Closing Time'
                  placeholder='Closing Time'
                  onChange={(val) => {
                    handleChange(
                      {
                        target: {
                          value: dayjs(val)
                            .set('year', 2022)
                            .set('month', 0)
                            .set('date', 1)
                            .format(),
                          name: 'end_time'
                        }
                      },
                      item
                    )
                  }}
                  dense
                />
              </Stack>
                )
              : (
              <Stack style={{ width: '100%', maxWidth: 380 }}></Stack>
                )}
            {i === 1 && (
              <Tooltip title="On pressing this option, all active day timings will be updated to Monday's timings!">
                <IconButton
                  onClick={() => {
                    let otherData = []
                    if (tempData[1].is_active === false) {
                      otherData = tempData.map((element, index) => {
                        if (element.is_active === false && index !== 1) {
                          return element
                        }
                        return {
                          ...element,
                          is_active: index === 1 || element.is_active,
                          start_time: dayjs().hour(8).minute(0),
                          end_time: dayjs().hour(18).minute(0)
                        }
                      })
                    } else {
                      otherData = tempData.map((element) => {
                        if (element.is_active === false) {
                          return element
                        }
                        return {
                          ...element,
                          start_time: tempData[1].start_time,
                          end_time: tempData[1].end_time
                        }
                      })
                    }
                    setTempData([...otherData])
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        ))}
      </Stack>
    </WideModal>
  )
}

export default ScheduleModal
