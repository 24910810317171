import * as React from 'react'
import {
  Typography,
  Stack,
  IconButton,
  Card,
  CardContent,
  Tooltip
} from '@mui/material'
import { PriorityHigh } from '@mui/icons-material'

// importing component
import Rating from '../../Rating/Rating'

export interface AggregateRatingCardProps {
  title: string;
  helper: string;
  startTime: string;
  endTime: string;
  dataRequest: () => Promise<any>;
  style?: any;
}

const AggregateRatingCard: React.FC<AggregateRatingCardProps> = ({
  title,
  helper,
  startTime,
  endTime,
  dataRequest,
  style = {}
}) => {
  const [data, setData] = React.useState([0, 0, 0, 0, 0]) // 5 stars, 4 stars, 3 stars, 2 stars, 1 stars
  const [loading, setLoading] = React.useState(true)

  const getBusinessAnalyticsData = () => {
    dataRequest()
      .then((data) => {
        const rating = data.body.rating
        const value = data.body.value
        const formattedRating = new Array(5).fill(0).map((_, index) => {
          const hasRatingValueIndex = rating.findIndex((val) => Number(val) === (5 - index))
          return hasRatingValueIndex === -1 ? 0 : Number(value[hasRatingValueIndex])
        })
        setData(formattedRating)
      })
      .catch((err) => console.log(err.message))
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (dataRequest && loading) {
      getBusinessAnalyticsData()
    }
  }, [dataRequest, startTime, endTime, loading])

  return (
    <Card
      elevation={0}
      sx={{
        width: {
          xs: '100%',
          sm: '280px'
        },
        marginRight: {
          xs: 'auto',
          sm: '8px'
        },
        marginBottom: '8px'
      }}
      style={{
        borderRadius: 10,
        border: '1px solid #EBEBEB',
        ...style
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            {title}
          </Typography>
          <Tooltip title={helper}>
            <IconButton
              style={{
                backgroundColor: 'grey',
                width: 16,
                height: 16,
                color: 'white'
              }}>
              <PriorityHigh style={{ width: 16 }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="column" spacing={0} sx={{ marginTop: 2 }}>
          {data.map((rating, index) => {
            return (
              <Stack key={index} direction="row" spacing={1}>
                <Rating maxValue={5} value={5 - index} style={{ marginRight: 4 }} />
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  {rating} rating
                </Typography>
              </Stack>
            )
          })}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default AggregateRatingCard
