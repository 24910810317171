import axios from 'axios'

// importing helpers (package)
import { getFileUploadProgressInPercentage } from 'helpers/browser'

/**
 * @function businessResponsePreSign Upload a response video to S3
 * @param {string} reviewIds - review's IDs
 * @param {string} videoFile - JS video File
 * @param {string} nrctUID - id of the user
 * @param {string} jwt - jwt token of the user
 * @param {string} onVideoUploadProgress - onVideoUploadProgress
 * @returns {Promise} Promise resolution represents true on success
 */
export const businessResponsePreSign = (
  reviewIds = [],
  description,
  videoFile,
  nrctUID,
  jwt,
  onVideoUploadProgress = (percentage) => {}
) => {
  let resTemp = {}
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/review/respond/pre_sign_url`, {
      review_ids: reviewIds,
      description: description,
      is_video_response: Boolean(videoFile)
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(async (res) => {
        resTemp = res
        await axios.put(res.data.body.URL, videoFile, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Context-Type': videoFile.type
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = getFileUploadProgressInPercentage(progressEvent)
            onVideoUploadProgress(uploadPercentage)
          }
        })
      })
      .then(() => resolve(resTemp.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessReviewRespond Sends the review respond video to customer via email
 * @param {string} reviewIds - review's IDs
 * @param {string} description - message from the business
 * @param {string} key - provided by pre-sign upload
 * @param {string} nrctUID - id of the user
 * @param {string} jwt - jwt token of the user
 * @returns {Promise} Promise resolution represents true on success
 */
// export const businessReviewRespond = (reviewIds = [], description, key, nrctUID, jwt) => {
//   return new Promise((resolve, reject) => {
//     axios.post(`${process.env.REACT_APP_BUSINESS_URL}/review/respond`, {
//       review_ids: reviewIds,
//       description: description,
//       response_key: key
//     }, {
//       headers: {
//         'NRCT-UID': nrctUID,
//         Authorization: `Bearer ${jwt}`
//       }
//     })
//       .then(() => resolve(true))
//       .catch((err) => {
//         reject(err)
//       })
//   })
// }

/**
 * @function businessSendReviewResponse Wraps video uploading and response route calls in one call
 * @param {string} reviewId - review's ID
 * @param {string} description - message from the business
 * @param {string} videoFile - JS video File
 * @param {string} nrctUID - id of the user
 * @param {string} jwt - jwt token of the user
 * @returns {Promise} Promise resolution represents true on success
 */
// export const businessSendReviewResponse = (reviewId, description, videoFile, nrctUID, jwt) => {
//   return new Promise((resolve, reject) => {
//     businessResponsePreSign(reviewId, description, videoFile, nrctUID, jwt)
//       .then((resBody) => businessReviewRespond(reviewId, description, resBody.uploadId, nrctUID, jwt))
//       .then((data) => resolve(data))
//       .catch((err) => reject(err))
//   })
// }
