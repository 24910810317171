import React from 'react'
import { Typography, Stack, Button } from '@mui/material'

// importing ui
import ShareOfferModal from 'ui/Custom/Modals/ShareOfferModal'
import StandardModal from 'ui/Modals/StandardModal'

// importing component
import ReviewActionModal from '../Components/Modals/ReviewActionModal/ReviewActionModal'
import PublishModal, { PublishModalPostType } from '../Components/Modals/PublishModal/PublishModal'
import AddEditContactModal, { AddEditContactModalContactType } from '../Components/Modals/AddEditContactModal/AddEditContactModal'
import DownloadReviewsModal from '../Components/Modals/DownloadReviewsModal/DownloadReviewsModal'
import UpgradePlanToAccess from '../Components/Modals/UpgradePlanToAccess/UpgradePlanToAccess'
import UpgradePlanPayments from '../Components/Modals/UpgradePlanPayment/UpgradePlanPayment'
import FreeTrialModal from '../Components/Modals/FreeTrialModal/FreeTrialModal'
import AuthModal from '../Components/Auth/AuthModal/AuthModal'
import SettingsModal from '../Components/Modals/SettingsModal/SettingsModal'

export interface GlobalFuncContextValueType {
  openShareModal: (
    whatsBeingShared: string,
    whatsBeingSharedDescription: string,
    shareableLinkPath: string,
    overrideURL?: string | undefined
  ) => void,
  openReviewActionModal: (
    type: 'hide' | 'response',
    selectedNearcasts: {
      username: string;
      userId: string;
      reviewId: string;
    }[],
    onSuccess: () => void
  ) => void,
  openPublishModal: (posts: PublishModalPostType[], offerLink: string) => void,
  openAddEditContactModal: (
    isEdit: boolean,
    onSuccess: () => void,
    contact?: AddEditContactModalContactType,
  ) => void;
  openDownloadReviewsModal: (
    reviewIds: string[],
    type: 'withoutWatermark' | 'withWatermark'
  ) => void;
  openUpgradePlanToAccess: () => void;
  openUpgradePayment: (mainTitle: string) => void;
  openFreeTrialModal: (onSuccess: () => void) => void;
  openAuthModal: (isLogin: boolean) => void;
  openSettingsModal: () => void;
  openDialog: (config: {
    title: string;
    description: string;
    buttonList: {
      text: string;
      color: 'primary' | 'secondary';
      variant: 'contained' | 'outlined' | 'text',
      onClick: () => void
    }[]
  }) => void;
}

const GlobalFuncContextValueInitial: GlobalFuncContextValueType = {
  openShareModal: (w: string, wd: string, s: string) => {},
  openReviewActionModal: (t: 'hide', sn: any) => {},
  openPublishModal: (p: PublishModalPostType[], o: string) => {},
  openAddEditContactModal: (e: false) => {},
  openDownloadReviewsModal: (e: string[], t: 'withoutWatermark' | 'withWatermark') => {},
  openUpgradePlanToAccess: () => {},
  openUpgradePayment: (m: string) => {},
  openFreeTrialModal: (os: () => void) => {},
  openAuthModal: (isLogin: boolean) => {},
  openSettingsModal: () => {},
  openDialog: () => {}
}

export const GlobalFuncContext = React.createContext(GlobalFuncContextValueInitial)

const GlobalFuncHOC = ({ children }) => {
  // state for share offer modal
  const shareOfferModalInitial = {
    isOpen: false,
    whatsBeingShared: '',
    whatsBeingSharedDescription: '',
    shareableLinkPath: '',
    overrideURL: undefined
  }
  const [shareOfferModal, setShareOfferModal] = React.useState(shareOfferModalInitial)
  // state for review action modal
  const reviewActionModalInitial: {
    isOpen: boolean;
    type: 'hide' | 'response';
    selectedNearcasts: {
      username: string;
      userId: string;
      reviewId: string;
    }[];
    onSuccess: () => void;
  } = {
    isOpen: false,
    type: 'hide',
    selectedNearcasts: [],
    onSuccess: () => {}
  }
  const [reviewActionModal, setReviewActionModal] = React.useState(reviewActionModalInitial)
  // state for publish modal
  const publishModalInitial = {
    isOpen: false,
    posts: [],
    offerLink: ''
  }
  const [publishModal, setPublishModal] = React.useState(publishModalInitial)
  const addEditContactModalInitial: {
    isOpen: boolean;
    isEdit: boolean;
    onSuccess?: () => void;
    contact?: AddEditContactModalContactType;
  } = {
    isOpen: false,
    isEdit: false
  }
  const [addEditContactModal, setAddEditContactModal] = React.useState(addEditContactModalInitial)
  const downloadReviewsInitial = {
    reviewIds: [''],
    type: ('withWatermark' as 'withWatermark' | 'withoutWatermark'),
    isOpen: false
  }
  const [downloadReviewsState, setDownloadReviewState] = React.useState(downloadReviewsInitial)
  // state for upgrade plan access
  const [upgradePlanToAccessState, setUpgradePlanToAccessState] = React.useState(false)
  // state for upgrade plan payments
  const upgradePlanPaymentInitial: {
    isOpen: boolean;
    mainTitle: string | undefined;
  } = {
    isOpen: false,
    mainTitle: undefined
  }
  const [upgradePlanPaymentState, setUpgradePlanPaymentState] = React.useState(upgradePlanPaymentInitial)
  // state for free trial modal
  const freeTrialInitial = {
    isOpen: false,
    onSuccess: () => {}
  }
  const [freeTrialState, setFreeTrialState] = React.useState(freeTrialInitial)
  // state for auth modal
  const authModalInitial = {
    isOpen: false,
    isLogin: false
  }
  const [authModalState, setAuthModalState] = React.useState(authModalInitial)
  // state for settings modal
  const [settingsState, setSettingsState] = React.useState(false)
  // state for dialog
  const dialogInitial = {
    isOpen: false,
    config: {
      title: '',
      description: '',
      buttonList: [] as {
        text: string;
        color: 'primary' | 'secondary';
        variant: 'contained' | 'outlined' | 'text',
        onClick: () => void
      }[]
    }
  }
  const [dialogState, setDialogState] = React.useState(dialogInitial)

  const value: GlobalFuncContextValueType = {
    openShareModal: (w, wd, s, b = undefined) => {
      setShareOfferModal({
        isOpen: true,
        whatsBeingShared: w,
        whatsBeingSharedDescription: wd,
        shareableLinkPath: s,
        overrideURL: b
      })
    },
    openReviewActionModal: (t, sn, ss) => {
      setReviewActionModal({
        isOpen: true,
        type: t,
        selectedNearcasts: sn,
        onSuccess: ss
      })
    },
    openPublishModal: (p: PublishModalPostType[], o: string) => {
      setPublishModal({
        isOpen: true,
        posts: p,
        offerLink: o
      })
    },
    openAddEditContactModal: (ie, os, c) => {
      console.log(ie, c)
      setAddEditContactModal({
        isOpen: true,
        isEdit: ie,
        onSuccess: os,
        contact: c
      })
    },
    openDownloadReviewsModal: (rIds, t) => {
      setDownloadReviewState({
        reviewIds: rIds,
        type: t,
        isOpen: true
      })
    },
    openUpgradePlanToAccess: () => setUpgradePlanToAccessState(true),
    openUpgradePayment: (m) => {
      setUpgradePlanPaymentState({
        isOpen: true,
        mainTitle: m
      })
    },
    openFreeTrialModal: (os) => {
      setFreeTrialState({
        isOpen: true,
        onSuccess: os
      })
    },
    openAuthModal: (iL) => {
      setAuthModalState({
        isOpen: true,
        isLogin: iL
      })
    },
    openSettingsModal: () => setSettingsState(true),
    openDialog: (config) => {
      setDialogState({
        isOpen: true,
        config: config
      })
    }
  }

  return (
    <GlobalFuncContext.Provider value={value}>
      {children}
      <ShareOfferModal
        isOpen={shareOfferModal.isOpen}
        onClose={() => setShareOfferModal(shareOfferModalInitial)}
        whatsBeingShared={shareOfferModal.whatsBeingShared}
        whatsBeingSharedDescription={shareOfferModal.whatsBeingSharedDescription}
        shareableLinkPath={shareOfferModal.shareableLinkPath}
        overrideURL={shareOfferModal.overrideURL}
      />
      <ReviewActionModal
        isOpen={reviewActionModal.isOpen}
        onClose={() => setReviewActionModal(reviewActionModalInitial)}
        onSuccess={reviewActionModal.onSuccess}
        type={reviewActionModal.type}
        selectedNearcasts={reviewActionModal.selectedNearcasts}
      />
      <PublishModal
        posts={publishModal.posts}
        offerLink={publishModal.offerLink}
        isOpen={publishModal.isOpen}
        onClose={() => setPublishModal(publishModalInitial)}
      />
      <AddEditContactModal
        isEdit={addEditContactModal.isEdit}
        contact={addEditContactModal.contact}
        onSuccess={addEditContactModal.onSuccess}
        isOpen={addEditContactModal.isOpen}
        onClose={() => setAddEditContactModal(addEditContactModalInitial)}
      />
      <DownloadReviewsModal
        reviewIds={downloadReviewsState.reviewIds}
        type={downloadReviewsState.type}
        isOpen={downloadReviewsState.isOpen}
        onClose={() => setDownloadReviewState(downloadReviewsInitial)}
      />
      <UpgradePlanToAccess open={upgradePlanToAccessState} onClose={() => setUpgradePlanToAccessState(false)} />
      <UpgradePlanPayments
        mainTitle={upgradePlanPaymentState.mainTitle}
        open={upgradePlanPaymentState.isOpen}
        onClose={() => setUpgradePlanPaymentState(upgradePlanPaymentInitial)}
      />
      <FreeTrialModal
        open={freeTrialState.isOpen}
        onClose={() => setFreeTrialState(freeTrialInitial)}
        onSuccess={freeTrialState.onSuccess}
      />
      <AuthModal
        isOpen={authModalState.isOpen}
        isLogin={authModalState.isLogin}
        setIsLogin={(b) => {
          setAuthModalState({
            ...authModalState,
            isLogin: b
          })
        }}
        onClose={() => setAuthModalState(authModalInitial)}
      />
      <SettingsModal
        open={settingsState}
        onClose={() => setSettingsState(false)}
      />
      {dialogState.isOpen && (
        <StandardModal
          isOpen={dialogState.isOpen}
          onClose={() => setDialogState(dialogInitial)}
          title={dialogState.config.title}
        >
          <Typography variant="body2">
            {dialogState.config.description}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            sx={{
              marginTop: 4
            }}
          >
            {dialogState.config.buttonList.map((btnProps, index) => {
              return (
                <Button
                  key={index}
                  color={btnProps.color}
                  variant={btnProps.variant}
                  onClick={() => {
                    btnProps.onClick()
                    setDialogState(dialogInitial)
                  }}
                  disableElevation
                >
                  {btnProps.text}
                </Button>
              )
            })}
          </Stack>
        </StandardModal>
      )}
    </GlobalFuncContext.Provider>
  )
}

export default GlobalFuncHOC
