/**
 * @function businessCoverView the url to retrieve the cover of the business
 * @param {string} businessId - what the param does
 * @returns {Promise} Promise object represents the cover image
 */
export const businessCoverView = (businessId) => {
  return `${
    process.env.REACT_APP_BUSINESS_URL
  }/business-view/cover?business_id=${businessId}&updated_at=${Date.now()}`
}
