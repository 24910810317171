import {
  CircularProgress,
  Stack,
  Typography
} from '@mui/material'
import * as React from 'react'
import { useSnackbar } from 'notistack'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'

// importing api
import { businessReviewDownload, businessReviewDownloadPro } from 'api/business/download'

// importing components
import { UserContext } from '../../../Context/UserContext'

// importing redux store
import { useAppDispatch } from '../../../Utils/redux/store'
import { updateUserSubscriptionDetailsInRedux } from '../../../Utils/redux/actions/subscriptionAction'

export interface DownloadReviewsModalProps {
  reviewIds: string[];
  type: 'withoutWatermark' | 'withWatermark'
  isOpen: boolean;
  onClose: () => void;
}

const DownloadReviewsModal: React.FC<DownloadReviewsModalProps> = (props) => {
  const { isOpen, onClose, reviewIds } = props
  const { business, activeBusiness, jwtToken } = React.useContext(UserContext)
  const reduxDispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  // component state
  const [step, setStep] = React.useState<'requesting' | 'loading' | 'done'>('requesting')

  const _onClose = () => {
    if (step !== 'done') {
      return enqueueSnackbar('Please wait we are processing your request!', { variant: 'info' })
    }
    setStep('requesting')
    onClose()
  }

  const withWaterMarkDownload = () => {
    businessReviewDownload(
      reviewIds,
      business[activeBusiness].ID,
      jwtToken
    )
      .then(async (resBody) => {
        await new Promise((resolve, reject) => setTimeout(resolve, 1000 * 10))
        setStep('loading')
        for (let i = 0; i < resBody.length; i++) {
          const { url, review } = resBody[i]
          const a = document.createElement('a')
          a.href = `${url}&download=${review}`
          a.target = '_blank'
          a.download = review
          a.click()
          await new Promise((resolve, reject) => setTimeout(resolve, 1000 * 1))
        }
        setStep('done')
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        reduxDispatch(updateUserSubscriptionDetailsInRedux())
      })
  }

  const withoutWaterMarkDownload = () => {
    businessReviewDownloadPro(
      reviewIds,
      business[activeBusiness].ID,
      jwtToken
    )
      .then(async (resBody) => {
        await new Promise((resolve, reject) => setTimeout(resolve, 1000 * 15))
        setStep('loading')
        await new Promise((resolve, reject) => setTimeout(resolve, 1000 * 15))
        for (let i = 0; i < resBody.length; i++) {
          const { url, review } = resBody[i]
          const a = document.createElement('a')
          a.href = `${url}&download=${review}`
          a.target = '_blank'
          a.download = review
          a.click()
          await new Promise((resolve, reject) => setTimeout(resolve, 1000 * 1))
        }
        setStep('done')
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        reduxDispatch(updateUserSubscriptionDetailsInRedux())
      })
  }

  React.useEffect(() => {
    if (isOpen) {
      switch (props.type) {
        case 'withWatermark':
          return withWaterMarkDownload()
        case 'withoutWatermark':
          return withoutWaterMarkDownload()
        default:
          console.log('Not supported type')
      }
    }
  }, [reviewIds])

  const renderInternalState = () => {
    if (step === 'requesting') {
      return (
        <>
          <CircularProgress />
          <Typography textAlign="center" variant="h4" sx={{ marginTop: 4 }}>
            Please wait, we are preparing your download ⏳
          </Typography>
        </>
      )
    }

    if (step === 'loading') {
      return (
        <>
          <CircularProgress />
          <Typography textAlign="center" variant="h4" sx={{ marginTop: 4 }}>
            Downloading Now 💾
          </Typography>
        </>
      )
    }

    return (
      <>
        <img
          src={require('./download.gif')}
          style={{
            width: 200,
            height: 200
          }}
        />
        <Typography textAlign="center" variant="h4">
          Successfully Downloaded ✌️
        </Typography>
      </>
    )
  }

  return (
      <StandardModal
        isOpen={isOpen}
        onClose={_onClose}
        title="Downloading Review(s)"
      >
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              minHeight: 300
            }}
        >
          {renderInternalState()}
        </Stack>
      </StandardModal>
  )
}

export default DownloadReviewsModal
