import axios from 'axios'

/**
 * @function businessOfferEdit Used to edit an offer
 * @param {string} offerId - offer's id, eg: "qwe....948ijg"
 * @param {object} offer - the props of offer to update (supports end_date or in_active)
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful offer edit
 */
export const businessOfferEdit = (offerId, offer, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_BUSINESS_URL}/offer/edit`, {
      offer: offer
    }, {
      params: {
        offer_id: offerId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
