import React from 'react'
import { Box, BoxTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

const CustomBox: OverridableComponent<BoxTypeMap<{}, 'div'>> = (allProps) => {
  const { children, style, ...props } = allProps
  const styles = {
    boxContainer: {
      backgroundColor: '#F4F7FD',
      borderRadius: 10,
      padding: 16,
      ...style
    }
  } as const
  return (
    <Box style={styles.boxContainer} {...props}>
      {children}
    </Box>
  )
}

export default CustomBox
