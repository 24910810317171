import React, { useState } from 'react'
import {
  Button
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import Menu from 'ui/Menu/CardMenu'

export interface DropDownMenuOption {
  text: string;
  value: string | number;
  icon?: JSX.Element;
  disabled?: boolean;
}

export interface DropDownMenuProps {
  selected: DropDownMenuOption;
  options: DropDownMenuOption[];
  onSelectedPress: (selectedOption: DropDownMenuOption) => void;
  style?: any;
}

const DropDownMenu: React.FC<DropDownMenuProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<any | null>(null)
  return (
    <div>
      <Button
        style={{
          height: 'auto',
          width: 'auto',
          padding: 2,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: '#E0E0E0',
          color: 'black',
          ...props.style
        }}
        variant="text"
        endIcon={<ArrowDropDown />}
        onClick={(e) => setIsMenuOpen(e.currentTarget)}
      >
        {props.selected.text}
      </Button>
      <Menu
        id="basic-menu"
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(null)}
        menuItems={props.options.map((option) => ({
          text: option.text,
          icon: option.icon,
          onPress: () => {
            props.onSelectedPress(option)
            setIsMenuOpen(null)
          },
          color: props.selected.value === option.value ? 'primary' : 'black',
          disabled: option.disabled
        }))}
      />
    </div>
  )
}

export default DropDownMenu
