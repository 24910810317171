import {
  Container,
  Grid,
  Typography,
  Stack,
  IconButton,
  CircularProgress,
  Fade,
  styled,
  Button,
  Collapse
} from '@mui/material'
import {
  ChevronLeft,
  Edit,
  Delete,
  ContentCopy,
  Share,
  Send,
  QrCode,
  Settings,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material'
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { Redirect, useParams, useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas'

// importing UI
import StandardModal from 'ui/Modals/StandardModal'
import NearcastCard from 'ui/Cards/NearcastCard/NearcastCard'
import Dialog from 'ui/Dialog/Dialog'
import RightItemCard from 'ui/Cards/RightItemCard/RightItemCard'
import PostViewModal from 'ui/Custom/Modals/PostViewModal/PostViewModal'
import Rating from 'ui/Custom/Rating/Rating'
import InsightGraphCard from 'ui/Custom/Stats/InsightGraphCard/InsightGraphCard'
import AggregateRatingCard from 'ui/Custom/Stats/AggregateRatingCard/AggregateRatingCard'

// importing components
import Header from '../../Components/General/Header/Header'
import DropDownMenu from '../../Components/DropDownMenu/DropDownMenu'
import ShareOfferModal from '../../Components/Modals/ShareOfferModal'
import CreateEditOfferModal from '../../Components/Modals/CreateEditOfferModal'
import SendOfferModal from '../../Components/Modals/SendOfferModal'
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'

// importing apis
import { businessOfferDetails } from 'api/business/offer/details'
import { businessOfferEnd } from 'api/business/offer/end'
import { businessReviewHide } from 'api/business/review/hide'
// analytic apis
import { businessAnalyticsOfferScans } from 'api/business/analytics/offer'
import { businessAnalyticsReviewsCreated, businessAnalyticsReviewsViews } from 'api/business/analytics/reviews'
import { businessAnalyticsOfferRating } from 'api/business/analytics/rating'
import { businessReviews } from 'api/business/review/index'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { postDownload } from 'api/post/download'

// importing redux store
import { useAppSelector } from '../../Utils/redux/store'

// used to control if insights should be displayed or not
const isOfferInsightsOn = true

// some helpful data
const allOfferReviewSortingTypes = [
  {
    text: 'Latest First',
    value: 'latest'
  },
  {
    text: 'Oldest First',
    value: 'oldest'
  },
  {
    text: 'Low Rating First',
    value: 'low-rating'
  },
  {
    text: 'High Rating First',
    value: 'high-rating'
  },
  {
    text: 'Popular First',
    value: 'popularity'
  }
]
const howManyDaysOptions = [
  {
    text: 'Last 7 Days',
    value: 7
  },
  {
    text: 'Last 1 Month',
    value: 30
  },
  {
    text: 'Last 1 Year',
    value: 365
  }
]

const TextButton = styled(Button)(() => ({
  margin: 0,
  padding: 0,
  paddingLeft: 4,
  paddingRight: 4,
  minHeight: 0,
  height: 'min-content',
  minWidth: 0
}))

const OfferAnalytics = (props) => {
  // user context
  const { business, jwtToken, activeBusiness, preferences } = useContext(UserContext)
  const {
    openReviewActionModal,
    openShareModal,
    openPublishModal,
    openDownloadReviewsModal,
    openUpgradePlanToAccess
  } = useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { state } = useLocation()
  const { id } = useParams()

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const subscriptionPlan = subscriptionState.subscription.planDetails
  const createOfferCount = subscriptionPlan.createOffer
  const canHideVideosFromBusinessPublicPage = subscriptionPlan.hideVideosFromBusinessPublicPage
  const canDownloadVideoWithoutWatermark = subscriptionPlan.downloadVideoWithoutWatermark

  const [loading, setLoading] = useState(true)
  const [offerDetails, setOfferDetails] = useState(null)
  const [howManyDays, setHowManyDays] = useState(howManyDaysOptions[0])
  const [offerInsightData, setOfferInsightData] = useState([])
  // offer nearcast
  // states for business nearcast reviews
  const offerReviewStateEmpty = {
    nearcasts: [],
    loading: true,
    page: 1
  }
  const [offerReviewState, setOfferReviewState] = useState(offerReviewStateEmpty)
  const [offerReviewOrderBy, setOfferReviewOrderBy] = useState(allOfferReviewSortingTypes[0])
  const [selectedPosts, setSelectedPosts] = useState([])
  const hasSelectedNonAnonymousPost = React.useMemo(() => {
    return !!offerReviewState.nearcasts.find((nearcast) => {
      if (selectedPosts.includes(nearcast.reviewId)) {
        return !!nearcast.postId
      }

      return false
    })
  }, [selectedPosts])
  // state for post display
  const postViewStateEmpty = {
    index: 0,
    nearcast: {},
    isOpen: false
  }
  const [postViewState, setPostViewState] = useState(postViewStateEmpty)
  // offer deletion states
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  // offer share states
  const [isShareOfferOpen, setIsShareOfferOpen] = useState(false)
  // offer send states
  const [isSendOfferOpen, setIsSendOfferOpen] = useState(false)
  // create edit offer modal states
  const [createEditType, setCreateEditType] = useState('edit') // 'edit' or 'duplicate'
  const [isCreateEditOpen, setIsCreateEditOpen] = useState(false)
  // state for show qr code
  const [isShowQrOpen, setIsShowQrOpen] = useState(false)
  // ref for qr code download
  const qrCodeRef = useRef(null)
  // state for collapsible options on mobile view
  const [isOptionsCollapsed, setIsOptionsCollapsed] = useState(true)

  const offerInsightGraphsDataGenerator = (days) => {
    return [
      {
        title: 'QR Codes Scanned',
        helper: 'The number of times your Offer QR code has been scanned by a device',
        startTime: dayjs().subtract(days, 'day').toISOString(),
        endTime: dayjs().toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            businessAnalyticsOfferScans(
              id,
              dayjs().subtract(days, 'day').toISOString(),
              dayjs().toISOString(),
              business[activeBusiness].ID,
              jwtToken
            )
              .then((resData) => resolve(resData.body))
              .catch((err) => reject(err))
          })
        }
      },
      {
        title: 'Reviews Created',
        helper: 'The number of offer reviews that your customers have made for this offer',
        startTime: dayjs().subtract(days, 'day').toISOString(),
        endTime: dayjs().toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            businessAnalyticsReviewsCreated(
              id,
              dayjs().subtract(days, 'day').toISOString(),
              dayjs().toISOString(),
              business[activeBusiness].ID,
              jwtToken
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        }
      },
      {
        title: 'Total Review Views',
        helper: 'The number of times potential customers viewed Reviews created for this offer.',
        startTime: dayjs().subtract(days, 'day').toISOString(),
        endTime: dayjs().toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            businessAnalyticsReviewsViews(
              id,
              dayjs().subtract(days, 'day').toISOString(),
              dayjs().toISOString(),
              business[activeBusiness].ID,
              jwtToken
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        }
      },
      {
        title: 'Aggregate Rating',
        helper: 'The total number of aggregate rating of each star.',
        startTime: dayjs().subtract(days, 'day').toISOString(),
        endTime: dayjs().toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            businessAnalyticsOfferRating(
              id,
              dayjs().subtract(days, 'day').toISOString(),
              dayjs().toISOString(),
              business[activeBusiness].ID,
              jwtToken
            )
              .then((resData) => resolve(resData))
              .catch((err) => reject(err))
          })
        },
        isRating: true
      }
    ]
  }

  const getBusinessOfferDetails = useCallback(() => {
    businessOfferDetails(id)
      .then((resBody) => setOfferDetails(resBody))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [id])

  const getOfferReviews = useCallback((
    pageNumber = offerReviewState.page,
    sortBy = offerReviewOrderBy.value
  ) => {
    setOfferReviewState({
      nearcasts: [],
      loading: true,
      page: pageNumber
    })
    businessReviews(
      business[activeBusiness].business_id,
      sortBy,
      pageNumber,
      id,
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resData) => {
        const formattedNearcasts = resData.body.map((post) => ({
          postId: post.post_id,
          username: post.username,
          userCover: personalAccountAvatar(post.personal_account_id),
          userId: post.personal_account_id,
          postCover: post.post_image_token,
          postCaption: post.caption || '',
          userId: post.personal_account_id,
          reviewId: post.id,
          postPlaybackToken: post.post_playback_token,
          postSticker: post.stickers,
          postMusic: post.music,
          rating: post.rating
        }))
        setOfferReviewState({
          nearcasts: formattedNearcasts,
          loading: false,
          page: pageNumber
        })
      })
      .catch((err) => console.log(err))
  }, [activeBusiness, business, jwtToken, offerDetails, id])

  const onBusinessOfferDelete = () => {
    setIsDeleting(true)
    businessOfferEnd(
      id,
      business[activeBusiness].ID,
      jwtToken
    )
      .then(() => {
        history.push('/offers')
        enqueueSnackbar('Offer deleted successfully', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
        setIsDeleting(false)
      })
  }

  useEffect(() => {
    setOfferInsightData(offerInsightGraphsDataGenerator(howManyDaysOptions[0].value))
  }, [])

  useEffect(() => {
    if (jwtToken && business.length > 0 && (activeBusiness >= 0)) {
      getBusinessOfferDetails()
      getOfferReviews()
    };
  }, [jwtToken, business, activeBusiness, getBusinessOfferDetails])

  useEffect(() => {
    if (state) {
      // if navigated with isNewOffer open the send offer modal
      if (state.isNewOffer) {
        getBusinessOfferDetails()
        getOfferReviews()
        setIsSendOfferOpen(true)
      }
    }
  }, [state])

  const getQrUrl = () => {
    const origin = window.location.origin
    if (origin.includes('staging') || origin.includes('localhost')) {
      return `https://staging.nearcast.com/offer/${offerDetails.in_store_redemption}`
    } else {
      return `https://nearcast.com/offer/${offerDetails.in_store_redemption}`
    }
  }

  const downloadQrCode = (ref) => {
    html2canvas(ref.current, {
      useCORS: true,
      allowTaint: true,
      windowWidth: ref.current.offsetWidth,
      windowHeight: ref.current.offsetHeight,
      scale: 1
    })
      .then((canvas) => {
        const qrImage = canvas.toDataURL('image/png')
        const tempATag = document.createElement('a')
        tempATag.href = qrImage
        tempATag.download = `qrcode${Date.now()}.png`
        tempATag.click()
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  const onSelectReviewAction = (option) => {
    const nearcasts = offerReviewState.nearcasts.filter((nearcast) => {
      return selectedPosts.includes(nearcast.reviewId)
    })
    switch (option.value) {
      case 0:
        return openPublishModal(
          nearcasts.map((nearcast) => ({
            postId: nearcast.postId,
            creatorName: nearcast.username
          })),
          offerDetails.offer_short_link
        )
      case 1:
        return openReviewActionModal(
          'response',
          nearcasts.map((nearcast) => ({
            username: nearcast.username,
            userId: nearcast.userId,
            reviewId: nearcast.reviewId
          })),
          () => {
            getOfferReviews()
            setSelectedPosts([])
          }
        )
      case 2:
        // only allow user to hide nearcasts if canHideVideosFromBusinessPublicPage is not zero
        if (canHideVideosFromBusinessPublicPage === 0) {
          return openUpgradePlanToAccess()
        }
        if (hasSelectedNonAnonymousPost === false) {
          // only anonymous posts are selected, so don't show the modal
          // directly hide
          businessReviewHide(
            nearcasts.map((nearcast) => {
              return nearcast.reviewId
            }),
            business[activeBusiness].ID,
            jwtToken
          )
            .then(() => {
              getOfferReviews()
              setSelectedPosts([])
            })
            .catch((err) => console.log(err.message))
          return
        }
        return openReviewActionModal(
          'hide',
          nearcasts.map((nearcast) => ({
            username: nearcast.username,
            userId: nearcast.userId,
            reviewId: nearcast.reviewId
          })),
          () => {
            getOfferReviews()
            setSelectedPosts([])
          }
        )
      case 3:
        return openDownloadReviewsModal(nearcasts.map((nearcast) => nearcast.reviewId), 'withWatermark')
      case 4:
        if (canDownloadVideoWithoutWatermark === 0) {
          return openUpgradePlanToAccess()
        }
        return openDownloadReviewsModal(nearcasts.map((nearcast) => nearcast.reviewId), 'withoutWatermark')
      default:
        console.log('no supported value')
    }
  }

  const renderOfferInsights = () => {
    if (isOfferInsightsOn === false) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            marginTop: 40,
            textAlign: 'center'
          }}
        >
          <img
            style={{
              width: 280,
              height: 'auto'
            }}
            src={require('../../Components/assets/offerAnalyticsEmptyState.svg').default}
          />
          <Typography variant="body1" style={{ marginTop: 8 }}>
            No data available
          </Typography>
          <Typography variant="body2" style={{ marginTop: 8, maxWidth: '60%' }}>
            Before we can create any charts, we'll first need to get some data in here!
          </Typography>
        </Stack>
      )
    }

    return (
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          justifyContent: {
            xs: 'center',
            sm: 'flex-start'
          },
          width: '100%'
        }}
      >
        {offerInsightData.map((offer) => {
          if (offer.isRating) {
            return (
              <AggregateRatingCard
                key={offer.title + offer.startTime}
                title={offer.title}
                helper={offer.helper}
                startTime={offer.startTime}
                endTime={offer.endTime}
                dataRequest={offer.dataRequest}
              />
            )
          }
          return (
            <InsightGraphCard
              key={offer.title + offer.startTime}
              title={offer.title}
              helper={offer.helper}
              startTime={offer.startTime}
              endTime={offer.endTime}
              dataRequest={offer.dataRequest}
            />
          )
        })}
      </Stack>
    )
  }

  const renderOfferNearcasts = () => {
    if (offerReviewState.loading) {
      return (
        <Stack direction="row" alignItem="center" justifyContent="center" style={{ width: '100%' }}>
          <CircularProgress />
        </Stack>
      )
    }

    if (offerReviewState.nearcasts.length === 0) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            marginTop: 40,
            textAlign: 'center'
          }}
        >
          <QRCode
            style={{ marginTop: 7, marginBottom: 7, width: 200, height: 200 }}
            size={300}
            value={getQrUrl()}
          />
          <Typography variant="h5" style={{ marginTop: 16 }}>
            No reviews created yet, scan this QR code to see how it works!
          </Typography>
        </Stack>
      )
    }

    return (
      <Stack direction="row" flexWrap="wrap" style={{ width: '100%' }}>
        {offerReviewState.nearcasts.map((nearcast, index) => {
          const isSelected = Boolean(selectedPosts.find((reviewId) => reviewId === nearcast.reviewId))
          return (
            <Stack sx={{ marginBottom: 2 }} key={nearcast.postId} direction="column">
              <NearcastCard
                author={nearcast.username || 'Anonymous'}
                authorCover={nearcast.userCover}
                postCover={nearcast.postCover}
                postCaption={nearcast.postCaption}
                onPressSelect={() => {
                  if (isSelected) {
                    setSelectedPosts(selectedPosts.filter((reviewId) => reviewId !== nearcast.reviewId))
                  } else {
                    setSelectedPosts([...selectedPosts, nearcast.reviewId])
                  }
                }}
                onPressCard={() => {
                  setPostViewState({
                    index: index,
                    nearcast: nearcast,
                    isOpen: true
                  })
                }}
                isSelected={isSelected}
                style={{
                  marginRight: 16
                }}
              />
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ marginRight: 4 }}>
                  Rating
                </Typography>
                <Rating
                  value={nearcast.rating}
                  maxValue={5}
                />
              </Stack>
            </Stack>
          )
        })}
        {postViewState.isOpen && (
          <PostViewModal
            nearcast={{
              postId: postViewState.nearcast.postId,
              postCaption: postViewState.nearcast.postCaption,
              postPlaybackToken: postViewState.nearcast.postPlaybackToken,
              postSticker: postViewState.nearcast.postSticker,
              postMusic: postViewState.nearcast.postMusic,
              creator: {
                userId: postViewState.nearcast.userId,
                username: postViewState.nearcast.username,
                userCover: postViewState.nearcast.userCover
              }
            }}
            open={postViewState.isOpen}
            onClose={() => setPostViewState(postViewStateEmpty)}
            hasUserDetails={{
              isUserLoggedIn: true,
              jwtToken: jwtToken,
              nrctUID: business[activeBusiness].ID,
              preferences: {
                videoMuted: preferences.videoMuted,
                toggleVideoMuted: preferences.toggleVideoMuted
              }
            }}
            openShareModal={openShareModal}
          />
        )}
      </Stack>
    )
  }

  return (
    <>
      {!business || business.length <= 0
        ? (
        <Redirect to="/" />
          )
        : (
        <>
          <Header />
          {loading
            ? (
                <div
                  style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress />
                </div>
              )
            : (
                <Container maxWidth="xl" sx={{ mt: '78px', marginBottom: '74px' }}>
                  <Grid container spacing={1}>
                    {/* Top Header */}
                    <Grid container item xs={12} sm={12} spacing={1}>
                      {/* Back Button */}
                      <Grid item xs={12} sm={1}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={{
                            justifyContent: {
                              xs: 'flex-start',
                              sm: 'flex-end'
                            }
                          }}
                        >
                          <IconButton
                            style={{
                              alignSelf: 'flex-end',
                              backgroundColor: '#F2F2F2'
                            }}
                            onClick={() => history.push('/offers')}
                          >
                            <ChevronLeft />
                          </IconButton>
                        </Stack>
                      </Grid>
                      {/* Offer header */}
                      <Grid item xs={12} sm={8}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1} style={{ height: 44 }}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography color="primary" variant="h3">
                              {offerDetails.title}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    {/* Offer Insights + Nearcast Reviews + Right most column  */}
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      spacing={1}
                      style={{
                        marginTop: 16
                      }}
                    >
                      {/* Empty first grid column: column under back button */}
                      <Grid item xs={12} sm={1} md={1}/>
                      {/* Offer Insights + Nearcasts Created */}
                      <Grid item xs={12} sm={11} md={11}>
                        <Stack
                          direction={{
                            xs: 'column-reverse',
                            md: 'row'
                          }}
                          spacing={2}
                        >
                          {/* Offer Insights + Nearcasts Created */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            sx={{
                              paddingTop: {
                                xs: 2,
                                md: 0
                              }
                            }}
                          >
                            {/* Offer Insights */}
                            <Stack
                              direction="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              flexWrap="wrap"
                              spacing={1}
                            >
                              <Stack direction="row" justifyContent="center">
                                <Typography variant="h4">
                                  Offer Insights
                                </Typography>
                                <DropDownMenu
                                  selected={howManyDays}
                                  options={howManyDaysOptions}
                                  onSelectedPress={(option) => {
                                    setHowManyDays(option)
                                    setOfferInsightData(offerInsightGraphsDataGenerator(Number(option.value)))
                                  }}
                                  style={{
                                    marginLeft: 10,
                                    backgroundColor: 'transparent',
                                    color: '#007AFF'
                                  }}
                                />
                              </Stack>
                              {renderOfferInsights()}
                            </Stack>
                            {/* Nearcasts Created */}
                            <Stack
                              direction="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              flexWrap="wrap"
                              spacing={1}
                              style={{
                                marginTop: 20
                              }}
                            >
                              <Stack
                                direction={{
                                  xs: 'column',
                                  md: 'row'
                                }}
                                alignItems={{
                                  xs: 'flex-start',
                                  md: 'center'
                                }}
                                justifyContent="space-between"
                                style={{
                                  width: '100%'
                                }}
                              >
                                <Typography variant="h4">
                                  Nearcast Created
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent={{
                                    xs: 'space-between',
                                    md: 'center'
                                  }}
                                  sx={{
                                    width: {
                                      xs: '100%',
                                      md: 'auto'
                                    },
                                    marginTop: {
                                      xs: 1,
                                      md: 0
                                    },
                                    marginBottom: {
                                      xs: 1,
                                      md: 0
                                    }
                                  }}
                                >
                                  <DropDownMenu
                                    selected={offerReviewOrderBy}
                                    options={allOfferReviewSortingTypes}
                                    onSelectedPress={(option) => {
                                      setOfferReviewOrderBy(option)
                                      getOfferReviews(
                                        1,
                                        option.value
                                      )
                                    }}
                                  />
                                  {selectedPosts.length > 0 && (
                                    <Fade in={selectedPosts.length > 0}>
                                      <div>
                                        <DropDownMenu
                                          selected={{
                                            text: `Selected: ${selectedPosts.length}`,
                                            value: -1
                                          }}
                                          options={[
                                            {
                                              text: 'Publish',
                                              value: 0
                                            },
                                            {
                                              text: 'Respond',
                                              value: 1,
                                              disabled: hasSelectedNonAnonymousPost === false
                                            },
                                            {
                                              text: 'Hide Nearcast(s)',
                                              value: 2
                                            },
                                            {
                                              text: 'Download',
                                              value: 3
                                            },
                                            {
                                              text: 'Download (without watermark)',
                                              value: 4
                                            }
                                          ]}
                                          onSelectedPress={onSelectReviewAction}
                                          style={{
                                            backgroundColor: '#3a8f78',
                                            color: 'white'
                                          }}
                                        />
                                      </div>
                                    </Fade>
                                  )}
                                  <Stack style={{ marginLeft: 16 }} direction="row" alignItems="center">
                                    <TextButton
                                      variant="text"
                                      color="primary"
                                      disabled={offerReviewState.page === 1 || loading}
                                      disableElevation
                                      style={{
                                        marginRight: 8
                                      }}
                                      onClick={() => {
                                        getOfferReviews(
                                          --offerReviewState.page
                                        )
                                      }}
                                    >
                                      PREV
                                    </TextButton>
                                    <TextButton
                                      variant="text"
                                      color="primary"
                                      disabled={offerReviewState.nearcasts.length < 20 || loading}
                                      disableElevation
                                      onClick={() => {
                                        getOfferReviews(
                                          ++offerReviewState.page
                                        )
                                      }}
                                    >
                                      NEXT
                                    </TextButton>
                                  </Stack>
                                </Stack>
                              </Stack>
                              {renderOfferNearcasts()}
                            </Stack>
                          </Grid>
                          {/* Right Column */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                          >
                            <Stack direction="column" justifyContent="center">
                              <Typography variant="h4">
                                Offer Details
                              </Typography>
                              <img
                                alt="Offer Cover"
                                src={offerDetails.cover}
                                style={styles.rightColumnOfferCover}
                              />
                              <Typography variant="h5">
                                {offerDetails.title}
                              </Typography>
                              <Typography variant="caption">
                                {offerDetails.description}
                              </Typography>
                              <Stack direction="row" style={{ marginTop: 12 }}>
                                <Typography variant="h6">
                                  Nearcoins:
                                </Typography>
                                <Typography style={{ marginLeft: 8 }} variant="h6" color="primary">
                                  {offerDetails.nearcoins}
                                </Typography>
                              </Stack>
                              <Stack direction="row">
                                <Typography variant="h6">
                                  Coupon Code:
                                </Typography>
                                <Typography style={{ marginLeft: 8 }} variant="h6" color="primary">
                                  {offerDetails.in_store_redemption}
                                </Typography>
                              </Stack>
                              <Stack direction="column" spacing={1.5}>
                                <RightItemCard
                                  sx={{
                                    display: {
                                      xs: 'flex',
                                      md: 'none'
                                    }
                                  }}
                                  title="All Options"
                                  titleLeftAction={<Settings />}
                                  titleRightAction={(
                                    <IconButton>
                                      {isOptionsCollapsed ? <ExpandMore /> : <ExpandLess />}
                                    </IconButton>
                                  )}
                                  onPress={() => setIsOptionsCollapsed((e) => !e)}
                                  elevation={0}
                                />
                                <Collapse
                                  in={!isOptionsCollapsed || window.innerWidth > 900}
                                  timeout="auto"
                                  unmountOnExit
                                  sx={{
                                    padding: {
                                      xs: 1,
                                      md: 0
                                    }
                                  }}
                                  style={{
                                    marginTop: 0
                                  }}
                                >
                                  <RightItemCard
                                    title="Share"
                                    titleLeftAction={<Share />}
                                    onPress={() => setIsShareOfferOpen(true)}
                                    elevation={0}
                                  />
                                  <RightItemCard
                                    title="Send"
                                    titleLeftAction={<Send />}
                                    onPress={() => setIsSendOfferOpen(true)}
                                    elevation={0}
                                  />
                                  <RightItemCard
                                    title="Edit"
                                    titleLeftAction={<Edit />}
                                    onPress={() => {
                                      setCreateEditType('edit')
                                      setIsCreateEditOpen(true)
                                    }}
                                    elevation={0}
                                  />
                                  <RightItemCard
                                    title="Show QR Code"
                                    titleLeftAction={<QrCode />}
                                    onPress={() => {
                                      setIsShowQrOpen(true)
                                    }}
                                    elevation={0}
                                  />
                                  <RightItemCard
                                    title="Duplicate"
                                    titleLeftAction={<ContentCopy />}
                                    onPress={() => {
                                      if (createOfferCount === 0) {
                                        return openUpgradePlanToAccess()
                                      }
                                      setCreateEditType('duplicate')
                                      setIsCreateEditOpen(true)
                                    }}
                                    elevation={0}
                                  />
                                  {isCreateEditOpen && (
                                    <CreateEditOfferModal
                                      type={createEditType}
                                      offerId={id}
                                      open={isCreateEditOpen}
                                      onClose={() => {
                                        setIsCreateEditOpen(false)
                                        getBusinessOfferDetails()
                                      }}
                                    />
                                  )}
                                  <RightItemCard
                                    title="Delete"
                                    titleLeftAction={<Delete />}
                                    onPress={() => setIsDeleteDialogOpen(true)}
                                    elevation={0}
                                  />
                                </Collapse>
                              </Stack>
                              <Dialog
                                title={`Would you like to delete offer "${offerDetails.title}"`}
                                isOpen={isDeleteDialogOpen}
                                cancelText='No'
                                onCancel={() => {
                                  if (isDeleting) {
                                    return
                                  }
                                  setIsDeleteDialogOpen(false)
                                }}
                                confirmText={isDeleting ? 'Deleting' : 'Delete'}
                                onConfirm={() => {
                                  if (isDeleting) {
                                    return
                                  }
                                  onBusinessOfferDelete()
                                }}
                              />
                              <ShareOfferModal
                                offerId={id}
                                offerRedemption={offerDetails.in_store_redemption}
                                offerDescription={offerDetails.description}
                                open={isShareOfferOpen}
                                onClose={() => setIsShareOfferOpen(false)}
                              />
                              <SendOfferModal
                                offerId={id}
                                open={isSendOfferOpen}
                                onClose={() => {
                                  setIsSendOfferOpen(false)
                                  // this clears the state for new offers so that
                                  // send offer does not pop up again on refresh
                                  history.push(`/offer/${id}`)
                                }}
                              />
                              <StandardModal
                                isOpen={isShowQrOpen}
                                onClose={() => setIsShowQrOpen(false)}
                                buttons={[
                                  {
                                    title: 'Download',
                                    onPress: () => downloadQrCode(qrCodeRef)
                                  }
                                ]}
                              >
                                <Stack
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  ref={qrCodeRef}
                                >
                                  <QRCode
                                    style={{ marginTop: 7, marginBottom: 7, width: 260, height: 260 }}
                                    size={300}
                                    value={getQrUrl()}
                                  />
                                </Stack>
                              </StandardModal>
                            </Stack>
                          </Grid>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              )
          }
        </>
          )}
    </>
  )
}

const styles = {
  rightColumnOfferCover: {
    width: 160,
    height: 260,
    objectFit: 'cover',
    backgroundColor: 'grey',
    borderRadius: 12,
    marginTop: 20,
    marginBottom: 20
  }
}

export default OfferAnalytics
