import { CircularProgress } from '@mui/material'
import firebase from 'firebase'
import { useState } from 'react'
import '../../../firebase'
import appleIcon from '../../assets/apple.png'

function AppleSignin ({ onSuccess, onError }) {
  const [loading, setLoading] = useState(false)

  const appleLogin = () => {
    setLoading(true)
    const provider = new firebase.auth.OAuthProvider('apple.com')
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        onError()
        setLoading(false)
      })
  }

  return (
    <button
      onClick={appleLogin}
      style={{
        background: '#000000',
        borderRadius: 10,
        color: '#FFFFFF',
        border: 'none',
        display: 'inline',
        padding: '16px 20px',
        cursor: 'pointer',
        fontSize: 16,
        margin: 2
      }}
      disabled={loading}
    >
      {loading
        ? (
        <CircularProgress
          size={18}
          style={{ verticalAlign: 'middle', marginRight: 10, color: '#FFFFFF' }}
        />
          )
        : (
        <img
          src={appleIcon}
          style={{
            verticalAlign: 'middle',
            marginRight: 10,
            height: 16,
            position: 'absolute',
            left: 24
          }}
          alt=""
        />
          )}
      Continue with Apple
    </button>
  )
}

export default AppleSignin
