import * as React from 'react'
import {
  Card,
  CardMedia,
  Box,
  CardContent,
  Typography,
  Stack
} from '@mui/material'
import {
  Campaign
} from '@mui/icons-material'

// importing ui
import RightItemCard from '../../Cards/RightItemCard/RightItemCard'

// importing helper
import { getFormattedDate } from 'helpers/date'

export interface TopOffersOfferType {
  id: string;
  cover: string;
  title: string;
  startDate: string;
  couponCode: string;
  nearcoins: number;
  shortLink?: string;
}

export interface TopOffersProps {
  isForBusinessPortal: boolean;
  offers: TopOffersOfferType[] | null;
  offersHeaderRightAction: JSX.Element;
  offersBottomAction: JSX.Element;
  offersEmptyActions: JSX.Element;
  onOfferPress: (t: TopOffersOfferType) => void;
}

const TopOffers: React.FC<TopOffersProps> = (props) => {
  return (
    <RightItemCard
      id="top-offers"
      title="Offers"
      titleLeftAction={<Campaign />}
      titleRightAction={props.offersHeaderRightAction}
      sx={{
        marginTop: 2
      }}
      style={{
        backgroundColor: '#F4F7FD'
      }}
      cardHeaderStyle={{
        backgroundColor: 'transparent'
      }}
      elevation={0}
      cardContent={
        <>
          {Boolean(props.offers) && (
            <>
              {props.offers.slice(0, 4).map((offer) => {
                return (
                  <Card
                    key={offer.id}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      borderRadius: 12
                    }}
                    sx={{
                      marginBottom: 2
                    }}
                    onClick={() => props.onOfferPress(offer)}
                    elevation={0}
                  >
                    <CardMedia
                      component="img"
                      style={{
                        width: '90px',
                        height: '140px',
                        objectFit: 'cover',
                        position: 'relative'
                      }}
                      image={offer.cover}
                      alt={offer.title}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography
                          variant="h5"
                          component="div"
                        >
                          {offer.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                        >
                          Started on{' '}
                          {getFormattedDate(offer.startDate)}
                        </Typography>
                        <Stack direction="row">
                          <Typography
                            variant="h6"
                            component="div"
                          >
                            Coupon Code:
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            color="primary"
                            style={{
                              marginLeft: 4
                            }}
                          >
                            {offer.couponCode}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            variant="h6"
                            component="div"
                          >
                            Earn Nearcoins:
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            color="primary"
                            style={{
                              marginLeft: 4
                            }}
                          >
                            {offer.nearcoins}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </Box>
                  </Card>
                )
              })}
              {props.offersBottomAction && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    marginTop: 1
                  }}
                  style={{
                    width: '100%'
                  }}
                >
                  {props.offersBottomAction}
                </Stack>
              )}
            </>
          )}
          {!props.offers && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <img
                alt=""
                src={
                  require('./assets/offers.svg')
                    .default
                }
                style={{
                  alignSelf: 'center'
                }}
              />
              {props.offersEmptyActions}
              <Typography
                variant="h6"
                style={{
                  textAlign: 'center',
                  maxWidth: '70%',
                  marginTop: 10
                }}
              >
                {props.isForBusinessPortal
                  ? 'Create on offer to incentivise walk-ins and grow within your community and reach new customers 🛍'
                  : 'No offers available. Come back later to check for more offers ⌛'
                }
              </Typography>
            </div>
          )}
        </>
      }
    />
  )
}

export default TopOffers
