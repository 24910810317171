import axios from 'axios'

/**
 * @function tokenRefresh Used refresh the user's token
 * @param {string} refreshJwt - the refresh token of the user
 * @returns {Promise} Promise object represents new token
 */
export const tokenRefresh = (refreshJwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/token/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshJwt}`
      }
    })
      .then((res) => {
        if (res.data.status === 200 && res.data.msg === 'success') {
          resolve(res.data.body)
        } else {
          throw new Error('Could not refresh your user token!')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
