import * as React from 'react'
import {
  Stack,
  Button,
  Box
} from '@mui/material'
import { useParams, useHistory } from 'react-router-dom'

// importing component
import Nearcast from 'ui/Icons/Nearcast'

const One = require('./1.mp4')
const Two = require('./2.mp4')

const Videos = () => {
  const { which } = useParams()
  const history = useHistory()

  const selectWhichVideo = () => {
    switch (Number(which)) {
      case 1:
        return One
      case 2:
        return Two
      default:
        return One
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          height: '100%',
          textAlign: 'center',
          width: {
            xs: '96%',
            md: '800px',
            paddingTop: '18px'
          }
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Nearcast style={{ width: 140 }} />
        </Stack>
        <Box
          sx={{
            marginTop: '4%',
            width: '100%',
            height: 'auto',
            position: 'relative'
          }}
        >
          <video
            src={selectWhichVideo()}
            style={{
              width: '100%',
              maxWidth: '800px',
              maxHeight: '500px'
            }}
            controls
            autoPlay
          />
        </Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            marginTop: '4%'
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              history.push('/signup')
            }}
            fullWidth
            style={{
              height: 50,
              fontSize: 18,
              fontWeight: 'bold'
            }}
            disableElevation
            data-testid="continueButton"
          >
            Get My Nearcast Domain Now
          </Button>
        </Stack>
      </Stack>
    </div>
  )
}

export default Videos
