import React from 'react'
import { Rating, Stack, styled, Typography } from '@mui/material'
import {
  CalendarToday,
  Chat,
  Favorite,
  InsertLink,
  LocationOn,
  Mail,
  Phone,
  ShoppingCartOutlined
} from '@mui/icons-material'

const StyledButton = styled('div')(({ theme }) => ({
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  padding: '5px 10px',
  borderRadius: 5,
  cursor: 'pointer',
  marginRight: '10px',
  color: 'white',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '10px'
  }
}))

export interface StickerButtonType {
  text: string;
  color: string;
  icon: React.ReactElement,
  onClick: (sticker: any) => void;
  type: number;
}

// the sticker type should always be index for this
export const stickerButtonTypes: StickerButtonType[] = [
  {
    text: 'Call Me',
    color: '#38bc18',
    icon: <Phone />,
    onClick: (sticker) => {
      window.open('tel:' + sticker.phone_number)
    },
    type: 0
  },
  {
    text: 'Message Me',
    color: '#3897f1',
    icon: <Chat />,
    onClick: (sticker) => {
      if (sticker.postDetails?.user_id) {
        window.open(`/chat?message_user=${sticker.postDetails.user_id}`)
      } else {
        window.open('/chat')
      }
    },
    type: 1
  },
  {
    text: 'Cost',
    color: '#ffcf0d',
    icon: <ShoppingCartOutlined />,
    onClick: (sticker) => {
      sticker.requestPaymentApi(false)
    },
    type: 2
  },
  {
    text: 'Email Me',
    color: '#ee4957',
    icon: <Mail />,
    onClick: (sticker) => {
      window.open('mailto:' + sticker.email)
    },
    type: 3
  },
  {
    text: 'Location',
    color: '#d1076a',
    icon: <LocationOn />,
    onClick: (sticker) => {
      window.open(`https://www.google.com/maps/search/?api=1&query=${sticker.location_lat}%2C${sticker.location_lon}`)
    },
    type: 4
  },
  {
    text: 'Custom URL',
    color: '#565bdb',
    icon: <InsertLink />,
    onClick: (sticker) => {
      window.open(sticker.custom_url)
    },
    type: 5
  },
  {
    text: 'Rating',
    color: '#d1076a',
    icon: null,
    onClick: () => { },
    type: 6
  },
  {
    text: 'Donate',
    color: '#d1076a',
    icon: <Favorite />,
    onClick: (sticker) => {
      sticker.requestPaymentApi(true)
    },
    type: 7
  },
  {
    text: 'Date',
    color: '#7cb345',
    icon: <CalendarToday />,
    onClick: () => { },
    type: 8
  },
  {
    text: 'Business',
    color: '#BADAD1',
    icon: null,
    onClick: () => { },
    type: 9
  }
]

export interface StickerButtonProps {
  sticker: any;
  overRideOnClick?: () => void;
  style?: any;
}

export const StickerButton: React.FC<StickerButtonProps> = ({ sticker, overRideOnClick, style = {} }) => {
  const { text, color, icon, onClick } = stickerButtonTypes[sticker.type]

  const renderIcon = () => {
    if (sticker.type === 6 || sticker.type === 9) {
      return (
        <Rating
          name="simple-controlled"
          value={sticker.rating}
          onChange={(event, newValue) => {
            // do something
          }}
          readOnly
        />
      )
    } else {
      return icon
    }
  }

  const renderText = () => {
    switch (sticker.type) {
      case 0:
      case 1:
      case 3:
      case 7:
        return text
      case 9:
        return `${sticker.business_name}: ${sticker.rating_description}`
      case 2:
        return `${sticker.price}`
      case 4:
        return `${sticker.location_name.length > 13 ? sticker.location_name.substring(0, 13) + '...' : sticker.location_name}`
      case 5:
        return sticker.custom_name
      case 6:
        return null
      case 8:
        return sticker.date
      default:
        return 'Sticker'
    }
  }

  switch (sticker.type) {
    case 9:
      return (
        <StyledButton
          style={{
            background: color,
            ...style,
            height: 'auto'
          }}
          onClick={() => {
            if (overRideOnClick) {
              return overRideOnClick()
            } else {
              onClick(sticker)
            }
          }}
        >
          <Stack
            direction="column"
            flexWrap="wrap"
            style={{
              width: 140,
              whiteSpace: 'initial'
            }}
          >
            <Rating
              name="simple-controlled"
              value={sticker.rating}
              onChange={(event, newValue) => {
                // do something
              }}
              readOnly
            />
            <Typography style={{ wordBreak: 'break-all' }} color="black" variant='caption' component="span">
              {sticker.business_name}: {sticker.rating_description}
            </Typography>
          </Stack>
        </StyledButton>
      )
    default:
      return (
        <StyledButton
          style={{
            background: color,
            ...style
          }}
          onClick={() => {
            if (overRideOnClick) {
              return overRideOnClick()
            } else {
              onClick(sticker)
            }
          }}
        >
          <div
            style={{
              display: 'block',
              marginRight: '10px',
              paddingTop: '4px'
            }}
          >
            {renderIcon()}
          </div>
          {renderText()}
        </StyledButton>
      )
  }
}

export default StickerButton
