import axios from 'axios'

/**
 * @function offers Used to retrieve a business's offer
 * @param {string} businessId - the business's id whom to unfollow
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents array of offers
 */
export const offers = (businessId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/offers`, {
      params: {
        business_id: businessId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function offersOld Used to retrieve a business's offer
 * @param {string} businessId - the business's id whom to unfollow
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from user context
 * @returns {Promise} Promise object represents array of offers
 */
export const offersOld = (businessId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/offers/old`, {
      params: {
        business_id: businessId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
