import { Button, Divider, FormControl, FormHelperText, Grid } from '@mui/material'
import axios from 'axios'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import Countdown from 'react-countdown'
import StandardModal from 'ui/Modals/StandardModal'
import OtpInput from 'ui/TextInputs/OtpInput'
import { TextLabel } from 'ui/TextInputs/TextInput'
import validator from 'validator'

const renderer = ({ seconds, completed }) => {
  return !completed ? <span>({seconds})</span> : null
}

function OtpModal ({
  isOpen,
  onClose,
  phone,
  setPhone,
  otp,
  setOTP,
  onVerify,
  children,
  selectedCountry,
  onBack
}) {
  const [phoneError, setPhoneError] = useState('')
  const [otpError, setOtpError] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [otpEndTime, setOtpEndTime] = useState(0)
  const [canSend, setCanSend] = useState(true)
  const [disableSend, setDisableSend] = useState(false)
  const otpLimit = 59000
  const { enqueueSnackbar } = useSnackbar()

  const onOtpTimeEnd = () => {
    setDisableSend(false)
    setCanSend(true)
  }

  const sendOTP = async () => {
    if (!validator.isMobilePhone(phone, 'any')) {
      return setPhoneError('Invalid Phone Number')
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/signup/otp/send`, {
        phone: phone
        // id_token: idToken,
      })
      .then((res) => {
        setDisableSend(true)
        setOtpEndTime(Date.now() + otpLimit)
        setOtpSent(true)
        setCanSend(false)
      })
      .catch((err) => {
        setDisableSend(true)
        setOtpEndTime(Date.now() + otpLimit * 5)
        enqueueSnackbar(
          'Could not send OTP. Please wait for 5 minutes before retrying',
          {
            variant: 'error'
          }
        )
      })
  }

  const verifyOTP = async () => {
    if (otp.length < 4) {
      return setOtpError('OTP will be a 4 digit code')
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/signup/otp/verify`, {
        phone: phone,
        otp: otp
      })
      .then((res) => {
        onVerify()
      })
      .catch((err) => {
        setOtpError('Invalid OTP')
      })
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title={'Verify Mobile'}
      isTitleBack={true}
      onPressTitleBack={onBack}
    >
      <Divider style={{ marginTop: 3 }} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
        style={{
          padding: '30px 24px 18px 24px'
        }}
      >
        <Grid item>
          <FormControl fullWidth>
            <TextLabel style={{ marginBottom: 8 }}>
              Phone Number*
            </TextLabel>
            <MaterialUiPhoneNumber
              defaultCountry={selectedCountry ?? 'us'}
              id="phone"
              variant="filled"
              size="small"
              InputProps={{ hiddenLabel: true, disableUnderline: true }}
              value={phone}
              onChange={(value) => {
                setPhone(value.replace(/\s|-|\(|\)/g, ''))
              }}
              style={{
                borderRadius: '8px'
              }}
              disableAreaCodes={true}
            />
            {phoneError !== '' && (
              <FormHelperText error>{phoneError}</FormHelperText>
            )}
            {otpSent && (
              <FormHelperText>
                Entered wrong phone number? You can change your phone number after 60s.
              </FormHelperText>
            )}
            <Button
              onClick={onVerify}
              disabled={disableSend}
              variant="contained"
              color="secondary"
              style={{ marginTop: 20 }}
            >
              {/* TODO: Add OTP Verification Again */}
              {/* {otpSent && !canSend
                ? (
                  <>
                    Resend OTP
                    <Countdown
                      date={otpEndTime}
                      renderer={renderer}
                      onComplete={onOtpTimeEnd}
                    />
                  </>
                  )
                : (
                  <>I'm Ready, Let's Get Started</>
                  )} */}
                  <>I'm Ready, Let's Get Started</>
            </Button>
          </FormControl>
        </Grid>
        {otpSent && (
          <>
            <Grid item style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 10 }}>
                Verification Code*
              </div>
              <FormControl fullWidth>
                <OtpInput
                  value={otp}
                  onChange={(val) => {
                    setOTP(val)
                  }}
                  numInputs={4}
                  isInputNum
                  containerStyle={{
                    justifyContent: 'center'
                  }}
                  inputStyle={{
                    width: '2rem',
                    padding: '12px 2px',
                    fontSize: '1.2rem',
                    fontWeight: '600',
                    marginRight: 4,
                    marginLeft: 4,
                    border: '1px solid #E4E7ED',
                    backgroundColor: '#F4F7FD',
                    borderRadius: 5
                  }}
                  focusStyle={{
                    border: '1px solid #D4D7FD',
                    backgroundColor: '#F5F8FE',
                    outline: 'none'
                  }}
                />
                {otpError !== '' && (
                  <FormHelperText
                    error
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {otpError}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item style={{ marginTop: '20px' }}>
              <FormControl fullWidth>
                <Button
                  onClick={verifyOTP}
                  variant="contained"
                  color="primary"
                  disabled={!otpSent}
                >
                  Continue
                </Button>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      {children}
    </StandardModal>
  )
}

export default OtpModal
