import axios from 'axios'

/**
 * @function reportComment Used to report a certain comment
 * @param {string} commentId - comment id of the comment to report
 * @param {string} reason - the reason for reporting
 * @param {string} nrctUID - personal[active_neighborhood].ID from user context
 * @param {string} jwt - jwt from the user context
 * @returns {Promise} Promise object represents true for successful reporting
 */
export const reportComment = (commentId, reason = '', nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/comment`, {
      comment_id: commentId,
      reason: reason
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
