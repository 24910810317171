import axios from 'axios'

/**
 * @function fetchMyProfile Used to get current user's full profile
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents
 */
export const fetchMyProfile = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/fetch/my/profile`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.status === 200) {
          resolve({
            ...res.data.body,
            userLocation: `${res.data.body.name}, ${res.data.body.metro}`
          })
        } else {
          throw new Error('Unable to retrieve user profile')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
