/**
 * @function businessAvatarView the url to retrieve the business's avatar
 * @param {string} businessId - what the param does
 * @returns {string} Url to image
 */
export const businessAvatarView = (businessId) => {
  return `${
    process.env.REACT_APP_BUSINESS_URL
  }/business-view/avatar?business_id=${businessId}&updated_at=${Date.now()}`
}
