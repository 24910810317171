import axios from 'axios'

/**
 * @function forgotVerify used to set new password for user
 * @param {string} password - user's new
 * @param {string} token - token received from email
 * @returns {Promise} Promise object represents true for successful resolution
 */
export const forgotVerify = (password, token) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgot/verify`, {
      password: password,
      token: token
    })
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
