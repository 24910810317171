import axios from 'axios'

/**
 * @function businessReviewHide Hide a particular nearcast review for a business
 * @param {string} reviewId - review's IDs
 * @returns {Promise} Promise resolution represents true on success
 */
export const businessReviewHide = (reviewId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_BUSINESS_URL}/business/review/hide`, {
      review_id: reviewId
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @function businessReviewUnhide Unhide a particular nearcast review for a business
 * @param {string} reviewId - review's IDs
 * @returns {Promise} Promise resolution represents true on success
 */
export const businessReviewUnhide = (reviewId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_BUSINESS_URL}/business/review/unhide`, {
      review_id: reviewId
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
