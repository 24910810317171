import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Table,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { businessContactsUpload } from 'api/business/contacts/upload'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import Papa from 'papaparse'
import React, { useContext, useEffect, useState } from 'react'
import CloudSpongeWidget from '../../CloudSpongeWidget'
import { UserContext } from '../../Context/UserContext'
import { downloadIcon, uploadIcon } from '../assets/Contacts/contactIcons'

const styles = makeStyles({
  accTableCellImage: {
    display: 'flex',
    width: 30,
    marginInline: 10,
    alignItems: 'center'
  },
  accTableCell: {
    display: 'flex',
    alignItems: 'center'
  },
  accTableCard: {
    marginLeft: 5,
    marginRight: 5,
    margin: 'auto',
    maxWidth: '300px',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 959px)']: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 599px)']: {
      maxWidth: '100%'
    }
  }
})

function OAuthCard ({ count, setTrigger }) {
  const classes = styles()
  const [exportLoad, setExport] = useState(false)
  const { business, jwtToken, activeBusiness } = useContext(UserContext)
  const { enqueueSnackbar } = useSnackbar()

  const fetchContacts = async (index) => {
    return axios
      .post(
        `${process.env.REACT_APP_BUSINESS_URL}/contacts/all`,
        {
          limit: 50,
          offset: index * 50,
          business_id: business[activeBusiness].business_id
        },
        {
          headers: {
            'NRCT-UID': business[activeBusiness].ID,
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then((res) => {
        if (res.data.body) {
          return res.data.body
        } else {
          return []
        }
      })
      .catch((err) => {})
  }

  const processAndUpload = async (contacts) => {
    if (contacts.length > 0) {
      const data = contacts.map((item) => {
        let name
        if (!item.first_name && !item.last_name) {
          name = item.__selectedMail__
        } else {
          name = `${item.first_name} ${item.last_name}`.trim()
        }
        return {
          name: name,
          email: item.__selectedMail__,
          phone: item.__selectedPhone__,
          city: item.__selectedAddress__
        }
      })
      businessContactsUpload(data, business[activeBusiness].ID, jwtToken)
        .then((res) => {
          enqueueSnackbar('Contacts Uploaded Successfully!', {
            variant: 'success'
          })
          setTrigger((prev) => !prev)
        })
        .catch((err) => {
          enqueueSnackbar('Error Uploading Contacts!', {
            variant: 'error'
          })
        })
    }
  }
  const downloadData = async () => {
    let batch = []
    setExport(true)
    for (let i = 0; i < Math.floor(count / 10) + 1; i++) {
      // eslint-disable-next-line
      await fetchContacts(i).then((temp) => {
        batch = [...temp, ...batch]
      })
    }
    const data = batch.map((obj) => [
      obj.name,
      obj.phone,
      obj.email,
      obj.city,
      obj.state
    ])
    const fields = ['name', 'phone', 'email', 'city', 'state']
    const csv = Papa.unparse({
      data,
      fields
    })
    const blob = new Blob([csv])
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob, { type: 'text/plain' })
    a.download = 'Nearcast-Contacts.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setExport(false)
  }

  useEffect(() => {
    window.gapi.load('auth2', function () {
      window.auth2 = window.gapi.auth2.init({
        client_id:
          '96110712600-mo0bsblm7grq851dahgjdrds2ubk5dlj.apps.googleusercontent.com',
        api_key: 'AIzaSyDmda7agGiyYpusMFHZt6AHm9F9oGtgkUA',
        // Scopes to request in addition to 'profile' and 'email'
        scope: 'https://www.googleapis.com/auth/contacts.readonly'
      })
    })
  }, [])

  return (
    <>
      <Card className={classes.accTableCard}>
        <CardContent style={{ padding: 0 }}>
          <Typography
            variant="h3"
            color="primary"
            style={{ padding: '16px 8px 16px 16px' }}
          >
            Accounts
          </Typography>
          <Divider />
          <Table>
            <TableRow>
              <TableCell align="left">
                <div className={classes.accTableCell}>
                  <div className={classes.accTableCellImage}>
                    <img
                      alt={'Upload Contacts'}
                      src={uploadIcon}
                      style={{ maxWidth: '100%', margin: 'auto' }}
                    />
                  </div>
                  {'Upload Contacts'}
                </div>
              </TableCell>
              <TableCell align={'right'}>
                <CloudSpongeWidget
                  options={{
                    afterSubmitContacts: processAndUpload,
                    css: '/contacts.css',
                    displayContactsColumns: ['email', 'phone']
                  }}
                >
                  <button
                    className="cloudsponge-launch"
                    style={{
                      outline: 'none',
                      border: 'none',
                      background: 'none',
                      color: '#0080DC',
                      font: "500 1rem 'Avenir Next'",
                      cursor: 'pointer'
                    }}
                  >
                    Connect
                  </button>
                </CloudSpongeWidget>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <div className={classes.accTableCell}>
                  <div className={classes.accTableCellImage}>
                    <img
                      alt={'Export Contacts'}
                      src={downloadIcon}
                      style={{ maxWidth: '100%', margin: 'auto' }}
                    />
                  </div>
                  {'Export Contacts'}
                </div>
              </TableCell>
              <TableCell align={exportLoad ? 'center' : 'right'}>
                {exportLoad
                  ? (
                  <CircularProgress size={16} style={{ color: '#0080DC' }} />
                    )
                  : (
                  <button
                    onClick={downloadData}
                    style={{
                      outline: 'none',
                      border: 'none',
                      background: 'none',
                      color: '#0080DC',
                      font: "500 1rem 'Avenir Next'",
                      cursor: 'pointer'
                    }}
                  >
                    Download
                  </button>
                    )}
              </TableCell>
            </TableRow>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

export default OAuthCard
