import axios from 'axios'

/**
 * @function postUpdate Update the caption of a post
 * @param {string} postId - post's id, eg: "qwe....948ijg"
 * @param {string} caption - string of max length 1022
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group cover upload
 */
export const postUpdate = (postId, caption, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/post/update`, {
      post_id: postId,
      caption
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.data.status === 200) {
          resolve(true)
        } else {
          resolve(true)
        }
      })
      .catch((err) => {
        reject('Error: Post caption not updated!')
      })
  })
}
