import {
  Button,
  Stack,
  Typography,
  Divider,
  Radio,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormControl
} from '@mui/material'
import React from 'react'
import { useSnackbar } from 'notistack'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

// importing ui
import ImageWideModal from 'ui/Modals/ImageWideModal'
import WideModal from 'ui/Modals/WideModal'
import StandardModal from 'ui/Modals/StandardModal'

// importing api
import { businessSubscriptions } from 'api/business/subscriptions'
import {
  businessPaymentSubscriptionIntent,
  businessPaymentSubscriptionStatus,
  businessPaymentSubscriptionCoupon
} from 'api/business/payment/subscription'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

// importing redux store
import { useAppDispatch, useAppSelector } from '../../../Utils/redux/store'
import { updateUserSubscriptionDetailsInRedux } from '../../../Utils/redux/actions/subscriptionAction'

// importing components
import './index.css'
import { UserContext } from '../../../Context/UserContext'

// import hooks
import useDebounce from 'hooks/useDebounce'

// constant data
const planDetailsKeyMapper = [
  {
    key: 'analytics',
    title: 'Get high level analytics from us',
    cusMapper: (value) => {
      const mapper = {
        0: 'None',
        1: 'Basic',
        2: 'Advance',
        3: 'Expert'
      }
      return mapper[value]
    }
  },
  {
    key: 'createOffer',
    title: 'Create exciting offers for your customers',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'videoStorageLimitation',
    title: 'We can store upto these many reviews for you',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'respondToReview',
    title: 'Respond to your loved customers',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'bulkEmails',
    title: 'Send your customers exiting offers and coupons from email',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'downloadVideoWithoutWatermark',
    title: 'Download your customer reviews without watermark',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'hideVideosFromBusinessPublicPage',
    title: 'Hide those reviews that you do not want on your public page',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  },
  {
    key: 'socialMediaMarketing',
    title: 'Get expert one to one support for social media marketing from our team',
    cusMapper: (value) => {
      if (value === -1) {
        return 'Unlimited'
      }
      return value
    }
  }
]

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      // color: "#32325d",
      color: 'black',
      fontFamily: '"Avenir next", sans-serif',
      backgroundColor: 'transparent',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: 'regular',
      '::placeholder': {
        fontWeight: 'regular',
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

export interface UpgradePlanPaymentsProps {
  mainTitle?: string;
  open: boolean;
  onClose: () => void;
}

const UpgradePlanPayments: React.FC<UpgradePlanPaymentsProps> = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()
  const reduxDispatch = useAppDispatch()

  // context
  const { business, jwtToken, activeBusiness, user } = React.useContext(UserContext)

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const planName = subscriptionState.subscription.planName

  // component states
  const [step, setStep] = React.useState<'loading' | 'plan' | 'pay' | 'success'>('loading')
  const [plans, setPlans] = React.useState([])
  const [selectedPlan, setSelectedPlan] = React.useState(null)
  const [isComparePlanOpen, setIsComparePlanOpen] = React.useState(false)
  const paymentStateInitial = {
    name: '',
    address: '',
    couponCode: '',
    isCouponValid: false,
    isCouponValidMsg: ''
  }
  const [paymentState, setPaymentState] = React.useState(paymentStateInitial)
  const inputError = {
    name: null,
    address: null
  }
  const [inputErrorState, setInputErrorState] = React.useState(inputError)
  const debouncedCoupon = useDebounce(paymentState.couponCode, 1000)
  const [processing, setProcessing] = React.useState(false)
  const [paymentProcessingError, setPaymentProcessingError] = React.useState(null)
  const [paymentTransactionId, setPaymentTransactionId] = React.useState(null)

  const _onClose = () => {
    if (processing) {
      return enqueueSnackbar('Please wait, we are processing your payment ⏳', {
        variant: 'success'
      })
    }

    setStep('loading')
    setPlans([])
    setSelectedPlan(null)
    setIsComparePlanOpen(false)
    setPaymentState(paymentStateInitial)
    setPaymentProcessingError(null)
    setPaymentTransactionId(null)
    props.onClose()
  }

  const validate = () => {
    const newInputError = inputError

    // name
    if (paymentState.name.length === 0) {
      newInputError.name = 'Please enter a name to continue'
    }

    // address
    if (paymentState.address.length === 0) {
      newInputError.address = 'Please enter an address to continue'
    }

    setInputErrorState(newInputError)
    if (Object.values(newInputError).find((a) => a !== null)) {
      return false
    } else {
      return true
    }
  }

  const onPressMakePayment = async () => {
    // make sure both stripe and element are initialized
    if (!stripe || !elements || processing) {
      return
    }

    recordGaEvent('upgradePlanPaymentModalMakingPayment', {
      currentPlan: planName,
      selectedPlan: selectedPlan.plan,
      businessId: business[activeBusiness].business_id,
      billingName: paymentState.name,
      billingAddress: paymentState.address,
      coupon: paymentState.couponCode
    })

    if (!validate()) {
      return enqueueSnackbar('Please check name and billing address to continue!', {
        variant: 'error'
      })
    }

    if (paymentState.couponCode.length > 0) {
      if (!paymentState.isCouponValid) {
        return enqueueSnackbar('Please check your coupon code or remove it to proceed!', {
          variant: 'error'
        })
      }
    }

    setProcessing(true)

    try {
      // call payment intent provider and put value paymentIntent
      const payment: any = await businessPaymentSubscriptionIntent(
        {
          name: paymentState.name,
          address: paymentState.address,
          coupon: paymentState.couponCode,
          planId: selectedPlan.id,
          special: Boolean(debouncedCoupon.toLowerCase().includes('special'))
        },
        business[activeBusiness].ID,
        jwtToken
      )

      // make sure we have payment stripe secret
      if (!payment.secret) {
        throw new Error('Payment failed, our servers could not process your payment at the moment. Please try again later.')
      }

      // get card element in the UI
      const cardElement = elements.getElement(CardElement)
      if (!cardElement) {
        throw new Error('The payment failed as we could not get your card details 😞 Try again with another card?')
      }

      // all good, process the payment with stripe
      const result = await stripe.confirmCardPayment(payment.secret, {
        payment_method: {
          card: cardElement
        },
        receipt_email: user.email
      })

      // make sure payment did not error out
      if (result.error) {
        throw new Error(result.error.message)
      }

      // check if payment was successful
      if (result.paymentIntent.status === 'succeeded') {
        setPaymentTransactionId(payment.transaction_id)
        enqueueSnackbar('Hooray! You have completed your payment 🎉', {
          variant: 'success'
        })
        setStep('success')
        await businessPaymentSubscriptionStatus(
          {
            intent: payment.intent,
            subscription: payment.subscription_id
          },
          business[activeBusiness].ID,
          jwtToken
        )
        reduxDispatch(updateUserSubscriptionDetailsInRedux())
        recordGaEvent('upgradePlanPaymentModalMakingPaymentSuccess', {
          currentPlan: planName,
          selectedPlan: selectedPlan.plan,
          businessId: business[activeBusiness].business_id,
          billingName: paymentState.name,
          billingAddress: paymentState.address,
          coupon: paymentState.couponCode
        })
      }
      console.log(result.paymentIntent.status)
    } catch (err) {
      enqueueSnackbar(`${err.message} Try again with another card?`, {
        variant: 'error'
      })
      setPaymentProcessingError(`${err.message} Try again with another card?`)
      recordGaEvent('upgradePlanPaymentModalMakingPaymentFailure', {
        currentPlan: planName,
        selectedPlan: selectedPlan.plan,
        businessId: business[activeBusiness].business_id,
        billingName: paymentState.name,
        billingAddress: paymentState.address,
        coupon: paymentState.couponCode,
        error: err.message
      })
    } finally {
      setProcessing(false)
    }
  }

  React.useEffect(() => {
    if (props.open) {
      businessSubscriptions(business[activeBusiness].ID, jwtToken)
        .then((resBody) => {
          setPlans(resBody.subscriptionPlans)
          setStep('plan')
          setSelectedPlan(resBody.subscriptionPlans[1])
        })
        .catch((err) => console.log(err.message))
    }
  }, [props.open])

  React.useEffect(() => {
    if (debouncedCoupon.length === 0) {
      return
    }
    businessPaymentSubscriptionCoupon(
      {
        coupon: debouncedCoupon,
        planId: selectedPlan.id,
        special: Boolean(debouncedCoupon.toLowerCase().includes('special'))
      },
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resBody: any) => {
        setPaymentState({
          ...paymentState,
          isCouponValid: true,
          isCouponValidMsg: `${resBody.percentage}% discount applied, final price $${resBody.amount}`
        })
      })
      .catch((err) => {
        setPaymentState({
          ...paymentState,
          isCouponValid: false,
          isCouponValidMsg: err.response.data.msg
        })
      })
      .finally(() => setProcessing(false))
  }, [debouncedCoupon])

  if (step === 'loading' || plans.length === 0) {
    return (
      <StandardModal
        isOpen={props.open}
        onClose={_onClose}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: 200
          }}
        >
          <CircularProgress />
          <Typography variant="h4" textAlign="center" sx={{ marginTop: 2 }}>
            Loading plan details, please wait ⏳
          </Typography>
        </Stack>
      </StandardModal>
    )
  }

  if (step === 'plan') {
    return (
      <ImageWideModal
        image={require('./girl.png')}
        isOpen={props.open}
        onClose={_onClose}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography variant="h3" color="secondary" textAlign="left">
            {props.mainTitle}
          </Typography>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              width: '100%',
              marginTop: 4
            }}
            spacing={3}
          >
            {plans.map((plan) => {
              return (
                <>
                  <Stack
                    key={plan.id}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{
                      width: '100%'
                    }}
                    spacing={2}
                  >
                    <Radio
                      checked={selectedPlan !== null ? selectedPlan.id === plan.id : false}
                      onChange={() => setSelectedPlan(plan)}
                      value={plan.id}
                      name="radio-buttons"
                      inputProps={{
                        'aria-label': 'A'
                      }}
                      color="secondary"
                      sx={{
                        padding: 0
                      }}
                    />
                    <Stack onClick={() => setSelectedPlan(plan)} sx={{ cursor: 'pointer' }}>
                      <Typography variant="h4">
                        {plan.plan} {plan.plan === planName && '(Current)'}
                      </Typography>
                      <Typography variant="h5">
                        ${plan.amount}/month
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider style={{ marginTop: 3 }} />
                </>
              )
            })}
          </Stack>
          <Typography variant="caption" component="div" sx={{ marginTop: 2 }}>
            Working with a big team?
          </Typography>
          <Typography
            variant="caption"
            component="div"
            sx={{
              color: '#00A7FF',
              cursor: 'pointer'
            }}
            onClick={() => window.open('https://calendly.com/talktoakshat', '_blank')}
          >
            Book an appointment for a plan tailor-made for your team.
          </Typography>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%'
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setStep('pay')
                recordGaEvent('upgradePlanPaymentModalSelectedNewPlan', {
                  currentPlan: planName,
                  selectedPlan: selectedPlan.plan,
                  businessId: business[activeBusiness].business_id
                })
              }}
              sx={{
                marginTop: 2,
                width: '100%'
              }}
              disableElevation
            >
              Change Plan
            </Button>
            <Typography
              variant="caption"
              sx={{
                color: '#00A7FF',
                cursor: 'pointer',
                marginTop: 1
              }}
              onClick={() => setIsComparePlanOpen(true)}
            >
              Compare Plans
            </Typography>
          </Stack>
        </Stack>
        <WideModal
          isOpen={isComparePlanOpen}
          onClose={() => setIsComparePlanOpen(false)}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  {plans.map((plan) => {
                    return (
                      <TableCell key={plan.id}>{plan.plan}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {planDetailsKeyMapper.map((planDetailMapper) => {
                  return (
                    <TableRow key={planDetailMapper.key}>
                      <TableCell>
                        {planDetailMapper.title}
                      </TableCell>
                      {plans.map((plan) => {
                        let value = plan.plan_details[planDetailMapper.key]
                        if (planDetailMapper.cusMapper) {
                          value = planDetailMapper.cusMapper(value)
                        }
                        return (
                          <TableCell key={`${plan.id}-${planDetailMapper.key}`}>{value}</TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </WideModal>
      </ImageWideModal>
    )
  }

  if (step === 'pay') {
    return (
      <ImageWideModal
        image={require('./girl.png')}
        isOpen={props.open}
        onClose={_onClose}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography variant="h3" color="secondary" textAlign="left">
            Payments
          </Typography>
          <Typography variant="h5" textAlign="left" sx={{ marginTop: 1 }}>
            Selected Plan: {selectedPlan?.plan} - ${selectedPlan?.amount}/month
          </Typography>
          <Typography variant="body2" textAlign="left">
            Please enter your card details
          </Typography>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              width: '100%',
              marginTop: 2
            }}
            spacing={1.5}
          >
            <FormControl fullWidth>
              <Typography variant="body2">
                Name
              </Typography>
              <TextField
                size='small'
                placeholder="Your Business Name"
                variant="outlined"
                value={paymentState.name}
                onChange={(e) => {
                  setPaymentState({
                    ...paymentState,
                    name: e.currentTarget.value.slice(0, 256)
                  })
                  setInputErrorState({
                    ...inputErrorState,
                    name: null
                  })
                }}
                error={Boolean(inputErrorState.name)}
              />
              {inputErrorState.name && (
                <Typography variant="caption" color="error" textAlign="left">
                  {inputErrorState.name}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="body2">
                Billing Address
              </Typography>
              <TextField
                size='small'
                placeholder="Your Business Address"
                variant="outlined"
                value={paymentState.address}
                onChange={(e) => {
                  setPaymentState({
                    ...paymentState,
                    address: e.currentTarget.value.slice(0, 512)
                  })
                  setInputErrorState({
                    ...inputErrorState,
                    address: null
                  })
                }}
                error={Boolean(inputErrorState.address)}
              />
              {inputErrorState.address && (
                <Typography variant="caption" color="error" textAlign="left">
                  {inputErrorState.address}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="body2">
                Card Details
              </Typography>
              <CardElement
                options={CARD_ELEMENT_OPTIONS}
                onChange={() => {
                  setPaymentProcessingError(null)
                }}
              />
              {paymentProcessingError && (
                <Typography variant="caption" color="error" textAlign="left">
                  {paymentProcessingError}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="body2">
                Coupon Code
              </Typography>
              <TextField
                size='small'
                placeholder="SIXTY"
                variant="outlined"
                value={paymentState.couponCode}
                onChange={(e) => {
                  if (e.currentTarget.value.length !== 0) {
                    setProcessing(true)
                  } else {
                    setProcessing(false)
                  }
                  setPaymentState({
                    ...paymentState,
                    couponCode: e.currentTarget.value.toUpperCase().slice(0, 8)
                  })
                }}
                error={!paymentState.isCouponValid && paymentState.couponCode.length > 0}
              />
              {!paymentState.isCouponValid && paymentState.couponCode.length > 0 && (
                <Typography variant="caption" color="error" textAlign="left">
                  {paymentState.isCouponValidMsg}
                </Typography>
              )}
              {paymentState.isCouponValid && paymentState.couponCode.length > 0 && (
                <Typography variant="caption" color="success" textAlign="left">
                  {paymentState.isCouponValidMsg}
                </Typography>
              )}
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              marginTop: 2
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={onPressMakePayment}
              sx={{
                width: '100%'
              }}
              disableElevation
              disabled={processing}
            >
              Make Payment
            </Button>
          </Stack>
        </Stack>
      </ImageWideModal>
    )
  }

  if (step === 'success') {
    return (
      <ImageWideModal
        image={require('./girl.png')}
        isOpen={props.open}
        onClose={_onClose}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography variant="h3" color="secondary" textAlign="left">
            Payment Successful
          </Typography>
          <Typography variant="h5" textAlign="left" sx={{ marginTop: 1 }}>
            Hooray! You have completed your payment 🎉
          </Typography>
          <Typography variant="caption" textAlign="left" sx={{ marginTop: 1 }}>
            Your Transaction Id: {paymentTransactionId}
          </Typography>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: 4,
              width: '100%'
            }}
          >
            <img
              src={require('../../assets/success.svg').default}
              style={{
                width: 160,
                height: 160
              }}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: 4,
              width: '100%'
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={_onClose}
              sx={{
                width: '100%'
              }}
            >
              Go to Dashboard
            </Button>
          </Stack>
        </Stack>
      </ImageWideModal>
    )
  }
}

// eslint-disable-next-line react/display-name
export default (props) => {
  // why? Component calling useStripe should be wrapped in stripe context
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  return (
    <Elements stripe={stripePromise}>
      <UpgradePlanPayments {...props} />
    </Elements>
  )
}
