import { Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'

function Page404 (props) {
  return (
    <div
      style={{
        display: 'grid',
        height: '100vh',
        width: '100vw',
        placeItems: 'center',
        textAlign: 'center'
      }}
    >
      <div>
        <Nearcast
          height={35}
          style={{ display: 'block' }}
          htmlColor={'#0C3A2D'}
        />
        <br />
        <Typography>PAGE NOT FOUND</Typography>
        <br />
        <Link to="/">
          <Button variant="contained" color="primary">
            Back to Homepage
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default Page404
