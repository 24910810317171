import React from 'react'

function Page404 (props) {
  // This auth-proxy.js can be placed on any HTML page to turn it into a
  //  CloudSponge Proxy URL.
  // It pulls the proper OAuth parameters out and sends them to CloudSponge
  //  to complete the next stage in the contact import.
  window.cloudspongeProxy = (function () {
    const proxyHost = 'https://api.cloudsponge.com/auth'
    let queryParams, oauthParams, key
    let redirecting = false

    function appendSearch () {
      let search = window.location.search
      if (search && search[0] === '?') {
        search = search + '&'
      } else {
        search = search + '?'
      }
      search = search + 'state=_csAuth%3D1%26import_id%3Dproxy-test'
      return search
    }

    // serializes an object into a query-string
    const serializeParams = function (params) {
      let k
      const results = []
      for (k in params) {
        results.push(k + '=' + encodeURIComponent(params[k]))
      }
      return results.join('&')
    }

    // converts the URL string into an object
    const parseParams = function (url) {
      const obj = {}
      url.replace(/([^?=&]+)(=([^&]*))?/g, function ($0, $1, $2, $3) {
        return (obj[$1] = decodeURIComponent($3))
      })
      return obj
    }

    // parse the query string
    queryParams = parseParams(window.location.search)

    // selects just the OAuth-related params out of the query
    oauthParams = {}
    for (key in queryParams) {
      if (
        key === 'code' ||
        key === 'state' ||
        key === 'error' ||
        key === 'error_code' ||
        key === 'forward'
      ) {
        oauthParams[key] = queryParams[key]
      }
    }

    // checks to see if this page actually the necessary received OAuth params
    // and then flings the state and code up to CloudSponge to do the heavy lifting work
    if (
      oauthParams.state &&
      (oauthParams.code || oauthParams.error || oauthParams.error_code)
    ) {
      redirecting = true
      window.location = proxyHost + '?' + serializeParams(oauthParams)
    }

    // add the redirect endpoint to any .cs-proxy links on the page
    window.addEventListener(
      'load',
      function () {
        let i
        const links = document.getElementsByClassName('cs-force')
        for (i = 0; i < links.length; i++) {
          links[i].href = proxyHost + appendSearch()
        }
      },
      false
    )

    return {
      redirecting: redirecting,
      force: function () {
        window.location = proxyHost + appendSearch()
        return false
      }
    }
  })()
  return (
    <>
      <p>Thank you for completing the OAuth flow.</p>
      <p>
        Click
        <a className="cs-force">
          here to verify that your Proxy URL is set up correctly
        </a>
        .
      </p>
    </>
  )
}

export default Page404
