import * as React from 'react'
import {
  Typography,
  Stack
} from '@mui/material'
import { Insights } from '@mui/icons-material'
import dayjs from 'dayjs'

// importing components
import { UserContext } from '../../Context/UserContext'

// importing ui
import RightItemCard from 'ui/Cards/RightItemCard/RightItemCard'
import InsightGraphCard from 'ui/Custom/Stats/InsightGraphCard/InsightGraphCard'
import AggregateRatingCard from 'ui/Custom/Stats/AggregateRatingCard/AggregateRatingCard'

// importing apis
import {
  businessAnalyticsBusinessReviews,
  businessAnalyticsBusinessReviewsCreated,
  businessAnalyticsBusinessReviewsRating
} from 'api/business/analytics/business'

// importing redux store
import { useAppSelector } from '../../Utils/redux/store'

export interface DashboardAnalyticsProps {
  shouldDisplayEmptyState?: boolean
}

const DashboardAnalytics: React.FC<DashboardAnalyticsProps> = (props) => {
  // user context
  const {
    business,
    jwtToken,
    activeBusiness
  } = React.useContext(UserContext)

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const subscriptionPlan = subscriptionState.subscription.planDetails
  const analyticPlanType = (subscriptionPlan.analytics as number) // 1 -> basic, 2 ->  advance and 3 -> advance and extra

  // heavy functions
  const analyticsData = React.useMemo(() => {
    let analytics: any = [
      {
        title: 'QR Codes Scanned',
        helper: "The number of times your business's QR codes were scanned.",
        startTime: dayjs().subtract(31, 'day').toISOString(),
        endTime: dayjs().toISOString(),
        dataRequest: async () => {
          return new Promise((resolve, reject) => {
            businessAnalyticsBusinessReviews(
              business[activeBusiness].business_id,
              dayjs().subtract(31, 'day').toISOString(),
              dayjs().toISOString(),
              business[activeBusiness].ID,
              jwtToken
            )
              .then((resBody) => resolve(resBody))
              .catch((err) => reject(err))
          })
        }
      },
    ]
    if (analyticPlanType > 2) {
      analytics = [
        {
          title: 'Aggregate Rating',
          helper: 'Your overall customer rating based on received reviews.',
          startTime: dayjs().subtract(31, 'day').toISOString(),
          endTime: dayjs().toISOString(),
          dataRequest: async () => {
            return new Promise((resolve, reject) => {
              businessAnalyticsBusinessReviewsRating(
                business[activeBusiness].business_id,
                dayjs().subtract(31, 'day').toISOString(),
                dayjs().toISOString(),
                business[activeBusiness].ID,
                jwtToken
              )
                .then((resData) => resolve(resData))
                .catch((err) => reject(err))
            })
          },
          isRating: true
        },
        ...analytics
      ]
    }
    return analytics
  }, [business, activeBusiness, jwtToken, analyticPlanType])

  return (
    <RightItemCard
      id="analytics"
      title="Analytics"
      titleLeftAction={<Insights />}
      sx={{
        marginTop: 2
      }}
      cardContentStyle={{
        padding: 16
      }}
      cardContent={
        props.shouldDisplayEmptyState
          ? (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: 2
                }}
              >
                <img
                  src={require('../../Components/assets/analyticsEmpty.png')}
                  style={{
                    width: '160px'
                  }}
                />
                <Typography variant="caption" textAlign="center" sx={{ width: '80%', marginTop: 1 }}>
                  Share your business page with your customers to start seeing how they interact with your videos.
                </Typography>
              </Stack>
            )
          : (
              <Stack direction="column">
                {analyticsData.map((analytic) => {
                  if (analytic.isRating) {
                    return (
                      <AggregateRatingCard
                        key={analytic.title + analytic.startTime}
                        title={analytic.title}
                        helper={analytic.helper}
                        startTime={analytic.startTime}
                        endTime={analytic.endTime}
                        dataRequest={analytic.dataRequest}
                        style={{
                          width: '100%'
                        }}
                      />
                    )
                  }
                  return (
                    <InsightGraphCard
                      key={analytic.title + analytic.startTime}
                      title={analytic.title}
                      helper={analytic.helper}
                      startTime={analytic.startTime}
                      endTime={analytic.endTime}
                      dataRequest={analytic.dataRequest}
                      style={{
                        width: '100%'
                      }}
                    />
                  )
                })}
              </Stack>
            )
      }
    />
  )
}

export default DashboardAnalytics
