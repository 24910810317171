// importing apis
import { businessUserSubscription } from 'api/business/userSubscription'

// importing user actions
import { logout } from './userActions'

/**
 * Action types
 * All the actions defined here will be implemented in reducer,
 * this way it's easier to manage action type strings
 */
const subscriptionReducerActionTypes = {
  clearUserSubscription: 'CLEAR_USER_SUBSCRIPTION',
  updateUserSubscription: 'UPDATE_USER_SUBSCRIPTION'
}

export default subscriptionReducerActionTypes

export const cleanSubscriptionDetails = () => {
  return (dispatch, getState) => {
    dispatch({
      type: subscriptionReducerActionTypes.clearUserSubscription
    })
  }
}

export const updateUserSubscriptionDetailsInRedux = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const nrctUID = state.root.userState.business[state.root.userState.activeBusiness].ID
    const jwtToken = state.root.userState.jwtToken
    try {
      const resBody = await businessUserSubscription(nrctUID, jwtToken)
      const subscription = {
        planDetails: resBody.userSubscription.plan_details,
        planId: resBody.userSubscription.plan_id,
        subscribedFor: resBody.userSubscription.subscribed_for,
        subscribedOn: resBody.userSubscription.subscribed_on,
        subscriptionEndDate: resBody.userSubscription.subscription_end_date,
        planName: resBody.userSubscription.plan_name,
        firstTimeShowed: resBody.userSubscription.first_time_showed,
        id: resBody.userSubscription.ID,
        isPlanCancelled: resBody.userSubscription.is_plan_cancelled
      }
      dispatch({
        type: subscriptionReducerActionTypes.updateUserSubscription,
        payload: subscription
      })
    } catch (err) {
      console.log('Error in redux subscription: ', err.message)
      dispatch(cleanSubscriptionDetails())
      dispatch(logout())
    }
  }
}
