import { Mail, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  useTheme
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

// importing ui
import TextField from 'ui/TextInputs/PriceFieldsInput'
import { TextLabel } from 'ui/TextInputs/TextInput'

// importing apis
import { signupEmail } from 'api/signup/email'

// import helpers
import useDebounce from 'hooks/useDebounce'

// const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|]).{8,32}$/
const passwordRegex = /^.{6,32}$/
// const passwordWrongMessage = 'Password must contain minimum 8 characters, at least 1 uppercase letter, one lowercase letter, one number and one special character'
const passwordWrongMessage = 'Password must contain minimum of 6 characters.'

export interface ContinueWithEmailProps {
  chosen: boolean;
  isLogin: boolean;
  setChosen: (e: boolean) => void;
  firebase: any; // pass the firebase app to use
  onSuccess: (token: string) => void;
  onError: (err: Error) => void;
}

const ContinueWithEmail: React.FC<ContinueWithEmailProps> = ({
  onSuccess,
  onError,
  chosen,
  setChosen,
  isLogin,
  firebase
}) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const debouncedPassword = useDebounce(password, 500)
  const [showPass, setShowPass] = useState(false)

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passError, setPassError] = useState('')
  const [userNotFoundOnSignUp, setUserNotFoundOnSignUp] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const validate = (next) => {
    // check name only on signup flow when userNotFoundOnSignUp is true
    if (!isLogin && name.trim().length === 0 && userNotFoundOnSignUp) {
      return setNameError('Name is required')
    } else {
      setNameError('')
    }

    if (email.trim().length === 0) {
      return setEmailError('Email is required')
    } else {
      setEmailError('')
    }

    if (!passwordRegex.test(password) && !isLogin) {
      return setPassError(passwordWrongMessage)
    } else {
      setPassError('')
    }

    return next()
  }

  const emailSignup = async () => {
    if (name.trim().length === 0) {
      enqueueSnackbar('Please enter your name.', {
        variant: 'error'
      })

      return
    }

    setLoading(true)
    signupEmail(email, name, password)
      .then((res) => emailLogin())
      .catch((error) => {
        onError(error)
        setLoading(false)
      })
  }

  const emailLogin = () => {
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            onSuccess(token)
            setLoading(false)
          })
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          // if sign up flow
          if (isLogin === false) {
            setUserNotFoundOnSignUp(true)
          } else {
            enqueueSnackbar('User Not Found!', { variant: 'error' })
          }
          setLoading(false)
        } else if (error.code === 'auth/wrong-password') {
          setPassError(
            'The password entered is either wrong or this account can only be logged into via OAuth!'
          )
          enqueueSnackbar('Invalid Password', { variant: 'error' })
          setLoading(false)
        } else {
          console.log(error)
          onError(error)
          setLoading(false)
        }
      })
  }

  const onPressContinue = () => {
    // if continue was email was already chosen by user
    if (chosen) {
      // then if its login
      if (isLogin) {
        return validate(emailLogin)
      }
      // if its for sign up flow
      // we first try to login user if they exist
      // else show them name field and make userNotFoundOnSignUp true
      if (userNotFoundOnSignUp) {
        validate(emailSignup)
      } else {
        validate(emailLogin)
      }
    } else {
      setChosen(true)
    }
  }

  useEffect(() => {
    setNameError('')
    setEmailError('')
    setPassError('')
    setLoading(false)
    setUserNotFoundOnSignUp(false)
  }, [chosen])

  useEffect(() => {
    if (debouncedPassword !== '' && !isLogin) {
      if (!passwordRegex.test(debouncedPassword)) {
        return setPassError(passwordWrongMessage)
      } else {
        setPassError('')
      }
    }
  }, [debouncedPassword])

  return (
    <>
      {chosen && (
        <>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label="Email"
                id="email"
                value={email}
                error={emailError !== ''}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                variant="outlined"
              />
              {emailError !== '' && (
                <FormHelperText error>{emailError}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
              <TextLabel
                style={{
                  marginLeft: 6,
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                Password
              </TextLabel>
              <InputBase
                sx={[
                  {
                    marginTop: '8px',
                    borderRadius: '5px',
                    position: 'relative',
                    border: '1px solid transparent',
                    backgroundColor: '#8aa6de14',

                    fontSize: 16,
                    padding: '8px 16px',
                    fontFamily: 'Avenir Next'
                  },
                  {
                    '&:focus': {
                      border: '1px solid #8aa6de41'
                    }
                  },
                  passError !== '' && {
                    border: '1px solid #ff000041',
                    backgroundColor: '#ff000014'
                  }
                ]}
                type={showPass ? 'text' : 'password'}
                error={passError !== ''}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass((prev) => !prev)}>
                      {showPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passError !== '' && (
                <FormHelperText error>{passError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {!isLogin && userNotFoundOnSignUp && (
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  label="Whats your name?"
                  id="name"
                  value={name}
                  error={nameError !== ''}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  variant="outlined"
                  autoFocus
                />
                {nameError !== '' && (
                  <FormHelperText error>{nameError}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        </>
      )}
      <Grid item>
        <FormControl fullWidth>
          {chosen
            ? (
            <>
              <button
                onClick={onPressContinue}
                style={{
                  background: theme.palette.primary.main,
                  borderRadius: 10,
                  color: '#FFFFFF',
                  border: '1px solid #494949',
                  display: 'inline',
                  padding: '14px',
                  cursor: 'pointer',
                  fontSize: 16,
                  margin: 2
                }}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={18}
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 10,
                      color: '#FFFFFF'
                    }}
                  />
                )}
                Continue
              </button>
            </>
              )
            : (
            <>
              <button
                onClick={onPressContinue}
                style={{
                  background: '#FFFFFF',
                  borderRadius: 10,
                  color: '#494949',
                  border: '1px solid #494949',
                  display: 'inline',
                  padding: '14px',
                  cursor: 'pointer',
                  fontSize: 16,
                  margin: 2
                }}
                disabled={loading}
              >
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAzdjE4aDI0di0xOGgtMjR6bTYuNjIzIDcuOTI5bC00LjYyMyA1LjcxMnYtOS40NThsNC42MjMgMy43NDZ6bS00LjE0MS01LjkyOWgxOS4wMzVsLTkuNTE3IDcuNzEzLTkuNTE4LTcuNzEzem01LjY5NCA3LjE4OGwzLjgyNCAzLjA5OSAzLjgzLTMuMTA0IDUuNjEyIDYuODE3aC0xOC43NzlsNS41MTMtNi44MTJ6bTkuMjA4LTEuMjY0bDQuNjE2LTMuNzQxdjkuMzQ4bC00LjYxNi01LjYwN3oiLz48L3N2Zz4="
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 10,
                    height: 20,
                    position: 'absolute',
                    left: 30
                  }}
                  alt=""
                />
                {loading && (
                  <CircularProgress
                    size={18}
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 10,
                      color: '#000000'
                    }}
                  />
                )}
                Continue With Email
              </button>
            </>
              )}
        </FormControl>
      </Grid>
    </>
  )
}

export default ContinueWithEmail
