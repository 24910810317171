import * as React from 'react'
import Webcam from 'react-webcam'
import { IconButton, Button, Chip, Stack, Typography } from '@mui/material'
import {
  FlipCameraAndroidRounded,
  Collections,
  Send,
  Close
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'

let clearId

const CreateVideo = ({ setVideoSrc }) => {
  // references
  const wrapperRef = React.useRef(null)
  const webcamRef = React.useRef(null)
  const mediaRecorderRef = React.useRef(null)
  const inputRef = React.useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  // component states
  const [pause, setPause] = React.useState(false)
  const [capturing, setCapturing] = React.useState(false)
  const [recordedChunks, setRecordedChunks] = React.useState([])
  const [userFacingMode, setUserFacingMode] = React.useState(false)
  const [time, setTime] = React.useState(0)
  const [recordEndBlobOperationDone, setRecordEndBlobOperationDone] = React.useState(false)
  const [isReset, setIsReset] = React.useState(false)

  const handleStartCaptureClick = () => {
    // some browsers like older verions of Safari don't have support for media recorder
    if (!window.MediaRecorder) {
      return enqueueSnackbar("Your browser doesn't support recording videos. Please try uploading a pre-recorded video.", { variant: 'error' })
    }
    try {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        bitsPerSecond: 312500 * 2
      })
      mediaRecorderRef.current.addEventListener(
        'dataavailable',
        handleDataAvailable
      )
      mediaRecorderRef.current.start()
      setCapturing(true)
      setPause(false)
      setRecordEndBlobOperationDone(false)
    } catch (err) {
      console.log(err)
      enqueueSnackbar('Oops, something went wrong. Please try uploading a pre-recorded video.', { variant: 'error' })
    }
  }

  const handleDataAvailable = (e) => {
    const { data } = e
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }

    // This if condition should be outside the data.size condition
    // at times the inactive call does not have any data hence data.size === 0
    // because of which setRecordEndBlobOperationDone(true) was not getting called at times
    if (e.currentTarget.state === 'inactive') {
      setRecordEndBlobOperationDone(true) // "dataavailable" blob event takes place after calling .stop() on media ref
    }
  }

  const onPressNext = () => {
    // stop recording, trigger final dataavailable event and then react.useEffect will set video
    mediaRecorderRef.current.stop()
    setCapturing(false)
    setPause(false)
    setTime(0)
  }

  React.useEffect(() => {
    if (capturing && !pause) {
      clearId = setInterval(() => {
        setTime((t) => t + 1)
      }, 1000)
    } else {
      // console.log("Interval cleared");
      clearInterval(clearId)
    }
  }, [capturing, pause])

  React.useEffect(() => {
    if (time > 60) {
      onPressNext()
    }
  }, [time])

  React.useEffect(() => {
    // should only enter if condition when triggered by onPressNext, or by isReset effect
    if (recordedChunks.length > 0 && recordEndBlobOperationDone) {
      // If isReset is true, then this is a reset event
      // set recordedChunks to empty array, set mediaRecorderRef to null, set isReset to false and return
      if (isReset) {
        setRecordEndBlobOperationDone(false)
        setRecordedChunks([])
        setIsReset(false)
        mediaRecorderRef.current = null
        return
      }
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4'
      })
      const videoURL = URL.createObjectURL(blob)
      setVideoSrc(videoURL, new File([blob], 'nearcast.mp4', { type: 'video/mp4' }))
      setRecordedChunks([])
    }
  }, [recordedChunks, recordEndBlobOperationDone, isReset])

  React.useEffect(() => {
    // isReset is true, then trigger cleanup after handleDataAvailable triggers video creation effect
    if (isReset) {
      mediaRecorderRef.current.stop()
      setCapturing(false)
      setPause(false)
      setTime(0)
    }
  }, [isReset])

  const styles = {
    createVideoWrapper: {
      background: 'black',
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 10
    },
    cameraMainControls: {
      width: '100%',
      height: '140px',
      position: 'absolute',
      bottom: 40,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cameraMainButton: {
      width: 60,
      height: 60,
      border: '2px white solid',
      borderRadius: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontWeight: '800',
      cursor: 'pointer',
      backgroundColor: '#D71648'
    }
  }

  // Older devices, specifically iPhone with iOS version less then 14.3
  // do not support MediaRecorder API, which this component requires
  // for such devices only an upload file option will be showcased
  if (!window.MediaRecorder) {
    return (
      <div ref={wrapperRef} style={{ ...styles.createVideoWrapper, backgroundColor: 'transparent' }}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            borderWidth: '2px',
            borderStyle: 'dashed',
            borderColor: theme.palette.link.main,
            paddingLeft: 2,
            paddingRight: 2,
            textAlign: 'center'
          })}
        >
          <Button
            color="link"
            onClick={() => inputRef.current.click()}
          >
            Upload
          </Button>
          <input
            ref={inputRef}
            type="file"
            accept="video/*"
            onChange={(e) => {
              const videoFile = e.target.files[0]
              // JS File size is in bytes, so 1 MB = 1024 * 1024 bytes
              if (Math.round(videoFile.size / (1024 * 1024)) > 20) {
                return enqueueSnackbar('File to large, max file upload 20Mb!', { variant: 'info' })
              }
              setVideoSrc(URL.createObjectURL(videoFile), videoFile)
            }}
            style={{
              display: 'none'
            }}
          />
          <Typography variant="caption" color="link">
            Max file size: 20Mb, Video length: 60s
          </Typography>
          <Typography
            variant="caption"
            color="error"
            style={{
              position: 'absolute',
              bottom: 10
            }}
          >
            Our internal video creation tool doesn't support your browser yet. Please try uploading a video!
          </Typography>
        </Stack>
      </div>
    )
  }

  return (
    <div ref={wrapperRef} style={styles.createVideoWrapper}>
      {pause && (
        <Chip
          label="Reset"
          variant="outlined"
          size="medium"
          color="error"
          onClick={() => setIsReset(true)}
          icon={<Close size="small" />}
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 99,
            fontWeight: '700'
          }}
        />
      )}
      <Webcam
        ref={webcamRef}
        style={{
          width: '100%',
          height: '100%'
        }}
        audio
        muted
        videoConstraints={{
          facingMode: userFacingMode ? 'user' : { ideal: 'environment' },
          width: { min: 960, ideal: 1024, max: 1280 },
          height: { min: 540, ideal: 576, max: 720 },
          aspectRatio: 1.777777778,
          frameRate: { max: 30 }
        }}
      />
      <div style={styles.cameraMainControls}>
        <Stack
          direction="column"
          alignItems="center"
        >
          {pause && (
            <Chip
              label="Paused"
              variant="outlined"
              size="small"
              style={{
                color: 'white',
                position: 'absolute',
                top: 0
              }}
            />
          )}
          <div
            style={styles.cameraMainButton}
            onClick={() => {
              if (capturing && !pause) {
                // pause recording
                setPause(true)
                mediaRecorderRef.current.requestData()
                mediaRecorderRef.current.pause()
              } else if (capturing && pause) {
                // resume recording
                setPause(false)
                mediaRecorderRef.current.resume()
              } else {
                handleStartCaptureClick()
              }
            }}
            data-testid="startStopRecording"
          >
            {capturing ? time : ''}
          </div>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{
            position: 'absolute',
            right: '16px',
            bottom: '48px'
          }}
        >
          {!capturing && (
            <IconButton
              onClick={() => inputRef.current.click()}
              size="large"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.4)'
              }}
            >
              <input
                  ref={inputRef}
                  type="file"
                  accept="audio/*,video/*"
                  onChange={(e) => {
                    const videoFile = e.target.files[0]
                    setVideoSrc(URL.createObjectURL(videoFile), videoFile)
                  }}
                  style={{
                    display: 'none'
                  }}
              />
              <Collections htmlColor="white" />
            </IconButton>
          )}
          {capturing
            ? (
              <IconButton
                onClick={onPressNext}
                size="large"
                disabled={capturing && !pause}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  color: 'white',
                  borderRadius: '8px'
                }}
                data-testid="sendPressNext"
              >
                <Typography variant="h6" sx={{ marginRight: 1 }}>
                  Send
                </Typography>
                <Send />
              </IconButton>
              )
            : (
              <IconButton
                onClick={() => setUserFacingMode(!userFacingMode)}
                size="large"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.4)'
                }}
              >
                <FlipCameraAndroidRounded htmlColor="white" />
              </IconButton>
              )}
        </Stack>
      </div>
    </div>
  )
}

export default CreateVideo
