import * as React from 'react'
import { FormControl, Grid, Stack } from '@mui/material'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import axios from 'axios'

// importing components
import { UserContext } from '../../../Context/UserContext'

// importing ui
import StandardModal, { StandardModalButtonProps } from 'ui/Modals/StandardModal'
import CustomPriceFields from 'ui/TextInputs/PriceFieldsInput'
import { TextLabel } from 'ui/TextInputs/TextInput'

export interface AddEditContactModalContactType {
  name: string,
  phone: string,
  email: string,
  city: string,
  state: string,
  id?: string,
}

export interface AddEditContactModalProps {
  contact?: AddEditContactModalContactType;
  isEdit: boolean;
  onSuccess?: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const AddEditContactModal: React.FC<AddEditContactModalProps> = (props) => {
  const { jwtToken, business, activeBusiness } = React.useContext(UserContext)

  // component states
  const contactInitial: AddEditContactModalContactType = {
    name: '',
    phone: '',
    email: '',
    city: '',
    state: ''
  }
  const [contact, setContact] = React.useState<AddEditContactModalContactType>(contactInitial)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const addNewContactApi = () => {
    if (isProcessing) {
      return
    }
    setIsProcessing(true)
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/contacts/upload`, [contact], {
      headers: {
        'NRCT-UID': business[activeBusiness].ID,
        Authorization: `Bearer ${jwtToken}`
      }
    })
      .then(() => {
        props.onSuccess()
        props.onClose()
        setContact(contactInitial)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsProcessing(false))
  }

  const updateContactApi = () => {
    if (isProcessing) {
      return
    }
    setIsProcessing(true)
    axios.patch(`${process.env.REACT_APP_BUSINESS_URL}/contact/edit`, contact, {
      params: {
        contact_id: contact.id
      },
      headers: {
        'NRCT-UID': business[activeBusiness].ID,
        Authorization: `Bearer ${jwtToken}`
      }
    })
      .then((res) => {
        props.onSuccess()
        props.onClose()
        setContact(contactInitial)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsProcessing(false))
  }

  React.useEffect(() => {
    if (props.isEdit) {
      setContact(props.contact)
    }
  }, [props.contact, props.isEdit])

  const modalButtons = (): StandardModalButtonProps[] => {
    if (props.isEdit) {
      return [
        {
          title: isProcessing ? 'Updating' : 'Update',
          color: !!contact.email && !!contact.phone && !isProcessing ? 'primary' : 'ghost',
          onPress: () => {
            if (!!contact.email && !!contact.phone) {
              updateContactApi()
            }
          }
        }
      ]
    }

    return [
      {
        title: isProcessing ? 'Creating' : 'Create',
        color: !!contact.email && !!contact.phone && !isProcessing ? 'primary' : 'ghost',
        onPress: () => {
          if (!!contact.email && !!contact.phone) {
            addNewContactApi()
          }
        }
      }
    ]
  }

  return (
    <StandardModal
      title={props.isEdit ? 'Edit Contact' : 'Add Contact'}
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose()
        setContact(contactInitial)
      }}
      buttons={modalButtons()}
    >
      <Grid container>
        <FormControl fullWidth margin='none'>
          <CustomPriceFields
            label='Name'
            placeholder="Your contact's name"
            value={contact.name}
            inputProps={{ maxLength: '63' }}
            onChange={(e) => {
              setContact({
                ...contact,
                name: e.target.value
              })
            }}
            style={{
              backgroundColor: '#f2f2f2',
              borderRadius: 10
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 16 }}>
          <TextLabel style={{ marginLeft: 4 }}>Phone</TextLabel>
          <MaterialUiPhoneNumber
            defaultCountry={'us'}
            id='phone'
            variant='filled'
            size='small'
            InputProps={{ hiddenLabel: true, disableUnderline: true }}
            value={contact.phone}
            onChange={(e) => {
              setContact({
                ...contact,
                phone: e.replace(/\s|-|\(|\)/g, '')
              })
            }}
            style={{
              backgroundColor: '#f2f2f2',
              borderRadius: 10
            }}
            disableAreaCodes={true}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 16 }}>
          <CustomPriceFields
            label='Email'
            placeholder="Your contact's email"
            type='email'
            value={contact.email}
            inputProps={{ maxLength: '63' }}
            onChange={(e) => {
              setContact({
                ...contact,
                email: e.target.value
              })
            }}
            style={{
              backgroundColor: '#f2f2f2',
              borderRadius: 10
            }}
          />
        </FormControl>
        <Stack direction='row' spacing={1} justifyContent='stretch'>
          <FormControl style={{ marginTop: 16, marginBottom: 8 }}>
            <TextLabel style={{ marginLeft: 4 }}>City</TextLabel>
            <CustomPriceFields
              placeholder='City'
              type='text'
              value={contact.city}
              inputProps={{ maxLength: '63' }}
              onChange={(e) => {
                setContact({
                  ...contact,
                  city: e.target.value
                })
              }}
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: 10
              }}
            />
          </FormControl>
          <FormControl style={{ marginTop: 16, marginBottom: 8 }}>
            <TextLabel style={{ marginLeft: 4 }}>State</TextLabel>
            <CustomPriceFields
              placeholder='State'
              type='text'
              value={contact.state}
              inputProps={{ maxLength: '63' }}
              onChange={(e) => {
                setContact({
                  ...contact,
                  state: e.target.value
                })
              }}
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: 10
              }}
            />
          </FormControl>
        </Stack>
      </Grid>
    </StandardModal>
  )
}

AddEditContactModal.defaultProps = {
  contact: {
    name: '',
    phone: '',
    email: '',
    city: '',
    state: ''
  },
  onSuccess: () => {}
}

export default AddEditContactModal
