import axios from 'axios'

/**
 * @function commentDelete Used to delete a comment on a post
 * @param {string} commentId - comment's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group cover upload
 */
export const commentDelete = (commentId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/comment/delete/${commentId}`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
