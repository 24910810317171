import React from 'react'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import './TimePicker.css'

// importing base component
import TextInput from '../../TextInputs/TextInput'
import DateAdapter from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

export interface TimePickerProps {
  dense?: boolean;
  value: string;
  onChange: (e: any) => void;
}

const TimePicker: React.FC<TimePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MobileTimePicker
        value={props.value}
        onChange={props.onChange}
        orientation='portrait'
        renderInput={(params) => {
          return (
            <TextInput
              color={'#8aa6de'}
              {...params}
              variant='outlined'
              inputProps={{
                ...params.inputProps,
                style: props.dense && { padding: '8px 12px' } // For dense
              }}
            />
          )
        }}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default TimePicker
