import Slide from '@mui/material/Slide'
import { SnackbarProvider } from 'notistack'
import React, { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ThemeProvider from 'ui/Theming/ThemeProvider'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// importing helpers
import { GoogleAnalyticsInitialize } from 'helpers/ga'

import UserContextProvider from './Context/UserContext'
import GlobalFuncHOC from './Context/GlobalFuncHOC'
import Page404 from './Layouts/404'
import ProxyPath from './Layouts/ProxyPath'

// new pages
import Landing from './pages/Landing/Landing'
import Dashboard from './pages/Dashboard/Dashboard'
import AllOffers from './pages/AllOffers/AllOffers'
import OfferAnalytics from './pages/OfferAnalytics/OfferAnalytics'
import Contacts from './pages/Contacts/Contacts'
import StreamChat from './pages/StreamChat/StreamChat'
import Videos from './pages/Videos/Videos'

// importing redux store
import { store, persister } from './Utils/redux/store'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    hj: any;
    _hjSettings: any;
  }
}

const App = () => {
  useEffect(() => {
    if (window.innerWidth < 600) {
      return
    }
    (function () {
      const s1 = document.createElement('script')
      const s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/61f753f0b9e4e21181bcac63/1fqn1vpuv'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()
    if (process.env.REACT_APP_DEPLOYMENT !== 'dev') {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
        h._hjSettings = { hjid: 2850988, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script'); r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
  }, [])
  useEffect(() => {
    GoogleAnalyticsInitialize()
  }, [])
  return (
    <ThemeProvider>
      <CookiesProvider>
        <ReduxProvider store={store}>
          <PersistGate persistor={persister} loading={<div>loading</div>}>
            <UserContextProvider>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={1500}
                preventDuplicate
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                TransitionComponent={Slide}
              >
                <BrowserRouter>
                  <GlobalFuncHOC>
                    <Switch>
                      <Route exact path='/join' component={Landing} />
                      <Route exact path='/' component={Landing} />
                      <Route exact path='/login' component={Landing} />
                      <Route exact path='/signup' component={Landing} />
                      <Route exact path='/admin-login*' component={Landing} />
                      <Route exact path='/forgot*' component={Landing} />
                      <Route exact path='/businesslogin*' component={Landing} />
                      <Route exact path='/dashboard*' component={Dashboard} />
                      <Route exact path='/offers' component={AllOffers} />
                      <Route exact path='/offer/:id' component={OfferAnalytics} />
                      <Route exact path='/contacts' component={Contacts} />
                      {/* <Route exact path="/chat" component={StreamChat} /> */}
                      <Route exact path="/video/:which" component={Videos} />
                      <Route path='/proxy' component={ProxyPath} />
                      <Route path='*' component={Page404} />
                    </Switch>
                  </GlobalFuncHOC>
                </BrowserRouter>
              </SnackbarProvider>
            </UserContextProvider>
          </PersistGate>
        </ReduxProvider>
      </CookiesProvider>
    </ThemeProvider>
  )
}

export default App
