const timeDefaults = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}

export const formatDate = (
  dateObj,
  {
    showTime,
    formatBy = ({ date, month, year }) => `${month} ${date}, ${year}`,
    ...options
  } = {}
) => {
  if (typeof dateObj === 'string') return formatDate(new Date(dateObj))
  if (!formatBy) {
    return dateObj.toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...options,
      ...(showTime ? timeDefaults : {})
    })
  }

  const [date, month, year] = dateObj
    .toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
    .split(' ')
  return formatBy({ date, month, year })
}

const alphaValues = [
  { above: 1000, letter: 'K' },
  { above: 1000000, letter: 'M' }
]

export const formatNumber = (n, { decimals = 2, alpha = false } = {}) => {
  const [num, suffix] = alpha
    ? alphaValues.reduce(
      ([num, suff], { above, letter }) => [
        n >= above ? n / above : num,
        n >= above ? letter : suff
      ],
      [n, '']
    )
    : [n, '']

  const ret = Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  }).format(num)
  return ret + suffix
}
