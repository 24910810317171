import React from 'react'
import { Menu, MenuItem, Typography, ListItemAvatar, Avatar } from '@mui/material'

export interface MenuItemProps {
  text: string;
  color?: 'primary' | 'secondary' | 'error' | 'ghost' | string;
  onPress: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
}

export interface CardMenuProps {
  id: string;
  isOpen: Element | null;
  onClose: () => void;
  menuItems: MenuItemProps[];
}

const CardMenu: React.FC<CardMenuProps> = (props) => {
  return (
    <Menu
        id={props.id}
        anchorEl={props.isOpen}
        open={Boolean(props.isOpen)}
        onClose={props.onClose}
        PaperProps={{
          style: {
            borderRadius: 10,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            padding: 0,
            marginTop: 4
          }
        }}
      >
        {props.menuItems.map((menuItem, i) => {
          return (
            <MenuItem
              key={`${i}`}
              onClick={menuItem.onPress}
              color={menuItem.color ? menuItem.color : 'black'}
              disabled={menuItem.disabled}
            >
              {Boolean(menuItem.icon) && (
                <ListItemAvatar>
                  <Avatar
                    color={menuItem.color ? menuItem.color : 'black'}
                  >
                    {menuItem.icon}
                  </Avatar>
                </ListItemAvatar>
              )}
              <Typography
                variant="h6"
                color={menuItem.color ? menuItem.color : 'black'}
              >
                {menuItem.text}
              </Typography>
            </MenuItem>
          )
        })}
      </Menu>
  )
}

export default CardMenu
