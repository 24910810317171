import axios from 'axios'

/**
 * @function businessContactsFetch Used to get all contacts depending on filter
 * @param {string} businessId - business's ID
 * @param {number} size - page size
 * @param {number} page - page index
 * @param {string} filter - all, email or phone
 * @param {string} nrctUID - user's business id
 * @param {string} jwt - jwt token
 * @returns {Promise} successful upload is true
 */
export const businessContactsFetch = (businessId, size, page, filter = 'all', nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/contacts`, {
      limit: size,
      offset: page,
      business_id: businessId
    }, {
      params: {
        filter: filter
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body || []))
      .catch((err) => {
        reject(err)
      })
  })
}
