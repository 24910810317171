import axios from 'axios'

export const businessCoverPresignGet = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BUSINESS_URL}/business/cover/pre-sign`, {
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
