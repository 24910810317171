import axios from 'axios'

/**
 * @function businessPaymentSubscriptionIntent Used to fetch intent for subscription payment
 * @param {Object} body - object for request body
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessPaymentSubscriptionIntent = (
  body: {
    name: string;
    address: string;
    coupon: string;
    planId: string;
    special?: boolean;
  },
  nrctUID: string,
  jwt: string
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/payment/subscription/intent`, {
      name: body.name,
      address: body.address,
      coupon: body.coupon,
      plan_id: body.planId,
      is_special_customer: body.special
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}

/**
 * @function businessPaymentSubscriptionStatus Used to indicate successful payment call
 * @param {Object} body - object for request body
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessPaymentSubscriptionStatus = (
  body: {
    intent: string;
    subscription: string;
  },
  nrctUID: string,
  jwt: string
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/payment/subscription/status`, {}, {
      params: {
        intent: body.intent,
        subscription: body.subscription
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}

/**
 * @function businessPaymentSubscriptionCoupon Used to check if coupon is correct or not
 * @param {Object} body - coupon and planId
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessPaymentSubscriptionCoupon = (
  body: {
    coupon: string;
    planId: string;
    special?: boolean;
  },
  nrctUID: string,
  jwt: string
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/payment/subscription/coupon`, {
      coupon: body.coupon,
      plan_id: body.planId,
      is_special_customer: body.special
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}

/**
 * @function businessPaymentSubscriptionCancel Used to cancel users subscription
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessPaymentSubscriptionCancel = (
  nrctUID: string,
  jwt: string
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/payment/subscription/cancel`, {}, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
