import makeStyles from '@mui/styles/makeStyles'
import * as dayjs from 'dayjs'
import React from 'react'

function TextPreview ({ offerData, smsData }) {
  const classes = styles()

  return (
    <div className={classes.root}>
      <div
        className={classes.imgContainer}
        style={{
          backgroundImage: `url(${offerData.cover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      ></div>
      <div className={classes.textContainer}>
        <div className={classes.message}>
          {smsData.message ? smsData.message : '[Enter a message]'}
        </div>
        <div className={classes.valid}>
          Valid upto: {dayjs(offerData.end_date).format('MM/DD/YYYY')}
        </div>
      </div>
    </div>
  )
}

export default TextPreview

const styles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '170px',
    borderRadius: 12,
    background: '#F2F2F2'
  },
  imgContainer: {
    width: '100%',
    height: '220px',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12
  },
  textContainer: {
    padding: '10px',
    fontSize: 12
  },
  message: {
    marginBottom: 10,
    overflowWrap: 'break-word'
  },
  valid: {
    color: '#464646'
  }
})
