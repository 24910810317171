import axios from 'axios'

/**
 * @function businessAnalyticsOfferScans Used to fetch list of offer scan data
 * @param {string} offerId - offer id for which to fetch scans for
 * @param {string} startTime - iso string of the start time from when needs to be fetched
 * @param {string} endTime - iso string of the end time till when data needs to be fetched
 * @param {string} nrctUID - user's id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} resolves to list of scans
 */
export const businessAnalyticsOfferScans = (offerId, startTime, endTime, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business-analytics/offers/scans`, {
      offer_id: offerId,
      from: startTime,
      to: endTime
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
