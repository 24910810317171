import axios from 'axios'

/**
 * @function businessOfferDetails Used to fetch details for a offer
 * @param {string} offerId - offer id
 * @returns {Promise} Promise represents offer details object for successful call
 */
export const businessOfferDetails = (offerId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/offer-details?offer_id=${offerId}`)
      .then((res) => {
        resolve(res.data.body)
      })
      .catch((err) => reject(err))
  })
}
