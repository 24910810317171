import {
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
  IconButton,
  Button,
  CircularProgress,
  FormControl,
  Slide,
  Avatar,
  Tooltip,
  TextField,
  MenuItem,
  Autocomplete
} from '@mui/material'
import {
  Insights,
  Contacts,
  ArrowForwardIos,
  Add,
  Edit,
  ShareRounded,
  Visibility,
  QrCode,
  Settings
} from '@mui/icons-material'
import React, { useContext, useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import axios from 'axios'
import validator from 'validator'
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas'

// importing ui
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import { TextLabel } from 'ui/TextInputs/TextInput'
import CropImageModal from 'ui/Custom/Modals/CropImageModal'
import DashboardTop from 'ui/Custom/Dashboard/DashboardTop'
// import StoreDetails from 'ui/Custom/Dashboard/StoreDetails'
// import OurStory from 'ui/Custom/Dashboard/OurStory'
import TopOffers from 'ui/Custom/Dashboard/TopOffers'
// import DashboardNearcasts from 'ui/Custom/Dashboard/DashboardNearcasts'
import RightItemCard from 'ui/Cards/RightItemCard/RightItemCard'
import ImagePicker from 'ui/Custom/ImagePicker/ImagePicker'
import PostViewModal from 'ui/Custom/Modals/PostViewModal/PostViewModal'
import IntroCreator from 'ui/Custom/IntroCreator'
import CustomBox from 'ui/Custom/Box/Box'

// importing components
import ScheduleModal from '../../Components/Modals/ScheduleModal'
import Header from '../../Components/General/Header/Header'
import CreateEditOfferModal from '../../Components/Modals/CreateEditOfferModal'
// import BusinessOnboardingModal from '../../Components/Modals/Walkthrough/BusinessOnboardingModal'
import CloudSpongeWidget from '../../CloudSpongeWidget'
import DashboardNearcasts from '../../Components/DashboardNearcasts/DashboardNearcasts'
import DashboardAnalytics from '../../Components/DashboardAnalytics/DashboardAnalytics'
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'

// importing apis
import { businessCoverView } from 'api/business/cover/view'
import { businessAvatarView } from 'api/business/avatar/view'
import { businessDetailsGet } from 'api/business/details'
import { businessUpdateDetailsPatch } from 'api/business/update/details'
import { offers, offersOld } from 'api/offers'
import { businessAvatarPresignGet } from 'api/business/avatar/presign'
import { businessCoverPresignGet } from 'api/business/cover/presign'
import { businessAnalyticsInsights } from 'api/business/analytics/insights'
import { businessContactsUpload } from 'api/business/contacts/upload'
import { businessCategories } from 'api/business/categories'

// importing helpers
import { getUserDomainURL } from 'helpers/url'
import { recordGaEvent } from 'helpers/ga'

// importing redux store
import { useAppSelector } from '../../Utils/redux/store'

const Dashboard = (props) => {
  // ref
  const dashboardTopRef = useRef(null)
  const settingsColumnRef = useRef(null)
  const qrCodeRef = useRef(null)

  // user context
  const {
    business,
    jwtToken,
    activeBusiness,
    switchNeighbourHood,
    user,
    preferences
  } = useContext(UserContext)
  const { openShareModal, openFreeTrialModal, openUpgradePlanToAccess, openSettingsModal } = useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  // const { state } = useLocation()

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const subscriptionPlan = subscriptionState.subscription.planDetails
  const analyticPlanType = subscriptionPlan.analytics // 1 -> basic, 2 ->  advance and 3 -> advance and extra
  const createOfferCount = subscriptionPlan.createOffer

  // component states
  const [loading, setLoading] = useState(true)
  const [avatar, setAvatar] = useState()
  const [cover, setCover] = useState()
  const [businessData, setBusinessData] = useState({})
  const [businessOffers, setBusinessOffers] = useState([])
  const [businessPastOffers, setBusinessPastOffers] = useState([])
  // state for business insights
  const insightsInitial = {
    fans: '0',
    reviews: '0',
    views: '0'
  }
  const [insights, setInsights] = useState(insightsInitial)
  // settings column states
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [copyOfOldBusinessData, setCopyOfOldBusinessData] = useState({})
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  // settingsSelectedImageTemp: store user's selected image here till user does not complete crop
  const [settingsSelectedImageTemp, setSettingsSelectedImageTemp] = useState(null)
  const [settingsAvatarEditOriginal, setSettingsAvatarEditOriginal] = useState(null)
  const [settingsAvatar, setSettingsAvatar] = useState(null)
  const [settingsCoverEditOriginal, setSettingsCoverEditOriginal] = useState(null)
  const [settingsCover, setSettingsCover] = useState(null)
  const [isCropImageModalOpen, setIsCropImageModalOpen] = useState(null) // null | "profile" | "cover"
  const [isSaving, setIsSaving] = useState(false)
  const [settingsInputError, setSettingsInputError] = useState({})
  // schedules
  const [openSchedules, setOpenSchedules] = useState(false)
  // create edit offer modal states
  const [isCreateEditOpen, setIsCreateEditOpen] = useState(false)
  // state for post display
  const postViewStateEmpty = {
    index: 0,
    nearcast: {},
    isOpen: false
  }
  const [postViewState, setPostViewState] = useState(postViewStateEmpty)
  // is onboarding open
  // const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)
  const [isWalkThrough, setIsWalkThrough] = useState(false)

  const getBusinessData = useCallback(() => {
    businessDetailsGet(
      business[activeBusiness].business_id,
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resData) => {
        setCopyOfOldBusinessData(resData.body.business_details)
        setBusinessData(resData.body.business_details)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [activeBusiness, business, jwtToken])

  const getBusinessOffers = useCallback(() => {
    offers(
      business[activeBusiness].business_id,
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resBody) => setBusinessOffers(resBody))
      .catch((err) => console.log(err))
  }, [activeBusiness, business, jwtToken])

  const getBusinessPastOffers = useCallback(() => {
    offersOld(business[activeBusiness].business_id, business[activeBusiness].ID, jwtToken)
      .then((resBody) => {
        if (resBody) {
          if (resBody !== null) {
            setBusinessPastOffers(resBody)
          } else {
            setBusinessPastOffers([])
          }
        }
      })
      .catch((err) => console.log(err))
  }, [activeBusiness, business, jwtToken])

  const getBusinessInsights = useCallback(() => {
    businessAnalyticsInsights(
      business[activeBusiness].business_id,
      dayjs().subtract(1, 'month').toISOString(),
      dayjs().toISOString(),
      business[activeBusiness].ID,
      jwtToken
    )
      .then((insights) => {
        setInsights({
          fans: insights.fans,
          reviews: insights.reviews,
          views: insights.views
        })
      })
      .catch((err) => {
        console.log(err.message)
        setInsights(insightsInitial)
      })
  }, [activeBusiness, business, jwtToken])

  const updateBusinessDetails = useCallback(
    async (updatedData) => {
      // check if there were any input errors
      if (Object.values(settingsInputError).includes(true)) {
        return enqueueSnackbar('We detected some errors in your form, please fix them and try again!', { variant: 'error' })
      }
      const businessKeys = Object.keys(businessData)
      const filteredUpdatedData = {}

      businessKeys.forEach((key) => {
        const oldData = copyOfOldBusinessData[key]
        const newData = updatedData[key]
        if (oldData !== newData) {
          filteredUpdatedData[key] = newData
        }
      })

      if (
        Object.keys(filteredUpdatedData).length === 0 &&
        settingsCover === null &&
        settingsAvatar === null
      ) {
        setIsSettingsOpen(false)
        return
      }

      setIsSaving(true)

      // extra checks for filteredUpdatedData
      if (Object.keys(filteredUpdatedData).length > 0) {
        if (filteredUpdatedData.website) {
          // check if website entered has http or https starting or not
          if (!filteredUpdatedData.website.includes('http')) {
            filteredUpdatedData.website = 'http://' + filteredUpdatedData.website
          }
        }
      }

      if (filteredUpdatedData.category) {
        filteredUpdatedData.category_id = selectedCategory.ID
      }

      try {
        // If new avatar provided then upload the avatar image
        if (settingsAvatar) {
          await businessAvatarPresignGet(business[activeBusiness].ID, jwtToken).then(async (res) => {
            if (res.url) {
              axios.put(res.url, settingsAvatar, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Context-Type': settingsAvatar.type
                }
              }).then(() => {
                setAvatar(businessAvatarView(business[activeBusiness].business_id))
                setSettingsAvatar(null)
              })
            }
          })
        }
        // If new cover provided then upload the cover image
        if (settingsCover) {
          await businessCoverPresignGet(business[activeBusiness].ID, jwtToken).then(async (res) => {
            if (res.url) {
              axios.put(res.url, settingsCover, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Context-Type': settingsCover.type
                }
              }).then(() => {
                setCover(businessCoverView(business[activeBusiness].business_id))
                setSettingsCover(null)
              })
            }
          })
        }
        await businessUpdateDetailsPatch(
          filteredUpdatedData,
          business[activeBusiness].ID,
          jwtToken
        )
        enqueueSnackbar('Your Business details have been updated!', {
          variant: 'success'
        })
        getBusinessData()
        setIsSettingsOpen(false)
        setIsSaving(false)
        // don't remove: this handles a edge case when new business is added
        // but on refreshing page after clicking close or save, the edit column
        // appears again
        history.push('/dashboard')
      } catch (err) {
        enqueueSnackbar(
          'Please Try again!',
          { variant: 'error' }
        )
        setIsSaving(false)
      }
    },
    [
      activeBusiness,
      business,
      businessData,
      enqueueSnackbar,
      getBusinessData,
      jwtToken,
      settingsCover,
      settingsAvatar
    ]
  )

  const processAndUploadContacts = async (contacts) => {
    if (contacts.length > 0) {
      const data = contacts.map((item) => {
        let name
        if (!item.first_name && !item.last_name) {
          name = item.__selectedMail__
        } else {
          name = `${item.first_name} ${item.last_name}`.trim()
        }
        return {
          name: name,
          email: item.__selectedMail__,
          phone: item.__selectedPhone__,
          city: item.__selectedAddress__
        }
      })
      businessContactsUpload(data, business[activeBusiness].ID, jwtToken)
        .then((res) => {
          enqueueSnackbar('Contacts Uploaded Successfully!', {
            variant: 'success'
          })
        })
        .catch((err) => {
          enqueueSnackbar('Error Uploading Contacts!', {
            variant: 'error'
          })
        })
    }
  }

  const downloadQrCode = (ref) => {
    html2canvas(ref.current, {
      useCORS: true,
      allowTaint: true,
      windowWidth: ref.current.offsetWidth,
      windowHeight: ref.current.offsetHeight,
      scale: 1
    })
      .then((canvas) => {
        const qrImage = canvas.toDataURL('image/png')
        const tempATag = document.createElement('a')
        tempATag.href = qrImage
        tempATag.download = `qrcode${Date.now()}.png`
        tempATag.click()
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  useEffect(() => {
    businessCategories()
      .then((resBody) => {
        setCategories(resBody.categories)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (jwtToken && business.length > 0 && activeBusiness >= 0) {
      getBusinessData()
      getBusinessOffers()
      getBusinessPastOffers()
      getBusinessInsights()
      setAvatar(businessAvatarView(business[activeBusiness].business_id))
      setCover(businessCoverView(business[activeBusiness].business_id))
    }
  }, [
    jwtToken,
    business,
    activeBusiness,
    getBusinessData,
    getBusinessOffers,
    getBusinessPastOffers
  ])

  // useEffect(() => {
  //   if (state !== undefined) {
  //     if (state.isNewBusiness) {
  //       const indexOfId = business.findIndex((b) => b.business_id === state.newBusinessId)
  //       switchNeighbourHood(indexOfId || 0)
  //       setIsOnboardingOpen(true)
  //       setIsWalkThrough(false)
  //       setIsSettingsOpen(false)
  //     }
  //   } else {
  //     if (isSettingsOpen) {
  //       setIsSettingsOpen(false)
  //     }
  //   }
  // }, [state])

  useEffect(() => {
    if (
      subscriptionState.subscription.firstTimeShowed === false &&
      subscriptionState.subscription.planName === 'Free'
    ) {
      openFreeTrialModal(() => setIsWalkThrough(true))
    }
  }, [subscriptionState.subscription])

  const renderTopOfferLeftButton = () => {
    if (businessOffers === null && businessPastOffers.length !== 0) {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push('/offers')}
          disableElevation
          disabled={isSettingsOpen}
          style={{
            marginBottom: 8
          }}
        >
          View Past Offers
        </Button>
      )
    }

    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        disableElevation
        onClick={() => {
          if (createOfferCount === 0) {
            return openUpgradePlanToAccess()
          }
          setIsCreateEditOpen(true)
        }}
        disabled={isSettingsOpen}
      >
        Create Offer
      </Button>
    )
  }
  const defaultSelectedCategory = useMemo(() => {
    if (categories.length > 0 && loading === false) {
      return categories.find((cate) => cate.Category === businessData.category)
    }
    return null
  }, [categories, businessData, loading])

  return (
    <>
      {!business || business.length <= 0
        ? (
          <Redirect to="/" />
          )
        : (
        <>
          <Header />
          {/* <BusinessOnboardingModal
            isOpen={isOnboardingOpen}
            onClose={() => {
              setIsOnboardingOpen(false)
              setIsSettingsOpen(true)
            }}
            onContinue={() => {
              setIsOnboardingOpen(false)
              setIsSettingsOpen(true)
              if (window.innerWidth > 800) {
                setIsWalkThrough(true)
              }
            }}
          /> */}
          {loading
            ? (
              <div
                style={{
                  position: 'fixed',
                  height: '100vh',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#FFF',
                  zIndex: 1000
                }}
              >
                <CircularProgress />
              </div>
              )
            : (
              <Container maxWidth="xl" sx={{ mt: '74px', marginBottom: '74px' }}>
                <Grid container spacing={2}>
                  {/* Settings Column */}
                  {isSettingsOpen && (
                    <Slide direction="up" in={isSettingsOpen}>
                      <Grid item xs={12} md={6} lg={3}>
                        <ScheduleModal
                          {...{
                            open: openSchedules,
                            setOpen: setOpenSchedules,
                            data: businessData,
                            setData: setBusinessData
                          }}
                        />
                        <Box ref={settingsColumnRef} style={{ ...styles.boxContainer, padding: 0 }}>
                          <Typography
                            variant="h4"
                            style={{ padding: styles.boxContainer.padding }}
                          >
                            Customize your business page
                          </Typography>
                          <Typography
                            variant="h5"
                            style={styles.settingsSectionTitle}
                          >
                            Page Information
                          </Typography>
                          <Stack style={styles.boxContainer}>
                            <FormControl margin="normal">
                              <PriceFieldsInput
                                label="Name*"
                                value={businessData.name}
                                onChange={(e) => {
                                  // min input length: 3
                                  if (e.currentTarget.value.length < 3) {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      name: true
                                    })
                                  } else {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      name: false
                                    })
                                  }
                                  // max input length: 128
                                  if (e.currentTarget.value.length > 128) {
                                    return
                                  }
                                  setBusinessData({
                                    ...businessData,
                                    name: e.currentTarget.value
                                  })
                                }}
                                error={settingsInputError.name}
                              />
                            </FormControl>
                            <FormControl
                              margin="normal"
                              style={{
                                width: '100%',
                                backgroundColor: '#F4F7FD'
                              }}
                              id="business-description-container"
                            >
                              <PriceFieldsInput
                                id="business-description"
                                multiline
                                rows={4}
                                label="Description"
                                placeholder="About your business"
                                value={businessData.story}
                                onChange={(e) => {
                                  // min input length: 128*5
                                  if (e.currentTarget.value.length > 128 * 5) {
                                    return
                                  }
                                  setBusinessData({
                                    ...businessData,
                                    story: e.currentTarget.value
                                  })
                                }}
                              />
                            </FormControl>
                            <FormControl margin="normal">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={selectedCategory || defaultSelectedCategory}
                                options={categories}
                                getOptionLabel={(category) => category.Category}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="Select Category" />}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setBusinessData({
                                      ...businessData,
                                      category: newValue.Category
                                    })
                                    setSelectedCategory(newValue)
                                  }
                                }}
                              />
                            </FormControl>
                          </Stack>
                          <Typography
                            variant="h5"
                            style={styles.settingsSectionTitle}
                          >
                            Contact Details
                          </Typography>
                          <Stack style={styles.boxContainer}>
                            <Typography variant="h6">Add Business Hours</Typography>
                            {businessData.business_schedules.filter(
                              (item) => item.is_active
                            ).length > 0
                              ? (
                              <>
                                {businessData.business_schedules.map(
                                  (item, index) => {
                                    return (
                                      <Typography
                                        key={`${index}`}
                                        style={{ fontSize: 14, marginTop: 5 }}
                                      >
                                        {item.day.substring(0, 3)}:{' '}
                                        {item.is_active
                                          ? `${dayjs(item.start_time).format(
                                              'hh:mm A'
                                            )} - ${dayjs(item.end_time).format(
                                              'hh:mm A'
                                            )}`
                                          : 'Closed'}
                                      </Typography>
                                    )
                                  }
                                )}
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: '#007AFF',
                                    marginTop: 5,
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setOpenSchedules(true)
                                  }}
                                >
                                  Change Timings
                                </span>
                              </>
                                )
                              : (
                              <IconButton
                                style={styles.settingsIconButtons}
                                onClick={() => {
                                  setOpenSchedules(true)
                                }}
                              >
                                <Add />
                              </IconButton>
                                )}
                            {/* <FormControl margin="normal">
                              <TextLabel style={{ marginBottom: 8 }}>
                                Owner Number
                              </TextLabel>
                              <MaterialUiPhoneNumber
                                defaultCountry={'us'}
                                variant="filled"
                                size="small"
                                InputProps={{
                                  hiddenLabel: true,
                                  disableUnderline: true
                                }}
                                value={user.phone}
                                disabled
                                disableDropdown
                                style={{
                                  borderRadius: '8px',
                                  backgroundColor: 'rgba(138, 166, 222, 0.08)'
                                }}
                                disableAreaCodes={true}
                              />
                            </FormControl> */}
                            <FormControl margin="normal">
                              <TextLabel style={{ marginBottom: 8, display: 'flex', justifyContent: 'space-between' }}>
                                Public Phone Number
                                {businessData.phone !== '' && <span
                                  style={{ marginLeft: 'auto', fontSize: 12, color: '#007AFF' }}
                                  onClick={ () => {
                                    setBusinessData({
                                      ...businessData,
                                      phone: ''
                                    })
                                  }}
                                >Remove</span>}
                              </TextLabel>
                              <MaterialUiPhoneNumber
                                id="business-phone"
                                defaultCountry={'us'}
                                variant="filled"
                                size="small"
                                InputProps={{
                                  hiddenLabel: true,
                                  disableUnderline: true
                                }}
                                error={settingsInputError.phone}
                                value={businessData.phone}
                                onChange={(e) => {
                                  if (!validator.isMobilePhone(e.replace(/\s|-|\(|\)/g, ''), 'any')) {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      phone: true
                                    })
                                  } else {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      phone: false
                                    })
                                  }
                                  setBusinessData({
                                    ...businessData,
                                    phone: e.replace(/\s|-|\(|\)/g, '')
                                  })
                                }}
                                style={{
                                  borderRadius: '8px',
                                  backgroundColor: settingsInputError.phone ? 'rgb(237, 223, 232)' : 'rgba(138, 166, 222, 0.08)'
                                }}
                                disableAreaCodes={true}
                              />
                            </FormControl>
                            <FormControl margin="normal">
                              <PriceFieldsInput
                                id="business-website"
                                label="Add Website"
                                placeholder="Enter your website"
                                value={businessData.website}
                                error={settingsInputError.website}
                                onChange={(e) => {
                                  if (!validator.isURL(e.currentTarget.value)) {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      website: true
                                    })
                                  } else {
                                    setSettingsInputError({
                                      ...settingsInputError,
                                      website: false
                                    })
                                  }
                                  if (e.currentTarget.value.length > 128) {
                                    return
                                  }
                                  setBusinessData({
                                    ...businessData,
                                    website: e.currentTarget.value
                                  })
                                }}
                              />
                            </FormControl>
                          </Stack>
                          <Typography
                            variant="h5"
                            style={styles.settingsSectionTitle}
                          >
                            Customize your page
                          </Typography>
                          <Stack style={styles.boxContainer}>
                            <Typography variant="h6">
                              Upload Profile Image
                            </Typography>
                            <Stack direction="row">
                              {Boolean(settingsAvatar) && (
                                <Avatar
                                  style={styles.settingsIconButtons}
                                  src={URL.createObjectURL(settingsAvatar)}
                                />
                              )}
                              <ImagePicker
                                  id="avatar-picker"
                                  onChange={(e) => {
                                    const file = e.target.files[0]
                                    setSettingsSelectedImageTemp(file)
                                    setIsCropImageModalOpen('profile')
                                  }}
                              />
                              {settingsAvatar
                                ? (
                                <Stack direction="row" alignSelf="flex-end" style={{ marginLeft: 4 }}>
                                  <Typography
                                    variant='caption'
                                    color={(theme) => theme.palette.link.main}
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      setSettingsSelectedImageTemp(settingsAvatarEditOriginal)
                                      setIsCropImageModalOpen('profile')
                                    }}
                                  >
                                    Edit
                                  </Typography>
                                  <Divider flexItem orientation="vertical" style={{ marginRight: 4, marginLeft: 4 }} />
                                  <Typography
                                    variant='caption'
                                    color={(theme) => theme.palette.link.main}
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      document.getElementById('avatar-picker').click()
                                    }}
                                  >
                                    Choose another image
                                  </Typography>
                                  <Divider flexItem orientation="vertical" style={{ marginRight: 4, marginLeft: 4 }} />
                                  <Typography
                                    variant='caption'
                                    color='error'
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      setSettingsAvatar(null)
                                      setSettingsAvatarEditOriginal(null)
                                      setSettingsSelectedImageTemp(null)
                                    }}
                                  >
                                    Remove
                                  </Typography>
                                </Stack>
                                  )
                                : (
                                <IconButton
                                  style={styles.settingsIconButtons}
                                  onClick={() => {
                                    document.getElementById('avatar-picker').click()
                                  }}
                                >
                                  <Add />
                                </IconButton>
                                  )}
                            </Stack>
                            <Typography variant="h6" style={{ marginTop: 10 }}>
                              Upload Cover Image
                            </Typography>
                            <Stack direction="row">
                              {Boolean(settingsCover) && (
                                <Avatar
                                  style={styles.settingsIconButtons}
                                  src={URL.createObjectURL(settingsCover)}
                                />
                              )}
                              <ImagePicker
                                id="cover-picker"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  setSettingsSelectedImageTemp(file)
                                  setIsCropImageModalOpen('cover')
                                }}
                              />
                              {settingsCover
                                ? (
                                <Stack direction="row" alignSelf="flex-end" style={{ marginLeft: 4 }}>
                                  <Typography
                                    variant='caption'
                                    color={(theme) => theme.palette.link.main}
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      setSettingsSelectedImageTemp(settingsCoverEditOriginal)
                                      setIsCropImageModalOpen('cover')
                                    }}
                                  >
                                    Edit
                                  </Typography>
                                  <Divider flexItem orientation="vertical" style={{ marginRight: 4, marginLeft: 4 }} />
                                  <Typography
                                    variant='caption'
                                    color={(theme) => theme.palette.link.main}
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      document.getElementById('cover-picker').click()
                                    }}
                                  >
                                    Choose another image
                                  </Typography>
                                  <Divider flexItem orientation="vertical" style={{ marginRight: 4, marginLeft: 4 }} />
                                  <Typography
                                    variant='caption'
                                    color='error'
                                    style={styles.settingsTextButton}
                                    onClick={() => {
                                      setSettingsCover(null)
                                      setSettingsCoverEditOriginal(null)
                                      setSettingsSelectedImageTemp(null)
                                    }}
                                  >
                                    Remove
                                  </Typography>
                                </Stack>
                                  )
                                : (
                                <IconButton
                                  style={styles.settingsIconButtons}
                                  onClick={() => {
                                    document.getElementById('cover-picker').click()
                                  }}
                                >
                                  <Add />
                                </IconButton>
                                  )}
                            </Stack>
                          </Stack>
                          {Boolean(isCropImageModalOpen) && (
                            <CropImageModal
                              open={Boolean(isCropImageModalOpen)}
                              onClose={() => {
                                setIsCropImageModalOpen(false)
                                setSettingsSelectedImageTemp(null)
                              }}
                              onCropComplete={(blob) => {
                                const imageFile = new File([blob], `${Date.now()}-biz`)
                                if (isCropImageModalOpen === 'profile') {
                                  setSettingsAvatar(imageFile)
                                  // for edit option
                                  setSettingsAvatarEditOriginal(settingsSelectedImageTemp)
                                } else if (isCropImageModalOpen === 'cover') {
                                  setSettingsCover(imageFile)
                                  // for edit option
                                  setSettingsCoverEditOriginal(settingsSelectedImageTemp)
                                }
                                setIsCropImageModalOpen(null)
                                setSettingsSelectedImageTemp(null)
                              }}
                              aspect={isCropImageModalOpen === 'profile' ? 4 / 4 : 16 / 9}
                              imageFile={settingsSelectedImageTemp}
                            />
                          )}
                          <Stack
                            style={styles.boxContainer}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <Button
                              color="ghost"
                              variant="contained"
                              style={{
                                marginRight: 10
                              }}
                              onClick={() => {
                                setSettingsAvatar(null)
                                setSettingsCover(null)
                                setBusinessData(copyOfOldBusinessData)
                                setIsSettingsOpen(false)
                                // don't remove: this handles a edge case when new business is added
                                // but on refreshing page after clicking close or save, the edit column
                                // appears again
                                history.push('/dashboard')
                              }}
                              disableElevation
                            >
                              Cancel
                            </Button>
                            <Button
                              color={isSaving ? 'ghost' : 'primary'}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                if (isSaving) {
                                  return
                                }
                                updateBusinessDetails(businessData)
                              }}
                              style={{
                                paddingRight: 26,
                                paddingLeft: 26
                              }}
                            >
                              {isSaving ? 'Saving' : 'Save'}
                            </Button>
                          </Stack>
                        </Box>
                      </Grid>
                    </Slide>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={isSettingsOpen ? 6 : 12}
                    lg={8}
                    style={{
                      height: 'min-content'
                    }}
                  >
                    <Grid item xs={12} ref={dashboardTopRef}>
                      <DashboardTop
                        key={businessData.id}
                        name={businessData.name}
                        category={businessData.category.length > 0 ? businessData.category : undefined}
                        website={businessData.custom_url}
                        avatar={settingsAvatar ? URL.createObjectURL(settingsAvatar) : avatar}
                        cover={settingsCover ? URL.createObjectURL(settingsCover) : cover}
                        dashboardActionContent={(
                          <>
                            <Tooltip title="Edit">
                              <IconButton
                                disabled={isSettingsOpen}
                                onClick={() => {
                                  if (isSettingsOpen && settingsColumnRef) {
                                    settingsColumnRef.current.style.border = '1px solid red'
                                    settingsColumnRef.current.scrollIntoView()
                                    setTimeout(() => {
                                      settingsColumnRef.current.style.border = 'none'
                                    }, 1000 * 3)
                                  }
                                  setIsSettingsOpen(true)
                                }}
                              >
                                <Edit htmlColor="black" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Share">
                              <IconButton
                                disabled={isSettingsOpen}
                                onClick={() => {
                                  openShareModal(
                                    'Business',
                                    'Check this amazing Business at Nearcast',
                                    '',
                                    businessData.custom_url
                                  )
                                }}
                              >
                                <ShareRounded htmlColor="black" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View as User">
                              <IconButton
                                disabled={isSettingsOpen}
                                onClick={() => window.open(getUserDomainURL(`/b/${business[activeBusiness].business_id}`))}
                              >
                                <Visibility htmlColor="black" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Settings">
                              <IconButton
                                disabled={isSettingsOpen}
                                onClick={() => {
                                  openSettingsModal()
                                  recordGaEvent('businessDashboardSettingsClicked', {
                                    name: businessData.name,
                                    id: business[activeBusiness].business_id
                                  })
                                }}
                              >
                                <Settings htmlColor="black" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    {/* Left Column - Store Details, Our Story and Gathering Section */}
                    {/* <Grid item xs={12} md={isSettingsOpen ? 12 : 5} lg={5}>
                      <StoreDetails
                        isForBusinessPortal={true}
                        address={businessData.address_line}
                        schedule={{
                          value: businessData.business_schedules
                        }}
                        accountMobile={user.phone}
                        businessMobile={{
                          value: businessData.phone,
                          focusAction: () => {
                            setIsSettingsOpen(true)
                            setTimeout(() => {
                              document.getElementById('business-phone').focus()
                            }, 1000)
                          }
                        }}
                        website={{
                          value: businessData.website,
                          focusAction: () => {
                            setIsSettingsOpen(true)
                            setTimeout(() => {
                              document.getElementById('business-website').focus()
                            }, 1000)
                          }
                        }}
                      />
                      <OurStory
                        isForBusinessPortal={true}
                        story={{
                          value: businessData.story,
                          focusAction: () => {
                            setIsSettingsOpen(true)
                            setTimeout(() => {
                              document.getElementById('business-description').focus()
                            }, 1000)
                          }
                        }}
                      />
                    </Grid> */}
                    {/* Business Reviews section */}
                    <Grid item xs={12} md={isSettingsOpen ? 12 : 12} lg={12}>
                      <CustomBox id="dashboard-nearcasts">
                        <DashboardNearcasts
                          title="Reviews"
                          isFor="business"
                          totalReviewCount={businessData.no_of_reviewers}
                        />
                      </CustomBox>
                      {postViewState.isOpen && (
                        <PostViewModal
                          nearcast={{
                            postId: postViewState.nearcast.postId,
                            postCaption: postViewState.nearcast.postCaption,
                            postPlaybackToken: postViewState.nearcast.postPlaybackToken,
                            postSticker: postViewState.nearcast.postSticker,
                            postMusic: postViewState.nearcast.postMusic,
                            creator: {
                              userId: postViewState.nearcast.userId,
                              username: postViewState.nearcast.username,
                              userCover: postViewState.nearcast.userCover
                            }
                          }}
                          open={postViewState.isOpen}
                          onClose={() => setPostViewState(postViewStateEmpty)}
                          hasUserDetails={{
                            isUserLoggedIn: true,
                            jwtToken: jwtToken,
                            nrctUID: business[activeBusiness].ID,
                            preferences: {
                              videoMuted: preferences.videoMuted,
                              toggleVideoMuted: preferences.toggleVideoMuted
                            }
                          }}
                          openShareModal={openShareModal}
                        />
                      )}
                    </Grid>
                  </Grid>
                  </Grid>
                  {/* Right Column */}
                  {!isSettingsOpen && (
                    <Slide direction="up" in={!isSettingsOpen}>
                      <Grid item xs={12} lg={4}>
                        {/* Right Column - Page insights, Contacts */}
                          {/* Business review qr code */}
                          <RightItemCard
                            id="business-review"
                            title="QR Code"
                            titleLeftAction={<QrCode />}
                            sx={{
                              marginTop: 0
                            }}
                            style={{
                              height: dashboardTopRef.current !== null
                                ? dashboardTopRef.current.offsetHeight
                                : 'auto'
                            }}
                            cardContentStyle={{
                              height: 'calc(100% - 56px - 16px)' // cardheader: 56, padding on cardContent: 16
                            }}
                            cardContent={
                              <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  width: '100%',
                                  height: '100%'
                                }}
                              >
                                <div ref={qrCodeRef} style={{ padding: 10 }}>
                                  <QRCode
                                    style={{ width: 160, height: 160 }}
                                    size={300}
                                    value={getUserDomainURL(`/business/review/${business[activeBusiness].business_id}`)}
                                  />
                                </div>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    downloadQrCode(qrCodeRef)
                                    recordGaEvent('businessDashboardDownloadQR', {
                                      qrCodeLink: getUserDomainURL(`/business/review/${business[activeBusiness].business_id}`),
                                      name: businessData.name
                                    })
                                  }}
                                  disableElevation
                                >
                                  Download
                                </Button>
                              </Stack>
                            }
                          />
                          {/* Page Insight */}
                          <RightItemCard
                            id="page-insights"
                            title="Page Insights"
                            titleLeftAction={<Insights />}
                            sx={{
                              marginTop: 2
                            }}
                            cardContentStyle={{
                              padding: 16
                            }}
                            cardContent={
                              businessData.no_of_reviewers === 0
                                ? (
                                    <Stack
                                      direction="column"
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        padding: 2
                                      }}
                                    >
                                      <img
                                        src={require('../../Components/assets/analyticsEmpty.png')}
                                        style={{
                                          width: '160px'
                                        }}
                                      />
                                      <Typography variant="caption" textAlign="center" sx={{ width: '80%', marginTop: 1 }}>
                                        You don't have enough visitors yet. Share your business page with your customers and check this page later.
                                      </Typography>
                                    </Stack>
                                  )
                                : (
                                    <Stack direction="column">
                                      <div style={styles.pageInsightValueContainer}>
                                        <Typography variant="h3">
                                          {insights.views}
                                        </Typography>
                                        <Typography variant="body2">
                                          Page Views
                                        </Typography>
                                      </div>
                                      <Divider />
                                      <Stack direction="row">
                                        <div style={styles.pageInsightValueContainer}>
                                          <Typography variant="h3">
                                            {insights.reviews}
                                          </Typography>
                                          <Typography variant="body2">
                                            Reviews
                                          </Typography>
                                        </div>
                                        <div style={styles.pageInsightValueContainer}>
                                          <Typography variant="h3">
                                            {insights.fans}
                                          </Typography>
                                          <Typography variant="body2">
                                            Fans
                                          </Typography>
                                        </div>
                                      </Stack>
                                    </Stack>
                                  )
                            }
                          />
                          {/* Analytics */}
                          {analyticPlanType > 1 && <DashboardAnalytics shouldDisplayEmptyState={businessData.no_of_reviewers === 0} />}
                          {/* Contacts */}
                          <RightItemCard
                            id="contacts"
                            sx={{
                              marginTop: 2
                            }}
                            title="Customer List"
                            titleLeftAction={<Contacts />}
                            titleRightAction={
                              <IconButton>
                                <ArrowForwardIos />
                              </IconButton>
                            }
                            onPress={() => {
                              history.push('/contacts')
                            }}
                          />
                          {/* Offers */}
                          <TopOffers
                            isForBusinessPortal={true}
                            offers={businessOffers === null
                              ? null
                              : businessOffers.map((offers) => ({
                                id: offers.id,
                                cover: offers.cover,
                                title: offers.title,
                                startDate: offers.start_date,
                                couponCode: offers.in_store_redemption,
                                nearcoins: offers.nearcoins
                              }))}
                            offersHeaderRightAction={renderTopOfferLeftButton()}
                            offersBottomAction={
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => history.push('/offers')}
                                disableElevation
                                disabled={isSettingsOpen}
                                style={{
                                  marginBottom: 8
                                }}
                              >
                                View All
                              </Button>
                            }
                            offersEmptyActions={
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  marginTop: 8,
                                  marginBottom: 16
                                }}
                                startIcon={<Add />}
                                disableElevation
                                onClick={() => {
                                  if (createOfferCount === 0) {
                                    return openUpgradePlanToAccess()
                                  }
                                  setIsCreateEditOpen(true)
                                }}
                                disabled={isSettingsOpen}
                              >
                                Create Offer
                              </Button>
                            }
                            onOfferPress={(offer) => history.push(`/offer/${offer.id}`)}
                          />
                      </Grid>
                    </Slide>
                  )}
                </Grid>
                {isCreateEditOpen && (
                  <CreateEditOfferModal
                    type='create'
                    open={isCreateEditOpen}
                    onClose={() => {
                      setIsCreateEditOpen(false)
                      // getBusinessOffers()
                    }}
                  />
                )}
                <CloudSpongeWidget
                  options={{
                    afterSubmitContacts: processAndUploadContacts,
                    css: '/contacts.css',
                    displayContactsColumns: ['email', 'phone']
                  }}
                >
                  <button className="cloudsponge-launch" id="cloudsponge-launch" style={{ display: 'none' }}></button>
                </CloudSpongeWidget>
                {(isWalkThrough && window.innerWidth > 600) && (
                  <IntroCreator
                    steps={[
                      {
                        id: 'business-review',
                        title: 'Business Reviews',
                        description: "This is your business's unique QR code, share it with your customers to let them create reviews for you.",
                        action: {
                          title: 'Download QR',
                          onPress: () => {
                            downloadQrCode(qrCodeRef)
                          }
                        }
                      },
                      {
                        id: 'page-insights',
                        title: 'Page Insights',
                        description: 'Aggregated page insights about your business page.'
                      },
                      {
                        id: 'analytics',
                        title: 'Analytics',
                        description: "Analytics provides information about your page's performance, which offer worked best, and how many people liked your business page.",
                        action: {
                          title: 'Done'
                        }
                      }
                    ]}
                  />
                )}
              </Container>
              )
          }
        </>
          )}
    </>
  )
}

const styles = {
  boxContainer: {
    backgroundColor: '#F4F7FD',
    borderRadius: 10,
    padding: 16
  },
  cardContainer: {
    borderRadius: 10
  },
  pageInsightValueContainer: {
    width: '100%',
    paddingTop: 18,
    paddingBottom: 18,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  settingsSectionTitle: {
    padding: 16,
    paddingBottom: 8,
    backgroundColor: '#F4EFE7'
  },
  settingsIconButtons: {
    backgroundColor: 'rgba(138, 166, 222, 0.08)',
    borderRadius: 10,
    width: 50,
    height: 50
  },
  settingsTextButton: {
    cursor: 'pointer'
  }
}

export default Dashboard
