import axios from 'axios'

import { alsoPublicRouteHeadersFormat } from '../helpers'

/**
 * @function feedData Used to feed data to Nearcast Analytics
 * @param {string} analyticType - the type of analytic being reported
 * @param {object} data - the data required by the analytic
 * @param {string} jwt - JWT token of the user
 * @param {string} nrctUID - ID of the user
 * @returns {Promise} Promise object
 */
export const feedData = (analyticType, data, jwt = null, nrctUID = null) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BUSINESS_URL}/business-analytics/feed`,
        {
          analytic_type: analyticType,
          data
        },
        {
          headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          resolve(res.data)
        } else {
          throw new Error('Unable to feed data to analytics')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminFeedPersonalProfile = (
  personalId,
  hasUserDetails = false,
  userId = null,
  neighborhoodId = null
) => {
  return new Promise((resolve, reject) => {
    const body = {
      personal_id: personalId,
      user_details: hasUserDetails
    }
    if (hasUserDetails && userId !== null && neighborhoodId !== null) {
      body.user_id = userId
      body.neighbourhood_id = neighborhoodId
    }
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/feed/personal/profile`,
      body
    )
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}

export const adminFeedActiveUser = (
  abstractId,
  neighborhoodId,
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/feed/active-user`,
      {
        abstract_account_id: abstractId,
        neighbourhood_id: neighborhoodId
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
