import React, { useEffect, useState, useContext } from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import QRCode from 'qrcode.react'
import Nearcast from 'ui/Icons/Nearcast'

// importing components
import { UserContext } from '../../../../Context/UserContext'
import { formatDate } from '../../../../Utils/format'

// importing static assets
import building from '../../../assets/building.svg'
import bggg from '../../../assets/printDefaultOfferCover.jpg'
import contact from '../../../assets/ic_contact.svg'
import location from '../../../assets/ic_location.svg'

// importing api
import { businessAvatarView } from 'api/business/avatar/view'
import { businessDetailsGet } from 'api/business/details'

const YellowTypography = styled(Typography)(({ theme }) => ({
  color: '#b28530'
}))

const Flyer1 = ({ forwardedRef, offerData, printData }) => {
  const { business, jwtToken, activeBusiness } = useContext(UserContext)
  const [businessData, setBusinessData] = useState({})

  const getFlyerQrUrl = () => {
    const origin = window.location.origin
    if (origin.includes('staging') || origin.includes('localhost')) {
      return `https://staging.nearcast.com/offer/${offerData.in_store_redemption}`
    } else {
      return `https://nearcast.com/offer/${offerData.in_store_redemption}`
    }
  }

  useEffect(() => {
    const getBusinessData = async () => {
      businessDetailsGet(
        business[activeBusiness].business_id,
        business[activeBusiness].ID,
        jwtToken
      )
        .then((resData) => {
          setBusinessData(resData.body)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    if (jwtToken && business?.length > 0) {
      getBusinessData()
    }
    // eslint-disable-next-line
  }, [jwtToken, business])

  return (
    <Box id='offer-preview' ref={forwardedRef} style={styles.mainFlyer1Container}>
      <Box style={styles.flyer1OfferCoverContainer}>
        <img alt='Fallback Offer Cover' src={bggg} style={styles.flyer1OfferCoverImage} />
        <img
          alt=''
          src={offerData.cover}
          style={styles.flyer1OfferCoverImage}
        />
      </Box>
      <Box style={styles.flyer1OfferContentContainer}>
        <Box style={styles.flyer1OfferContentContainerInner}>
          <Box style={styles.flyer1OfferAvatarContainer}>
            <img alt='Fallback offer Avatar' src={building} style={styles.flyer1OfferAvatar} />
            <img
              alt=''
              src={businessAvatarView(business[activeBusiness].business_id)}
              style={styles.flyer1OfferAvatar}
            />
          </Box>
          <Stack direction='column' spacing={0}>
            <YellowTypography variant='h6' style={{ marginTop: 6 }}>
              {printData.title}
            </YellowTypography>
            <Typography style={{ fontSize: 10 }}>{printData.description}</Typography>
          </Stack>
          <Stack style={styles.bottomStack} direction='column' spacing={0}>
            {Boolean(businessData.address_line) && (
              <Stack
                direction='row'
                alignItems='flex-start'
                spacing={0}
                style={{
                  marginBottom: 7
                }}
              >
                <img
                  alt='Location Icon'
                  src={location}
                  style={{
                    width: 10
                  }}
                />
                <YellowTypography style={{ fontSize: 8, marginLeft: 4 }}>
                  {businessData.address_line}
                </YellowTypography>
              </Stack>
            )}
            {Boolean(businessData.phone) && (
              <Stack
                direction='row'
                alignItems='center'
                spacing={0}
                style={{
                  marginBottom: 7
                }}
              >
                <img
                  alt='Contact Icon'
                  src={contact}
                  style={{
                    width: 10
                  }}
                />
                <YellowTypography style={{ fontSize: 8, marginLeft: 4 }}>
                  {businessData.phone}
                </YellowTypography>
              </Stack>
            )}
            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
              <div>
                <Typography style={{ fontSize: 8 }}>
                  Offer expires on {formatDate(offerData.end_date)}
                </Typography>
                <Typography style={{ fontSize: 8, marginTop: 4 }}>
                  Coupon Code:{' '}
                  <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>
                    {offerData.in_store_redemption}
                  </span>
                </Typography>
                <QRCode
                  style={{ marginTop: 7, marginBottom: 0, width: 60, height: 60 }}
                  size={200}
                  value={getFlyerQrUrl()}
                />
                <Typography style={{ fontSize: 8 }}>
                  Scan this QR Code to Redeem this Offer
                </Typography>
              </div>
              <div>
                <Typography style={{ fontSize: 8 }}>Powered By</Typography>
                <Nearcast style={{ width: 60 }} />
              </div>
            </Stack>
            <Box style={styles.flyer1PageEndBar}>
              <Typography style={{ fontSize: 5, color: 'white', padding: 2 }}>
                Please show this coupon at {businessData.address_line}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  mainFlyer1Container: {
    width: 300,
    height: 425,
    backgroundColor: 'white',
    border: '1px solid black',
    overflow: 'hidden'
  },
  flyer1OfferCoverContainer: {
    width: '100%',
    height: 120,
    position: 'relative',
    backgroundColor: 'pink',
    zIndex: 3,
    overflow: 'hidden'
  },
  flyer1OfferCoverImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    zIndex: 2
  },
  flyer1OfferContentContainer: {
    position: 'relative',
    marginTop: -40,
    zIndex: 9,
    height: 425 - 120 + 20,
    backgroundColor: 'white',
    transform: 'skewY(6deg)'
  },
  flyer1OfferContentContainerInner: {
    position: 'relative',
    padding: 10,
    height: 425 - 120 + 20,
    transform: 'skewY(-6deg)'
  },
  flyer1OfferAvatarContainer: {
    width: 50,
    height: 50,
    backgroundColor: 'grey',
    position: 'relative',
    borderRadius: 6
  },
  flyer1OfferAvatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    borderRadius: 6
  },
  bottomStack: {
    position: 'absolute',
    width: 280,
    backgroundColor: 'white',
    zIndex: 11,
    bottom: 0,
    right: 0,
    left: 0,
    padding: 10,
    paddingBottom: 14
  },
  flyer1PageEndBar: {
    position: 'absolute',
    width: 300,
    backgroundColor: '#3a8f78',
    zIndex: 12,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default Flyer1
