import MaterialTable from '@material-table/core'
import React from 'react'
import tableIcons from '../../../Utils/tableIcons'
import StandardModal from 'ui/Modals/StandardModal'

function ContactSelectModal ({
  contacts,
  open,
  onClose,
  updateSelected,
  observer,
  isLoad,
  hasMore
}) {
  const columns = [
    {
      title: 'E-Mail',
      field: 'email'
    }
  ]

  return (
    <StandardModal
      title="Select Contacts"
      isOpen={open}
      onClose={onClose}
      buttons={[
        {
          title: 'Done',
          color: 'primary',
          onPress: () => onClose()
        }
      ]}
    >
      <div
        style={{
          width: '100%'
        }}
      >
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={contacts}
          options={{
            selection: true,
            paging: false,
            search: false
          }}
          onSelectionChange={(rows) => {
            updateSelected(rows)
          }}
          title="Your Uploaded Contacts"
        />
        <div style={{ padding: 4, textAlign: 'center' }} ref={observer}>
          {isLoad ? 'Loading...' : !hasMore ? 'No more contacts' : ''}
        </div>
      </div>
    </StandardModal>
  )
}

export default ContactSelectModal
