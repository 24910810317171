import { Public } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useSnackbar } from 'notistack'

// importing helpers
import { copyToClipboard } from 'helpers/browser'

export interface DashboardTopProps {
  avatar: string,
  cover: string,
  name: string,
  category: string,
  website: string,
  dashboardActionContent: JSX.Element;
}

const DashboardTop: React.FC<DashboardTopProps> = ({
  cover,
  avatar,
  name,
  category = 'Restaurant',
  website,
  dashboardActionContent
}) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Box
      sx={{
        backgroundColor: '#F4F7FD',
        borderRadius: '10px',
        overflow: 'hidden'
      }}
    >
      <Box
        style={{
          backgroundImage: `url(${cover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: 200,
          objectFit: 'cover',
          marginBottom: '-50px'
        }}
      />
      <Box
        sx={{
          padding: '10px 15px',
          display: 'flex',
          flexDirection: {
            lg: 'row',
            md: 'row',
            sm: 'row',
            xs: 'column'
          },
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          src={avatar}
          style={{
            width: 150,
            height: 150,
            backgroundColor: 'white',
            borderRadius: '15px',
            borderWidth: 4,
            borderColor: 'white',
            borderStyle: 'solid'
          }}
          alt="User"
        />
        <Box
          style={{
            position: 'relative',
            alignSelf: 'flex-end'
          }}
          sx={{
            mt: { xs: 2, sm: 5 },
            px: '15px',
            width: { xs: '100%', sm: 'calc(100% - 180px)' }
          }}
        >
          <Typography variant="h3" sx={{ width: '70%' }}>
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: '10px', color: '#007AFF', fontWeight: 600 }}
          >
            {category}
          </Typography>
          {/* <Tooltip title="Click to copy the business's Nearcast custom URL!">
            <Typography
              variant="body2"
              sx={{
                mt: '10px',
                fontSize: '0.75rem',
                width: 'fit-content',
                maxWidth: {
                  xs: '40%',
                  md: '60%'
                },
                cursor: 'pointer'
              }}
              onClick={async () => {
                try {
                  await copyToClipboard(website)
                  enqueueSnackbar('Business custom URL copied successfully!', { variant: 'success' })
                } catch (err) {
                  enqueueSnackbar(err.message, { variant: 'error' })
                }
              }}
            >
              <Public
                sx={{
                  fontSize: 14,
                  verticalAlign: 'sub',
                  color: '#4F4F4F',
                  mr: '4px',
                  ml: '-4px'
                }}
              />
              {website}
            </Typography>
          </Tooltip> */}
          <Box
            sx={{
              position: 'absolute',
              bottom: {
                md: 0,
                xs: 0
              },
              top: {
                md: 'auto',
                xs: 0
              },
              right: 10
            }}
          >
            {dashboardActionContent}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardTop
