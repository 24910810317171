import { useSnackbar } from 'notistack'
import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UserContext } from '../../../Context/UserContext'
import LoginModal from '../LoginModal/LoginModal'
import OtpModal from '../OtpModal/OtpModal'

// importing ui
import CreateBusinessModal from 'ui/Custom/Modals/CreateBusinessModal/CreateBusinessModal'

// importing api
import { login as loginAPICore } from 'api/login'
import { signUp as signupAPICore } from 'api/signup'
import { signupInit } from 'api/signup/init'
import { userPersonalAccountAdd as addAccAPICore } from 'api/user/personalAccount'

// importing helpers
import { recordGaEvent } from 'helpers/ga'

function AuthModal ({ isOpen, onClose, isLogin, setIsLogin }) {
  const [step, setStep] = useState(0)
  const [business, setBusiness] = useState({})
  const [phone, setPhone] = useState('')
  const [otp, setOTP] = useState('')
  const [otpCountry, setOtpCountry] = useState('us')
  const [userToken, setUserToken] = useState('')
  // state for auto signup email campaign
  const [isAutoSignUp, setIsAutoSignUp] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const { login } = useContext(UserContext)

  const _onClose = () => {
    setStep(0)
    setBusiness({})
    setPhone('')
    setOTP('')
    setOtpCountry('us')
    setUserToken('')
    setIsAutoSignUp(false)
    onClose()
  }

  // analytics for business campaign success - after new business signed up
  const recordBusinessEmailCampaignSuccess = () => {
    if (location.pathname === '/businesslogin') {
      const autoSignUpData = {}
      for (const [key, value] of new URLSearchParams(location.search).entries()) {
        autoSignUpData[key] = value
      }
      return recordGaEvent('businessLandingSignUpCampaignSuccess', autoSignUpData)
    }
  }

  const checkExist = async (token) => {
    signupInit('', token)
      .then((res) => {
        if (isLogin) {
          enqueueSnackbar('Claim your Business Page', { variant: 'info' })
          setUserToken(token)
          setIsLogin(false)
          // onClose()
        } else {
          if (isAutoSignUp) {
            return signupApi(token)
          }
          setUserToken(token)
          setStep(2)
        }
      })
      .catch((err) => {
        if (err.response.data.msg === 'userexists') loginAPI(token)
      })
  }

  const loginAPI = async (token) => {
    loginAPICore(token)
      .then((resData) => {
        if (resData) {
          if (business && Object.keys(business).length > 0) {
            addAccount(resData)
          } else if (
            resData.personal_accounts.filter(
              (item) => item.is_business === true
            ).length > 0
          ) {
            login({
              jwt_token: resData.jwtToken,
              ref_token: resData.refreshToken,
              accounts: resData.personal_accounts,
              user: resData.user
            })
            _onClose()
            history.push('/dashboard')
          } else {
            enqueueSnackbar('Claim your business page!', { variant: 'info' })
            onClose()
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar('User Not Found! Please Signup', { variant: 'error' })
      })
  }

  const addAccount = async (data) => {
    addAccAPICore(
      business?.address,
      data.personal_accounts[0].ID,
      data.jwtToken,
      true,
      business?.business
    )
      .then((resData) => {
        enqueueSnackbar('Business Account Created', { variant: 'success' })
        login({
          jwt_token: resData.body.jwtToken,
          ref_token: resData.body.refreshToken,
          accounts: [...data.personal_accounts, resData.body.user],
          user: resData.body.user
        })
        recordBusinessEmailCampaignSuccess()
        _onClose()
        history.push('/dashboard', {
          isNewBusiness: true,
          newBusinessId: resData.body.user.business_id
        })
      })
      .catch((err) => {
        let error
        if (err.response && err.response.data && err.response.data.msg) {
          error = err.response.data.msg
        } else {
          error = 'Please Try Again'
        }
        enqueueSnackbar(error, { variant: 'error' })
      })
  }

  const signupApi = async (token) => {
    business.business.address = {
      ...business.business.address,
      ...business.address
    }
    signupAPICore(
      token,
      business?.address,
      business?.lat,
      business?.lon,
      phone,
      otp,
      true,
      business?.business
    )
      .then((resData) => {
        login({
          jwt_token: resData.jwtToken,
          ref_token: resData.refreshToken,
          accounts: resData.personal_accounts,
          user: resData.user
        })
        _onClose()
        recordBusinessEmailCampaignSuccess()
        history.push('/dashboard', {
          isNewBusiness: true,
          newBusinessId: resData.personal_accounts[0].business_id
        })
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar('Could not sign up. Please try again!', {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    if (isOpen) {
      if (location.pathname === '/businesslogin') {
        const autoSignUpDetails = {
          name: null,
          email: null,
          lat: null,
          lon: null,
          slug: null,
          address: null,
          city: 'city',
          state: 'state',
          country: 'usa',
          phone: ''
        }
        const autoSignUpDetailsRequiredKeys = ['name', 'email', 'lat', 'lon', 'slug', 'address']
        for (const [key, value] of new URLSearchParams(location.search).entries()) {
          autoSignUpDetails[key] = value
        }
        console.log(autoSignUpDetails)
        if (autoSignUpDetailsRequiredKeys.find((key) => autoSignUpDetails[key] === null)) {
          console.log('Auto Login/SignUp not possible')
          return
        }
        const autoBusinessData = {
          is_business: true,
          business: {
            business_name: autoSignUpDetails.name,
            address: {
              address_line: autoSignUpDetails.address,
              city: autoSignUpDetails.city,
              state: autoSignUpDetails.state,
              country: autoSignUpDetails.country,
              zip: 11111
            },
            custom_url: `https://nearcast.biz/${autoSignUpDetails.slug}-${Math.floor(Math.random() * 100)}`
          },
          address: {
            address: autoSignUpDetails.address,
            city: autoSignUpDetails.city,
            state: autoSignUpDetails.state,
            country: autoSignUpDetails.country,
            zip: 11111,
            lat: Number(autoSignUpDetails.lat),
            lon: Number(autoSignUpDetails.lon)
          },
          lat: Number(autoSignUpDetails.lat),
          lon: Number(autoSignUpDetails.lon)
        }
        console.log(autoBusinessData)
        setBusiness(autoBusinessData)
        setPhone(autoSignUpDetails.phone)
        setIsAutoSignUp(true)
        setStep(1)
      }
    }
  }, [isOpen])

  if (isLogin) {
    return (
      <LoginModal
        isOpen={isOpen}
        onClose={_onClose}
        onSuccess={checkExist}
        isLogin={true}
        showBack={false}
      >
      </LoginModal>
    )
  }

  if (step === 0) {
    return (
      <CreateBusinessModal
        isOpen={isOpen}
        onClose={_onClose}
        data={business}
        setOtpCountry={setOtpCountry}
        next={(data) => {
          setBusiness(data)
          if (userToken.length === 0) {
            setStep(1)
          } else {
            setStep(2)
          }
        }}
      />
    )
  } else if (step === 1) {
    return (
      <LoginModal
        isOpen={isOpen}
        onClose={_onClose}
        isLogin={false}
        onSuccess={checkExist}
        onBack={() => setStep((prev) => prev - 1)}
      >
      </LoginModal>
    )
  } else if (step === 2) {
    return (
      <OtpModal
        isOpen={isOpen}
        onClose={_onClose}
        {...{ otp, setOTP, phone, setPhone }}
        onVerify={() => {
          signupApi(userToken)
        }}
        onBack={() => {
          setStep((prev) => prev - 1)
        }}
        selectedCountry={otpCountry}
      >
      </OtpModal>
    )
  }
}

export default AuthModal
