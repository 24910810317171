import React from 'react'
import {
  Dialog as DefaultDialog,
  Typography,
  Button
} from '@mui/material'

export interface DialogProps {
    isOpen: boolean;
    title: string;
    cancelText?: string;
    onCancel: () => void;
    confirmText?: string;
    onConfirm: () => void;
    icon?: () => JSX.Element;
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  title,
  cancelText = 'Cancel',
  onCancel,
  confirmText = 'Confirm',
  onConfirm,
  icon
}) => {
  return (
        <DefaultDialog
            open={isOpen}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            PaperProps={{
              style: {
                width: 280,
                padding: 40,
                textAlign: 'center',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }
            }}
        >
            <div
                style={{
                  marginTop: 10
                }}
            >
                {icon && icon()}
            </div>
            <Typography variant="h5" style={{ marginTop: 10 }}>
                {title}
            </Typography>
            <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}
            >
                <Button
                    variant="contained"
                    color="ghost"
                    onClick={onCancel}
                    disableElevation
                >
                    {cancelText}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    disableElevation
                >
                    {confirmText}
                </Button>
            </div>
        </DefaultDialog>
  )
}

export default Dialog
