import axios from 'axios'

/**
 * @function commentCreate Used to delete a comment on a post
 * @param {string} postId - comment's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful group cover upload
 */
export const commentCreate = (postId, comment, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/comment/create`, {
      post_id: postId,
      comment
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
