import { MenuRounded, Add, ChatBubbleOutline } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
  Typography,
  ListItemAvatar,
  ListItemText,
  Divider,
  Badge,
  Button
} from '@mui/material'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'
import { UserContext } from '../../../Context/UserContext'
import { SidebarDrawer } from '../Sidebar'
import styles from './HeaderStyles'
import { useSnackbar } from 'notistack'
import { useChatContext } from 'stream-chat-react'
import { formatDistance } from 'date-fns'
import { GlobalFuncContext } from '../../../Context/GlobalFuncHOC'

// importing ui
import CreateBusinessModal from 'ui/Custom/Modals/CreateBusinessModal/CreateBusinessModal'

// importing apis
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { userPersonalAccountAdd } from 'api/user/personalAccount'

// importing redux store
import { useAppSelector } from '../../../Utils/redux/store'
import dayjs from 'dayjs'

function Header (props) {
  const { client } = useChatContext()
  const classes = styles()
  const history = useHistory()
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('md'))
  const { openUpgradePayment } = useContext(GlobalFuncContext)

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const RenderRenewSubscriptionButton = useMemo(() => {
    /**
     * Component Job
     * Should render to users with free trial version and should intimate them
     * to buy one of the paid plans. This button should not render if users already
     * bought a paid plain, but if users current plan subscription was cancelled and the
     * endDate is in past display no current plan active
     *
     */
    const endDate = subscriptionState.subscription.subscriptionEndDate
    const planName = subscriptionState.subscription.planName
    const isPlanCancelled = subscriptionState.subscription.isPlanCancelled

    if (isPlanCancelled && dayjs(endDate).isBefore(dayjs())) {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => openUpgradePayment('Choose a new plan')}
          sx={{
            marginRight: 2,
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
          disableElevation
        >
          No Current Plan Active
        </Button>
      )
    }

    if (planName && planName.toLowerCase() !== 'free') {
      return null
    }

    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => openUpgradePayment('Choose a new plan')}
        sx={{
          marginRight: 2,
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
        disableElevation
      >
        Trial ends {formatDistance(new Date(endDate), new Date(), { addSuffix: true })}
      </Button>
    )
  }, [
    subscriptionState.subscription.subscriptionEndDate,
    subscriptionState.subscription.planName,
    subscriptionState.subscription.isPlanCancelled
  ])

  // component states
  const [open, setOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const {
    logout,
    business,
    activeBusiness,
    switchNeighbourHood,
    personal,
    jwtToken,
    login
  } = useContext(UserContext)
  const { palette } = useTheme()
  const [newAccountOpen, setNewAccountOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // message unread count
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  // useEffect(() => {
  //   const clientEventListener = client.on((event) => {
  //     if (event.total_unread_count !== null && event.total_unread_count !== undefined) {
  //       setUnreadMessageCount(Number(event.total_unread_count))
  //     }
  //     if (event.type === 'health.check' && event.me !== undefined) {
  //       if (event.me.total_unread_count !== undefined) {
  //         setUnreadMessageCount(Number(event.me.total_unread_count))
  //       }
  //     }
  //   })
  //   return () => {
  //     clientEventListener.unsubscribe()
  //   }
  // }, [business, activeBusiness])

  return (
    <>
      <AppBar className={classes.root} elevation={1}>
        <Toolbar className={classes.toolbar}>
          {business && business.length > 0 && small && (
            <div style={{ width: business === null ? '75px' : 'auto' }}>
              <IconButton
                edge="start"
                onClick={() => {
                  setDrawerOpen(true)
                }}
                style={{ color: 'black', borderColor: 'black' }}
                size="large"
              >
                <MenuRounded />
              </IconButton>
            </div>
          )}
          <Link
            to={'/'}
            style={
              business && business.length > 0 && small
                ? {
                    display: 'contents',
                    justifySelf: 'center'
                  }
                : {}
            }
          >
            <Nearcast
              height={27}
              style={{
                display: 'block',
                alignSelf: 'center',
                marginInline: small ? 'auto' : ''
              }}
              htmlColor={palette.primary.main}
            />
          </Link>
          <div
            className={classes.cta}
            style={
              (!business || business.length < 0) && small
                ? {
                    marginLeft: 'auto'
                  }
                : {}
            }
          >
            {RenderRenewSubscriptionButton}
            {/* <IconButton
              style={{ marginRight: '18px' }}
              onClick={() => history.push('/chat')}
              size="large">
              <Badge badgeContent={unreadMessageCount} color="primary">
                <ChatBubbleOutline />
              </Badge>
            </IconButton> */}
            <Avatar
              onClick={(e) => {
                setOpen(e.currentTarget)
              }}
              aria-controls="user-controls"
              alt={business[activeBusiness]?.username}
              style={{ width: 35, height: 35, cursor: 'pointer' }}
              src={personalAccountAvatar(business[activeBusiness]?.ID)}
            />
            <Menu
              id="user-controls"
              anchorEl={open}
              open={Boolean(open)}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              onClose={() => {
                setOpen(null)
              }}
              className="dropdown-menu"
            >
              <div
                style={{
                  width: 377,
                  maxWidth: '100%',
                  margin: 0,
                  padding: 0,
                  borderRadius: 20,
                  maxHeight: '70vh',
                  overflowY: 'scroll'
                }}
              >
                {business.length > 0 && business.map((profile, i) => {
                  return (
                    <MenuItem
                      key={`business-${profile.ID}`}
                      onClick={() => {
                        switchNeighbourHood(business.findIndex((p) => p.ID === profile.ID))
                        setOpen(null)
                        history.push('/dashboard')
                      }}
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderTopLeftRadius: i === 0 ? 10 : 0,
                        borderTopRightRadius: i === 0 ? 10 : 0,
                        backgroundColor: business[activeBusiness]?.ID === profile.ID ? '#ededed' : 'transparent'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          key={`business-${profile.ID}`}
                          src={personalAccountAvatar(profile.ID)}
                          style={{
                            width: 57,
                            height: 57,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#1A6751',
                            marginRight: 16
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="h5"
                            style={{
                              color: business[activeBusiness]?.ID === profile.ID ? '#178747' : 'black'
                            }}
                          >
                            {profile.username}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  )
                })}
                <Divider style={{ margin: 0 }} />
                <MenuItem
                  onClick={() => {
                    setNewAccountOpen(true)
                    setOpen(null)
                  }}
                  style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 28
                  }}
                >
                  <IconButton>
                    <Add />
                  </IconButton>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        style={{ color: 'black' }}
                      >
                        Add Business Account
                      </Typography>
                    }
                  />
                </MenuItem>
                <Divider style={{ margin: 0 }} />
                <MenuItem
                  onClick={() => {
                    logout()
                    history.push('/')
                    setOpen(false)
                  }}
                  style={{
                    color: '#CA4A4A',
                    textAlign: 'center',
                    padding: 16
                  }}
                >
                  <p
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      margin: 0
                    }}
                  >
                    Sign Out
                  </p>
                </MenuItem>
                </div>
              </Menu>
          </div>
        </Toolbar>
        <SwipeableDrawer
          anchor="left"
          variant="temporary"
          open={drawerOpen}
          onOpen={() => {
            setDrawerOpen(true)
          }}
          onClose={() => {
            setDrawerOpen(false)
          }}
        >
          <SidebarDrawer />
        </SwipeableDrawer>
        <CreateBusinessModal
          isOpen={newAccountOpen}
          onClose={() => {
            setNewAccountOpen(false)
          }}
          data={null}
          next={(data) => {
            userPersonalAccountAdd(
              data.address,
              personal[0].ID,
              jwtToken,
              true,
              data.business
            )
              .then((resData) => {
                enqueueSnackbar('Business Account Created', { variant: 'success' })
                login({
                  accounts: [...business, resData.body.user],
                  isNotFresh: true
                })
                history.push('/dashboard', {
                  isNewBusiness: true,
                  newBusinessId: resData.body.user.business_id
                })
                setNewAccountOpen(false)
              })
              .catch((err) => {
                let error
                if (err.response && err.response.data && err.response.data.msg) {
                  error = err.response.data.msg
                } else {
                  error = 'Please Try Again'
                }
                enqueueSnackbar(error, { variant: 'error' })
              })
          }}
          setOtpCountry={(e) => {}}
        />
      </AppBar>
    </>
  )
}

export default Header
