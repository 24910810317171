import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Stack,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import validator from 'validator'

import StandardModal from 'ui/Modals/StandardModal'
import WideModal from 'ui/Modals/WideModal'
import { UserContext } from '../../Context/UserContext'
import MailSelectModal from './ContactModal/MailSelectModal'
import PhoneSelectModal from './ContactModal/PhoneSelectModal'
import EmailPreview from './SendPreviews/EmailPreview'
import TextPreview from './SendPreviews/TextPreview'
import Flyer1 from './SendPreviews/Flyers/Flyer1'
import Flyer2 from './SendPreviews/Flyers/Flyer2'
import Flyer3 from './SendPreviews/Flyers/Flyer3'

// importing apis
import { businessContactsUpload } from 'api/business/contacts/upload'
import { businessDetailsGet } from 'api/business/details'
import { businessContactsFetch } from 'api/business/contacts/fetch'
import { TextLabel } from 'ui/TextInputs/TextInput'

// import helpers
import { getUserDomainURL } from 'helpers/url'

const canvasToPdf = (canvas, ref) => {
  // Page Size in px.. considering basic padding
  const width = ref.current.offsetWidth
  const height = ref.current.offsetHeight
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('p', 'px', [width, height], true)
  pdf.addImage(canvas, 'JPEG', 0, 0, width, height)
  pdf.save('flyer.pdf')
}

const _exportPdf = async (ref, onErr) => {
  if (window.navigator.brave && (await window.navigator.brave.isBrave())) {
    onErr()
  }
  html2canvas(ref.current, {
    useCORS: true,
    allowTaint: true,
    windowWidth: ref.current.offsetWidth,
    windowHeight: ref.current.offsetHeight,
    scale: 3
  }).then((c) => canvasToPdf(c, ref))
}

const styles = makeStyles(({ palette }) => ({
  sidebar: {
    marginRight: 40,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 960px)']: {
      marginRight: '0'
    }
  },
  contentContainer: {
    padding: 0,
    marginTop: '0',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 960px)']: {
      marginTop: '16px',
      padding: 16
    }
  },
  heading: {
    fontSize: '1.3rem',
    fontWeight: '500',
    color: '#104F3D'
  },
  sendOption: {
    padding: '5px 15px 0 0',
    color: 'black',
    cursor: 'pointer'
  },
  optionSelected: {
    color: palette.primary.light,
    textDecoration: 'underline'
  },
  contactSelect: {
    '&:disabled': {
      cursor: 'pointer'
    }
  },
  actionBtn: {
    height: 55,
    width: 160
  }
}))

function OfferSend ({ open, onClose, offerId }) {
  const classes = styles()

  const { enqueueSnackbar } = useSnackbar()

  const { jwtToken, business, activeBusiness } = useContext(UserContext)

  const [preview, setPreview] = useState({})
  const [mailOrText, setMailOrText] = useState('mail')
  const [mailContactSelected, setMailSelected] = useState([])
  const [mailCustomContacts, setMailCustomContacts] = useState([])
  const [textContactSelected, setTextSelected] = useState([])
  const [textCustomContacts, setTextCustomContacts] = useState([])

  const [isLoad, setIsLoad] = useState(true)
  const [pageNumber, setPageNumber] = useState(0)

  const mainContactsInit = {
    hasMore: true,
    mails: [],
    loading: true
  }
  const [mailContacts, setMailContacts] = useState(mainContactsInit)
  const [mailsModal, setMailsModal] = useState(false)

  const phoneContactsInit = {
    hasMore: true,
    phones: [],
    loading: true
  }
  const [phoneContacts, setPhoneContacts] = useState(phoneContactsInit)
  const [phoneModal, setPhoneModal] = useState(false)

  const [emailData, setEmailData] = useState({ subject: '', body: '' })
  const [textData, setTextData] = useState({ message: '' })
  const [printData, setPrintData] = useState({ title: '', description: '' })
  const [printErrMes, setPrintErrMes] = useState(false)

  const [sendBtnLoading, setBtnLoading] = useState(false)
  const printRef = useRef()
  const [printPreviewLayout, setPrintPreviewLayout] = useState(2) // 1, 2, 3

  const observerMail = useRef()
  const tableEndRefMail = useCallback(
    (node) => {
      if (mailContacts.loading) {
        return
      }
      if (observerMail.current) {
        observerMail.current.disconnect()
      }
      observerMail.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && mailContacts.hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) {
        observerMail.current.observe(node)
      }
    },
    [mailContacts]
  )
  const observerPhone = useRef()
  const tableEndRefPhone = useCallback(
    (node) => {
      if (phoneContacts.loading) {
        return
      }
      if (observerPhone.current) {
        observerPhone.current.disconnect()
      }
      observerPhone.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && phoneContacts.hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) {
        observerPhone.current.observe(node)
      }
    },
    [phoneContacts]
  )

  const _onClose = () => {
    setMailSelected([])
    setMailCustomContacts([])
    setTextSelected([])
    setTextCustomContacts([])
    setBtnLoading(false)
    onClose()
  }

  const getContacts = (filter = 'email') => {
    setIsLoad(true)
    if (filter === 'email') {
      if (mailContacts.hasMore === false) {
        return
      }
    } else {
      if (phoneContacts.hasMore === false) {
        return
      }
    }
    businessContactsFetch(
      business[activeBusiness].business_id,
      50,
      pageNumber * 50,
      filter,
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resBody) => {
        if (filter === 'email') {
          setMailContacts((prev) => ({
            hasMore: !(resBody.length < 10),
            mails: [...prev.mails, ...resBody],
            loading: false
          }))
        } else {
          setPhoneContacts((prev) => ({
            hasMore: !(resBody.length < 10),
            phones: [...prev.phones, ...resBody],
            loading: false
          }))
        }
        setIsLoad(false)
      })
      .catch((err) => {
        console.log(err.message)
        setIsLoad(false)
      })
  }

  const validate = () => {
    if (mailOrText === 'mail') {
      // IF NO CONTACTS SELECTED
      // if (mailContactSelected.length === 0 && mailCustomContacts.length === 0) {
      //   enqueueSnackbar('Select atleast one contact', {
      //     variant: 'error',
      //     autoHideDuration: 3000
      //   })
      //   return false
      // }

      // IF MAIL CONTACTS SELECTED BUT NO SUBJECT OR BODY
      if (mailContactSelected.length > 0) {
        if (emailData.subject.trim().length === 0 || emailData.body.trim().length === 0) {
          enqueueSnackbar('All fields are mandatory 🛑', {
            variant: 'error',
            autoHideDuration: 3000
          })
          setMailOrText('mail')
          return false
        }
      }

      // IF NO EMAIL CONTACTS SELECTED BUT SUBJECT OR BODY GIVEN
      if (
        mailContactSelected.length === 0 && mailCustomContacts.length === 0 &&
        (emailData.subject.trim().length > 0 || emailData.body.trim().length > 0)
      ) {
        enqueueSnackbar('Please select email contacts.', {
          variant: 'error',
          autoHideDuration: 3000
        })
        setMailOrText('mail')
        return false
      }
    } else if (mailOrText === 'text') {
      // IF NO CONTACTS SELECTED
      // if (textContactSelected.length === 0 && textCustomContacts.length === 0) {
      //   enqueueSnackbar('Select atleast one contact', {
      //     variant: 'error',
      //     autoHideDuration: 3000
      //   })
      //   return false
      // }

      // IF TEXT CONTACTS SELECTED BUT NO MESSAGE
      if (textContactSelected.length > 0) {
        if (textData.message.trim().length === 0) {
          enqueueSnackbar('All fields are mandatory 🛑', {
            variant: 'error',
            autoHideDuration: 3000
          })
          setMailOrText('text')
          return false
        }
      }

      // IF NO SMS CONTACTS SELECTED BUT MESSAGE GIVEN
      if (textContactSelected.length === 0 && textCustomContacts.length === 0 && textData.message.trim().length > 0) {
        enqueueSnackbar('Please select SMS contacts.', {
          variant: 'error',
          autoHideDuration: 3000
        })
        setMailOrText('text')
        return false
      }
    }
    return true
  }

  const sendOffer = async () => {
    if (!validate()) {
      return
    }
    if (sendBtnLoading) {
      return
    }
    setBtnLoading(true)

    let customIds = []
    if (mailCustomContacts.length !== 0 && mailOrText === 'mail') {
      const mappedMails = []
      mailCustomContacts.forEach((email) => {
        if (validator.isEmail(email)) {
          mappedMails.push({
            name: 'Anonymous',
            phone: '',
            email,
            city: '',
            state: ''
          })
        }
      })
      try {
        const resData = await businessContactsUpload(
          mappedMails,
          business[activeBusiness].ID,
          jwtToken
        )
        customIds = resData.body.map((newContact) => newContact.ID)
      } catch (err) {
        console.log(err)
      }
    } else if (textCustomContacts.length !== 0 && mailOrText === 'text') {
      const contactsMapped = []
      textCustomContacts.forEach((phone) => {
        if (validator.isMobilePhone(phone)) {
          contactsMapped.push({
            name: 'Anonymous',
            phone,
            email: '',
            city: '',
            state: ''
          })
        }
      })
      try {
        const resData = await businessContactsUpload(
          contactsMapped,
          business[activeBusiness].ID,
          jwtToken
        )
        customIds = resData.body.map((newContact) => newContact.ID)
      } catch (err) {
        console.log(err)
      }
    }

    const url = `${process.env.REACT_APP_BUSINESS_URL}/offer/send?offer_id=${offerId}`
    const data = {
      email: mailOrText === 'mail' ? emailData : undefined,
      sms: mailOrText === 'text' ? textData : undefined,
      email_contacts:
        mailOrText === 'mail'
          ? [...mailContactSelected.map((item) => item.id), ...customIds]
          : undefined,
      sms_contacts:
        mailOrText === 'text'
          ? [...textContactSelected.map((item) => item.id), ...customIds]
          : undefined
    }

    if (mailOrText === 'mail') {
      if (data.email_contacts.length === 0) {
        setBtnLoading(false)
        return enqueueSnackbar('No valid emails to send offer to!', { variant: 'info' })
      }
    } else {
      if (data.sms_contacts.length === 0) {
        setBtnLoading(false)
        return enqueueSnackbar('No valid phone numbers to send offer to!', { variant: 'info' })
      }
    }

    try {
      // move axios call to api package
      const res = await axios.post(url, data, {
        headers: {
          'NRCT-UID': business[activeBusiness].ID,
          Authorization: `Bearer ${jwtToken}`
        }
      })

      // console.log(res.data);
      enqueueSnackbar('Offer notifications sent!', {
        variant: 'success',
        autoHideDuration: 3000
      })
      // Modal closes after sending offer
      _onClose()
    } catch (error) {
      // console.log(error);
      enqueueSnackbar('There was some problem in sending the offer! Please try again.', {
        variant: 'error',
        autoHideDuration: 3000
      })
    } finally {
      setBtnLoading(false)
    }
  }

  const onPrintErr = () => setPrintErrMes(true)

  const renderPrintPreview = () => {
    switch (printPreviewLayout) {
      case 1:
        return <Flyer1 forwardedRef={printRef} offerData={preview} printData={printData} />
      case 2:
        return <Flyer2 forwardedRef={printRef} offerData={preview} printData={printData} />
      case 3:
        return <Flyer3 forwardedRef={printRef} offerData={preview} printData={printData} />
      default:
        return <Flyer2 forwardedRef={printRef} offerData={preview} printData={printData} />
    }
  }

  useEffect(() => {
    // use the axios call in api package
    const getData = async () => {
      let offerRes = {}
      axios
        .get(`${process.env.REACT_APP_BUSINESS_URL}/offer-details?offer_id=${offerId}`)
        .then((res) => {
          offerRes = res
          return businessDetailsGet(
            business[activeBusiness].business_id,
            business[activeBusiness].ID,
            jwtToken
          )
        })
        .then((resData) => {
          setPreview(offerRes.data.body)
          setPrintData({
            title: offerRes.data.body.title,
            description: offerRes.data.body.description
          })
          setEmailData({
            subject: offerRes.data.body.title,
            body: offerRes.data.body.description
          })
          // ${offerRes.data.body.offer_short_link}
          setTextData({
            message: `Title: ${offerRes.data.body.title}\n${offerRes.data.body.description}\n${getUserDomainURL(`/offer/${offerRes.data.body.in_store_redemption}`)}\n${resData.body.name} at ${resData.body.address_line}`
          })
        })
    }
    getData()
  }, [])

  useEffect(() => {
    getContacts('email')
    getContacts('phone')
  }, [pageNumber])

  return (
    <WideModal
      isOpen={open}
      onClose={_onClose}
      title={'Send Offer'}
      buttons={[
        {
          title: 'Cancel',
          onPress: _onClose,
          color: 'ghost'
        },
        {
          title: mailOrText === 'print' ? 'Print' : sendBtnLoading ? 'Sending' : 'Send',
          onPress: mailOrText === 'print' ? () => _exportPdf(printRef, onPrintErr) : sendOffer,
          color: 'primary'
        }
      ]}
    >
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} md={7}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 700
            }}
          >
            <span
              className={`${classes.sendOption} ${mailOrText === 'mail' ? classes.optionSelected : ' '
                }`}
              onClick={() => {
                setMailOrText('mail')
              }}
            >
              Email
            </span>
            {/* <span
              className={`${classes.sendOption} ${mailOrText === 'text' ? classes.optionSelected : ' '
                }`}
              onClick={() => {
                setMailOrText('text')
              }}
            >
              Text
            </span> */}
            <span
              className={`${classes.sendOption} ${mailOrText === 'print' ? classes.optionSelected : ' '
                }`}
              onClick={() => {
                setMailOrText('print')
              }}
            >
              Print
            </span>
          </div>
          {mailOrText !== 'print' && (
            <FormControl fullWidth margin='normal'>
              <Typography variant='body1' style={{ fontWeight: '600', marginBottom: 10 }}>
                To:{' '}
              </Typography>

              <TextField
                variant='outlined'
                label='Select Contacts'
                placeholder={mailOrText === 'mail' ? 'Type Email' : 'Type Number'}
                inputProps={{
                  className: classes.contactSelect
                }}
                InputProps={{
                  className: classes.contactSelect,
                  startAdornment: (
                    <InputAdornment
                      onClick={(e) => {
                        e.preventDefault()
                        if (mailOrText === 'mail') setMailsModal(true)
                        else setPhoneModal(true)
                      }}
                      style={{
                        cursor: 'hover'
                      }}
                      position='start'
                    >
                      {mailOrText === 'mail'
                        ? mailContactSelected.length
                        : textContactSelected.length}{' '}
                      My Contacts,
                    </InputAdornment>
                  )
                }}
                value={`${mailOrText === 'mail'
                  ? mailCustomContacts.map((cus) => cus)
                  : textCustomContacts.map((cus) => cus)
                  }`}
                onChange={(e) => {
                  const cusCons = e.currentTarget.value.replaceAll(' ', '').split(',').slice(0)
                  if (mailOrText === 'mail') {
                    setMailCustomContacts(cusCons)
                  } else if (mailOrText === 'text') {
                    setTextCustomContacts(cusCons)
                  }
                }}
                data-testid="sendTextField"
              />
              {mailOrText === 'text' && textCustomContacts.length > 1 && (
                <Typography variant="caption">
                  Please make sure you enter phone numbers with correct country code
                </Typography>
              )}
            </FormControl>
          )}
          {mailOrText === 'mail' && (
            <FormControl fullWidth margin='normal'>
              <TextField
                variant='outlined'
                label='Subject'
                placeholder='Subject'
                value={emailData.subject}
                onChange={(e) => setEmailData((prev) => ({ ...prev, subject: e.target.value }))}
              />
            </FormControl>
          )}
          {mailOrText === 'print' && (
            <FormControl fullWidth margin='normal'>
              <TextField
                variant='outlined'
                label='Title'
                placeholder='Title'
                value={printData.title}
                onChange={(e) => {
                  if (e.target.value.length > 64) {
                    return
                  }
                  setPrintData((prev) => ({ ...prev, title: e.target.value }))
                }}
              />
            </FormControl>
          )}
          <FormControl fullWidth margin='normal'>
            {mailOrText === 'mail' && (
              <TextField
                variant='outlined'
                multiline
                minRows={3}
                maxRows={6}
                label='Email Body'
                placeholder='Describe your offering in more detail'
                value={emailData.body}
                onChange={(e) => setEmailData((prev) => ({ ...prev, body: e.target.value }))}
              />
            )}
            {mailOrText === 'text' && (
              <TextField
                variant='outlined'
                multiline
                minRows={3}
                maxRows={6}
                label='Message Body'
                placeholder='Describe your offering in more detail'
                value={textData.message}
                onChange={(e) => setTextData((prev) => ({ ...prev, message: e.target.value }))}
              />
            )}
            {mailOrText === 'print' && (
              <TextField
                variant='outlined'
                multiline
                minRows={3}
                maxRows={6}
                label='Description'
                placeholder='Describe your offering in more detail'
                value={printData.description}
                onChange={(e) => {
                  if (e.target.value.length > 258) {
                    return
                  }
                  setPrintData((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }}
              />
            )}
          </FormControl>
          {mailOrText === 'print' && (
            <FormControl fullWidth margin='normal'>
              <TextLabel>Flyer Style</TextLabel>
              <RadioGroup row value={printPreviewLayout} onChange={(event) => setPrintPreviewLayout(Number(event.target.value))}>
                <FormControlLabel value={1} control={<Radio />} label='Style 01' />
                <FormControlLabel value={2} control={<Radio />} label='Style 02' />
                <FormControlLabel value={3} control={<Radio />} label='Style 03' />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={classes.heading}>Preview</div>
          {mailOrText === 'mail'
            ? (
              <EmailPreview offerData={preview} mailData={emailData} />
              )
            : mailOrText === 'text'
              ? (
                <TextPreview offerData={preview} smsData={textData} />
                )
              : mailOrText === 'print'
                ? (
                  <Stack direction='column' spacing={1}>
                    {renderPrintPreview()}
                  </Stack>
                  )
                : null}
        </Grid>
      </Grid>
      <MailSelectModal
        open={mailsModal}
        onClose={() => setMailsModal(false)}
        contacts={mailContacts.mails}
        updateSelected={setMailSelected}
        observer={tableEndRefMail}
        {...{ isLoad, hasMore: mailContacts.hasMore }}
      />
      <PhoneSelectModal
        open={phoneModal}
        onClose={() => setPhoneModal(false)}
        contacts={phoneContacts.phones}
        updateSelected={setTextSelected}
        observer={tableEndRefPhone}
        {...{ isLoad, hasMore: phoneContacts.hasMore }}
      />
      <StandardModal
        isOpen={printErrMes}
        onClose={() => setPrintErrMes(false)}
        title='Alert'
        buttons={[
          {
            title: 'Okay',
            onPress: () => setPrintErrMes(false),
            color: 'primary'
          }
        ]}
      >
        <Typography variant='body2'>
          You might face issues while printing on Brave Browser. Please use Chrome/Firefox for the
          purpose
        </Typography>
      </StandardModal>
    </WideModal>
  )
}

export default OfferSend
