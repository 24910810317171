import {
  Add,
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  Delete,
  Edit,
  MoreHoriz
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

// importing components
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'
import OAuthCard from '../../Components/Contacts/OAuthCard'
import Header from '../../Components/General/Header/Header'

// importing apis
import { businessContactsCount } from 'api/business/contacts/count'
import { businessContactsFetch } from 'api/business/contacts/fetch'

function MenuButton ({ row, setTrigger, onPressEditContact }) {
  const { jwtToken, business, activeBusiness } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const deleteAPI = async () => {
    axios
      .delete(`${process.env.REACT_APP_BUSINESS_URL}/contact/delete`, {
        headers: {
          'NRCT-UID': business[activeBusiness].ID,
          Authorization: `Bearer ${jwtToken}`
        },
        data: {
          id: row.id
        }
      })
      .then((res) => {
        setTrigger((prev) => !prev)
        // console.log("Changed page");
      })
      .catch((err) => {})
  }

  return (
    <>
      <IconButton
        size='small'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: { borderRadius: 10 }
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key={0}
          onClick={() => {
            // console.log("Editable true");
            // setEditable(true)
            onPressEditContact()
          }}
          disableRipple
          style={{
            padding: '5px 18px 5px 10px',
            minHeight: 24,
            lineHeight: 1,
            color: '#0C3A2D'
          }}
        >
          <Edit style={{ height: '0.7em', marginRight: 5 }} />
          Edit
        </MenuItem>
        <Divider style={{ margin: '5px 10px' }} />
        <MenuItem
          key={1}
          onClick={deleteAPI}
          disableRipple
          style={{
            padding: '5px 18px 5px 10px',
            minHeight: 24,
            lineHeight: 1,
            color: '#E71D36'
          }}
        >
          <Delete style={{ height: '0.7em', marginRight: 5 }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

function ContactRow ({ row, setTrigger }) {
  const { jwtToken, business, activeBusiness } = useContext(UserContext)
  const { openAddEditContactModal } = useContext(GlobalFuncContext)

  const { enqueueSnackbar } = useSnackbar()

  const uploadDP = () => {
    const file = document.getElementById('upload-avatar').files[0]
    // console.log(file);
    if (file) {
      axios
        .get(`${process.env.REACT_APP_BUSINESS_URL}/contact/pre-sign?contact_id=${row.id}`, {
          headers: {
            'NRCT-UID': business[activeBusiness].ID,
            Authorization: `Bearer ${jwtToken}`
          }
        })
        .then(async (res) => {
          if (res.data.url) {
            axios
              .put(res.data.url, file, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Context-Type': 'image/jpg'
                }
              })
              .then((response) => {
                enqueueSnackbar('Avatar Updated Successfully', {
                  variant: 'success'
                })
              })
          }
        })
    }
  }

  const onPressEditContact = () => {
    openAddEditContactModal(
      true,
      () => setTrigger((prev) => ++prev),
      {
        name: row.name,
        phone: row.phone,
        email: row.email,
        city: row.city,
        state: row.state,
        id: row.id
      }
    )
  }

  return (
    <TableRow key={row.id}>
      <TableCell>
        <Avatar
          alt={row.name}
          style={{
            width: 30,
            height: 30,
            margin: 'auto',
            verticalAlign: 'middle'
          }}
          src={row.image}
        />
      </TableCell>
      <TableCell>
        <span
          style={{
            lineHeight: '30px'
          }}
        >
          {row.name}
        </span>
      </TableCell>
      <TableCell>{row.phone}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>
        {row.city === '' && row.state === '' ? 'Not Available' : `${row.city}, ${row.state}`}
      </TableCell>
      <TableCell>
        <MenuButton onPressEditContact={onPressEditContact} {...{ row, setTrigger }} />
      </TableCell>
    </TableRow>
  )
}

const Contacts = (props) => {
  const { jwtToken, business, activeBusiness } = useContext(UserContext)
  const { openAddEditContactModal } = useContext(GlobalFuncContext)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [trigger, setTrigger] = useState(false)

  const history = useHistory()

  const getCount = async () => {
    businessContactsCount(business[activeBusiness].ID, jwtToken)
      .then((resData) => {
        console.log({ resData })
        setCount(resData)
      })
      .catch((err) => console.log(err))
  }

  const fetchContacts = async (index, isCSV) => {
    businessContactsFetch(
      business[activeBusiness].business_id,
      isCSV ? 50 : 10,
      isCSV ? index * 50 : index * 10,
      'all',
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resBody) => {
        if (isCSV) {
          return resBody
        } else {
          setRows(resBody)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  useEffect(() => {
    fetchContacts(page - 1, false)
    // eslint-disable-next-line
  }, [page, trigger])

  useEffect(() => {
    getCount()
    // eslint-disable-next-line
  }, [trigger])

  return (
    <>
      {!business || business.length <= 0
        ? (
        <Redirect to='/' />
          )
        : (
        <>
          <Header />
          <Container maxWidth='xl' sx={{ mt: '78px', marginBottom: '74px' }}>
            <Grid container spacing={1} xs={12} lg={12}>
              {/* My Offers Past Offers screen */}
              <Grid container item xs={12} lg={9} spacing={1}>
                <Grid item lg={1}>
                  <Stack direction='row' justifyContent='flex-end'>
                    <IconButton
                      style={{
                        alignSelf: 'flex-end',
                        backgroundColor: '#F2F2F2'
                      }}
                      onClick={() => history.goBack()}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={11}>
                  {/* Contacts header */}
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                  >
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Typography color='primary' variant='h3'>
                        Contacts
                      </Typography>
                      <Typography variant='h3' style={{ color: 'grey' }}>
                        ({count})
                      </Typography>
                    </Stack>
                    <Button
                      variant='contained'
                      color='secondary'
                      startIcon={<Add />}
                      onClick={() => openAddEditContactModal(false, () => fetchContacts())}
                    >
                      Add Customer
                    </Button>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    flexWrap='wrap'
                    style={{
                      marginTop: '20px'
                    }}
                  >
                    <TableContainer component={Card}>
                      <Table aria-label='Contacs'>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.length > 0
                            ? <>
                              {rows.map((row, index) => (
                                <ContactRow {...{ row, setTrigger }} key={index} />
                              ))}
                            </>
                            : <TableRow>
                              <TableCell colSpan={6}>
                                <Stack
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  style={{
                                    width: '100%',
                                    marginTop: 40,
                                    textAlign: 'center'
                                  }}
                                >
                                <img
                                  style={{
                                    width: 280,
                                    height: 'auto'
                                  }}
                                  src={require('../../Components/assets/contactsEmptyState.svg').default}
                                />
                                <Typography variant="body1" style={{ marginTop: 8 }}>
                                Import your contacts to send offers, create gathering and much more!
                                </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          }
                        </TableBody>
                        {rows.length > 0 && <TableFooter>
                          <TableCell colSpan={5}>
                            Page: {page} of {Math.ceil(count / 10)}
                            <IconButton
                              size='small'
                              onClick={() => {
                                setPage((page) => page - 1)
                              }}
                              disabled={page === 1}
                            >
                              <ArrowLeft />
                            </IconButton>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setPage((page) => page + 1)
                              }}
                              disabled={page === Math.ceil(count / 10)}
                            >
                              <ArrowRight />
                            </IconButton>
                          </TableCell>
                        </TableFooter>}
                      </Table>
                    </TableContainer>
                  </Stack>
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} lg={3}>
                <Stack direction='column' justifyContent='center'>
                  <OAuthCard {...{ count, setTrigger }} />
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </>
          )}
    </>
  )
}

export default Contacts
