import axios from 'axios'

/**
 * @function businessOfferEnd Used to delete an offer
 * @param {string} offerId - offer's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful offer end
 */
export const businessOfferEnd = (offerId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_BUSINESS_URL}/offer/end`, {
      params: {
        offer_id: offerId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
