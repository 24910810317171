import axios from 'axios'

export const businessAnalyticsInsights = (
  businessId,
  startTime,
  endTime,
  nrctUID,
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business-analytics/insights`, {
      business_id: businessId,
      user_id: nrctUID,
      from: startTime,
      to: endTime
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
