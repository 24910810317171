import * as React from 'react'
import { FormControl, Stack, Switch, Typography } from '@mui/material'
import { useChatContext } from 'stream-chat-react'
import { useSnackbar } from 'notistack'

// importing components
import { UserContext } from '../../../Context/UserContext'

// importing ui
import WideModal, { WideModalButtonProps } from 'ui/Modals/WideModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'
import TextAreaInput from 'ui/TextInputs/TextAreaInput'
import CreateVideo from 'ui/Custom/CreateVideo/CreateVideo'
import NearcastVideoPlayer from 'ui/Custom/NearcastVideoPlayer/NearcastVideoPlayer'

// importing api
import { businessReviewHide } from 'api/business/review/hide'
import { businessResponsePreSign } from 'api/business/review/respond'

// the different screen dimensions to record in
const screenDimensionsPortrait = {
  width: 300,
  height: 600
}

export interface ReviewActionModalProps {
  type: 'hide' | 'response';
  selectedNearcasts: {
    username: string;
    userId: string;
    reviewId: string;
  }[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ReviewActionModal: React.FC<ReviewActionModalProps> = ({
  type,
  selectedNearcasts,
  isOpen,
  onClose,
  onSuccess
}) => {
  const { client } = useChatContext()
  const { enqueueSnackbar } = useSnackbar()
  const {
    business,
    activeBusiness,
    jwtToken,
    preferences
  } = React.useContext(UserContext)

  // component states
  const [step, setStep] = React.useState<0 | 1>(0)
  const [addPersonalizedVideoMessage, setAddPersonalizedVideoMessage] = React.useState(false)
  const [personalVideo, setPersonalVideo] = React.useState<File | null>(null)
  // state for recipients
  const [recipients, setRecipients] = React.useState<string[]>([])
  const [message, setMessage] = React.useState<string>('')
  // state for processing - any api call wait
  const [processing, setProcessing] = React.useState(false)
  const [videoUploadProgress, setVideoUploadProgress] = React.useState(0)

  const _onClose = () => {
    onClose()
    setStep(0)
    setMessage('')
    setAddPersonalizedVideoMessage(false)
    setPersonalVideo(null)
    setProcessing(false)
    setVideoUploadProgress(0)
  }

  const sendGetStreamMessage = async (userId) => {
    if (
      userId === null ||
      userId.length === 0 ||
      userId === business[activeBusiness].ID ||
      userId === '00000000-0000-0000-0000-000000000000'
    ) {
      return
    }
    // if empty message
    let m = message
    if (m.length === 0) {
      if (type === 'hide') {
        m = 'Your response has been hidden!'
      } else {
        m = 'You have a response for your review!'
      }
    }
    const channel = client.channel(
      'messaging',
      {
        members: [userId, business[activeBusiness].ID]
      }
    )
    await channel.watch()
    if (!channel.id) {
      // worse case: could not create the channel
      return console.log('Cannot message this user:', userId)
    }
    try {
      const streamMessage = {
        text: m,
        attachments: []
      }
      if (personalVideo !== null) {
        const response = await channel.sendFile(personalVideo)
        streamMessage.attachments = [
          {
            type: 'video',
            asset_url: response.file
          }
        ]
      }
      channel.sendMessage(streamMessage)
    } catch (err) {
      console.log(err)
    }
  }

  const onSendMessage = () => {
    if (processing) {
      return
    }
    // if user toggled on addPersonalizedVideoMessage but hasn't finished recording the video message
    // then don't do anything on send message press
    if (addPersonalizedVideoMessage && !personalVideo) {
      return
    }
    setProcessing(true)

    // create a request pool
    const allRequests = []

    // send all selectedNearcasts get stream messages
    selectedNearcasts.forEach((nearcast) => {
      sendGetStreamMessage(nearcast.userId)
    })

    // add hide request to pool
    if (type === 'hide') {
      allRequests.push(
        businessReviewHide(
          selectedNearcasts.map((nearcast) => nearcast.reviewId),
          business[activeBusiness].ID,
          jwtToken
        )
      )
    }

    // emails will not be sent without a video response
    // add respond request to pool
    const nonAnonymousUserIds = selectedNearcasts
      .filter((nearcast) => nearcast.username.length !== 0)
      .map((nearcast) => nearcast.reviewId)
    if (addPersonalizedVideoMessage && personalVideo && nonAnonymousUserIds.length !== 0) {
      allRequests.push(businessResponsePreSign(
        nonAnonymousUserIds,
        message,
        personalVideo,
        business[activeBusiness].ID,
        jwtToken,
        (p) => setVideoUploadProgress(p)
      ))
    }

    // perform all requests at once
    Promise.all(allRequests)
      .then(() => {
        if (type === 'hide') {
          enqueueSnackbar('Review Nearcasts successfully hidden!', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Successfully responded to the reviews!', {
            variant: 'success'
          })
        }
        onSuccess()
        _onClose()
      })
      .catch((err) => {
        console.log(err.message)
        enqueueSnackbar('Ops something went wrong!', {
          variant: 'error'
        })
      })
      .finally(() => setProcessing(false))
  }

  const renderModalContent = () => {
    if (step === 0) {
      return (
        <Stack
          direction="column"
          sx={{
            padding: 2
          }}
        >
          <FormControl margin="normal">
            <PriceFieldsInput
              label="Recipients"
              value={recipients.join(', ')}
              editable={false}
            />
          </FormControl>
          <FormControl margin="normal">
            <TextAreaInput
              label="Message"
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant='h6'>
              Add a personalized video
            </Typography>
            <Switch
              checked={addPersonalizedVideoMessage}
              onChange={() => setAddPersonalizedVideoMessage(!addPersonalizedVideoMessage)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        </Stack>
      )
    }

    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            height: screenDimensionsPortrait.height,
            width: screenDimensionsPortrait.width,
            backgroundColor: 'black',
            borderRadius: 8
          }}
        >
          {personalVideo !== null
            ? (
                <NearcastVideoPlayer
                  type="preview"
                  video={{
                    source: URL.createObjectURL(personalVideo),
                    muted: preferences.videoMuted,
                    toggleMuted: preferences.toggleVideoMuted
                  }}
                />
              )
            : (
                <CreateVideo
                  setVideoSrc={(videoUrl, videoFile) => {
                    setPersonalVideo(videoFile)
                  }}
                />
              )}
        </div>
      </Stack>
    )
  }

  const renderModalButtons = (): WideModalButtonProps[] => {
    if (step === 0) {
      if (addPersonalizedVideoMessage) {
        return [
          {
            title: 'Next',
            color: 'primary',
            onPress: () => {
              setStep(1)
            }
          }
        ]
      } else {
        return [
          {
            title: processing ? 'Sending Message' : 'Send Message',
            color: 'primary',
            onPress: onSendMessage
          }
        ]
      }
    }

    return [
      {
        title: 'Back',
        color: 'ghost',
        onPress: () => {
          setStep(0)
          setPersonalVideo(null)
        }
      },
      {
        title: processing ? `Sending Message: ${videoUploadProgress}%` : 'Send Message',
        color: personalVideo ? 'primary' : 'ghost',
        onPress: onSendMessage
      }
    ]
  }

  React.useEffect(() => {
    const filtered = selectedNearcasts.filter((e) => e.username.length !== 0)
    setRecipients(filtered.map((nearcast) => nearcast.username))
  }, [type, selectedNearcasts])

  return (
    <WideModal
      title={type === 'hide' ? 'Hide Nearcast(s)' : 'Respond To'}
      isOpen={isOpen}
      onClose={_onClose}
      buttons={renderModalButtons()}
    >
      {renderModalContent()}
    </WideModal>
  )
}

export default ReviewActionModal
