import ReactGA from 'react-ga4'
import axios from 'axios'

const shouldUseGa = process.env.REACT_APP_GA
const trackWhenShouldUseGaIs = 'prod'

/**
 * Why is their an API call below? Even after having API package.
 *
 * This is a design choice. The API package uses some functions from helpers package therefor
 * importing API package in helpers will cause a dependency cycle. This API is tightly coupled with GA events
 * so it also makes it easier to have it as close to the actual GA implementation, making it simpler for future
 * development of this package (helpers/ga)
 */

/**
 * @function feedGoogleAnalyticsCustom Used to store recorded google analytic events for more in-dept study
 * @param {string} eventName
 * @param {Object} jsonData
 * @returns {Promise} Promise object represents
 */
export const feedGoogleAnalyticsCustom = (eventName, jsonData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/google-analytics`, {
      event_name: eventName,
      data: jsonData
    })
      .then((res) => resolve(true))
      .catch((err) => reject(err))
  })
}

export const GoogleAnalyticsInitialize = () => {
  if (shouldUseGa === trackWhenShouldUseGaIs) {
    // Initialize Google Analytics
    ReactGA.initialize(process.env.REACT_APP_GA_TAG)
    ReactGA.send('pageview')
  }
}

export const recordGaEvent: (name: string, params?: any) => void = (name, params = {}) => {
  if (shouldUseGa === trackWhenShouldUseGaIs) {
    ReactGA.event(name, params)
  }
  // trigger own postgres store for record event
  feedGoogleAnalyticsCustom(name, params)
}
