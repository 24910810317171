import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(({ breakpoints, palette }) => {
  return {
    toolbar: {
      minHeight: 54
    },
    root: {
      backgroundColor: 'white',
      position: 'fixed',
      height: 'auto',
      padding: '5px 20px',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width:960px)']: {
        padding: '5px 0'
      }
    },
    cta: {
      marginLeft: 'auto',
      // [breakpoints.down("sm")]: {
      //   marginLeft: "0px",
      // },
      display: 'flex',
      alignItems: 'center',
      // minWidth: 210,
      '& .host': {
        backgroundColor: `${palette.secondary.light}`,
        textAlign: 'center',
        height: '45px',
        color: `${palette.grey[50]}`,
        margin: '0 3px',
        border: `2px solid ${palette.secondary.light}`,
        borderRadius: '10px',
        textTransform: 'none',
        '& .MuiButton-label': {
          display: 'inline',
          height: '14px',
          lineHeight: 'normal'
        },
        '&:hover': {
          backgroundColor: `${palette.grey[50]}`,
          color: `${palette.secondary.light}`
        }
      },
      '& .login': {
        backgroundColor: '#FFFFFF',
        color: `${palette.primary.main}`,
        textAlign: 'center',
        height: '45px',
        margin: '0 3px',
        border: `2px solid ${palette.primary.main}`,
        borderRadius: '10px',
        textTransform: 'none',
        '& .MuiButton-label': {
          display: 'inline',
          height: '14px',
          lineHeight: 'normal'
        },
        '&:hover': {
          backgroundColor: `${palette.primary.main}`,
          color: '#FFFFFF'
        }
      }
    }
  }
})
