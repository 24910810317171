import makeStyles from '@mui/styles/makeStyles'
import * as React from 'react'
import Draggable from 'react-draggable'
import StickerButton from '../../Buttons/StickerButton/StickerButton'

const DraggableSticker = ({ sticker, updateSticker }) => {
  const classnames = styles()
  const stickerRef = React.useRef(null)
  const [overlayProp, setOverlayProp] = React.useState(0)

  React.useEffect(() => {
    const overlay = document.getElementById('stickers-overlay')
    setOverlayProp({
      width: overlay.offsetWidth,
      height: overlay.offsetHeight
    })
  }, [])

  const isStickerGettingOutOfBond = (d) => {
    const stickerWidth = stickerRef.current.offsetWidth
    const stickerHeight = stickerRef.current.offsetHeight

    return (
      d.x - (stickerWidth / 2) < -(overlayProp.width / 2) ||
      d.x + (stickerWidth / 2) > overlayProp.width / 2 ||
      d.y - (stickerHeight / 2) < -(overlayProp.height / 2) ||
      d.y + (stickerHeight / 2) > (overlayProp.height / 2) * 0.8 // 0.8 of the overall height as app requires a safe area from the bottom
    )
  }

  const defaultPosition = {
    x: sticker.x,
    y: sticker.y
  }

  return (
        <Draggable
            key={sticker.id}
            axis="both"
            handle=".handle"
            defaultPosition={defaultPosition}
            position={defaultPosition}
            grid={[1, 1]}
            scale={1}
            onStart={() => {}}
            onDrag={(e, d) => {
              if (isStickerGettingOutOfBond(d)) {
                stickerRef.current.style.border = '2px solid red'
              } else {
                stickerRef.current.style.border = 'none'
              }
            }}
            onStop={(e, d) => {
              if (isStickerGettingOutOfBond(d)) {
                // remove the sticker
                updateSticker(sticker, true)
              } else {
                const newSticker = {
                  ...sticker,
                  x: d.x,
                  y: d.y
                }
                updateSticker(newSticker, false)
              }
            }}>
                <div className="handle" style={{ display: 'inline-flex', position: 'absolute' }}>
                    <div ref={stickerRef} className={classnames.stickerWrapper}>
                        <StickerButton
                            sticker={sticker}
                            overRideOnClick={() => {}}
                            style={{
                              margin: 0
                            }}
                        />
                    </div>
                </div>
        </Draggable>
  )
}

const styles = makeStyles(() => ({
  stickerWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 8,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export default DraggableSticker
