import axios from 'axios'

/**
 * @function forgotPassword used to send a reset password link to user
 * @param {string} email - user's email
 * @returns {Promise} Promise object represents true for successful resolution
 */
export const forgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgot/password`, {
      email: email
    })
      .then((res) => resolve(true))
      .catch((err) => {
        reject(err)
      })
  })
}
