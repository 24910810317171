import {
  Typography,
  Stack,
  CircularProgress,
  Fade,
  Button,
  styled
} from '@mui/material'
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import QRCode from 'qrcode.react'

// importing UI
import NearcastCard from 'ui/Cards/NearcastCard/NearcastCard'
import PostViewModal from 'ui/Custom/Modals/PostViewModal/PostViewModal'
import Rating from 'ui/Custom/Rating/Rating'

// importing components
import DropDownMenu, { DropDownMenuOption } from '../DropDownMenu/DropDownMenu'
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'

// importing apis
import { businessReviews, businessReviewsHidden } from 'api/business/review/index'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { businessReviewHide, businessReviewUnhide } from 'api/business/review/hide'

// importing helpers
import { getUserDomainURL } from 'helpers/url'

// importing redux store
import { useAppSelector, useAppDispatch } from '../../Utils/redux/store'
import { updateUserSubscriptionDetailsInRedux } from '../../Utils/redux/actions/subscriptionAction'

// some helpful data
const allReviewSortingTypes: DropDownMenuOption[] = [
  {
    text: 'Latest First',
    value: 'latest'
  },
  {
    text: 'Oldest First',
    value: 'oldest'
  },
  {
    text: 'Low Rating First',
    value: 'low-rating'
  },
  {
    text: 'High Rating First',
    value: 'high-rating'
  },
  {
    text: 'Popular First',
    value: 'popularity'
  }
]

const TextButton = styled(Button)(() => ({
  margin: 0,
  padding: 0,
  paddingLeft: 4,
  paddingRight: 4,
  minHeight: 0,
  height: 'min-content',
  minWidth: 0
}))

export interface DashboardNearcastObjectType {
  postId: string;
  username: string;
  userCover: string;
  userId: string;
  postCover: string;
  postCaption: string;
  reviewId: string;
  postPlaybackToken: string;
  postSticker: any[];
  postMusic: any[];
  rating: number;
}

export interface DashboardNearcastsProps {
  isFor: 'business' | 'offer'
  title?: string;
  totalReviewCount?: number;
}

const DashboardNearcasts: React.FC<DashboardNearcastsProps> = ({
  isFor,
  title = 'Business Reviews',
  totalReviewCount = 0
}) => {
  const reduxDispatch = useAppDispatch()
  const { pathname } = useLocation()
  // user context
  const { business, jwtToken, activeBusiness, preferences } = useContext(UserContext)
  const {
    openReviewActionModal,
    openShareModal,
    openPublishModal,
    openDownloadReviewsModal,
    openUpgradePlanToAccess
  } = useContext(GlobalFuncContext)

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  const subscriptionPlan = subscriptionState.subscription.planDetails
  const canHideVideosFromBusinessPublicPage = (subscriptionPlan.hideVideosFromBusinessPublicPage as number)
  const canDownloadVideoWithoutWatermark = (subscriptionPlan.downloadVideoWithoutWatermark as number)

  // Component states
  // states for business nearcast reviews
  const businessReviewStateEmpty: {
    nearcasts: DashboardNearcastObjectType[],
    loading: boolean,
    page: number
  } = {
    nearcasts: [],
    loading: true,
    page: 1
  }
  const [businessReviewState, setBusinessReviewState] = useState(businessReviewStateEmpty)
  // state for URL actors
  const [wasLatestReviewOpened, setWasLatestReviewOpened] = useState(false)
  // states for business nearcast reviews
  const [reviewOrderBy, setReviewOrderBy] = useState(allReviewSortingTypes[0])
  const [selectedPosts, setSelectedPosts] = useState([])
  const hasSelectedNonAnonymousPost = React.useMemo(() => {
    return !!businessReviewState.nearcasts.find((nearcast) => {
      if (selectedPosts.includes(nearcast.reviewId)) {
        return !!nearcast.postId
      }

      return false
    })
  }, [selectedPosts])
  // state for post display
  const postViewStateEmpty: {
    index: number;
    nearcast: any;
    isOpen: boolean;
  } = {
    index: 0,
    nearcast: {},
    isOpen: false
  }
  const [postViewState, setPostViewState] = useState(postViewStateEmpty)
  // state for which type of reviews to display
  const [reviewType, setReviewType] = useState<'normal' | 'hidden'>('normal')

  const getBusinessReviews = useCallback((pageNumber = 1) => {
    setBusinessReviewState({
      ...businessReviewState,
      nearcasts: [],
      loading: true,
      page: pageNumber
    })
    let reviewRequestCall = businessReviews
    if (reviewType === 'hidden') {
      reviewRequestCall = businessReviewsHidden
    }
    reviewRequestCall(
      business[activeBusiness].business_id,
      reviewOrderBy.value,
      pageNumber,
      null,
      business[activeBusiness].ID,
      jwtToken
    )
      .then((resData) => {
        const formattedNearcasts = resData.body.map((post) => ({
          postId: post.post_id,
          username: post.username,
          userCover: personalAccountAvatar(post.personal_account_id),
          userId: post.personal_account_id,
          postCover: post.post_image_token,
          postCaption: post.caption || '',
          postPlaybackToken: post.post_playback_token,
          postSticker: post.stickers,
          postMusic: post.music,
          rating: post.rating,
          reviewId: post.id
        }))
        setBusinessReviewState({
          ...businessReviewState,
          nearcasts: formattedNearcasts,
          loading: false,
          page: pageNumber
        })

        // check if validity reached on status 2041
        if (resData.status === 2041) {
          openUpgradePlanToAccess()
        }

        // URL actor - open_latest_reviews
        // also check is it was not previously opened: wasLatestReviewOpened
        if (pathname.includes('open_latest_reviews') && wasLatestReviewOpened === false) {
          setWasLatestReviewOpened(true)
          setPostViewState({
            index: 0,
            nearcast: formattedNearcasts[0],
            isOpen: true
          })
        }
      })
      .catch((err) => console.log(err))
  }, [activeBusiness, business, jwtToken, reviewOrderBy, reviewType])

  const onSelectReviewAction = (option) => {
    const filteredNearcasts = businessReviewState.nearcasts.filter((nearcast) => {
      return selectedPosts.includes(nearcast.reviewId)
    })
    switch (option.value) {
      case 0:
        return openPublishModal(
          filteredNearcasts.map((nearcast) => ({
            postId: nearcast.postId,
            creatorName: nearcast.username
          })),
          getUserDomainURL(`/business/review?id=${business[activeBusiness].business_id}`)
        )
      case 1:
        return openReviewActionModal(
          'response',
          filteredNearcasts.map((nearcast) => ({
            username: nearcast.username,
            userId: nearcast.userId,
            reviewId: nearcast.reviewId
          })),
          () => {
            setSelectedPosts([])
            reduxDispatch(updateUserSubscriptionDetailsInRedux())
          }
        )
      case 2:
        // only allow user to hide nearcasts if canHideVideosFromBusinessPublicPage is not zero
        if (canHideVideosFromBusinessPublicPage === 0) {
          return openUpgradePlanToAccess()
        }
        if (hasSelectedNonAnonymousPost === false) {
          // only anonymous posts are selected, so don't show the modal
          // directly hide
          businessReviewHide(
            filteredNearcasts.map((nearcast) => nearcast.reviewId),
            business[activeBusiness].ID,
            jwtToken
          )
            .then(() => {
              setSelectedPosts([])
              getBusinessReviews()
            })
            .catch((err) => console.log(err.message))
            .finally(() => {
              reduxDispatch(updateUserSubscriptionDetailsInRedux())
            })
          return
        }
        return openReviewActionModal(
          'hide',
          filteredNearcasts.map((nearcast) => ({
            username: nearcast.username,
            userId: nearcast.userId,
            reviewId: nearcast.reviewId
          })),
          () => {
            setSelectedPosts([])
            getBusinessReviews()
            reduxDispatch(updateUserSubscriptionDetailsInRedux())
          }
        )
      case 3:
        return openDownloadReviewsModal(filteredNearcasts.map((nearcast) => nearcast.reviewId), 'withWatermark')
      case 4:
        if (canDownloadVideoWithoutWatermark === 0) {
          return openUpgradePlanToAccess()
        }
        return openDownloadReviewsModal(filteredNearcasts.map((nearcast) => nearcast.reviewId), 'withoutWatermark')
      case 5:
        return businessReviewUnhide(
          filteredNearcasts.map((nearcast) => nearcast.reviewId),
          business[activeBusiness].ID,
          jwtToken
        )
          .then(() => {
            getBusinessReviews()
            setSelectedPosts([])
          })
          .catch((err) => console.log(err.message))
      default:
        console.log('no supported value')
    }
  }

  useEffect(() => {
    if (jwtToken && business.length > 0 && activeBusiness >= 0) {
      getBusinessReviews()
      setSelectedPosts([])
    }
  }, [jwtToken, business, activeBusiness, getBusinessReviews, reviewType])

  const memoizedSelectedOptions = useMemo(() => {
    if (reviewType !== 'hidden') {
      return [
        {
          text: 'Publish',
          value: 0
        },
        {
          text: 'Respond',
          value: 1,
          disabled: hasSelectedNonAnonymousPost === false
        },
        {
          text: 'Hide Nearcast(s)',
          value: 2
        },
        {
          text: 'Download',
          value: 3
        },
        {
          text: 'Download (without Watermark)',
          value: 4
        }
      ]
    }

    return [
      {
        text: 'Unhide Review',
        value: 5
      }
    ]
  }, [reviewType, hasSelectedNonAnonymousPost])

  const renderOfferNearcasts = () => {
    if (businessReviewState.loading) {
      return (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            minHeight: '200px'
          }}
        >
          <CircularProgress />
        </Stack>
      )
    }

    if (businessReviewState.nearcasts.length === 0) {
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            marginTop: 40,
            textAlign: 'center'
          }}
        >
          <QRCode
            style={{ marginTop: 7, marginBottom: 7, width: 200, height: 200 }}
            size={300}
            value={getUserDomainURL(`/business/review/${business[activeBusiness].business_id}`)}
          />
          <Typography variant="h5" style={{ marginTop: 16 }}>
            No reviews created yet, scan this QR code to see how it works!
          </Typography>
        </Stack>
      )
    }

    return (
      <Stack
        direction="row"
        flexWrap="wrap"
        style={{
          width: '100%'
        }}
      >
        {businessReviewState.nearcasts.map((nearcast, index) => {
          const isSelected = Boolean(selectedPosts.find((reviewId) => reviewId === nearcast.reviewId))
          return (
            <Stack key={nearcast.reviewId} sx={{ marginBottom: 3, marginLeft: 1 }} direction="column">
              <NearcastCard
                author={nearcast.username || 'Anonymous'}
                authorCover={nearcast.userCover}
                postCover={nearcast.postCover}
                postCaption={nearcast.postCaption}
                onPressSelect={() => {
                  if (isSelected) {
                    setSelectedPosts(selectedPosts.filter((reviewId) => reviewId !== nearcast.reviewId))
                  } else {
                    setSelectedPosts([...selectedPosts, nearcast.reviewId])
                  }
                }}
                onPressCard={() => {
                  setPostViewState({
                    index: index,
                    nearcast: nearcast,
                    isOpen: true
                  })
                }}
                isSelected={isSelected}
                style={{
                  marginRight: 16
                }}
              />
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" style={{ marginRight: 4 }}>
                  Rating
                </Typography>
                <Rating
                  value={nearcast.rating}
                  maxValue={5}
                />
              </Stack>
            </Stack>
          )
        })}
        {postViewState.isOpen && (
          <PostViewModal
            nearcast={{
              postId: postViewState.nearcast.postId,
              postCaption: postViewState.nearcast.postCaption,
              postPlaybackToken: postViewState.nearcast.postPlaybackToken,
              postSticker: postViewState.nearcast.postSticker,
              postMusic: postViewState.nearcast.postMusic,
              creator: {
                userId: postViewState.nearcast.userId,
                username: postViewState.nearcast.username,
                userCover: postViewState.nearcast.userCover
              }
            }}
            open={postViewState.isOpen}
            onClose={() => setPostViewState(postViewStateEmpty)}
            hasUserDetails={{
              isUserLoggedIn: true,
              jwtToken: jwtToken,
              nrctUID: business[activeBusiness].ID,
              preferences: {
                videoMuted: preferences.videoMuted,
                toggleVideoMuted: preferences.toggleVideoMuted
              }
            }}
            openShareModal={openShareModal}
            forWhichPortal="business"
          />
        )}
      </Stack>
    )
  }

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexWrap="wrap"
      spacing={2}
    >
      <Stack
        direction={{
          xs: 'column',
          md: 'row'
        }}
        alignItems={{
          xs: 'flex-start',
          md: 'center'
        }}
        justifyContent="space-between"
        style={{
          width: '100%'
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant={reviewType === 'normal' ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => setReviewType('normal')}
            disableElevation
          >
            {title} {totalReviewCount > 0 && (`(${totalReviewCount})`)}
          </Button>
          <Button
            variant={reviewType === 'hidden' ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => setReviewType('hidden')}
            disableElevation
          >
            Hidden Reviews
          </Button>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={{
            xs: 'space-between',
            md: 'center'
          }}
          sx={{
            width: {
              xs: '100%',
              md: 'auto'
            },
            marginTop: {
              xs: 1,
              md: 0
            },
            marginBottom: {
              xs: 1,
              md: 0
            }
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2" sx={{ marginRight: 1 }}>
              Sort by:
            </Typography>
            <DropDownMenu
              selected={reviewOrderBy}
              options={allReviewSortingTypes}
              onSelectedPress={(option) => {
                setReviewOrderBy(option)
              }}
            />
          </Stack>
          {selectedPosts.length > 0 && (
            <Fade in={selectedPosts.length > 0}>
              <div>
                <DropDownMenu
                  selected={{
                    text: `Selected: ${selectedPosts.length}`,
                    value: -1
                  }}
                  options={memoizedSelectedOptions}
                  onSelectedPress={onSelectReviewAction}
                  style={{
                    backgroundColor: '#3a8f78',
                    color: 'white'
                  }}
                />
              </div>
            </Fade>
          )}
          <Stack style={{ marginLeft: 16 }} direction="row" alignItems="center">
            <TextButton
              variant="text"
              color="primary"
              disabled={businessReviewState.page === 1 || businessReviewState.loading}
              disableElevation
              style={{
                marginRight: 8
              }}
              onClick={() => getBusinessReviews(--businessReviewState.page)}
            >
              PREV
            </TextButton>
            <TextButton
              variant="text"
              color="primary"
              disabled={businessReviewState.nearcasts.length < 20 || businessReviewState.loading}
              disableElevation
              onClick={() => getBusinessReviews(++businessReviewState.page)}
            >
              NEXT
            </TextButton>
          </Stack>
        </Stack>
      </Stack>
      {renderOfferNearcasts()}
    </Stack>
  )
}

export default DashboardNearcasts
