import { Component } from 'react'

class CloudSpongeWidget extends Component {
  addJavascript (src, id, callback) {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      callback && callback()
      return
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.async = 1
    if (id) {
      scriptTag.id = id
    }

    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (
            scriptTag.readyState === 'loaded' ||
            scriptTag.readyState === 'complete'
          ) {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null
            callback()
          }
        }
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback()
        }
      }
    }

    // assign the src attribute
    scriptTag.src = src
    // add the script to the page
    document.body.appendChild(scriptTag)
  }

  componentDidMount () {
    this.addJavascript(
      `https://api.cloudsponge.com/widget/${process.env.REACT_APP_CLOUDSPONGEKEY}.js`,
      '__cloudsponge_widget_script',
      () => {
        // calling init attaches the cloudsponge.launch action to any cloudsponge-launch links on the page
        //  we need to wait until the script has loaded so we aren't "bandwidthist"
        if (window.cloudsponge) {
          window.cloudsponge.init(this.props.options)
        }
      }
    )
  }

  // just render the children, if any.
  //  typically the children will include at least one link decorated with
  //  class="cloudsponge-launch" so that the cloudsponge library will attach
  //  to its onClick handler to launch the widget
  render () {
    return this.props.children
  }
}

export default CloudSpongeWidget
