import axios from 'axios'

/**
 * @function businessPaymentTransactionHistory Used to fetch stripe invoice history
 * @param {int} page - int value
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents business subscription data
 */
export const businessPaymentTransactionHistory = (
  page = 1,
  nrctUID: string,
  jwt: string
) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/payment/transaction/history`, {
      params: {
        page: page
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
