import axios from 'axios'

/**
 * @function businessContactsCount Used to upload business contacts
 * @param {String} nrctUID - user's business id
 * @param {string} jwt - jwt token
 * @returns {Promise} successful upload is true
 */
export const businessContactsCount = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BUSINESS_URL}/contacts/count`, {
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => {
        resolve(res.data.body)
      })
      .catch((err) => reject(err))
  })
}
