// Imports: Dependencies
import { combineReducers } from 'redux'

// importing reducers
import userReducer from './userReducer'
import subscriptionReducer from './subscriptionReducer'

const defaultRootReducer = combineReducers({
  userState: userReducer,
  subscriptionState: subscriptionReducer
})

// Redux: Root Reducer
const rootReducer = combineReducers({
  root: defaultRootReducer
})

export default rootReducer
