import axios from 'axios'

/**
 * @function businessContactsUpload Used to upload business contacts
 * @param {Array} contacts - array of objects having: name, phone, email, city, state
 * @param {String} nrctUID - user's business id
 * @param {string} jwt - jwt token
 * @returns {Promise} successful upload is true
 */
export const businessContactsUpload = (contacts, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/contacts/upload`, contacts, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
