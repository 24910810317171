import axios from 'axios'

export const businessUpdateSchedulePatch = (data, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        `${process.env.REACT_APP_BUSINESS_URL}/business/update/schedule`,
        data,
        {
          headers: {
            'NRCT-UID': nrctUID,
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err)
      })
  })
}
