import React from 'react'
import {
  Modal,
  Card,
  CardProps,
  IconButton,
  styled,
  Grid
} from '@mui/material'
import { ArrowBack, CloseRounded } from '@mui/icons-material'

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: 800,
  height: 600,
  outline: 'none',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: '60vw',
    maxHeight: '90vh'
  },
  [theme.breakpoints.down('sm')]: {
    width: '92vw',
    maxHeight: '90vh'
  }
}))

export interface ImageWideModalButtonProps {
  title: string;
  onPress: () => void;
  color: 'primary' | 'secondary' | 'ghost';
  variant?: 'contained' | 'outlined' | 'text';
}

export interface ImageWideModalProps {
  image: any;
  isOpen: boolean;
  onClose: () => void;
  isTitleBack?: boolean;
  onPressTitleBack?: () => void;
  children: any;
}

const ImageWideModal: React.FC<ImageWideModalProps> = ({
  image,
  isOpen,
  onClose,
  isTitleBack,
  onPressTitleBack,
  children
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledCard>
        <Grid container sx={{ height: '100%' }}>
          <Grid item md={6} xs={0}>
            <div
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'fill',
                backgroundPosition: 'center',
                height: '100%',
                width: '100%'
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              padding: 2,
              height: '100%',
              overflowY: 'scroll',
              overFlowX: 'hidden'
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginBottom: 6
              }}
            >
              {isTitleBack && (
                <IconButton
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '8px'
                  }}
                  onClick={onPressTitleBack}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
              )}
              <IconButton
                style={{
                  backgroundColor: '#bdbdbd',
                  width: '14px',
                  height: '14px',
                  marginLeft: 'auto'
                }}
                onClick={onClose}
                size="large"
              >
                <CloseRounded />
              </IconButton>
            </div>
            <div
              style={{
                width: '100%',
                overflowY: 'auto'
                // paddingRight: 20
              }}
            >
              {children}
            </div>
          </Grid>
        </Grid>
      </StyledCard>
    </Modal>
  )
}

export default ImageWideModal
