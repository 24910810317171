import {
  Button,
  Stack,
  Typography
} from '@mui/material'
import { Check } from '@mui/icons-material'
import React from 'react'

// importing ui
import ImageWideModal from 'ui/Modals/ImageWideModal'

// import api
import { businessUserSubscriptionTrialModalSeen } from 'api/business/userSubscription'

// importing redux store
import { useAppSelector, useAppDispatch } from '../../../Utils/redux/store'
import { updateUserSubscriptionDetailsInRedux } from '../../../Utils/redux/actions/subscriptionAction'

// importing component
import { UserContext } from '../../../Context/UserContext'

const freeTrialArray = [
  'Access to all features (unlimited reviews, unlimited response, priority support)',
  'Hide upto 15 reviews',
  'Download 15 reviews without watermark',
  'Advanced Analytics'
]

export interface FreeTrialModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const FreeTrialModal: React.FC<FreeTrialModalProps> = (props) => {
  const reduxDispatch = useAppDispatch()

  // redux state
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)

  // user context
  const {
    business,
    jwtToken,
    activeBusiness
  } = React.useContext(UserContext)

  React.useEffect(() => {
    if (props.open) {
      businessUserSubscriptionTrialModalSeen(
        subscriptionState.subscription.id,
        business[activeBusiness].ID,
        jwtToken
      )
        .then(() => {})
        .catch((err) => console.log(err.message))
        .finally(() => {
          reduxDispatch(updateUserSubscriptionDetailsInRedux())
        })
    }
  }, [props.open])

  return (
    <ImageWideModal
      image={require('./upgrade.png')}
      isOpen={props.open}
      onClose={() => {
        props.onSuccess()
        props.onClose()
      }}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography variant="h3">
          Welcome to Nearcast Business's free trial 👋
        </Typography>
        <Typography variant="h5" sx={{ marginTop: 4 }}>
          What you get during your 14-Day trial
        </Typography>
        <Stack spacing={2} sx={{ marginTop: 1, width: '100%' }}>
          {freeTrialArray.map((freeTrial) => {
            return (
              <Stack key={freeTrial} direction="row" alignItems="center" justifyContent="flex-start" sx={{ marginTop: 1 }}>
                <Check />
                <Typography variant="caption" sx={{ marginLeft: 1, fontSize: '14px' }}>
                  {freeTrial}
                </Typography>
              </Stack>
            )
          })}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onSuccess()
              props.onClose()
            }}
            sx={{
              marginTop: '32px !important'
            }}
            disableElevation
          >
            Got It
          </Button>
        </Stack>
      </Stack>
    </ImageWideModal>
  )
}

export default FreeTrialModal
