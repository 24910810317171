import {
  Stack,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider
} from '@mui/material'
import React from 'react'
import {
  Facebook,
  Twitter,
  Public
} from '@mui/icons-material'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'

// importing heplers
import { getUserDomainPreviewUrl } from 'helpers/url'

export interface PublishModalPostType {
  postId: string;
  creatorName: string;
}

export interface PublishModalProps {
  posts: PublishModalPostType[];
  offerLink: string;
  isOpen: boolean;
  onClose: () => void;
}

const PublishModal: React.FC<PublishModalProps> = (props) => {
  const { isOpen, onClose, posts, offerLink } = props

  const postLinksOnPublicUser = posts.map((post, i) => `${i + 1}. ${getUserDomainPreviewUrl(`/p/${post.postId}`)}`)
  const postCreatorNames = posts.map((post) => post.creatorName)
  const message = `Watch these reviews created by ${postCreatorNames.join(', ')} \
on @NearcastHQ for our offer: ${offerLink} \n ${postLinksOnPublicUser.join(' \n ')}`

  // const click = () => {
  //   navigator.clipboard.writeText(link)
  //   enqueueSnackbar('Link copied to clipboard', {
  //     variant: 'success'
  //   })
  // }

  const postOnFacebook = () => {
    const facebookParameters = []
    facebookParameters.push('u=' + encodeURI(offerLink))
    facebookParameters.push('quote=' + encodeURI(message))
    const url = 'https://www.facebook.com/sharer/sharer.php?' + facebookParameters.join('&')
    window.open(url, '_blank')
  }

  const postOnTwitter = () => {
    const newAnchorTag = document.createElement('a')
    newAnchorTag.className = 'twitter-share-button'
    newAnchorTag.href = `https://twitter.com/intent/tweet?text=${message}`
    newAnchorTag.target = '_blank'
    newAnchorTag.click()
  }

  return (
      <StandardModal
        isOpen={isOpen}
        onClose={onClose}
        title="Publish Review"
        buttons={[]}
      >
        <Stack
            direction="column"
            alignItems="flex-start"
            style={{
              width: '100%'
            }}
        >
          <Divider style={{ width: '100%' }} />
          <List
            style={{
              width: '100%',
              paddingBottom: 0
            }}
          >
            <ListItem
              sx={{
                marginTop: 2,
                marginBottom: 2,
                paddingRight: 0,
                paddingLeft: 0
              }}
              secondaryAction={
                <Button variant="outlined" onClick={() => postOnTwitter()}>
                  Publish
                </Button>
              }
            >
              <ListItemIcon >
                <Twitter style={{ fontSize: 40 }} htmlColor="#47acdf" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h4">
                    Twitter
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                marginTop: 2,
                // marginBottom: 2,
                paddingRight: 0,
                paddingLeft: 0
              }}
              secondaryAction={
                <Button variant="outlined" onClick={() => postOnFacebook()}>
                  Publish
                </Button>
              }
            >
              <ListItemIcon >
                <Facebook style={{ fontSize: 40 }} htmlColor="#0163e0" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h4">
                    Facebook
                  </Typography>
                }
              />
            </ListItem>
            {/* <ListItem
              sx={{
                marginTop: 2,
                marginBottom: 2,
                paddingRight: 0,
                paddingLeft: 0
              }}
              secondaryAction={
                <Button variant="outlined">
                  Publish
                </Button>
              }
            >
              <ListItemIcon >
                <Public style={{ fontSize: 40 }} htmlColor="#000" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h4">
                    Website
                  </Typography>
                }
              />
            </ListItem> */}
          </List>
        </Stack>
      </StandardModal>
  )
}

export default PublishModal
