import axios from 'axios'

/**
 * @function postDelete Used to delete a specific post of the user
 * @param {string} postId - the id of the post that needs to be deleted
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful post deletion
 */
export const postDelete = (postId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/post/delete/${postId}`, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(true))
      .catch((err) => {
        reject(new Error('Error: Unable to delete the post!'))
      })
  })
}
