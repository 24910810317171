import axios from 'axios'

export const businessCategories = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/categories`)
      .then((res) => resolve(res.data.body))
      .catch((err) => {
        reject(err)
      })
  })
}
