import * as React from 'react'
import { Stack } from '@mui/material'

const StarSvg = ({
  color
}:{
  color?: string;
}) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.58 0.75C19.2251 0.75 19.75 1.27494 19.75 1.92V19.08C19.75 19.7251 19.2251 20.25 18.58 20.25H1.42C0.77494 20.25 0.249999 19.7251 0.249999 19.08V1.92C0.249999 1.27494 0.77494 0.75 1.42 0.75H18.58Z" fill={color}/>
    <path d="M6.65484 14.8136L7.4119 10.7941L4.72914 7.79271L8.6272 7.51885L10.4776 3.95928L12.2116 7.64824L16.2234 8.41516L13.2291 11.0876L13.9341 14.9311L10.2281 12.9781L6.65484 14.8136Z" fill="white"/>
    </svg>
  )
}

StarSvg.defaultProps = {
  color: '#4A9449'
}

export interface RatingProps {
  value: number;
  maxValue: number;
  style?: any;
}

const Rating: React.FC<RatingProps> = (props) => {
  const colorSelector = () => {
    switch (Math.round(props.maxValue / props.value)) {
      case 1:
        return '#4a9449'
      case 2:
        return '#e1b73d'
      default:
        return 'red'
    }
  }
  return (
    <Stack direction="row">
      {new Array(props.value).fill(1).map((_, index) => {
        return (
          <div key={index} style={props.style}>
            <StarSvg
              color={colorSelector()}
            />
          </div>
        )
      })}
      {new Array(props.maxValue - props.value).fill(1).map((_, index) => {
        return (
          <div key={index} style={props.style}>
            <StarSvg
              color="#d9d9d9"
            />
          </div>
        )
      })}
    </Stack>
  )
}

Rating.defaultProps = {
  style: {}
}

export default Rating
