import {
  FormControl,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'
import {
  Facebook,
  Twitter,
  InsertLink
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import StandardModal from 'ui/Modals/StandardModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'

export interface ShareOfferModalProps {
  whatsBeingShared: string;
  whatsBeingSharedDescription: string;
  shareableLinkPath: string;
  overrideURL?: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const ShareOfferModal: React.FC<ShareOfferModalProps> = ({
  isOpen,
  onClose,
  whatsBeingShared,
  whatsBeingSharedDescription,
  shareableLinkPath,
  overrideURL
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const getShareableFullLink = (): string => {
    const origin = window.location.origin
    const baseURL = process.env.REACT_APP_SHARE_URL
    if (overrideURL) return overrideURL
    if (origin.includes('staging') || origin.includes('localhost')) {
      if (baseURL) {
        return `${baseURL}${shareableLinkPath}`
      } else {
        return `https://staging.nearcast.com${shareableLinkPath}`
      }
    } else {
      if (baseURL) {
        return `${baseURL}${shareableLinkPath}`
      } else {
        return `https://nearcast.com${shareableLinkPath}`
      }
    }
  }
  const link: string = getShareableFullLink()

  const click = () => {
    navigator.clipboard.writeText(link)
    enqueueSnackbar('Link copied to clipboard', {
      variant: 'success'
    })
  }

  const postOnFacebook = () => {
    const facebookParameters = []
    facebookParameters.push('u=' + encodeURI(link))
    facebookParameters.push('quote=' + encodeURI(`"${whatsBeingSharedDescription}"`))
    const url = 'https://www.facebook.com/sharer/sharer.php?' + facebookParameters.join('&')
    window.open(url, '_blank')
  }

  const postOnTwitter = () => {
    const newAnchorTag = document.createElement('a')
    newAnchorTag.className = 'twitter-share-button'
    newAnchorTag.href = `https://twitter.com/intent/tweet?text=${whatsBeingSharedDescription}&url=${link}`
    newAnchorTag.target = '_blank'
    newAnchorTag.click()
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Share ${whatsBeingShared}`}
    >
      <FormControl fullWidth margin="none">
        <PriceFieldsInput
          value={link}
          size="small"
        />
      </FormControl>
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Share it on:
      </Typography>
      <Stack direction="row" alignItems="flex-start" flexWrap="wrap">
        <IconButton
          style={styles.shareIconButtons}
          onClick={postOnFacebook}
        >
          <Facebook htmlColor="#000" />
        </IconButton>
        <IconButton
          style={styles.shareIconButtons}
          onClick={postOnTwitter}
        >
          <Twitter htmlColor="#000" />
        </IconButton>
        <IconButton style={styles.shareIconButtons} onClick={click}>
          <InsertLink htmlColor="#000" />
        </IconButton>
      </Stack>
    </StandardModal>
  )
}

const styles = {
  shareIconButtons: {
    border: 'solid 1px #dae1ed',
    width: 50,
    height: 50,
    marginRight: 8
  }
}

export default ShareOfferModal
