import axios from 'axios'

/**
 * @function businessReviewDownload Used to download a review for business
 * @param {string} reviewIds - review ids eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful call
 */
export const businessReviewDownload = (reviewIds, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business/download`, {
      id: reviewIds
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}

/**
 * @function businessReviewDownloadPro Used to download reviews for business
 * @param {string} reviewIds - review ids, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful call
 */
export const businessReviewDownloadPro = (reviewIds, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BUSINESS_URL}/business/download/pro`, {
      id: reviewIds
    }, {
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
