import axios from 'axios'
import React, {
  createContext,
  useEffect,
  useCallback,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { Chat } from 'stream-chat-react'
import { StreamChat as DefaultStreamChat } from 'stream-chat'

// importing redux helpers
import { useAppDispatch, useAppSelector } from '../Utils/redux/store'
import {
  changeActiveBusiness,
  login as reduxLogin,
  logout as reduxLogout,
  refreshUserProfiles,
  refreshUserTokens,
  updatePreferences
} from '../Utils/redux/actions/userActions'
import { cleanSubscriptionDetails, updateUserSubscriptionDetailsInRedux } from '../Utils/redux/actions/subscriptionAction'

// importing apis
import { axiosResponseInterceptorBuilder } from 'api'
import { fetchMyProfile } from 'api/fetch/my'
import { personalAccount } from 'api/personal_account/index'
import { tokenRefresh } from 'api/token/refresh'
// import { chatLogin } from 'api/chat/login'
import { personalAccountAvatar } from 'api/personal_account/avatar'
import { businessDetailsGet } from 'api/business/details'

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
  const reduxDispatcher = useAppDispatch()
  const userState = useAppSelector((state) => state.root.userState)
  const subscriptionState = useAppSelector((state) => state.root.subscriptionState)
  // state for chat
  const [isLoggedInChat, setIsLoggedInChat] = useState(false)
  const [wasLoggedInFor, setWasLoggedInFor] = useState('')

  const [cookies, setCookie, removeCookie] = useCookies([
    'refresh_token',
    'jwt_token'
  ])

  const detectDomain = () => window.location.hostname

  const login = useCallback(
    (data) => {
      if (!data.isNotFresh) {
        const loginData = {
          refToken: data.ref_token,
          jwtToken: data.jwt_token,
          user: data.user,
          accounts: data.accounts,
          userProfiles: data.userProfiles
        }
        reduxDispatcher(reduxLogin(loginData))
      } else {
        if (data.jwt_token) {
          reduxDispatcher(refreshUserTokens({
            jwtToken: data.jwt_token,
            refreshToken: data.ref_token
          }))
        }
        if (data.accounts) {
          reduxDispatcher(refreshUserProfiles({
            userProfiles: [],
            accounts: data.accounts
          }))
        }
      }
      const cookieOptions = {
        path: '/',
        domain: detectDomain(),
        samesite: 'lax'
      }
      if (data.ref_token) {
        setCookie('refresh_token', data.ref_token, cookieOptions)
      }
      if (data.jwt_token) {
        setCookie('jwt_token', data.jwt_token, cookieOptions)
      }
    },
    [setCookie]
  )

  const refreshTokenApi = useCallback(
    (orignalRequest, refresh_token) => {
      tokenRefresh(refresh_token)
        .then((resBody) => {
          login({
            jwt_token: resBody.jwtToken,
            ref_token: resBody.refreshToken,
            isNotFresh: true
          })
          return axios(orignalRequest)
        })
        .catch((error) => {
          return error
        })
    },
    [login]
  )

  const getProfile = (id, jwt_token) => {
    return new Promise((resolve, reject) => {
      fetchMyProfile(id, jwt_token)
        .then(async (resBody) => resolve(resBody))
        .catch((err) => {
          // console.log(err);
          reject(err)
        })
    })
  }

  const refreshProfilesApi = async () => {
    if (!userState.active_neighbourhood) {
      return setTimeout(refreshProfilesApi, [1000])
    }
    personalAccount(
      userState.personal[userState.activeBusiness].id,
      userState.jwtToken
    )
      .then(async (resData) => {
        const userProfile = await Promise.all(
          resData.pa.map(
            async (account, _) => await getProfile(account.ID, userState.jwtToken)
          )
        )
        login({
          accounts: resData.pa,
          userProfiles: userProfile,
          isNotFresh: true
        })
      })
      .catch((error) => {
        // console.log(error);
        return error
      })
  }

  const logout = () => {
    logoutOfChat()
    reduxDispatcher(reduxLogout())
    reduxDispatcher(cleanSubscriptionDetails())
    // clear out all localStorage set by context
      ['business', 'active_neighbourhood', 'user_profiles', 'user'].forEach(
        (t) => {
          localStorage.removeItem(t)
        }
      )
    const cookieNames = Object.keys(cookies)
    cookieNames.forEach((cookieName) => {
      removeCookie(cookieName, {
        path: '/',
        domain: detectDomain(),
        samesite: 'lax'
      })
    })
    window.location = '/'
  }

  const switchNeighbourHood = (neighbourhoodIdx) => {
    reduxDispatcher(changeActiveBusiness({ index: neighbourhoodIdx }))
  }

  const toggleVideoMuted = () => {
    reduxDispatcher(updatePreferences({
      videoMuted: userState.preferences ? !userState.preferences.videoMuted : false
    }))
  }

  useEffect(() => {
    refreshProfilesApi()
  }, [userState.jwtToken])

  useEffect(() => {
    const refreshToken = userState.refreshToken
    if (refreshToken === null) {
      return
    }
    axiosResponseInterceptorBuilder(
      (res) => {
        return res
      },
      (error) => {
        const orignalRequest = error.config
        if (
          error.response &&
          error.response.status === 401 &&
          orignalRequest.url ===
            `${process.env.REACT_APP_BACKEND_URL}/token/refresh`
        ) {
          logout()
          return Promise.reject(error)
        }
        if (
          error.response &&
          error.response.status === 401 &&
          orignalRequest.headers.Authorization &&
          !orignalRequest._retry
        ) {
          orignalRequest._retry = true
          return refreshTokenApi(orignalRequest, refreshToken)
        }
        return Promise.reject(error)
      }
    )
  }, [refreshTokenApi, userState.refreshToken])

  const client = DefaultStreamChat.getInstance(
    `${process.env.REACT_APP_GETSTREAM_KEY}`
  )

  /**
   * This currently updates only the Avatar whenever app opened.
   */
  const updateSelf = async () => {
    const selfId = userState.business[userState.activeBusiness].ID
    await client.partialUpdateUser({
      id: selfId,
      set: {
        image: personalAccountAvatar(selfId)
      }
    })
  }

  const makeReq = async () => {
    console.log('Logging In of Stream Chat')
    try {
      const token = await chatLogin(
        userState.business[userState.activeBusiness].ID,
        userState.jwtToken
      )
      let username = userState.business[userState.activeBusiness].username
      if (userState.business[userState.activeBusiness].is_business) {
        const businessDetails = await businessDetailsGet(
          userState.business[userState.activeBusiness].business_id,
          userState.business[userState.activeBusiness].ID,
          userState.jwtToken
        )
        username = businessDetails.body.business_details.name
      } else {
        const profile = await fetchMyProfile(
          userState.business[userState.activeBusiness].ID,
          userState.jwtToken
        )
        username = `${profile.first_name} ${profile.last_name}`
      }
      console.log('Get Stream Chat Username:', username)
      await client.connectUser(
        {
          id: userState.business[userState.activeBusiness].ID,
          name: username
        },
        token
      )
      await updateSelf()
      setIsLoggedInChat(true)
    } catch (e) {
      console.log(e.toString())
    }
  }

  const logoutOfChat = async () => {
    console.log('Logging Out of Stream Chat')
    try {
      await client.disconnectUser()
      setIsLoggedInChat(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    (async () => {
      if (userState.jwtToken.length !== 0 && userState.business.length !== 0) {
        if (wasLoggedInFor !== userState.business[userState.activeBusiness].ID && isLoggedInChat) {
          await logoutOfChat()
        }
        if (!isLoggedInChat) {
          // Login
          setWasLoggedInFor(userState.business[userState.activeBusiness].ID)
          await makeReq()
        }
      } else {
        if (isLoggedInChat) {
          logoutOfChat()
        }
      }
    })()
  }, [isLoggedInChat, userState.jwtToken, userState.activeBusiness, userState.business])

  useEffect(() => {
    if (userState.business.length !== 0) {
      reduxDispatcher(updateUserSubscriptionDetailsInRedux())
    }
  }, [userState.business])

  const value = {
    ...userState,
    preferences: {
      ...userState.preferences,
      toggleVideoMuted
    },
    chatConnected: isLoggedInChat,
    login,
    logout,
    switchNeighbourHood,
    refreshProfilesApi,
    getProfile
  }

  if (!subscriptionState || !subscriptionState.subscription.planDetails) {
    reduxDispatcher(cleanSubscriptionDetails())
    return null
  }

  return (
    <UserContext.Provider value={value}>
      {children}
      {/* <Chat client={client} theme='light' >
        {children}
      </Chat> */}
    </UserContext.Provider>
  )
}

export default UserContextProvider
