import * as React from 'react'
import ReactPlayer from 'react-player'
import { VolumeOff, VolumeUp } from '@mui/icons-material'

// importing components
import DraggableSticker from '../DraggableSticker/DraggableSticker'

export interface NearcastVideoPlayerVideoProps {
  source: string;
  muted: boolean;
  toggleMuted: () => void;
}

export interface NearcastVideoPlayerProps {
  type: 'preview' | 'post';
  video: NearcastVideoPlayerVideoProps;
  stickers?: any[];
  updateSticker?: (s: any, isDeleted: boolean) => void;
  screenDimensions?: {
    width: number;
    height: number;
  };
}

const NearcastVideoPlayer: React.FC<NearcastVideoPlayerProps> = (props) => {
  const { screenDimensions, type, stickers, updateSticker } = props
  return (
    <div
      style={{
        width: screenDimensions.width,
        height: screenDimensions.height,
        display: 'flex',
        border: '1px green solid',
        borderRadius: 8,
        background: 'black',
        position: 'relative'
      }}
    >
      {type === 'preview'
        ? (
            <video
              style={{ width: '100%', height: '100%' }}
              src={props.video.source}
              autoPlay
              controls
              loop
              muted={props.video.muted}
              playsInline
            />
          )
        : (
            <ReactPlayer
              url={props.video.source}
              loop={true}
              playing={true}
              volume={props.video.muted ? 0 : 1}
              width={screenDimensions.width}
              height={screenDimensions.height}
              style={{
                borderRadius: 8,
                backgroundColor: 'black',
                overflow: 'hidden'
              }}
            />
          )}
      <div id="stickers-overlay" style={styles.videoOverlay}>
        {
          stickers.map((sticker, index) => {
            return (
              <DraggableSticker
                key={`${index}`}
                sticker={sticker}
                updateSticker={updateSticker}
              />
            )
          })
        }
        {props.video.muted
          ? (
              <VolumeOff
                htmlColor="white"
                fontSize="large"
                style={{
                  padding: 10,
                  backgroundColor: '#00000040',
                  borderRadius: '30px',
                  cursor: 'pointer'
                }}
                onClick={() => props.video.toggleMuted()}
              />
            )
          : (
              <VolumeUp
                htmlColor="white"
                fontSize="large"
                style={{
                  padding: 10,
                  backgroundColor: '#00000040',
                  borderRadius: '30px',
                  cursor: 'pointer'
                }}
                onClick={() => props.video.toggleMuted()}
              />
            )}
      </div>
    </div>
  )
}

const styles = {
  videoOverlay: {
    zIndex: 99,
    position: 'absolute',
    color: 'white',
    height: '92%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
} as const

NearcastVideoPlayer.defaultProps = {
  stickers: [],
  updateSticker: (s, m) => {},
  screenDimensions: {
    width: 300,
    height: 600
  }
}

export default NearcastVideoPlayer
