import axios from 'axios'

export const businessAdminLogin = (id, passcode) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/signup/admin/business/login`, {
      params: {
        id: id,
        key: passcode
      }
    })
      .then((res) => resolve(res.data.body))
      .catch((err) => reject(err))
  })
}
