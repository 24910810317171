import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Nearcast from 'ui/Icons/Nearcast'

const style = makeStyles(({ breakpoints, palette }) => {
  return {
    sideBarElement: {
      display: 'flex',
      alignItems: 'center',
      margin: 5,
      marginTop: 10,
      marginLeft: 'auto',
      fontWeight: 500,
      maxWidth: 200,
      width: 'calc(100% - 50px)',
      [breakpoints.down('sm')]: {
        width: 170,
        margin: 12
      },
      padding: '10px 20px',
      borderRadius: 5,
      cursor: 'pointer',
      '&:hover': {
        background: '#F0F3F9'
      }
    },
    sideBarElementActive: {
      backgroundColor: palette.primary.light,
      color: 'white',
      '&:hover': {
        backgroundColor: `${palette.primary.light}de`
      }
    },
    sideBarElementInactive: {
      backgroundColor: '#F4F7FD',
      color: 'black',
      '&:hover': {
        backgroundColor: '#DAE1EF'
      }
    },
    iconDiv: {
      display: 'block',
      marginRight: '10px',
      width: '20px',
      paddingLeft: '5px',
      paddingTop: '0.5px'
    },
    iconDivActive: {
      filter: 'invert(1)'
    },
    drawer: {
      left: 0,
      top: 64,
      background: '#FFFFFF',
      zIndex: 1
    },
    root: {
      background: '#FFFFFF',
      // eslint-disable-next-line no-useless-computed-key
      [breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }
})

export function SidebarElement (props) {
  const classes = style()

  return (
    <div
      onClick={props.onClick}
      className={`${classes.sideBarElement} ${
        props.pathCheck
          ? classes.sideBarElementActive
          : classes.sideBarElementInactive
      }`}
      style={props.style}
    >
      {/* <div className={classes.iconDiv}>
        <img
          className={props.pathCheck ? classes.iconDivActive : null}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            margin: "auto",
          }}
          src={props.icon}
          alt="home"
        />
      </div> */}
      <div>{props.children}</div>
    </div>
  )
}

function NavPaths () {
  const location = useLocation()
  const history = useHistory()

  return (
    <>
      <SidebarElement
        onClick={() => history.push('/dashboard')}
        pathCheck={location.pathname === '/dashboard'}
        // icon={homeIcon}
      >
        Dashboard
      </SidebarElement>
      <SidebarElement
        onClick={() => history.push('/contacts')}
        pathCheck={location.pathname === '/contacts'}
        // icon={gatheringIcon}
      >
        Contacts
      </SidebarElement>
      <SidebarElement
        onClick={() => history.push('/offers')}
        pathCheck={
          location.pathname.startsWith('/offers') ||
          location.pathname.startsWith('/offer')
        }
        // icon={groupIcon}
      >
        Offers
      </SidebarElement>
    </>
  )
}

export function SidebarDrawer (props) {
  const classes = style()
  return (
    <div className={classes.drawer}>
      <Nearcast
        height={27}
        style={{
          display: 'block',
          alignSelf: 'center',
          marginInline: 'auto',
          marginTop: '30px',
          marginBottom: '30px'
        }}
        htmlColor={'#0C3A2D'}
      />
      <NavPaths />
    </div>
  )
}

function Sidebar (props) {
  const classes = style()

  return (
    <div className={classes.root + ' ' + props.className}>
      <NavPaths />
    </div>
  )
}

export default Sidebar
