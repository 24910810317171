import * as React from 'react'
import {
  Button,
  Stack,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress
} from '@mui/material'
import { Check } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useSnackbar } from 'notistack'
import { useLocation, useHistory } from 'react-router-dom'

// importing components
import LandingHeader from '../../Components/Headers/LandingHeader/LandingHeader'
import LandingFooter from '../../Components/Footers/LandingFooter/LandingFooter'
import { UserContext } from '../../Context/UserContext'
import { GlobalFuncContext } from '../../Context/GlobalFuncHOC'

// importing ui
import StandardModal from 'ui/Modals/StandardModal'
import ForgotPasswordModal from 'ui/Custom/Modals/ForgetPasswordModal/ForgotPasswordModal'

// importing helpers
import { getUserDomainURL } from 'helpers/url'
import { recordGaEvent } from 'helpers/ga'

// importing api
import { businessAdminLogin } from 'api/business/adminLogin'

// styled components
const FullWidthMainPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

// constant data
const howItWorks = [
  {
    title: 'Customers create video feedback',
    description: 'Customer scans the QR code placed by the business and creates a review video sharing their experience.',
    image: require('./assets/howItWorks1.png')
  },
  {
    title: 'Watch customer feedback',
    description: 'You watch the video review created by your customers on your Nearcast Business page.',
    image: require('./assets/howItWorks2.png')
  },
  {
    title: 'Publish it on social media',
    description: 'Publish the best videos to your social media handles with \'1-click\' or download the videos.',
    image: require('./assets/howItWorks3.png')
  }
]

const pricing = [
  {
    title: 'Growth',
    description: 'Hide negative reviews, download videos wihout watermarks, extended limits and better analytics.',
    cost: 99,
    discount: '36%',
    everythingIn: null,
    included: [
      'Hide 5 negative reviews',
      'Download 5 videos without our watermark',
      'Create and share 10 offers',
      '3 organic customer reviews assured',
      'Access to 200 latest reviews',
      'Send 10,000 bulk emails',
      'Earn 30 Nearcoins',
      'Advance analytics',
      'Priority support (online)'
    ],
    theme: 'light'
  },
  {
    title: 'Enterprise',
    description: 'Everything included in Pro, plus $150 credit for social media promotion, custom ads to promote your business, and other tailor-made services for your business.',
    cost: 299,
    discount: 0,
    everythingIn: 'Growth, Pro',
    included: [
      'Unlimited customer feedback videos',
      'Unlimited responses to customer reviews',
      'Download unlimited videos with our watermark',
      'Create and share unlimited offers',
      'Access to all latest reviews',
      'Send bulk emails',
      'Expert analytics',
      'Priority Support'
    ],
    theme: 'dark'
  },
  {
    title: 'Pro',
    description: 'Extended limits to leverage the most of Nearcast for mid-sized teams.',
    cost: 199,
    discount: '31%',
    everythingIn: 'Growth',
    included: [
      'Hide 15 negative reviews',
      'Download 15 videos without our watermark',
      'Create and share 30 offers',
      '7 organic customer reviews assured',
      'Access to 500 latest reviews',
      'Send 50,000 bulk emails',
      'Earn 50 Nearcoins',
      'Premium analytics',
      'Priority support (online + in-person)'
    ],
    theme: 'light'
  }
]

const Landing = () => {
  const priceSectionRef = React.useRef(null)
  const aboutSectionRef = React.useRef(null)
  const location = useLocation()
  const history = useHistory()
  const { login, jwtToken } = React.useContext(UserContext)
  const { openAuthModal } = React.useContext(GlobalFuncContext)
  const { enqueueSnackbar } = useSnackbar()

  // component states
  // only if admin-login
  const adminLoginDetailsInitial = {
    id: null,
    passcode: null
  }
  const [adminLoginDetails, setAdminLoginDetails] = React.useState(adminLoginDetailsInitial)
  // state for reset password
  const [isResetPassword, setIsResetPassword] = React.useState<string | false>(false)
  // state for auto signUp email campaign
  const autoSignUpInitial = {
    isAuto: false,
    businessName: ''
  }
  const [autoSignUpState, setAutoSignUpState] = React.useState(autoSignUpInitial)

  React.useEffect(() => {
    // for admin auto login on /admin-login*
    const id = new URLSearchParams(location.search).get('id')
    const passcode = new URLSearchParams(location.search).get('passcode')
    if (id && passcode) {
      setAdminLoginDetails({
        id,
        passcode
      })
      businessAdminLogin(id, passcode)
        .then((resData) => {
          login({
            jwt_token: resData.jwtToken,
            ref_token: resData.refreshToken,
            accounts: resData.personal_accounts,
            user: resData.user
          })
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar('Could not login, please try again!')
        })
    } else {
      setAdminLoginDetails(adminLoginDetailsInitial)
    }
  }, [location])

  React.useEffect(() => {
    const resetToken: string = new URLSearchParams(location.search).get('token')
    // if it is forgot password flow we open the reset password
    // and return so that we are not send to home by navigate call
    if (resetToken) {
      setIsResetPassword(resetToken)
      return
    }
    if (jwtToken) {
      history.push('/dashboard')
    }
  }, [location, jwtToken])

  React.useEffect(() => {
    if (location.pathname === '/login') {
      return openAuthModal(true)
    }
    if (location.pathname === '/signup') {
      return openAuthModal(false)
    }
    if (location.pathname === '/businesslogin') {
      const businessName = new URLSearchParams(location.search).get('name')
      setAutoSignUpState({
        isAuto: businessName.length > 0,
        businessName
      })

      // analytics for business campaign
      const autoSignUpData = {}
      for (const [key, value] of new URLSearchParams(location.search).entries()) {
        autoSignUpData[key] = value
      }
      return recordGaEvent('businessLandingCampaignLinkOpened', autoSignUpData)
    }
  }, [location])

  return (
    <FullWidthMainPageContainer>
      <LandingHeader
        leftAction={[
          <Button
            key="1"
            variant="text"
            sx={{
              color: 'black',
              fontSize: '14px'
            }}
            size="large"
            onClick={() => {
              window.open(getUserDomainURL('/'), '_blank')
            }}
          >
            For Users
          </Button>,
          <Button
          key="2"
          variant="text"
          sx={{
            color: 'black',
            fontSize: '14px'
          }}
          size="large"
          onClick={() => {
            window.open('https://docs.google.com/document/d/1nBrpdkouZPPsOpdpEFQdNf_My9JT2YBzXltMDad6KGk/edit', '_blank')
          }}
        >
          Whitepaper
        </Button>,
          <Button
            key="3"
            variant="text"
            sx={{
              color: 'black',
              fontSize: '14px'
            }}
            size="large"
            onClick={() => {
              window.scrollTo({
                top: priceSectionRef.current.offsetTop,
                behavior: 'smooth'
              })
            }}
          >
            Pricing
          </Button>,
          <Button
            key="4"
            variant="text"
            sx={{
              color: 'black',
              fontSize: '14px'
            }}
            size="large"
            onClick={() => {
              window.scrollTo({
                top: aboutSectionRef.current.offsetTop,
                behavior: 'smooth'
              })
            }}
          >
            About
          </Button>
        ]}
        rightActions={[
          <Button
            key="2"
            variant="contained"
            color="primary"
            sx={{
              fontSize: '14px',
              marginLeft: 1
            }}
            size="small"
            onClick={() => {
              // open login modal
              openAuthModal(true)
              // analytics
              recordGaEvent('businessLandingLoginClicked')
              // analytics for business campaign
              if (autoSignUpState.isAuto) {
                const autoSignUpData = {}
                for (const [key, value] of new URLSearchParams(location.search).entries()) {
                  autoSignUpData[key] = value
                }
                recordGaEvent('businessLandingLoginClickedCampaign', autoSignUpData)
              }
            }}
            disableElevation
          >
            Login
          </Button>,
          <Button
            key="1"
            variant="contained"
            color="secondary"
            sx={{
              fontSize: '14px'
            }}
            size="small"
            disableElevation
            onClick={() => {
              // open signUp modal
              openAuthModal(false)
              // analytics
              recordGaEvent('businessLandingSignUpClicked')
              // analytics for business campaign
              if (autoSignUpState.isAuto) {
                const autoSignUpData = {}
                for (const [key, value] of new URLSearchParams(location.search).entries()) {
                  autoSignUpData[key] = value
                }
                recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
              }
            }}
          >
            Start Now
          </Button>
        ]}
      />
      <Stack
        direction={{
          xs: 'column-reverse',
          md: 'row'
        }}
        sx={{
          width: '100vw',
          height: '100vh'
        }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent={{
            xs: 'flex-end',
            md: 'center'
          }}
          sx={{
            width: {
              xs: '100vw',
              md: '50vw'
            },
            height: {
              xs: '100vh',
              md: '100%'
            },
            position: {
              xs: 'absolute',
              md: 'relative'
            },
            background: {
              xs: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 45.47%, rgba(0, 0, 0, 0.6) 100%), url(pexels-roman-odintsov-4552130.jpg)',
              md: 'linear-gradient(151.08deg, #30987A 3.85%, #1A6751 96.19%)'
            }
          }}
        >
          <Typography
            variant="h1"
            component="span"
            textAlign="left"
            sx={{
              marginLeft: {
                xs: 2,
                md: 11
              },
              marginRight: {
                xs: 2,
                md: 11
              },
              color: 'white',
              fontSize: {
                xs: '28px',
                md: '46px'
              },
              fontWeight: '700'
            }}
          >
            Increase {autoSignUpState.isAuto
            ? (
                <Typography
                  variant="h1"
                  component="span"
                  sx={(theme) => ({
                    marginLeft: 0.6,
                    color: '#ffd700',
                    fontSize: {
                      xs: '28px',
                      md: '46px'
                    },
                    fontWeight: '700'
                  })}
                >
                  {autoSignUpState.businessName}'s
                </Typography>
              )
            : 'your business'}<br/>growth with<br/>video
            <Typography
              variant="h1"
              component="span"
              sx={{
                marginLeft: 1,
                color: '#ffd700',
                fontSize: {
                  xs: '28px',
                  md: '46px'
                },
                fontWeight: '700'
              }}
            >
              reviews.
            </Typography>
          </Typography>
          <Typography
            variant="h5"
            component="span"
            textAlign="left"
            sx={{
              marginLeft: {
                xs: 2,
                md: 11
              },
              marginRight: {
                xs: 2,
                md: 11
              },
              marginTop: {
                xs: 2,
                md: 4
              },
              color: 'white',
              fontWeight: '500'
            }}
          >
            Instead of relying on faceless, typed-out text,
            Nearcast helps you get video reviews for your business.
          </Typography>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              marginBottom: {
                xs: 12,
                md: 0
              }
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                // open signUp modal
                openAuthModal(false)
                // analytics
                recordGaEvent('businessLandingSignUpClicked')
                // analytics for business campaign
                if (autoSignUpState.isAuto) {
                  const autoSignUpData = {}
                  for (const [key, value] of new URLSearchParams(location.search).entries()) {
                    autoSignUpData[key] = value
                  }
                  recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
                }
              }}
              size="large"
              sx={{
                marginLeft: {
                  xs: 2,
                  md: 11
                },
                marginTop: {
                  xs: 2,
                  md: 4
                },
                fontSize: 16,
                padding: '16px 32px'
              }}
              disableElevation
            >
              Start Now
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: {
              xs: '100vw',
              md: '50vw'
            },
            height: {
              xs: '100vh',
              md: '100%'
            }
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={require('./assets/mobileReview.png')}
          />
        </Box>
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100vw',
          paddingTop: 10,
          paddingBottom: 10
        }}
        spacing={4}
      >
        <Typography color="secondary" variant="h4" sx={{ fontWeight: '700' }}>
          How it Works?
        </Typography>
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            fontWeight: '700',
            fontSize: {
              xs: '18px',
              md: '25px',
              lg: '36px'
            },
            width: {
              xs: '80%',
              md: '60%'
            }
          }}
        >
          3 simple steps to get video feedback and share them on your social handles.
        </Typography>
        <Stack
          direction="column"
          alignItems={{
            xs: 'flex-start',
            lg: 'center'
          }}
          justifyContent="center"
          sx={{
            overflowX: {
              xs: 'auto',
              md: 'none'
            },
            width: '100vw'
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
            sx={{
              marginLeft: {
                xs: '1.4rem',
                lg: 0
              },
              marginRight: {
                xs: '1.4rem',
                lg: 0
              },
              paddingTop: '10px',
              paddingBottom: '10px'
            }}
          >
            {howItWorks.map((workStep, index) => {
              return (
                <Card
                  key={workStep.title}
                  sx={{
                    background: 'linear-gradient(151.08deg, #30987A 3.85%, #1A6751 96.19%)',
                    color: 'white',
                    width: {
                      xs: '300px',
                      md: '380px'
                    },
                    height: '400px',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      transition: 'transform 0.5s ease'
                    },
                    zIndex: 9
                  }}
                >
                  <CardContent>
                    <CardMedia
                      component="img"
                      height="220"
                      image={workStep.image}
                      alt="How it works image"
                      sx={(theme) => ({
                        borderRadius: theme.shape.borderRadius
                      })}
                    />
                    <Typography variant="h1" sx={{ fontWeight: 700, marginTop: 3 }}>
                      0{index + 1}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 0 }}>
                      {workStep.title}
                    </Typography>
                    <Typography variant="caption" component="div" sx={{ lineHeight: 1.2 }} style={{ marginTop: 5 }}>
                      {workStep.description}
                    </Typography>
                  </CardContent>
                </Card>
              )
            })}
          </Stack>
        </Stack>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            // open signUp modal
            openAuthModal(false)
            // analytics
            recordGaEvent('businessLandingSignUpClicked')
            // analytics for business campaign
            if (autoSignUpState.isAuto) {
              const autoSignUpData = {}
              for (const [key, value] of new URLSearchParams(location.search).entries()) {
                autoSignUpData[key] = value
              }
              recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
            }
          }}
          disableElevation
        >
          Start My Trial
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          width: '100vw',
          background: '#1A6751'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: {
              xs: 2,
              md: 12
            },
            paddingRight: {
              xs: 2,
              md: 12
            },
            paddingTop: 12,
            paddingBottom: 12,
            width: '100%'
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row'
            }}
            alignItems="center"
            justifyContent="center"
            spacing={{
              xs: 4,
              md: 8
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                  borderRadius: 8
                },
                minWidth: '300px',
                maxWidth: '500px'
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 'auto'
                }}
                src={require('./assets/growth.png')}
              />
            </Box>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                color: 'white'
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700
                }}
              >
                Boost Your Sales
              </Typography>
              <Typography
                variant="body2"
              >
                Video reviews have more impact than text reviews.
              </Typography>
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row'
                }}
                alignItems={{
                  xs: 'center',
                  md: 'flex-start'
                }}
                justifyContent="flex-start"
                sx={{
                  marginTop: 4,
                  width: '100%',
                  position: 'relative'
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    width: {
                      xs: '90%',
                      md: '36%'
                    },
                    padding: 1,
                    border: '1px solid #BDBDBD',
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h1" sx={{ fontWeight: 700 }}>
                    88%
                  </Typography>
                  <Typography variant="caption" sx={{ marginTop: 1 }}>
                    Customers trust video testimonials as much as personalised recommendations.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: {
                      xs: '90%',
                      md: '36%'
                    },
                    padding: 1,
                    border: '1px solid #BDBDBD',
                    borderRadius: 1
                  }}
                >
                  <Typography variant="h1" sx={{ fontWeight: 700 }}>
                    90%
                  </Typography>
                  <Typography variant="caption" sx={{ marginTop: 1 }}>
                    Customers say that positive reviews influenced their buying decisions.
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={{
              xs: 'column-reverse',
              sm: 'row'
            }}
            alignItems="center"
            justifyContent="center"
            spacing={{
              xs: 4,
              md: 8
            }}
            sx={{
              marginTop: 10
            }}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                color: 'white'
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700
                }}
              >
                Reach your neighbors easily
              </Typography>
              <Typography
                variant="body2" style={{ marginTop: 4 }}
              >
                Anything you post goes to everyone in your city on the Nearcast app. You can create videos to promote local events, services, and ventures.
              </Typography>
            </Stack>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '50%'
                },
                minWidth: '300px',
                maxWidth: '500px'
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 16
                }}
                src={require('./assets/businessOpen.png')}
              />
            </Box>
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row'
            }}
            alignItems="center"
            justifyContent="center"
            spacing={{
              xs: 4,
              md: 8
            }}
            sx={{
              marginTop: 10
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '50%'
                },
                minWidth: '300px',
                maxWidth: '500px'
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 8
                }}
                src={require('./assets/payment.png')}
              />
            </Box>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{
                color: 'white'
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700
                }}
              >
                Respond to customer feedback with personalized videos
              </Typography>
              <Typography
                variant="body2" style={{ marginTop: 4 }}
              >
                Customers love it when their feedback is taken seriously and acknowledged. Make your customers feel valued and happy by creating videos that reflect their feedback.
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              // open signUp modal
              openAuthModal(false)
              // analytics
              recordGaEvent('businessLandingSignUpClicked')
              // analytics for business campaign
              if (autoSignUpState.isAuto) {
                const autoSignUpData = {}
                for (const [key, value] of new URLSearchParams(location.search).entries()) {
                  autoSignUpData[key] = value
                }
                recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
              }
            }}
            sx={{
              marginTop: 8
            }}
            disableElevation
          >
            Start My Trial
          </Button>
        </Box>
      </Stack>
      <Stack
        ref={priceSectionRef}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100vw',
          paddingTop: 10,
          paddingBottom: 10
        }}
        spacing={4}
      >
        <Typography color="secondary" variant="h4" sx={{ fontWeight: '700' }}>
          Pricing
        </Typography>
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            fontWeight: '700',
            fontSize: {
              xs: '18px',
              md: '25px',
              lg: '36px'
            },
            width: {
              xs: '80%',
              md: '60%'
            }
          }}
        >
          Plans for all types of business needs
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row'
          }}
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={4}
        >
          {pricing.map((price, index) => {
            const background = price.theme === 'light' ? 'white' : 'linear-gradient(151.08deg, #30987A 3.85%, #1A6751 96.19%)'
            const color = price.theme === 'light' ? 'black' : 'white'
            return (
              <Stack
                key={price.title}
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                {index === 1 && (
                  <Box
                    sx={{
                      width: '220px',
                      background: 'linear-gradient(94.21deg, #FFB245 8.28%, #E8931B 94.7%)',
                      borderRadius: 2,
                      padding: 1,
                      position: 'absolute',
                      zIndex: -1
                    }}
                  >
                    <Typography textAlign="center" variant="body2" sx={{ width: '100%' }} >
                      Most Popular
                    </Typography>
                  </Box>
                )}
                <Card
                  sx={{
                    background: background,
                    color: color,
                    width: '300px',
                    border: '1px solid #A6A6A6',
                    marginTop: 4
                  }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      height: '320px',
                      borderBottom: '1px solid #A6A6A6'
                    }}
                  >
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      sx={{
                        height: '100%'
                      }}
                    >
                      <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h3">
                          {price.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            lineHeight: 1.2,
                            marginTop: 2,
                            fontSize: '14px'

                          }}
                        >
                          {price.description}
                        </Typography>
                        {price.cost === 0 && (
                          <Typography variant="h2" sx={{ marginTop: 4 }}>
                            Starter
                          </Typography>
                        )}
                        {price.cost !== 0 && (
                          <Typography variant="h2" component="div" sx={{ marginTop: 4 }}>
                            ${price.cost}
                            <Typography variant="caption" component="span">
                              /month
                            </Typography>
                          </Typography>
                        )}
                        {price.discount !== 0 && (
                          <Typography
                            variant="caption"
                            component="div"
                            color="secondary"
                            sx={(theme) => ({
                              color: price.theme === 'light' ? theme.palette.secondary.main : '#FFB245'
                            })}
                          >
                            {price.discount} discounted price
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: '100%'
                        }}
                      >
                        <Button
                          variant="contained"
                          color={price.theme === 'light' ? 'primary' : 'secondary'}
                          onClick={() => {
                            // open signUp modal
                            openAuthModal(false)
                            // analytics
                            recordGaEvent('businessLandingSignUpClicked', {
                              plan: price.title
                            })
                            // analytics for business campaign
                            if (autoSignUpState.isAuto) {
                              const autoSignUpData = {
                                plan: price.title
                              }
                              for (const [key, value] of new URLSearchParams(location.search).entries()) {
                                autoSignUpData[key] = value
                              }
                              recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
                            }
                          }}
                          disableElevation
                          sx={{
                            width: '100%'
                          }}
                        >
                          Start Now
                        </Button>
                        <Typography
                          variant="caption"
                          component="div"
                          textAlign="center"
                          sx={{
                            width: '100%',
                            color: price.theme === 'light' ? '#828282' : '#e0e0e0'
                          }}
                          style={{ marginTop: 10 }}
                        >
                          Free 14 days trial
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                  <CardContent>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      {price.everythingIn ? `Everything in ${price.everythingIn}, plus:` : 'Whats included:'}
                    </Typography>
                    {price.included.map((include) => {
                      return (
                        <Stack key={include} direction="row" alignItems="center" justifyContent="flex-start" sx={{ marginTop: 1 }}>
                          <Check />
                          <Typography variant="caption" sx={{ marginLeft: 1, fontSize: '14px' }}>
                            {include}
                          </Typography>
                        </Stack>
                      )
                    })}
                  </CardContent>
                </Card>
              </Stack>
            )
          })}
        </Stack>
        {/* <Card
          sx={{
            width: {
              xs: '300px',
              md: '440px'
            },
            border: '1px solid #A6A6A6',
            marginTop: 4
          }}
          elevation={0}
        >
          <CardContent
            sx={{
              borderBottom: '1px solid #A6A6A6'
            }}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{
                height: '100%'
              }}
            >
              <Box>
                <Typography variant="h3">
                  Enterprise
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    lineHeight: 1.2,
                    marginTop: 1,
                    fontSize: '14px'

                  }}
                >
                  Everything included in Pro, plus $150 credit for social media promotion, custom ads to promote your business, and other tailor-made services for your business.
                </Typography>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{
                  width: '100%',
                  marginTop: 2
                }}
              >
                <div>
                  <Typography variant="caption" component="div">
                    Starting From
                  </Typography>
                  <Typography variant="h2" component="div">
                    $299
                    <Typography variant="caption" component="span">
                      /mo
                    </Typography>
                  </Typography>
                </div>
                <Box
                  sx={{
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // open signUp modal
                      openAuthModal(false)
                      // analytics
                      recordGaEvent('businessLandingSignUpClicked', {
                        plan: 'Enterprise'
                      })
                      // analytics for business campaign
                      if (autoSignUpState.isAuto) {
                        const autoSignUpData = {
                          plan: 'Enterprise'
                        }
                        for (const [key, value] of new URLSearchParams(location.search).entries()) {
                          autoSignUpData[key] = value
                        }
                        recordGaEvent('businessLandingSignUpClickedCampaign', autoSignUpData)
                      }
                    }}
                    disableElevation
                    sx={{
                      width: '100%'
                    }}
                  >
                    Start Now
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card> */}
      </Stack>
      <LandingFooter aboutRef={aboutSectionRef} />
      {/* For admin auto-login  */}
      <StandardModal
        isOpen={adminLoginDetails.id !== null}
        onClose={() => { }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
          <Typography variant="h4" style={{ marginTop: 16 }}>
            Login in Admin
          </Typography>
        </Stack>
      </StandardModal>
      {/* For resetting password from email  */}
      {Boolean(isResetPassword) && (
        <ForgotPasswordModal
          open={Boolean(isResetPassword)}
          onClose={() => {
            setIsResetPassword(false)
            history.push('/')
          }}
          hasResetToken={isResetPassword}
          navigate={(route) => history.push(route)}
        />
      )}
    </FullWidthMainPageContainer>
  )
}

export default Landing
