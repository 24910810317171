import axios from 'axios'

/**
 * @function login used to login a user
 * @param {string} token - login token
 * @param {string} reviewId - (optional) review Id to assign user too
 * @returns {Promise} Promise object represents
 */
export const login = (token, reviewId = null) => {
  const data = {
    id_token: token
  }

  if (reviewId !== null) {
    data.review_id = reviewId // review id for: review offer flow -> login or signup
  }
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, data)
      .then((res) => {
        if (res.data) {
          resolve(res.data.body)
        } else {
          throw new Error('Unable to login')
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
