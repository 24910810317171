import React from 'react'
import { Card, CardActionArea, CardMedia, CardContent, Stack, Avatar, Typography } from '@mui/material'
import { DoneRounded } from '@mui/icons-material'

export interface NearcastCardProps {
  author: string;
  authorCover: string;
  postCover: string;
  postCaption: string;
  onPressCard?: () => void;
  onPressSelect?: () => void;
  isSelected?: boolean;
  style?: any;
}

const NearcastCard: React.FC<NearcastCardProps> = (props) => {
  const [hover, setHover] = React.useState(false)

  const cardDimensions = {
    width: 154,
    height: 270
  }

  return (
    <Card
      sx={{
        marginRight: 1,
        marginBottom: 1,
        ...cardDimensions
      }}
      style={{
        borderRadius: 8,
        position: 'relative',
        ...props.style
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.onPressSelect && (
        <CardActionArea
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: 24,
            height: 24,
            border: 'solid 1.5px #fff',
            borderRadius: '50%',
            zIndex: 30,
            backgroundColor: props.isSelected ? '#65db2e' : 'transparent'
          }}
          onClick={props.onPressSelect}
        >
          {props.isSelected && (
            <DoneRounded
              style={{
                color: 'white'
              }}
            />
          )}
        </CardActionArea>
      )}
      <CardActionArea onClick={props.onPressCard ? props.onPressCard : () => {}}>
        <CardContent
          style={{
            position: 'absolute',
            display: hover ? 'flex' : 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 24
          }}
          sx={{
            ...cardDimensions
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Avatar
              style={{
                width: 22,
                height: 22
              }}
              src={props.authorCover}
            />
            <Typography variant='caption' style={{ color: 'white', marginLeft: 8 }}>
              {props.author}
            </Typography>
          </Stack>
          <Typography variant='caption' style={{ color: 'white', marginTop: 4 }}>
            {props.postCaption.slice(0, 40)}
          </Typography>
        </CardContent>
        <CardMedia
          image={props.postCover}
          sx={{
            ...cardDimensions
          }}
          style={{
            zIndex: 12
          }}
        />
      </CardActionArea>
    </Card>
  )
}

export default React.memo(NearcastCard)
