import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as dayjs from 'dayjs'
import React from 'react'

function EmailPreview ({ offerData, mailData }) {
  const classes = styles()

  const getClaimUrl = () => {
    const origin = window.location.origin
    if (origin.includes('staging') || origin.includes('localhost')) {
      return `https://staging.nearcast.com/offer/${offerData.in_store_redemption}`
    } else {
      return `https://nearcast.com/offer/${offerData.in_store_redemption}`
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {mailData.subject ? mailData.subject : '[Enter a subject]'}
      </div>
      <div
        className={classes.imgPreview}
        style={{
          backgroundImage: `url(${offerData.cover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      ></div>
      <a href={getClaimUrl()}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
        >
          CLAIM OFFER
        </Button>
      </a>
      <div className={classes.desContainer}>
        <div className={classes.description}>
          {mailData.body ? mailData.body : '[Body goes here]'}
        </div>
        <div className={classes.disclosure}>
          Please print or show coupon on your phone at the location.
        </div>
        <div className={classes.expiry}>
          Expires on: {dayjs(offerData.end_date).format('MMMM DD, YYYY')}
        </div>
      </div>
    </div>
  )
}

export default EmailPreview

const styles = makeStyles({
  root: {
    boxShadow: '0px 4px 18px 0 rgba(25, 1, 52, 0.12)',
    borderRadius: 8,
    padding: 12,
    width: '100%',
    maxWidth: 280,
    margin: '10px auto 20px auto',
    '@media (max-width: 900px)': {
      maxWidth: 320
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 2,
    color: '#272727'
  },
  imgPreview: {
    width: '100%',
    height: 130,
    borderRadius: 6
  },
  actionBtn: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 500,
    marginTop: 10
  },
  desContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px 0'
  },
  description: {
    textAlign: 'center',
    color: '#272727',
    marginBottom: 15
  },
  disclosure: {
    color: '#272727',
    fontSize: 10,
    marginBottom: 15
  },
  expiry: {
    textAlign: 'center',
    color: '#272727',
    fontSize: 10,
    fontWeight: 500
  }
})
