import axios from 'axios'

/**
 * @function postView Used to track post views
 * @param {string} postId - post's id, eg: "qwe....948ijg"
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @returns {Promise} Promise object represents true for successful call
 */
export const postView = (postId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/post/view`, {
      params: {
        postID: postId
      },
      headers: {
        'NRCT-UID': nrctUID,
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(() => resolve(true))
      .catch((err) => reject(err))
  })
}
