import axios from 'axios'
import { alsoPublicRouteHeadersFormat } from '../helpers'

export const businessDetailsGet = (bizId, nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_BUSINESS_URL}/business/details?business_id=${bizId}`,
      {
        headers: alsoPublicRouteHeadersFormat(nrctUID, jwt)
      }
    )
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
