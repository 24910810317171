import axios from 'axios'

/**
 * @function userPersonalAccountAdd Used add a new neighborhood for the current user
 * @param {string} address - object of the place
 * @param {string} nrctUID - personal[active_neighbourhood].ID from user context
 * @param {string} jwt - jwt token from user context
 * @param {boolean} isBusiness - (optional) if sent would register a business account
 * @param {Object} business - (optional) is required if isBusiness is true
 * @returns {Promise} Promise object represents true for success
 */
export const userPersonalAccountAdd = (
  address,
  nrctUID,
  jwt,
  isBusiness = false,
  business = null
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/personal_account/add`,
        {
          address: address,
          is_business: isBusiness,
          business: business
        },
        {
          headers: {
            'NRCT-UID': nrctUID,
            Authorization: `Bearer ${jwt}`
          }
        }
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response.data.msg) {
          return reject(new Error(err.response.data.msg))
        }
        reject(err)
      })
  })
}
