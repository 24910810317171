import { Button, Divider, FormControl, Grid, Typography } from '@mui/material'
import firebase from 'firebase'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// importing ui
import Dialog from 'ui/Dialog/Dialog'
import StandardModal from 'ui/Modals/StandardModal'
import PriceFieldsInput from 'ui/TextInputs/PriceFieldsInput'

// importing components
import '../../../firebase'
import AppleSignin from '../OAuth/AppleSignin'
import EmailSignup from '../OAuth/EmailMixed'
import FacebookSignin from '../OAuth/FacebookSignin'
import GoogleSignin from '../OAuth/GoogleSignin'

// importing ui
import ContinueWithEmail from 'ui/Custom/Auth/ContinueWithEmail'
import ForgotPasswordModal from 'ui/Custom/Modals/ForgetPasswordModal/ForgotPasswordModal'

export const getProviderForProviderId = (id) => {
  if (id === 'google.com') {
    return new firebase.auth.GoogleAuthProvider()
  } else if (id === 'apple.com') {
    return new firebase.auth.OAuthProvider('apple.com')
  } else if (id === 'facebook.com') {
    return new firebase.auth.FacebookAuthProvider()
  }
  return new firebase.auth.GoogleAuthProvider()
}

function LoginModal ({
  isOpen,
  onClose,
  onSuccess,
  isLogin,
  children,
  showBack,
  onBack
}) {
  const history = useHistory()
  const [chosenEmail, setChosenEmail] = useState(false)
  const [alreadyHasAccount, setAlreadyHasAccount] = useState(null)
  const [askUserForPassword, setAskUserForPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  // state for forgot password modal
  const [isForgotOpen, setIsForgotOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const errorFunction = (error) => {
    if (error?.code === 'auth/account-exists-with-different-credential') {
      const pendingCred = error.credential
      const email = error.email
      setAlreadyHasAccount({
        pendingCred,
        email
      })
      firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then((methods) => {
          if (methods[0] === 'password') {
            setAskUserForPassword(true)
            return
          }
          setOpenDialog(methods[0])
        })
    } else {
      enqueueSnackbar('Oops something went wrong! Please try again.', {
        variant: 'error'
      })
    }
  }

  return (
    <>
      <StandardModal
        isOpen={isOpen}
        onClose={onClose}
        title={isLogin ? 'Login' : 'Let’s get in'}
        isTitleBack={chosenEmail || showBack}
        onPressTitleBack={() => {
          if (chosenEmail) {
            setChosenEmail(false)
          } else {
            onBack()
          }
        }}
      >
      <Divider style={{ marginTop: 3 }} />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
          style={{
            padding: '30px 24px 10px 24px'
          }}
        >
          {!chosenEmail && (
            <>
              <Grid item>
                <FormControl fullWidth>
                  <GoogleSignin onSuccess={onSuccess} onError={errorFunction} />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <FacebookSignin
                    onSuccess={onSuccess}
                    onError={errorFunction}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <AppleSignin onSuccess={onSuccess} onError={errorFunction} />
                </FormControl>
              </Grid>
            </>
          )}
          <ContinueWithEmail
            onSuccess={onSuccess}
            chosen={chosenEmail}
            setChosen={setChosenEmail}
            isLogin={isLogin}
            onError={errorFunction}
            firebase={firebase}
          />
          { chosenEmail && isLogin && (
            <Typography
              variant='caption'
              textAlign='right'
              style={{
                color: '#0080dc',
                cursor: 'pointer',
                marginTop: 10
              }}
              onClick={() => setIsForgotOpen(true)}
            >
              Forgot your password?
            </Typography>
          ) }
        </Grid>
        {children}
      </StandardModal>
      <StandardModal
        isOpen={askUserForPassword}
        ocClose={() => setAskUserForPassword(false)}
        title="Login to Existing Account"
      >
        <Typography variant="h6">
          We detected you already have an account with{' '}
          {alreadyHasAccount?.email} email. If you would like to continue please
          type your password below so we can connect your accounts.
        </Typography>
        <FormControl fullWidth margin="normal">
          <PriceFieldsInput
            label="Password"
            placeholder="Type your account password here"
            value={password}
            inputProps={{ maxLength: '63' }}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            style={{
              backgroundColor: '#f2f2f2',
              borderRadius: 10
            }}
          />
          <Button
            style={{
              marginTop: 10
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              firebase
                .auth()
                .signInWithEmailAndPassword(alreadyHasAccount.email, password)
                .then((user) =>
                  user.linkWithCredential(alreadyHasAccount.pendingCred)
                )
                .then(() => {
                  firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then((token) => {
                      onSuccess(token)
                    })
                })
                .catch((err) => {
                  // console.log(err);
                  enqueueSnackbar(
                    "Sorry we couldn't link your account, please try again later",
                    {
                      variant: 'error'
                    }
                  )
                })
            }}
          >
            Continue
          </Button>
        </FormControl>
      </StandardModal>
      <Dialog
        isOpen={Boolean(openDialog)}
        title="The email is already being used with another account, would you like to link the oauth provider? If yes, please select the same account on next popup as well"
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => {
          const provider = getProviderForProviderId(openDialog)
          firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
              if (result.user.email !== alreadyHasAccount.email) {
                return
              }
              result.user
                .linkWithCredential(alreadyHasAccount.pendingCred)
                .then(() => {
                  firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then((token) => {
                      onSuccess(token)
                    })
                })
                .catch((err) => {
                  // console.log(err);
                  enqueueSnackbar(
                    "Sorry we couldn't link your account, please try again later",
                    {
                      variant: 'error'
                    }
                  )
                })
            })
        }}
      />
      <ForgotPasswordModal
        open={isForgotOpen}
        onClose={() => setIsForgotOpen(false)}
        navigate={(route) => history.push(route)}
      />
    </>
  )
}

LoginModal.defaultProps = {
  showBack: true,
  onBack: () => {}
}

export default LoginModal
