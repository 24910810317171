import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  SxProps,
  Theme,
  CardActionArea,
  Typography
} from '@mui/material'

export interface RightItemCardProps {
  id?: string;
  title: string;
  titleLeftAction: JSX.Element;
  titleRightAction?: JSX.Element;
  onPress?: () => void;
  cardContent?: JSX.Element;
  cardContentStyle?: any;
  style?: any;
  sx?: SxProps<Theme>;
  elevation?: number;
  cardHeaderStyle?: any;
}

const RightItemCard: React.FC<RightItemCardProps> = (props) => {
  const renderCardHeader = () => {
    return (
      <CardHeader
        style={{
          backgroundColor: '#F4EFE7',
          cursor: props.onPress ? 'pointer' : 'default',
          ...props.cardHeaderStyle
        }}
        avatar={props.titleLeftAction}
        action={props.titleRightAction}
        title={
          <Typography variant='h5'>
            {props.title}
          </Typography>
        }
      />
    )
  }

  return (
    <Card
      id={props.id}
      style={{
        borderRadius: 10,
        ...props.style
      }}
      sx={{
        marginTop: 1,
        ...props.sx
      }}
      elevation={props.elevation}
    >
      {props.onPress
        ? (
        <CardActionArea onClick={props.onPress}>
          {renderCardHeader()}
        </CardActionArea>
          )
        : renderCardHeader()}
      {Boolean(props.cardContent) && (
        <CardContent
          style={{
            padding: 8,
            ...props.cardContentStyle
          }}
        >
          {props.cardContent}
        </CardContent>
      )}
    </Card>
  )
}

export default RightItemCard
