// importing action types
import subscriptionReducerActionTypes from '../actions/subscriptionAction'

// store design
const initialState = {
  loaded: false,
  updatedAt: Date.now(),
  subscription: {
    planDetails: {
      becomePopularInNeighborhood: -1,
      watchAllReviews: -1,
      hideVideosFromBusinessPublicPage: 0,
      downloadVideoWithWatermark: -1,
      downloadVideoWithoutWatermark: 0,
      respondToReview: -1,
      createOffer: 0,
      videoStorageLimitation: 0,
      analytics: 1, // 1 -> basic, 2 -> advance, 3 -> advance + extra
      bulkSms: 0,
      bulkEmails: 0,
      organicCustomerReviewsAssured: 0,
      socialMediaMarketing: 0
    },
    planId: null,
    subscribedFor: Date.now(),
    subscribedOn: Date.now(),
    subscriptionEndDate: Date.now(),
    planName: '',
    firstTimeShowed: true,
    id: '',
    isPlanCancelled: false
  }
}

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case subscriptionReducerActionTypes.clearUserSubscription:
      return {
        ...initialState,
        updatedAt: Date.now()
      }
    case subscriptionReducerActionTypes.updateUserSubscription:
      return {
        ...state,
        loaded: true,
        updatedAt: Date.now(),
        subscription: action.payload
      }
    default:
      return state
  }
}

export default subscriptionReducer
