import axios from 'axios'

/**
 * @function businessAvatarPresignGet Get Presign URL for avatar upload
 * @param {string} nrctUID - user's current neighborhood id
 * @param {string} jwt - user's jwt token
 * @returns {Promise} Promise object represents response with data.url being the field of interest
 */

export const businessAvatarPresignGet = (nrctUID, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BUSINESS_URL}/business/avatar/pre-sign`, {
        headers: {
          'NRCT-UID': nrctUID,
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err)
      })
  })
}
