import * as React from 'react'
import {
  FormControl
} from '@mui/material'
import TextArea from 'ui/TextInputs/TextAreaInput'
import StandardModal from 'ui/Modals/StandardModal'
import { useSnackbar } from 'notistack'

// importing components
import { postUpdate } from 'api/post/update'

export interface EditPostCaptionProps {
  postDetails: {
    postId: string;
    postCaption: string;
  },
  userDetails: {
    nrctUID: string;
    jwtToken: string;
  }
  isOpen: boolean;
  onClose: () => void;
}

const EditPostCaption: React.FC<EditPostCaptionProps> = ({
  postDetails,
  userDetails,
  isOpen,
  onClose
}) => {
  const { enqueueSnackbar } = useSnackbar()
  // component states
  const [newCaption, setNewCaption] = React.useState('')
  const [isUpdating, setIsUpdating] = React.useState(false)

  // ActionModal onCancel
  const onCancel = () => {
    // clean up the states
    setNewCaption(postDetails.postCaption)
    // do everything else, then call onClose prop from parent
    onClose()
  }

  // ActionModal onConfirm
  const onConfirm = async () => {
    // perform the update action
    if (isUpdating) {
      return
    }
    setIsUpdating(true)
    try {
      await postUpdate(
        postDetails.postId,
        newCaption,
        userDetails.nrctUID,
        userDetails.jwtToken
      )
      enqueueSnackbar('Caption Updated', {
        variant: 'success'
      })
      onClose()
    } catch (err) {
      console.log(err.message)
      enqueueSnackbar('We could not update your post', {
        variant: 'error'
      })
    } finally {
      setIsUpdating(false)
    }
  }

  React.useEffect(() => {
    setNewCaption(postDetails.postCaption)
  }, [postDetails.postCaption])

  return (
        <StandardModal
          isOpen={isOpen}
          onClose={onCancel}
          title="Edit Post Caption"
          buttons={[
            {
              title: 'Cancel',
              color: 'ghost',
              onPress: onCancel
            },
            {
              title: isUpdating ? 'Updating' : 'Update',
              color: 'primary',
              onPress: onConfirm
            }
          ]}
      >
        <FormControl fullWidth margin="none">
          <TextArea
            rows={4}
            variant="outlined"
            value={newCaption}
            inputProps={{
              maxLength: 256
            }}
            onChange={(e) => {
              if (e.target.value.length > 256) {
                return
              }
              setNewCaption(e.target.value)
            }}
          />
          <span style={{ fontSize: '10px', textAlign: 'right' }}>
            {256 - newCaption.length} characters left
          </span>
        </FormControl>
      </StandardModal>
  )
}

export default EditPostCaption
